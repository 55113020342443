import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {FilterFormatter} from '../../common/filters/filter-formatter';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';
import {GeneralService} from "ac-infra";
import {ServerInfoService} from "../../common/services/server-info.service";
import {CallDurationFilter} from '../../common/filters/calls-other-filter/calls-other-filter.component';


@Injectable({providedIn: 'root'})
export class CallsFilterFormatterService extends FilterFormatter {

    private qualityMappers: any;

    constructor(private networkFilterFormatterService: NetworkFilterFormatterService, private generalService: GeneralService) {
        super({
            setFilter: (resultFilterObject, filterObject) => {
                this.setFilter(resultFilterObject, filterObject);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject, callingApi) => {
                this.addTimeAndDateFilter(filterObject, resultFilterObject, 'callEndTime');
            }
        });

        this.qualityMappers = {
            controlStatus: {failed: 'FAIL', success: 'SUCCESS'},
            qualityCause: {
                none: 'NONE',
                mos: 'MOS_CQ\';\'MOS_LQ',
                jitter: 'JITTER',
                delay: 'DELAY',
                pLoss: 'PACKET_LOSS',
                echo: 'RERL'
            },
            callQuality: {poor: 'POOR', fair: 'FAIR', good: 'GOOD', unknown: 'UNDEFINED'}
        };
    }

    setFilter = (resultFilterObject, filterObject) => {
        if (filterObject?.specificEntity) { // drilldown service obj.auxiliary.filter.specificEntity = selectedItems[0].id; for endpoint
            resultFilterObject.reportingNodeId = resultFilterObject.reportingNodeId || [];
            resultFilterObject.reportingNodeId.push(filterObject.specificEntity?.id);
        }

        this.addTopologyFilter(filterObject, resultFilterObject);
        this.addTopologyOrServiceGroupIdsFilter(filterObject, resultFilterObject);
        this.addLiveCloudTopologyFilter(filterObject, resultFilterObject, 'calls', ['channel', 'lcCustomer', 'service', 'link'], true);
        this.addArrayFilterParameter(filterObject, 'userLocations', resultFilterObject, 'callerLocation', 'adFilters');
        this.addArrayFilterParameter(filterObject, 'userLocations', resultFilterObject, 'calleeLocation', 'adFilters');
        this.addSourceTypeFilter(filterObject, resultFilterObject);
        this.addQualityFilter(filterObject, resultFilterObject);
        this.addMoreFilter(filterObject, resultFilterObject);

        this.addTimeAndDateFilter(filterObject, resultFilterObject, 'callEndTime');

        this.networkFilterFormatterService.setFilter(resultFilterObject, filterObject, 'calls');
    };


    private addTopologyFilter = (filterObject, resultFilterObject) => {
        const topologyFilter = filterObject.Topology;

        if (topologyFilter) {
            this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: topologyFilter.tenant});
            this.assignToFieldIfArray({resultFilterObject, field: 'regionId', array: topologyFilter.region});
            this.assignToFieldIfArray({resultFilterObject, field: 'siteId', array: topologyFilter.site});
            this.assignArrayToFilterById(resultFilterObject, 'linkIds', topologyFilter.link, '~');

            this.addDevicesToFilter(resultFilterObject, topologyFilter.device);
        }
    };

    private addSourceTypeFilter = (filterObject, resultFilterObject) => {
        if (filterObject.sourceType && Object.getOwnPropertyNames(filterObject.sourceType).length > 0) {
            const callSource = [];
            const sourceList: any = {
                teams: ['TEAMS'],
                endpoint: ['IP_PHONE'],
                site: ['SITE'],
                link: ['LINK'],
                userLocation: ['LOCATION']
            };

            if (ServerInfoService.oneLiveMode) {
                sourceList.device = ['AC'];
            } else {
                sourceList.device = ['MS_LYNC', 'AC', 'TEAMS'];
            }

            Object.getOwnPropertyNames(sourceList).forEach((sourceItem) => {
                if (filterObject.sourceType?.types.includes(sourceItem)) {
                    callSource.push.apply(callSource, sourceList[sourceItem]);
                }
            });

            if (callSource.length > 0) {
                resultFilterObject.callSource = callSource;
            }
        }
    };

    private addQualityFilter = (filterObject, resultFilterObject) => {
        const qualityFilter = filterObject.Quality;

        if (qualityFilter) {
            this.assignToFieldIfArray({
                resultFilterObject,
                field: 'controlStatus',
                array: qualityFilter.status,
                mapEnumObject: this.qualityMappers
            });
            this.assignToFieldIfArray({
                resultFilterObject,
                field: 'callQuality',
                array: qualityFilter.quality,
                mapEnumObject: this.qualityMappers
            });
            this.assignToFieldIfArray({
                resultFilterObject,
                field: 'qualityCause',
                array: qualityFilter.cause,
                mapEnumObject: this.qualityMappers
            });
        }
    };

    private addMoreFilter = (filterObject, resultFilterObject) => {
        const moreFilter = filterObject.moreFilters_calls;

        if (!moreFilter) {
            return;
        }

        const callDuration: CallDurationFilter = moreFilter.callDuration; // this assignment for type restriction
        if (callDuration) {
            resultFilterObject.callDuration = callDuration;
        }
        this.assignStringToFilter(resultFilterObject, 'caller', moreFilter.caller);
        this.assignStringToFilter(resultFilterObject, 'callee', moreFilter.callee);

        this.assignStringToFilter(resultFilterObject, 'calleror', moreFilter.callerOrCallee);
        this.assignStringToFilter(resultFilterObject, 'calleeor', moreFilter.callerOrCallee);

        this.assignStringToFilter(resultFilterObject, 'callCorrelationId', moreFilter.callCorrelationId, '=');

        this.assignStringToFilter(resultFilterObject, 'callerCallID', moreFilter.callId, '=');
        this.assignStringToFilter(resultFilterObject, 'calleeCallID', moreFilter.callId, '=');

        this.assignToFieldIfArray({
            resultFilterObject,
            field: 'callerConnectionType',
            array: moreFilter.callerConnectionType,
            mapByProperty: 'none'
        });
        this.assignToFieldIfArray({
            resultFilterObject,
            field: 'calleeConnectionType',
            array: moreFilter.calleeConnectionType,
            mapByProperty: 'none'
        });

        this.assignToFieldIfArray({
            resultFilterObject,
            field: 'mediaType',
            array: moreFilter.mediaType,
            mapByProperty: 'none'
        });
        this.assignToFieldIfArray({
            resultFilterObject,
            field: 'callType',
            array: moreFilter.callType,
            mapByProperty: 'none'
        });

        this.appendToFilter(resultFilterObject, 'terminationReasonDescription', moreFilter.ACTerminationReason);
        this.assignStringToFilter(resultFilterObject, 'terminationReasonTeams', moreFilter.teamsTerminationReason);
        this.appendToFilter(resultFilterObject, 'terminationReasonDescription', moreFilter.LyncTerminationReason);
    };

    private addDevicesToFilter = (resultFilterObject, devices) => {
        if (_.isArray(devices) && devices.length > 0) {
            devices.forEach((device) => {
                if (['MS_LYNC_MEDIATION', 'MS_LYNC_EDGE', 'MS_LYNC_SBA'].indexOf(device.productType) > -1) {
                    resultFilterObject.nodeIds = resultFilterObject.nodeIds || {value: [], operator: '~'};
                    resultFilterObject.nodeIds.value.push(device.id);
                } else {
                    resultFilterObject.reportingNodeId = resultFilterObject.reportingNodeId || [];
                    resultFilterObject.reportingNodeId.push(device.id);
                }
            });
        }
    };
}
