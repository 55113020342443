import {Injectable} from '@angular/core';
import {AcDialogRef, DialogConfig, PromiseService} from 'ac-infra';
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {LicenseRestService} from '../apis/license-rest.service';
import {TemplatesRestService} from '../../../system/configuration/configuration-api/templates-rest.service';
import {NetworkTenantDialogComponent} from '../../dialogs/network-tenant-dialog/network-tenant-dialog.component';
import {RestResponseFailure, RestResponseSuccess} from '../../../common/server-actions/rest';
import {MessagesService} from '../../../common/utilities/messages.service';
import {OperatorsRestService} from '../../../system/administration/administration-api/operators-rest.service';
import {Actions} from '../../../common/server-actions/actions';
import {NetworkOperatorConnectDialogComponent} from '../../dialogs/network-operator-connect-dialog/network-operator-connect-dialog.component';
import {SaveDataToFileService} from '../../../common/utilities/save-data-to-file.service';
import {GroupsActions} from './groups-actions.service';
import {CallsStorageRestService} from '../../../system/configuration/configuration-api/calls-storage-rest.service';
import {CallsStorageActionsService} from '../../../system/configuration/configuration-actions/calls-storage-actions.service';
import {ServerInfoService} from '../../../common/services/server-info.service';
import {MetadataService} from '../../../metadata/metadata.service';

@Injectable({providedIn: 'root'})
export class TenantsActionsService extends Actions {

    ignoreServicesList = ['SMART_TAP', 'LIVEHUB'];
    servicesWithoutMeteringTagList = ['LIVEHUB', 'SMART_TAP'];
    dataObject;

    constructor(public messagesService: MessagesService,
                private tenantsRestService: TenantsRestService,
                private operatorsRestService: OperatorsRestService,
                private licenseRestService: LicenseRestService,
                private saveDataToFileService: SaveDataToFileService,
                private groupsActions: GroupsActions,
                private callsStorageRestService: CallsStorageRestService,
                private callsStorageActionsService: CallsStorageActionsService,
                private templatesRestService: TemplatesRestService) {
        super({entityName: 'tenant', entityService: tenantsRestService, isWsEntity: true});
    }

    addTenant = () => {
        const dialogRef = this.acDialogService.open();
        this.getDialogOptionListsData(false, dialogRef, {});
    };

    editTenant = (tenantId) => {
        const dialogRef = this.acDialogService.open();
        const success = (response: RestResponseSuccess) => {
            this.getDialogOptionListsData(true, dialogRef, response.data);
        };
        this.tenantsRestService.getById({success, extendParameters: true, skipPopulateFilter: true, id: tenantId});
    };

    deleteTenant = (tenants) => {
        const confirmMessage = this.messagesService.getConfirmMessage({
            entityName: 'tenant', entitiesArray: tenants,
            messagePostfix: '<br><br><b class="warning-note">Note: deleting tenant might have an impact on its devices traffic!<b>'
        });


        this.delete({entityArray: tenants, confirmMessage, fakeMultipleDelete: true});

    };

    editOperatorConnect = (spAttachedTenant) => {
        const tenant = {services: {OC: _.cloneDeep(spAttachedTenant.services.OC)}};
        tenant.services.OC.info.appRegistrationClientId = 'fake->only for schema - will not be sent to server';
        tenant.services.OC.info.appRegistrationClientSecret = 'fake->only for schema - will not be sent to server';

        const dialogData = {tenant};

        const serverCallback = (onSuccess, onFailure) => {
            const operatorConnectToServer = {offers: dialogData.tenant.services.OC.info.offers, operatorContacts: dialogData.tenant.services.OC.info.operatorContacts};
            this.tenantsRestService.put(onSuccess, onFailure, operatorConnectToServer, 'topology/operatorConnect/' + spAttachedTenant.id);
        };
        const dialogComponentType = NetworkOperatorConnectDialogComponent;
        const dialogConfig = {
            title: 'Operator Connect Details',
            width: 800,
            height: 720,
            id: 'operator-connect-dialog',
            cancelButtonText: 'Close',
        };

        this.genericAction({serverCallback, dialogData, dialogComponentType, dialogConfig});
    };

    downloadOperatorConnectJSON = (tenant) => {
        this.saveDataToFileService.createJsonDataFile(tenant.generatedJson, 'operator-connect-' + tenant.id);
    };

    addTenantNameToDialogTitle = (tenantObject, title) => {
        const tenant = tenantObject?.id ? this.tenantsRestService.getEntityById(tenantObject.id) : undefined;
        return title + (tenant?.name ? ' (' + tenant.name + ')' : '');
    };

    private getDialogOptionListsData = (isEdit, dialogRef, tenant?) => {
        this.dataObject = {};
        const initPromises = [
            this.getData('license', (onSuccess, onFailure) => {
                this.licenseRestService.getLicense(onSuccess, onFailure);
            }),
            this.getData('operatorsObj', (onSuccess, onFailure) => {
                this.operatorsRestService.getOperators(onSuccess, onFailure);
            })
        ];

        if (isEdit) {
            const licenseAllocationPromise = this.getData('licenseAllocations',
                (onSuccess, onFailure) => {
                    this.licenseRestService.getLicenseAllocationById(tenant.id, {success: onSuccess, failure: onFailure});
                });
            const tenantCallStoragePromise = this.getData('tenantCallStorage',
                (onSuccess, onFailure) => {
                    this.callsStorageRestService.getSingleTenantCallsStorageSettings(onSuccess, onFailure, tenant.id);
                });
            initPromises.push(licenseAllocationPromise, tenantCallStoragePromise);
        } else {
            this.dataObject.tenants = this.tenantsRestService.getAllEntities();
            const httpPromise = this.getData('httpProfile',
                (onSuccess, onFailure) => {
                    this.templatesRestService.getHttpTemplate(onSuccess, onFailure);
                });
            const snmpPromise = this.getData('snmpProfile',
                (onSuccess, onFailure) => {
                    this.templatesRestService.getSnmpTemplate(onSuccess, onFailure);
                });

            const systemCallStoragePromise = this.getData('systemCallStorage',
                (onSuccess, onFailure) => {
                    this.callsStorageRestService.getSystemCallsStorageSettings(onSuccess, onFailure);
                });
            initPromises.push(httpPromise, snmpPromise, systemCallStoragePromise);
        }

        Promise.all(initPromises).then(() => {
            this.openTenantDialog(this.dataObject, isEdit, tenant, dialogRef);
        }).catch(() => dialogRef.close());
    };

    private openTenantDialog = (dataObject, isEdit, tenant, dialogRef: AcDialogRef) => {
        const tenantLicenseAllocation: any = {
            licensePoolFeatures: {
                totalCBAnalogDevices: 0,
                totalCBPbxUsers: 0,
                totalCBUsers: 0,
                totalCBVoicemailAccounts: 0,
                totalDevices: 0,
                totalSBCRegistrations: 0,
                totalSBCSessions: 0,
                totalSBCSignaling: 0,
                totalSBCTranscoding: 0
            },
            voiceQualityFeatures: {
                totalDevices: 0,
                totalEndpoints: 0,
                totalSBCSessions: 0,
                totalUsers: 0,
                totalReports: 0,
                analyticsStatus: 0
            },
            endpointsFeatures: {totalEndpoints: 0}
        };

        if (ServerInfoService.serverInfo.saasMode && !ServerInfoService.oneLiveMode) {
            tenantLicenseAllocation.flexPoolLicenseFeatures = {
                daysTillShutdown: 0, totalDevices: 0, totalSBCRegistrations: 0,
                totalSBCSessions: 0, totalSBCSignaling: 0, totalSBCSipRecStreams: 0, totalSBCTranscoding: 0, totalSBCWebRTCSessions: 0
            };
        }

        dataObject.licenseAllocations = dataObject.licenseAllocations ? dataObject.licenseAllocations : tenantLicenseAllocation;

        if (dataObject.systemCallStorage) {
            const entitiesMap = {
                ...CallsStorageRestService.callsStatusSlidersData,
                ...CallsStorageRestService.statisticsStatusSlidersData
            };

            _.forOwn(entitiesMap, (entity, key) => {
                if (dataObject.systemCallStorage?.[entity.objName]?.maxEntities) {
                    dataObject.systemCallStorage[entity.objName].maxEntities = 0;
                }
            });

            dataObject.tenantCallStorage = cloneDeep(dataObject.systemCallStorage);
            dataObject.tenantCallStorage.tenantId = -1;
        }

        delete dataObject.tenantCallStorage.id;
        delete dataObject.tenantCallStorage.url;
        delete dataObject.tenantCallStorage.callToSipCallConversionRate;
        delete dataObject.tenantCallStorage.callToSipConversionRate;
        delete dataObject.tenantCallStorage.tenantName;

        const tenantToSend = isEdit ? _.cloneDeep(tenant) : this.prepareNewTenantProperties(dataObject);
        if (isEdit) {
            this.cleanEmptyValues(tenantToSend);
        }
        const id = tenantToSend.id;

        const dialogData: any = {
            entity: tenantToSend,
            licenseObj: dataObject.license,
            operators: dataObject.operatorsObj.operators,
            licenseAllocations: dataObject.licenseAllocations,
            tenantCallStorage: dataObject.tenantCallStorage,
            isEdit
        };

        const onSuccessCallback = (response) => {
            if (!isEdit) {
                this.updateLicenseAllocations(id || response.data.id, dataObject.licenseAllocations);
                this.updateTenantCallStorage(id || response.data.id, dataObject.tenantCallStorage);
            }
        };

        dialogData.onSuccessCallback = onSuccessCallback;

        const serverCallback = (onSuccess, onFailure) => {
            this.removeUnnecessaryFields(tenantToSend);
            if (isEdit) {
                this.tenantsRestService.edit(onSuccess, onFailure, tenantToSend, id);
            } else {
                this.tenantsRestService.add(onSuccess, onFailure, tenantToSend);
            }
        };

        this.genericAction({serverCallback, dialogData, dialogRef, dialogComponentType: NetworkTenantDialogComponent});
    };

    private prepareNewTenantProperties = (dataObject) => {
        const tenant: any = {
            snmpProfile: dataObject.snmpProfile,
            uriRegExp: '*',
            httpProfile: dataObject.httpProfile,
            tenantAddressSpace: {subnetMasks: []},
            licensePoolOperatorId: -1
        };

        if (ServerInfoService.oneLiveMode) {
            tenant.appRegistrationSettings = {isEnable: false, urlMode: 'organizations'};
            tenant.contacts = {};
        }

        if (!ServerInfoService.serverInfo.saasMode) {
            tenant.isDefault = dataObject.tenants.length === 0;
        }

        if (ServerInfoService.oneLiveMode) {
            tenant.services = {};
            this.updateEmptyServicesObject(tenant, 'tenant');
        }

        return tenant;
    };

    updateEmptyServicesObject = (entity, type) => {
        const serviceSourceMapper = MetadataService.getType('ServiceSource');

        _.forOwn(serviceSourceMapper, (value, key) => {
            if (!this.ignoreServicesList.includes(key)) {
                entity.services[key] = {};

                if (!this.servicesWithoutMeteringTagList.includes(key)) {
                    entity.services[key].meteringTag = '';
                }

                if (type === 'tenant') {
                    entity.services[key].info = (key === 'OC' || key === 'ZOOM') ? {} : null;
                }
                if (type === 'tenant' || type === 'channel') {
                    entity.services[key].enabled = false;
                }
            }
        });
    };

    private getData = (objectName, callback) => {
        const defer = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            this.dataObject[objectName] = response.data;
            defer.resolve();
        };
        const onFailure = () => {
            defer.reject();
        };

        callback(onSuccess, onFailure);

        return defer.promise;
    };

    updateLicenseAllocations = (tenantId, licenseAllocations) => {
        const defer = PromiseService.defer();
        const success = () => {
            defer.resolve();
        };
        const failure = (error: RestResponseFailure) => {
            defer.reject();
        };
        const requestObject: any = {
            id: tenantId,
            licensePoolFeatures: licenseAllocations.licensePoolFeatures ? licenseAllocations.licensePoolFeatures : {},
            voiceQualityFeatures: licenseAllocations.voiceQualityFeatures ? licenseAllocations.voiceQualityFeatures : {},
            endpointsFeatures: licenseAllocations.endpointsFeatures ? licenseAllocations.endpointsFeatures : {}
        };

        if (ServerInfoService.serverInfo.saasMode && !ServerInfoService.oneLiveMode) {
            requestObject.flexPoolLicenseFeatures = licenseAllocations.flexPoolLicenseFeatures ? licenseAllocations.flexPoolLicenseFeatures : {};
        }

        this.licenseRestService.edit(success, failure, requestObject, tenantId);

        return defer.promise;
    };

    updateTenantCallStorage = (tenantId, tenantCallStorage) => {
        const defer = PromiseService.defer();
        const toServerCallStorage = this.callsStorageActionsService.prepareCallStorageSettingsForServer(tenantCallStorage);

        delete toServerCallStorage.tenantId;
        this.callsStorageRestService.updateCallsStorageTenantsSettings(() => {
            defer.resolve();
        }, () => {
            defer.reject();
        }, toServerCallStorage, tenantId);

        return defer.promise;
    };

    private removeUnnecessaryFields(tenantToSend: any) {
        if (ServerInfoService.oneLiveMode && !ServerInfoService?.serverInfo?.meteringEnabled) {
            _.forOwn(tenantToSend.services, (service) =>{
                delete service?.meteringTag;
            });
        }

        _.forOwn(tenantToSend.services, (service) => {
            if(service && !service.enabled && service.meteringTag){
                service.meteringTag = '';
            }
        });

        if (tenantToSend?.snmpProfile?.snmpV3DefaultProfile?.authProtocol === 'NO_AUTH_PROTOCOL') {
            delete tenantToSend.snmpProfile.snmpV3DefaultProfile.authPassword;
        }
        if (tenantToSend?.snmpProfile?.snmpV3DefaultProfile?.privProtocol === 'NO_PRIV_PROTOCOL') {
            delete tenantToSend.snmpProfile.snmpV3DefaultProfile.privPassword;
        }
    }
}
