import {Injectable} from '@angular/core';
import {Rest, RestResponseFailure, RestResponseSuccess} from '../../../common/server-actions/rest';
import {PromiseService} from "ac-infra";

@Injectable({providedIn: 'root'})
export class SystemAuthenticationRestService extends Rest {


    constructor() {
        super({url: 'security/settings/globalSettings'});
    }

    public getSecuritySettings = (success, failure) => {
        this.get({skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public editSecuritySettings = (success, failure, securitySettings) => {
        this.put(success, failure, securitySettings);
    };

    public getAuthentication = (success, type) => {
        this.get({url: 'security/settings/' + type, skipPopulateFilter: true, build204JSON: false}).then(success);
    };

    public getAuthenticationByType = (type) => {
        const defer = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            defer.resolve(response);
        }

        const onFailure = (error: RestResponseFailure) => {
            defer.reject(error);
        }

        this.get({url: 'security/settings/' + type, skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(onFailure);

        return defer.promise;
    };

    public editAuthentication = (success, failure, authentication, type) => {
        this.put(success, failure, authentication, 'security/settings/' + type);
    };

    public checkConnectivity(object, success, failure, type, id) {
        this.put(success, failure, object, 'security/actions/' + type + '/' + id);
    }
}
