import {Component, Inject} from '@angular/core';
import {DIALOG_CONFIG, DialogConfig, SchemaHelperService} from 'ac-infra';

@Component({
    selector: 'move-device-dialog',
    templateUrl: './move-device-dialog.component.html'
})
export class MoveDeviceDialogComponent {
    formSettings: any = {valid: false, touched: false};
    devices: any[];
    selectedEntities;
    additionalSettings: any = {};
    isPerformedDevice: boolean;
    hasTenantsChanged = false;
    auxModel = {confirmTenantChange: false};

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.devices = this.dialogConfig.dialogData.devices;
        this.isPerformedDevice = this.devices.some((device) => device.sbcInfo && device.sbcInfo.preProvInfo && device.sbcInfo.preProvInfo.state === 'NOT_PERFORMED');

        this.selectedEntities = this.dialogConfig.dialogData.entity;

        this.additionalSettings.tenantId = this.selectedEntities.tenantId;
    }

    tenantChanged = (selectedTenantId) => {
        this.hasTenantsChanged = this.devices.some((device) => device.tenantId !== selectedTenantId);
    };

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        if (this.hasTenantsChanged && !auxModel.confirmTenantChange) {
            errors.push(SchemaHelperService.buildErrorItem({
                inputName: 'auxModel.confirmTenantChange',
                message: 'Parameter should be checked'
            }));
        }
    };
}


