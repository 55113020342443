import {Injectable} from '@angular/core';
import {FilterFormatter} from '../../common/filters/filter-formatter';


@Injectable({providedIn: 'root'})
export class UsersFilterFormatterService extends FilterFormatter{

    constructor() {
        super({
            setFilter: (resultFilterObject, filterObject, callingApi) => {
                this.setFilter(resultFilterObject, filterObject, callingApi);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject) => {}
        });
    }

    setFilter = (resultFilterObject, filterObject, callingApi) => {
        if (['users', 'userExperience', 'userStatistics'].indexOf(callingApi) > -1) {
            this.addArrayFilterParameter(filterObject, 'tenants', resultFilterObject, 'tenantId', 'moreFilters_users');
            this.addArrayFilterParameter(filterObject, 'activeDirectories', resultFilterObject, 'adServerId', 'adFilters');
            this.addArrayFilterParameter(filterObject, 'userLocations', resultFilterObject, 'location', 'adFilters');
            this.addStringFilterParameter(filterObject, 'country', resultFilterObject, 'country', 'moreFilters_users');
            this.addStringFilterParameter(filterObject, 'name', resultFilterObject, 'fullName', 'moreFilters_users');
            this.addStringFilterParameter(filterObject, 'department', resultFilterObject, 'department', 'moreFilters_users');
            this.addStringFilterParameter(filterObject, 'msLyncLineUri', resultFilterObject, 'mslynclineuri', 'moreFilters_users');
        }

        this.addTopologyOrServiceGroupIdsFilter(filterObject, resultFilterObject);
        this.addLiveCloudTopologyFilter(filterObject, resultFilterObject, callingApi, ['channel', 'lcCustomer', 'service', 'link'], true);
        if (['userExperience', 'userStatistics'].indexOf(callingApi) > -1) {
            this.addTimeAndDateFilter(filterObject, resultFilterObject);
        }

        this.addFilterParameterIfNeeded(resultFilterObject, callingApi, 'userExperience', 'callsCount', {
            value: 0,
            operator: '>'
        });
    };

    private addFilterParameterIfNeeded = (resultFilterObject, callingApi, forApi, filterName, filterToAdd) => {
        if (callingApi === forApi) {
            resultFilterObject[filterName] = filterToAdd;
        }
    };
}
