import {Injectable} from '@angular/core';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';

@Injectable({providedIn: 'root'})
export class LCCustomersRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'topology/lcCustomers', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter', entityType: 'lcCustomers'});
    }
}
