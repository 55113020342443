import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MetadataService} from '../../../metadata/metadata.service';

import * as _ from 'lodash';
import {GeneralService} from 'ac-infra';

@Component({
    selector: 'snmp',
    templateUrl: './snmp.component.html',
    styleUrls: ['./snmp.component.less']
})
export class SNMPComponent {
    @Input() snmpObject: any;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();

    @Input() snmpName = '';
    @Input() isEdit: any;
    @Input() snmpV2ProfileName: string;
    @Input() snmpV3ProfileName: string;
    @Input() snmpTrapFieldName: string;
    @Input() componentType: string; // tenant, rule, device,system
    @Input() viewType: string; // separators, radioButtons
    @Input() ngDisabled = false;
    @Input() hasDefaultObject = false;
    @Input() hasCheckboxForV3Edit = false;
    useDefaultSettingsCheckboxTitle;
    useDefaultSNMPSettings = true;

    editSNMPV3Settings = false;
    snmpOriginal: any;

    snmpRadioItems: any = [
        {value: 'SNMP_VERSION_2C', text: 'SNMP v2'},
        {value: 'SNMP_VERSION_3', text: 'SNMP v3'}
    ];

    securityLevelOptionsList: any;
    authenticationProtocolOptionsList: any;
    privacyProtocolOptionsList: any;

    securityLevelList = MetadataService.getType('SecurityLevel');
    usmAuthProtocolList = MetadataService.getType('UsmAuthProtocol');
    usmPrivProtocolList = MetadataService.getType('UsmPrivProtocol');

    securityLevelObject = {
        NO_AUTH_NO_PRIV: {authProtocol: 'NO_AUTH_PROTOCOL', privProtocol: 'NO_PRIV_PROTOCOL'},
        AUTH_NO_PRIV: {authProtocol: 'MD5', privProtocol: 'NO_PRIV_PROTOCOL'},
        AUTH_PRIV: {authProtocol: 'MD5', privProtocol: 'DES'}
    };
    snmpVersion;
    tenantInitialized = false;
    private _selectedTenantFullObject: any;

    @Input() set selectedTenantFullObject(selectedTenantFullObject: any) {
        this._selectedTenantFullObject = selectedTenantFullObject;

        if (this._selectedTenantFullObject && !this.isEdit) {// only for add
            this.snmpOriginal = this.snmpOriginal || _.cloneDeep(this.snmpObject);

            // V2
            const snmpV2Obj = this.snmpV2ProfileName ? this.snmpOriginal[this.snmpV2ProfileName] : this.snmpOriginal;
            snmpV2Obj.snmpReadCommunity = this._selectedTenantFullObject.snmpProfile.snmpV2DefaultProfile.snmpReadCommunity;
            snmpV2Obj.snmpWriteCommunity = this._selectedTenantFullObject.snmpProfile.snmpV2DefaultProfile.snmpWriteCommunity;

            // V3
            this.updateSnmpV3AccordingToSelectedTenant(this._selectedTenantFullObject, this.snmpOriginal);
            this.snmpOriginal.snmpVersion === 'SNMP_VERSION_3' && this.changeAuthPrivProtocol(this.snmpOriginal);
            this.tenantInitialized = true;
        }
    }

    constructor(private generalService: GeneralService,
                private cdRef: ChangeDetectorRef) {
        this.securityLevelOptionsList = [this.securityLevelList.NO_AUTH_NO_PRIV, this.securityLevelList.AUTH_NO_PRIV, this.securityLevelList.AUTH_PRIV];
        this.authenticationProtocolOptionsList = this.generalService.convertObjectToArray(this.usmAuthProtocolList);
        this.privacyProtocolOptionsList = this.generalService.convertObjectToArray(this.usmPrivProtocolList);
    }

    ngOnInit() {
        if(!this.tenantInitialized){
            this.snmpOriginal = _.cloneDeep(this.snmpObject);
        }

        this.snmpVersion = this.snmpOriginal.snmpVersion;

        if(this.isEdit){
            if(this.componentType === 'system' || this.snmpOriginal.snmpVersion === 'SNMP_VERSION_2C'){
                this.editSNMPV3Settings = true;
            }

            if(this.hasCheckboxForV3Edit){
                this.manageSNMP3Object();
            }
        }else{ // checkbox is visible only on add tenant\ac device dialogs
            const defaultObjectType = this.componentType === 'device' ? 'Tenant' : 'System';// snmp default snmp object of device is tenant
            this.useDefaultSettingsCheckboxTitle = 'Use ' + defaultObjectType + ' SNMP Profile';
        }

        this.componentType !== 'system' && this.snmpVersionChanged(this.snmpVersion);

        this.cdRef.detectChanges();
    }

    getSnmpV2Obj = (name, snmpObject) => {
        const snmp = snmpObject && this.snmpV2ProfileName ? snmpObject[this.snmpV2ProfileName] : snmpObject;

        return snmp && snmp[name] !== undefined ? snmp[name] : null;
    };

    setSnmpV2Obj = (name, event, snmpObject) => {
        const pathArr = this.snmpV2ProfileName ? [this.snmpV2ProfileName, name] : [name];
        const snmp = this.generalService.createObjectByPath(snmpObject, pathArr, event);
        this.acChanged.emit(_.cloneDeep(snmp));
    };

    snmpVersionChanged = (newVersion, useDefaultSNMPSettings = false) => {
        if(!useDefaultSNMPSettings){
            if(!this.snmpObject){
                this.snmpObject = {};
            }

            this.snmpObject.snmpVersion = newVersion;
            if (newVersion === 'SNMP_VERSION_3') {
                if (this._selectedTenantFullObject && this._selectedTenantFullObject.snmpProfile) {
                    this.updateSnmpV3AccordingToSelectedTenant(this._selectedTenantFullObject, this.snmpObject);
                } else if(this.snmpOriginal && this.snmpOriginal[this.snmpV3ProfileName]){
                    this.snmpObject[this.snmpV3ProfileName] = _.cloneDeep(this.snmpOriginal[this.snmpV3ProfileName]);
                } else if (this.snmpObject) {
                    this.snmpObject[this.snmpV3ProfileName] = {userSecurityLevel: 'NO_AUTH_NO_PRIV', authProtocol: 'NO_AUTH_PROTOCOL', privProtocol: 'NO_PRIV_PROTOCOL'};
                }
                this.changeAuthPrivProtocol(this.snmpObject);

                this.hasCheckboxForV3Edit && this.manageSNMP3Object();
            } else if (this.snmpObject) {
                delete this.snmpObject[this.snmpV3ProfileName];
            }
        }

        this.useDefaultSNMPSettingsChanged();
    };

    isSecurityLevel = (type) => this.snmpObject && this.snmpObject[this.snmpV3ProfileName]?.userSecurityLevel === type;

    changeAuthPrivProtocol = (snmpObject) => {
        const securityLevel = snmpObject[this.snmpV3ProfileName].userSecurityLevel;

        snmpObject[this.snmpV3ProfileName].authProtocol = snmpObject[this.snmpV3ProfileName].authProtocol || this.securityLevelObject[securityLevel].authProtocol;
        snmpObject[this.snmpV3ProfileName].privProtocol = snmpObject[this.snmpV3ProfileName].privProtocol || this.securityLevelObject[securityLevel].privProtocol;

        if (this.isSecurityLevel('NO_AUTH_NO_PRIV')) {
            delete snmpObject[this.snmpV3ProfileName].authPassword;
            delete snmpObject[this.snmpV3ProfileName].privPassword;
        } else if (this.isSecurityLevel('AUTH_NO_PRIV')) {
            delete snmpObject[this.snmpV3ProfileName].privPassword;
        }
    };

    changAuthPassword = () => {
        delete this.snmpObject[this.snmpV3ProfileName].authPassword;
    };

    changPrivPassword = () => {
        delete this.snmpObject[this.snmpV3ProfileName].privPassword;
    };

    isAuthenticationProtocolSelected = () => this.isSecurityLevel('NO_AUTH_NO_PRIV') || (this.snmpObject && this.snmpObject[this.snmpV3ProfileName]?.authProtocol === 'NO_AUTH_PROTOCOL');

    isPrivacyProtocolSelected = () => this.isSecurityLevel('NO_AUTH_NO_PRIV') || (this.snmpObject && this.snmpObject[this.snmpV3ProfileName]?.privProtocol === 'NO_PRIV_PROTOCOL');

    updateSnmpV3AccordingToSelectedTenant = (selectedTenant, snmpObject) => {
        if (!snmpObject) {
            snmpObject = {[this.snmpV3ProfileName]: {}};
        }

        if (!snmpObject[this.snmpV3ProfileName]) {
            snmpObject[this.snmpV3ProfileName] = {};
        }

        const tempSecurityLevel = this.securityLevelOptionsList.find((securityLevel) => securityLevel.name === selectedTenant.snmpProfile.snmpV3DefaultProfile.userSecurityLevel);
        snmpObject[this.snmpV3ProfileName].userSecurityLevel = tempSecurityLevel.name;

        const tempPrivacyProtocol = this.privacyProtocolOptionsList.find((privProtocol) => privProtocol.name === selectedTenant.snmpProfile.snmpV3DefaultProfile.privProtocol);
        snmpObject[this.snmpV3ProfileName].privProtocol = tempPrivacyProtocol.name;

        const tempAuthProtocol = this.authenticationProtocolOptionsList.find((authProtocol) => authProtocol.name === selectedTenant.snmpProfile.snmpV3DefaultProfile.authProtocol);
        snmpObject[this.snmpV3ProfileName].authProtocol = tempAuthProtocol.name;

        snmpObject[this.snmpV3ProfileName].userSecurityName = selectedTenant.snmpProfile.snmpV3DefaultProfile.userSecurityName;
        snmpObject[this.snmpV3ProfileName].privPassword = selectedTenant.snmpProfile.snmpV3DefaultProfile.privPassword;
        snmpObject[this.snmpV3ProfileName].authPassword = selectedTenant.snmpProfile.snmpV3DefaultProfile.authPassword;
    };

    manageSNMP3Object = () => {
        if (this.isEdit && (this.componentType === 'tenant' || this.snmpVersion === 'SNMP_VERSION_3')) {
            if (this.editSNMPV3Settings) {
                this.snmpObject[this.snmpV3ProfileName] = this.snmpObject[this.snmpV3ProfileName] || _.cloneDeep(this.snmpOriginal[this.snmpV3ProfileName]);
            } else{
                delete this.snmpObject[this.snmpV3ProfileName];
            }
        }
    };

    useDefaultSNMPSettingsChanged = () => {
        if(this.hasDefaultObject && !this.isEdit){
            if (this.useDefaultSNMPSettings) {
                this.acChanged.emit({snmpVersion: this.snmpVersion});
            } else{
                this.acChanged.emit(this.snmpObject);
            }
        }
    };
}



