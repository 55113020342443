import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    id: 'select-lc-customer-dialog',
    submitButtonText: 'Select',
    cancelButtonText: 'Close',
    width: 500,
    overrideSystemViewMode: true
})
@Component({
    selector: 'select-lc-customer-dialog',
    templateUrl: './select-lc-customer-dialog.component.html',
})
export class SelectLCCustomerDialogComponent {

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.dialogConfig.title = this.dialogConfig.dialogData.title || 'Select Customer';
        this.dialogConfig.formSettings = {valid: true, touched: true};
    }
}


