<ac-form *ngIf="dialogConfig.ready"
         [formModel]="region"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [validateAuxiliaryForm]="networkValidationsService.validateNetworkEntityName('region', additionalSettings.name,
         region.tenantId || additionalSettings.tenantId, 'tenantId')"
         formModelName="region">

    <div id="region-details-dialog-content">

        <div class="flexRowParentEqualChildren">
            <ac-input-container formFieldName="region.name" label="Region Name">
                <ac-textfield [(acModel)]="region.name"
                              textFieldId="region-name"
                ></ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="region.description" label="Description">
                <ac-textfield [(acModel)]="region.description"
                              textFieldId="region-description">
                </ac-textfield>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="region"
                                         [additionalSettings]="additionalSettings"
                                         (acTenantChanged)="region.tenantId = $event"
                                         [ngDisabledTenant]="isEdit"
                                         [showRegion]="false"
                                         [horizontalView]="true">
        </network-tenant-region-selection>

        <ac-input-container *ngIf="!serverInfoService.serverInfo.saasMode"
                            formFieldName="region.autoDetectionAddressSpace.subnetMasks" [ignoreRequired]="true" label="Subnet (CIDR Notation)">
            <ac-select [(acModel)]="region.autoDetectionAddressSpace.subnetMasks"
                       [addCustomTags]="true"
                       [bindLabel]="null" [bindValue]="null"
                       selectionName="region.autoDetectionAddressSpace.subnetMasks">
            </ac-select>
        </ac-input-container>
    </div>
</ac-form>
