<label class="ac-checkbox-label" [class.checkbox-sides-margin]="sideMargin" [class.fullLine]="fullLine">
    <mat-checkbox *ngIf="!labelOnly else label"
                  class="ac-checkbox"
                  color="primary"
                  [id]="checkboxId"
                  [attr.value]="model"
                  [labelPosition]="labelPosition"
                  [disabled]="ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
                  [(ngModel)]="model"
                  [(indeterminate)]="indeterminate"
                  (ngModelChange)="checkboxValueChanged($event)">
        <ng-container [ngTemplateOutlet]="label"></ng-container>
        <ng-content></ng-content>
    </mat-checkbox>
</label>

<ng-template #label>
    <span *ngIf="labelText" class="ac-checkbox-bottom-label information-key">{{labelText}}</span>
</ng-template>
