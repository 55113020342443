import {Injectable} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {WSMessage} from "ac-infra";
import {NetworkLiveCloudDataService, TreeEntityType} from "../data/network-live-cloud-data.service";
import {TopologyTreeBase} from "./topology-tree-base";
import {cloneDeep} from "lodash";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class LiveCloudTopologyTreeService extends TopologyTreeBase {
    readonly ENTITY_TYPE_MAP = {
        "tenant": true,
        "channel": true,
        "lcCustomer": true,
        "service": true,
        "siteLocation": true,
        "link": true
    }

    constructor(private networkLiveCloudDataService: NetworkLiveCloudDataService) {
        super();
    }

    onWSEntitiesUpdate({messageType, entityType, entityTypeName, entitiesIds}: WSMessage) {
        entityType = entityType?.toLowerCase();
        entityType = NetworkLiveCloudDataService.WS_ENTITY_TYPE_MAP[entityType] || entityType;

        if (!this.ENTITY_TYPE_MAP[entityType]) {
            return;
        }

        switch (messageType) {
            case "Create": {
                this.createFlatNodes(entitiesIds, entityTypeName);
                break;
            }
            case "Update": {
                this.updateFlatNodes(entitiesIds, entityTypeName);
                break;
            }
            case "Delete": {
                this.deleteFlatNodes(entitiesIds);
                break;
            }
            default : {
                return;
            }
        }
        this.emitTreeNodesUpdate();
    }

    private createFlatArtificial(flatParentNode: any, newNode) {
        const artificialNode = this.networkLiveCloudDataService.getBaseArtificialParent(flatParentNode.originalEntity, newNode);

        artificialNode.haveHybridEntities = artificialNode.haveHybridEntities || this.networkLiveCloudDataService.isEntityHybrid(newNode);

        if (!this.flatTreeMap[artificialNode.id]) {
            this.buildFlatTree(this.flatTreeMap, artificialNode, flatParentNode);
            this.addNodeToFlatParentChildren(flatParentNode, artificialNode);
        }

        return this.flatTreeMap[artificialNode.id];
    }

    private addNodeToFlatParent(flatParent, newNode) {
        const needArtificialParent = !!flatParent && this.networkLiveCloudDataService.needArtificialParent(newNode.entityType, flatParent.originalEntity.entityType);

        flatParent = needArtificialParent ? this.createFlatArtificial(flatParent, newNode) : flatParent;

        this.addNodeToFlatParentChildren(flatParent, newNode);
    }

    private createFlatNodes(entitiesIds: any[], entityTypeName) {
        const newNodes = cloneDeep(this.wsEntitiesService.getEntitiesArray(entityTypeName, entitiesIds));

        newNodes.forEach(newNode => {
            this.buildFlatTree(this.flatTreeMap, newNode);

            const parentId = this.networkLiveCloudDataService.getNativeParentId(newNode);
            const flatParent = this.flatTreeMap[parentId];

            this.addNodeToFlatParent(flatParent, newNode);

            this.updateNodesQueue.push(newNode.tenantId || newNode.id);
        });
    }

    private updateFlatNodes(entitiesIds: any[], entityTypeName: string) {
        const updatedNodes = cloneDeep(this.wsEntitiesService.getEntitiesArray(entityTypeName, entitiesIds));

        updatedNodes.forEach((updatedNode: any) => {
            const oldFlatNode = this.flatTreeMap[updatedNode.id];

            if (!oldFlatNode) {
                return;
            }

            const oldParent = this.flatTreeMap[oldFlatNode.parent?.id]?.originalEntity;

            const newParentId = this.networkLiveCloudDataService.getNativeParentId(updatedNode);

            this.updateOriginalEntity(oldFlatNode, updatedNode);
            if (oldParent && oldParent.id !== newParentId) {
                this.deleteNode(this.flatTreeMap[oldParent.id], updatedNode.id);

                this.addNodeToFlatParent(this.flatTreeMap[newParentId], updatedNode);
            }

            this.updateNodesQueue.push(updatedNode.tenantId || updatedNode.id);
        });
    }

}
