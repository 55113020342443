import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {FilterFormatter} from '../../common/filters/filter-formatter';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';
import {MetadataService} from '../../metadata/metadata.service';


@Injectable({providedIn: 'root'})
export class AlarmsFilterFormatterService extends FilterFormatter {

    AlarmOIDTypeByCategoriesList;
    constructor(private networkFilterFormatterService: NetworkFilterFormatterService) {
        super({
            setFilter: (resultFilterObject, filterObject, callingApi, saveToFile) => {
                this.setFilter(resultFilterObject, filterObject, callingApi, saveToFile);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject, callingApi) => {
                const propertyName = callingApi === 'dynamicTabJournal' ? 'createDateTime' : 'alarmReceivedTime';

                this.addTimeAndDateFilter(filterObject, resultFilterObject, propertyName);
            }
        });
    }

    static addIdFilter(filerObj: any, resultFilterObject: any) {
        if (filerObj && filerObj.specificAlarm) {
            resultFilterObject.id = filerObj.specificAlarm;
        }

        if (filerObj && filerObj.specificUnitIdAlarm) {
            resultFilterObject.unitId = filerObj.specificUnitIdAlarm?.id;
        }
    }

    setFilter = (resultFilterObject, filterObject, callingApi, saveToFile) => {
        this.AlarmOIDTypeByCategoriesList = MetadataService.getType('AlarmOIDTypeByCategories');
        const moreFiltersName = callingApi === 'journal' ? 'moreFilters_journal' : 'moreFilters_alarms';
        this.addTopologyFilter(filterObject, resultFilterObject, saveToFile);
        this.addLiveCloudTopologyFilter(filterObject, resultFilterObject, callingApi, ['channel', 'lcCustomer', 'service', 'link'], false, true);
        this.addTopologyOrServiceGroupIdsFilter(filterObject, resultFilterObject);
        this.addSourceTypeFilter(filterObject, resultFilterObject);

        if(filterObject.unitId){
            resultFilterObject.unitId = filterObject.unitId;
        }

        if (callingApi === 'active' || callingApi === 'history') {
            AlarmsFilterFormatterService.addIdFilter(filterObject, resultFilterObject);
        }

        if (callingApi !== 'active') {
            const propertyName = callingApi === 'journal' ? 'createDateTime' : 'alarmReceivedTime';
            this.addTimeAndDateFilter(filterObject, resultFilterObject, propertyName);
        }

        if (callingApi === 'active' || callingApi === 'history') {
            if (filterObject[moreFiltersName] && filterObject[moreFiltersName].alarmNames && filterObject[moreFiltersName].alarmNames.length > 0) {
                resultFilterObject.name = filterObject[moreFiltersName].alarmNames.map((item) => '\'' + this.AlarmOIDTypeByCategoriesList[item].viewName + '\'');
            }
            this.networkFilterFormatterService.setFilter(resultFilterObject, filterObject, callingApi);
        }

        if (callingApi === 'journal') {
            this.addFreeTextFilter('operator', filterObject[moreFiltersName], resultFilterObject);
            this.addFreeTextFilter('description', filterObject[moreFiltersName], resultFilterObject);

            if (filterObject[moreFiltersName]) {
                this.assignToFieldIfArray({
                    resultFilterObject,
                    field: 'name',
                    array: filterObject[moreFiltersName].journalName,
                    mapByProperty: 'none'
                });
            }
        }

        this.addSource(filterObject[moreFiltersName], resultFilterObject);

        if (['journal', 'fwdRules'].indexOf(callingApi) < 0) {
            this.addState(filterObject[moreFiltersName], resultFilterObject);
            this.addSeverity(filterObject.Severity?.severities, resultFilterObject);
        }
    };

    private addTopologyFilter = (filterObject, resultFilterObject, saveToFile) => {
        const topologyFilter = filterObject.Topology;

        if (topologyFilter) {
            this.assignToFieldIfArray({
                resultFilterObject,
                field: 'tenant',
                array: topologyFilter.tenant,
                saveToFile,
                mapByProperty: saveToFile ? 'name' : 'id'
            });
            this.assignToFieldIfArray({
                resultFilterObject,
                field: 'region',
                array: topologyFilter.region,
                saveToFile,
                mapByProperty: saveToFile ? 'name' : 'id'
            });
            this.assignToFieldIfArray({
                resultFilterObject,
                field: 'site',
                array: topologyFilter.site,
                saveToFile,
                mapByProperty: saveToFile ? 'name' : 'id'
            });

            this.mergeLinksAndDeviceAsUnitId(resultFilterObject, topologyFilter, saveToFile);
        }
    };

    private mergeLinksAndDeviceAsUnitId = (resultFilterObject, topologyFilter, saveToFile) => {
        const devices = topologyFilter.device || [];
        const links = topologyFilter.link || [];
        const endpoints = topologyFilter.endpoint || [];

        if (saveToFile) {
            this.assignToFieldIfArray({resultFilterObject, array: devices, field: 'Devices', mapByProperty: 'name'});
            this.assignToFieldIfArray({resultFilterObject, array: links, field: 'Links', mapByProperty: 'name'});
            this.assignToFieldIfArray({resultFilterObject, array: links, field: 'Endpoints', mapByProperty: 'name'});
        } else {
            const mergedArrays = devices.concat(links).concat(endpoints);
            this.assignToFieldIfArray({resultFilterObject, array: mergedArrays, field: 'unitId'});
        }

    };

    private addSourceTypeFilter = (filterObject, resultFilterObject) => {
        const sourceTypeFilterMap = {
            device: 'Device',
            site: 'Site',
            link: 'Link',
            endpoint: 'Endpoint',
            arm: 'Arm',
            zoom: 'Zoom',
            service: 'Service',
            lcCustomer: 'Customer'
        };

        const sourceTypeFilter = filterObject.sourceType?.types;

        if (sourceTypeFilter) {
            resultFilterObject.unitType = sourceTypeFilter.map(function(mask) {
                return sourceTypeFilterMap[mask];
            });
        }
    };

    private addFreeTextFilter = (fieldName, filerObj, resultFilterObject) => {
        if (filerObj && filerObj[fieldName] && filerObj[fieldName] !== '') {
            resultFilterObject[fieldName] = {operator: ':', value: '\'' + filerObj[fieldName] + '\''};
        }
    };

    private addSource = (filerObj, resultFilterObject) => {
        if (filerObj && filerObj.sources && filerObj.sources.length > 0) {
            resultFilterObject.source = {operator: ':', value:  filerObj.sources.map((item) => '\'' + item + '\'')};
        }
    };

    private addSeverity = (severity, resultFilterObject) => {
        if (_.isArray(severity) && severity.length > 0) {
            resultFilterObject.severity = severity.slice();
        }
    };

    private addState = (filterObj, resultFilterObject) => {
        if (filterObj && _.isString(filterObj.alarmsType)) {
            switch (filterObj.alarmsType) {
                case 'Only Events':
                    resultFilterObject.isEvent = 1;
                    break;
                case 'Only Alarms':
                    resultFilterObject.isEvent = 0;
                    break;
            }
        }
    };
}
