import {ChangeDetectorRef, Directive, Input, TemplateRef} from '@angular/core';

import {AcLayoutSectionChildDirective} from './ac-layout-section-child.directive';


@Directive({
    selector: '[acLayoutPanel]',
    providers: [{provide: AcLayoutSectionChildDirective, useExisting: AcLayoutPanelDirective}]
})
export class AcLayoutPanelDirective extends AcLayoutSectionChildDirective {

    @Input('acLayoutPanel') title = '';
    @Input('acLayoutPanelHeaderTemplate') headerTemplate: TemplateRef<any>;
    @Input('acLayoutPanelIf') if = true;
    @Input('acLayoutPanelElse') else: TemplateRef<any>;
    @Input('acLayoutPanelVisible') visible = true;
    @Input('acLayoutPanelShowHeader') showHeader = true;
    @Input('acLayoutPanelDefaultStyle') defaultStyle = true;
    @Input('acLayoutPanelIcon') iconName = '';
    @Input('acLayoutPanelLoading') loading = false;
    @Input('acLayoutPanelPanelClass') panelClass: any = '';
    @Input('acLayoutPanelPanelActions') panelActions: TemplateRef<any>;
    @Input('acLayoutPanelFooterActions') footerActions: TemplateRef<any>;

    constructor(private cdRef: ChangeDetectorRef, public templateRef: TemplateRef<any>) {
        super();
    }

    setTitle = (title) => {
        this.title = title;
        this.cdRef.detectChanges();
    };
}
