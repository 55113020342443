<div class="calls-storage-grid grid-4">
    <div class="calls-storage-header">Quality</div>
    <div class="calls-storage-header">Save Calls</div>
    <div class="calls-storage-header">Include Call Flow</div>
    <div class="calls-storage-header">Include Call Trend</div>

    <ng-container *ngFor="let key of (tableObj | acFunc: objectKeys)">
        <div>
            <i class="fa fa-circle status-color {{tableObj[key].status}}"></i>
            <span class="statusLabel">{{tableObj[key].title}}</span>

            <span *ngIf="tableObj[key].titleSecondary" class="separator">/</span>

            <i *ngIf="tableObj[key].statusSecondary"
               class="fa fa-circle status-color {{tableObj[key].statusSecondary}}"></i>
            <span *ngIf="tableObj[key].titleSecondary" class="statusLabel">{{tableObj[key].titleSecondary}}</span>
        </div>
        <div class="toggleCell">
            <ac-toggle [(acModel)]="tableObj[key].values.toggle"
                       (acModelChange)="toggleChanged(tableObj[key].values, key)"
                       [toggleId]="key + 'ToggleId'">
            </ac-toggle>
        </div>
        <div class="calls-storage-checkbox-container" *ngIf="key !== 'unknown'">
            <ac-checkbox [(acModel)]="tableObj[key].values.flow"
                         (acModelChange)="checkBoxChanged(tableObj[key].values, key)"
                         [class]="key + 'Flow'"
                         [ngDisabled]="!tableObj[key].values.toggle">
            </ac-checkbox>
        </div>
        <div class="calls-storage-checkbox-container" *ngIf="key !== 'unknown'">
            <ac-checkbox [(acModel)]="tableObj[key].values.trend"
                         (acModelChange)="checkBoxChanged(tableObj[key].values, key)"
                         [class]="key + 'Trend'"
                         [ngDisabled]="!tableObj[key].values.toggle">
            </ac-checkbox>
        </div>
    </ng-container>
</div>
