import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';
import {RequestOptions} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class SoftwareManagerRestService extends Rest {


    constructor() {
        super({url: 'swManager/files'});
    }

    public getFiles = ({success, failure = () => null, ...requestOptions}: RequestOptions) => {
        const onSuccess = (response) => {
            success(this.getData(response));
        };
        this.get({...requestOptions, extendParameters: true, skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(failure);
    };

    public addFile = (model, fileContent, dialogConfig, success, failure) => {
        this.upload({success, failure, model, fileContent, dialogConfig, responseType: 'json'});
    };

    public saveFile = (id, success, failure=()=>{}) => {
        this.get({url: 'swManager/actions/receiveSwFile/' + id, responseType: 'blob', skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public getFileContent = (success, failure, id) => {
        this.getById({url: 'swManager/actions/receiveSwFile', success, failure, id, responseType: 'blob', skipPopulateFilter: true, build204JSON: false, authMandatory: false, getOnlyRequestUrl: true});
    };

    public validateFile = ({success = (...args) => null, failure = (...args) => null, dataObject = undefined} = {}) => {
        this.put(success, failure, dataObject, 'swManager/filesValidation');
    };

    getData = (value) => value.status === 204 ?
        {data: {files: [], pages: {size: 0, total: 0, current: 0, totalElements: 0}}} : value;

}
