import {Injectable} from "@angular/core";
import {CellTemplatesService} from "./cell-templates.service";
import {TenantsRestService} from "../../network/services/apis/tenants-rest.service";
import {RegionsRestService} from "../../network/services/apis/regions-rest.service";
import {StatusToClassPipe} from "../services/status-to-class.pipe";
import {
    AcSvgComponent,
    AcTableCell,
    AcTableColumn,
    AcTableService,
    ColumnProperties,
    GeneralService,
    IPDisplayPipe
} from 'ac-infra';
import {DomSanitizer} from "@angular/platform-browser";
import {ChannelsRestService} from "../../network/services/apis/channels-rest.service";
import {LCCustomersRestService} from "../../network/services/apis/lc-customers-rest.service";
import {DevicesRestService} from '../../network/services/apis/devices-rest.service';
import {ServicesRestService} from "../../network/services/apis/services-rest.service";

@Injectable({providedIn: "root"})
export class CommonColumnTemplatesService {

    static networkCommonTableOptions = {
        initialSorting: [
            {field: "name", dir: "asc"}
        ],
        paging: {
            pageSize: 25,
            pageSizes: [25, 50, 100, 300, 500]
        }
    };

    STATUS_MAP;
    statusColorsList;
    fieldsMap;
    callsQualityMap;

    constructor(private cellTemplatesService: CellTemplatesService,
                private tenantsRestService: TenantsRestService,
                private domSanitizer: DomSanitizer,
                private ipDisplayPipe: IPDisplayPipe,
                private acTableService: AcTableService,
                private regionsRestService: RegionsRestService,
                private devicesRestService: DevicesRestService,
                private channelsRestService: ChannelsRestService,
                private servicesRestService: ServicesRestService,
                private lcCustomersRestService: LCCustomersRestService) {
        this.STATUS_MAP = StatusToClassPipe.STATUS_MAP;
        this.statusColorsList = GeneralService.statusColors;

        this.fieldsMap = {
            failedCalls: {title: "Failed", color: this.statusColorsList.redStatus},
            successCalls: {title: "Successful", color: this.statusColorsList.greenStatus}
        };

        this.callsQualityMap = {
            poorQualityCalls: {title: "Poor", color: this.statusColorsList.redStatus},
            fairQualityCalls: {title: "Fair", color: this.statusColorsList.yellowStatus},
            goodQualityCalls: {title: "Good", color: this.statusColorsList.greenStatus}
        };
    }

    getTenantColumn = (columnProperties: ColumnProperties = {}): AcTableColumn => {
        columnProperties.headerSort = columnProperties.headerSort || false;
        if (!columnProperties.minWidth) {
            columnProperties.width = 140;
        }
        columnProperties.field = columnProperties.field || 'tenantId';
        columnProperties.title = 'Tenant';
        columnProperties.formatter = this.cellTemplatesService.nameTemplateFromService({
            restService: this.tenantsRestService,
            nullValue: columnProperties.nullValue
        });

        return columnProperties as AcTableColumn;
    };

    getRegionColumn = (columnProperties: ColumnProperties = {}) => {
        columnProperties.headerSort = false;
        if (!columnProperties.minWidth) {
            columnProperties.width = 140;
        }
        columnProperties.field = "regionId";
        columnProperties.title = "Region";
        columnProperties.formatter = this.cellTemplatesService.nameTemplateFromService({restService: this.regionsRestService});

        return columnProperties;
    };

    getDeviceColumn = (columnProperties: ColumnProperties = {}): AcTableColumn => {
        if (!columnProperties.minWidth) {
            columnProperties.width = 140;
        }
        columnProperties.field = columnProperties.field || "deviceId";
        columnProperties.title = "device";
        columnProperties.formatter = this.cellTemplatesService.nameTemplateFromService({restService: this.devicesRestService});

        return columnProperties as AcTableColumn;
    };

    getChannelColumn = (columnProperties: ColumnProperties = {}) => {
        columnProperties.headerSort = false;
        if (!columnProperties.minWidth) {
            columnProperties.width = 140;
        }
        columnProperties.field = "channelId";
        columnProperties.title = "Channel";
        columnProperties.formatter = this.cellTemplatesService.nameTemplateFromService({restService: this.channelsRestService});

        return columnProperties;
    };

    getCustomerColumn = (columnProperties: ColumnProperties = {}) => {
        columnProperties.headerSort = false;
        if (!columnProperties.minWidth) {
            columnProperties.width = 140;
        }
        columnProperties.field = "lcCustomerId";
        columnProperties.title = "Customer";
        columnProperties.formatter = this.cellTemplatesService.nameTemplateFromService({restService: this.lcCustomersRestService});

        return columnProperties;
    };

    getServiceColumn = (columnProperties: ColumnProperties = {}) => {
        columnProperties.headerSort = false;
        if (!columnProperties.minWidth) {
            columnProperties.width = 140;
        }
        columnProperties.field = columnProperties.field || "assignedGroups";
        columnProperties.title = "Service";
        columnProperties.formatter = this.cellTemplatesService.nameTemplateFromService({restService: this.servicesRestService});

        return columnProperties;
    };

    getStatusColumn = (columnProperties: ColumnProperties, defaultValue = ''): AcTableColumn => {
        const acTableColumn: AcTableColumn = {
            field: 'status',
            title: 'Status',
            ...columnProperties,
        };
        if (!acTableColumn.formatter) {
            acTableColumn.formatter = ({viewContainerRef, ...cell}: AcTableCell) => {
                const cellValue = cell.getValue() || defaultValue;
                return this.acTableService.createComponentRef(viewContainerRef, AcSvgComponent, {
                    componentInputs: {
                        updatable: false,
                        name: 'circle',
                        height: '10px', width: '10px',
                        title: acTableColumn?.titleFunc?.(cell) || acTableColumn.statusMap?.[cellValue]?.text || acTableColumn.title || '',
                        fillColor: acTableColumn.statusMap?.[cellValue]?.color || GeneralService.statusToColorMap[cellValue],
                    },
                    attributes: {
                        status: cellValue
                    }
                });
            };
        } // acTableColumn.statusMap || CommonColumnTemplatesService.statusMap

        return acTableColumn;
    };

    getCLMStatusColumn = (columnProperties: ColumnProperties) => {
        columnProperties.statusMap = this.STATUS_MAP;
        return this.getStatusColumn(columnProperties);
    };

    getCallsQualityMapColumn = (columnProperties: ColumnProperties) => {
        columnProperties.field = "";
        columnProperties.title = columnProperties.title || "Quality";
        columnProperties.formatter = this.cellTemplatesService.visualBarTemplateFn(this.callsQualityMap);
        return columnProperties;
    };

    getSuccessfulFailedColumn = (columnProperties: ColumnProperties) => {
        columnProperties.field = "";
        columnProperties.title = "Successful/Failed";
        columnProperties.formatter = this.cellTemplatesService.visualBarTemplateFn(this.fieldsMap);
        return columnProperties;
    };

    getDescriptionColumn = (columnProperties: ColumnProperties) => {
        columnProperties.field = "description";
        columnProperties.title = "Description";
        return columnProperties;
    };

    getTotalCallsColumn = (columnProperties: ColumnProperties) => {
        columnProperties.field = "totalCalls";
        columnProperties.title = columnProperties.title || "Calls";
        columnProperties.formatter = this.cellTemplatesService.formattedNumberTemplateFn();
        return columnProperties;
    };

    getIPAddressOrFQDNColumn = (columnProperties: ColumnProperties) => {
        columnProperties.field = columnProperties.field || "ipAddress";
        columnProperties.title = columnProperties.title || "IP Address / FQDN";
        columnProperties.formatter = columnProperties.withFormatter ? this.buildDeviceAddressTplFn(columnProperties.field) : undefined;
        return columnProperties;
    };

    getFQDNColumn = (columnProperties: ColumnProperties) => {
        columnProperties.title = columnProperties.title || "FQDN";
        columnProperties.field = "FQDN";
        columnProperties.formatter = this.buildDeviceFQDNTplFn();
        return columnProperties;
    };

    getIPAddressColumn = (columnProperties: ColumnProperties) => {
        columnProperties.field = columnProperties.field || "ipAddress";
        columnProperties.title = columnProperties.title || "IP Address";
        columnProperties.formatter = this.buildIpAddressTplFn();
        return columnProperties;
    };

    private buildDeviceFQDNTplFn = () => (cell) => {
        const rowData = cell.getRow();
        const deviceProp = rowData.device ? rowData.device : rowData;
        const deviceInfo = deviceProp.sbcInfo || deviceProp.lyncInfo || {};
        return (deviceInfo.deviceFqdn || deviceInfo.FQDN);
    };

    private buildDeviceAddressTplFn = (field = "ipAddress") => (cell) => {
        const rowData = cell.getRow();
        const deviceProp = rowData.device ? rowData.device : rowData;
        const deviceInfo = deviceProp.sbcInfo || deviceProp.lyncInfo || {};
        return (deviceInfo.deviceFqdn || deviceInfo.FQDN) || this.ipDisplayPipe.transform(deviceProp[field]) || "";
    };


    private buildIpAddressTplFn = () => (cell) => {
        return this.ipDisplayPipe.transform(cell.getValue()) || "";
    };
}
