<ac-form *ngIf="dialogConfig.ready && tenantCallStorage"
         [formModel]="tenantCallStorage"
         [formSchema]="{}"
         [formSettings]="formSettings"
         formModelName="tenantCallStorage"
         formName="tenantCallStorage">
    <ac-tabs *ngIf="tenantCallStorage" tabsId="call-storage-dialog-tab-set" id="call-storage-dialog-content">

        <calls-storage-entities *acTab="'Calls Storage Entities';  id:'call-storage-entities-tenant-section-tab'"
                                [tenantCallStorage]="tenantCallStorage">
        </calls-storage-entities>

        <calls-storage-settings  *acTab="'Settings'; id:'settings-section-tab'"
                                [serverCallsStorage]="tenantCallStorage">
        </calls-storage-settings>

        <calls-storage-period *acTab="'Max Storage Period (Days)'; id:'period-section-tab'"
                              [serverCallsStorage]="tenantCallStorage"
                              [(uriEnabled)]="uriEnabled"
                              [(locationEnabled)]="locationEnabled">
        </calls-storage-period>

        <calls-storage-call-flow *acTab="'Max Calls Storage (Long Term)'; id:'long-term-section-tab'"
                                [tenantCallStorage]="tenantCallStorage"
                                [additionalSettings]="additionalSettings">
        </calls-storage-call-flow>
    </ac-tabs>
</ac-form>
