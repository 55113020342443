import {Injectable} from '@angular/core';

import {LoginRestService} from './login-rest.service';
import {GeneralService, PromiseService, SessionStorageService} from 'ac-infra';
import {WebSocketService} from '../../common/services/ws/ws.service';
import {Router} from '@angular/router';
import {SessionRestService} from './session-rest.service';
import {RestResponseSuccess} from '../../common/server-actions/rest';
import {Actions} from '../../common/server-actions/actions';
import {UpdatePasswordDialogComponent} from '../../dialogs/update-password-dialog/update-password-dialog.component';
import {ServerDownDialogComponent} from '../../dialogs/server-down-dialog/server-down-dialog.component';
import {NavigationService} from '../../common/utilities/navigation.service';
import {CachedConnection} from '../../common/services/communication/cached-connection.service';
import {SessionService} from '../../common/services/session.service';
import {ServerInfoService} from '../../common/services/server-info.service';
import {
    ChoosingCustomerOrChannelDialogComponent
} from '../../dialogs/choosing-customer-or-channel-dialog/choosing-customer-or-channel-dialog.component';

export interface LoginCredentials {
    username: string;
    password?: string;
    redirect?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LoginService extends Actions {
    MFAWindow;
    MFAInterval;
    MFASessionId;
    MFAType: string;

    constructor(private loginRestService: LoginRestService,
                private cachedConnection: CachedConnection,
                private sessionService: SessionService,
                private webSocketService: WebSocketService,
                private router: Router,
                private sessionRestService: SessionRestService,
                private navigationService: NavigationService,
    ) {
        super({});
    }

    login = (credentials: LoginCredentials) => {
        const defer = PromiseService.defer();
        const success = (response) => {
            defer.resolve();

            if (response.status === 202 && response.data.operatorAuthenticationMode === 'AAD') { // Azure MFA
                this.handleMFAWindow(response);
            } else {
                if (response.data.passwordExpirationDaysLeft < 0) {
                    this.saveLoggedUserAndStartSession(response.data);
                    return;
                }

                let message = 'Password will expire ';
                message += response.data.passwordExpirationDaysLeft === 0 ? 'today' : 'in ' + response.data.passwordExpirationDaysLeft + ' days';

                this.acDialogService.info(message, {onClose: () => this.saveLoggedUserAndStartSession(response.data)});
            }
        };

        const failure = (error) => {
            defer.reject(error);
            if (error) {
                this.handleErrorStatus(credentials.username, credentials.password, error.status);
            }
        };
        let body;
        if (credentials.redirect && ServerInfoService.serverInfo.whiteLabeling?.id) {
            body = {
                id: ServerInfoService.serverInfo.whiteLabeling?.id,
                type: ServerInfoService.serverInfo.whiteLabeling?.type
            };
        }
        this.loginRestService.login({
            username: credentials.username,
            password: credentials.password,
            body,
            success,
            failure
        });
        return defer.promise;
    };

    logout = () => {
        const successMessage = 'User logout successfully';
        const failureMessage = 'Failed to logout successfully';

        const onSuccess = () => {
            this.navigationService.goTo('/login');
        };
        const onFailure = (error) => {
            this.navigationService.goTo('/login');
        };

        this.webSocketService.disconnect(true, true);
        SessionStorageService.clearAllData();
        this.generalService.resetLoading();
        if (this.sessionService.activeSession) {
            this.loginRestService.logout(onSuccess, onFailure);
            this.sessionService.activeSession = undefined;
        } else {
            onSuccess();
        }
    };

    openMFALoginPage = () => {
        const success = (response: RestResponseSuccess) => {
            this.handleMFAWindow(response);
        };

        let dataObject: any;
        const whiteLabeling = ServerInfoService.serverInfo.whiteLabeling;

        if (whiteLabeling && whiteLabeling.id && whiteLabeling.type) {
            dataObject = {id: whiteLabeling.id, type: whiteLabeling.type};
        }
        this.loginRestService.add(success, () => null, dataObject, 'security/actions/login', undefined, undefined, false);
    };

    handleMFAWindow = (response) => {
        this.MFAType = response.data.operatorAuthenticationMode;
        clearInterval(this.MFAInterval);
        this.router.navigateByUrl('login/mfa');

        this.MFASessionId = response.data.sessionId;
        this.openMFAWindow(response);

        this.MFAInterval = setInterval(() => {
            if (this.MFAWindow.closed) {
                this.closeMFAWindow();
            }
        });
    };

    getSession = (sessionId) => {
        const success = (response: RestResponseSuccess) => {
            if (response?.data?.ocCustomersList?.length > 0 || response?.data?.ovlChannelsList?.length > 0) {
                const type = response?.data?.ocCustomersList?.length > 0 ? 'customers' : 'channels';
                this.handleCustomerOrChannelsLogin(response.data.ocCustomersList || response.data.ovlChannelsList, type);
            } else if (response.data.securityLevel) {
                this.saveLoggedUserAndStartSession(response.data);
            } else {
                this.generalService.loginErrorMessage = 'Login Failed';
                this.logout();
            }
        };

        const failure = () => {
            this.logout();
        };

        this.sessionRestService.getById({
            id: sessionId,
            success,
            failure,
        });
    };

    handleCustomerOrChannelsLogin = (ocList, type) => {
        const dialogData: any = {entities: ocList, type};

        this.acDialogService.open(ChoosingCustomerOrChannelDialogComponent, {
            onSubmit: () => {
                const customerOrChannel = dialogData.entity;

                const onSuccess = (response) => {
                    this.saveLoggedUserAndStartSession(response.data);
                    return;
                };

                this.loginRestService.add(onSuccess, this.logout, customerOrChannel, 'security/actions/oclogin');
            },
            onCancel: () => this.logout(),
            dialogData,
        });
    };

    openMFAWindow = (response) => {
        const location = response.headers.get('Location');
        const left = (screen.width - 500) / 2;
        const top = (screen.height - 500) / 4;
        this.MFAWindow = window.open(location, '',
            'toolbar=no, resizable=no, status=no, menubar=1, scrollbars=no, width=' + 500 + ', height=' + 500 + ', top=' + top + ', left=' + left);
        this.generalService.MFAInProgress = true;
    };

    closeMFAWindow = () => {
        this.MFAWindow.close();
        clearInterval(this.MFAInterval);
        this.generalService.MFAInProgress = false;
        this.sessionService.activeSession = {sessionId: this.MFASessionId};
        this.getSession(this.MFASessionId);
    };

    focusMFAWindow = () => {
        this.MFAWindow.focus();
    };

    ngOnDestroy() {
        clearInterval(this.MFAInterval);
    }

    saveLoggedUserAndStartSession = (userDetails, sessionRestored = false) => {
        this.sessionService.startSession(userDetails);
        if (!sessionRestored) {
            this.router.navigateByUrl('');
        }
    };

    handleErrorStatus = (username, password, status) => {
        if (status === 403) {
            this.updateOperatorPassword(username, password);
        } else if (status === 503) {
            this.acDialogService.open(ServerDownDialogComponent);
        }
    };

    updateOperatorPassword = (operatorName, operatorPassword = '') => {
        const successMessage = 'Password for operator "' + operatorName + '" was updated successfully';
        const failureMessage = 'Failed to update password for operator "' + operatorName + '"';


        const onSuccessCallback = () => this.login({
            username: dialogData.entity.operatorName,
            password: dialogData.entity.newPassword
        });
        const onFailureCallback = (error) => this.acDialogService.fail(error.data.description);

        const dialogData = {
            entity: {operatorName, oldPassword: operatorPassword, newPassword: '', confirmNewPassword: ''},
            dontPerformRefreshOnSuccess: true,
            successMessage,
            failureMessage,
            onSuccessCallback,
            onFailureCallback
        };
        const serverCallback = (onSuccess, onFailure) => this.loginRestService.updateOperatorPassword(dialogData.entity, onSuccess, onFailure);
        this.genericAction({
            dialogComponentType: UpdatePasswordDialogComponent,
            serverCallback,
            dialogData,
        });
    };
}
