import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import * as _ from 'lodash';
import {TenantsActionsService} from "../../services/actions/tenants-actions.service";

@AcDialog({
    id: 'network-tenant-call-storage-dialog',
    cancelButtonText: 'Close',
    width: 900,
    height: 680
})
@Component({
    selector: 'network-tenant-call-storage-dialog',
    templateUrl: './network-tenant-call-storage-dialog.component.html',
    styleUrls: ['./network-tenant-call-storage-dialog.component.less'],
})
export class NetworkTenantCallStorageDialogComponent {

    formSettings: any = {valid: false, touched: false};
    schema = require('../../../../schema/put/callsmonitorsettingsprocessor.json');
    tenantCallStorage: any;
    uriEnabled = false;
    locationEnabled = false;
    additionalSettings;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig, private tenantsActionsService: TenantsActionsService) {
    }

    ngOnInit() {
        this.tenantCallStorage = this.dialogConfig.dialogData.entity;
        this.additionalSettings = _.cloneDeep(this.tenantCallStorage);
        this.uriEnabled = this.dialogConfig.dialogData.uriEnabled;
        this.locationEnabled = this.dialogConfig.dialogData.locationEnabled;
        this.dialogConfig.title = this.tenantsActionsService.addTenantNameToDialogTitle(this.tenantCallStorage, 'Calls Storage Details');
    }
}


