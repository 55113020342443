<div class="trusted-provider-issuers-container">
    <mat-accordion>
        <ac-expansion-panel [classes]="'contact-container operator-connect-expansion-panel ' + (cardHasError ? 'cardHasError' : '')"
                            [expanded]="true"
                            [headerTemplate]="headerTemplate"
                            [descriptionTemplate]="removeButtonTemplate"
                            [descriptionObject]="{index: index, type: 'trustedProviderIssuers'}">

            <ac-input-container [formFieldName]="'tenant.services.OC.info.trustedProviderIssuers.' + index" label="Provider Issuer">
                <ac-textfield [acModel]="parentObject.trustedProviderIssuers[index]"
                              (acModelChange)="parentObject.trustedProviderIssuers[index] = $event"
                              [textFieldId]="'tenant-operatorConnectInfo-trustedProviderIssuers' + index">
                </ac-textfield>
            </ac-input-container>

        </ac-expansion-panel>

        <ng-template #headerTemplate>
            <div class="oc-panel-header">
                {{'Trusted Provider Issuer ' + (index + 1)}}
            </div>
        </ng-template>
    </mat-accordion>
</div>

