<ac-checkbox *ngIf="hasDefaultObject && !isEdit" [(acModel)]="useDefaultHTTPSettings"
             [labelText]="useDefaultSettingsCheckboxTitle"
             (acModelChange)="useDefaultHTTPSettings = $event; useDefaultHTTPSettingsChanged()">
</ac-checkbox>

<ac-checkbox *ngIf="hasEditHttpCheckbox && isEdit" labelText="Edit HTTP Settings" [(acModel)]="editHttpSettings"
             (acModelChange)="editHttpSettings = $event; useDefaultHTTPSettingsChanged()">
</ac-checkbox>

<ng-container *ngIf="((!isEdit && useDefaultHTTPSettings) || (isEdit && !editHttpSettings) ? originalHTTPObject : httpObject) as http">
    <ac-input-container [formFieldName]="httpObjectName + '.gatewayUser'" label="Device Admin User">
        <ac-textfield (acModelChange)="http.gatewayUser = $event"
                      [acModel]="http.gatewayUser"
                      [ngDisabled]="(!isEdit && useDefaultHTTPSettings) || (isEdit && !editHttpSettings)">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container [formFieldName]="httpObjectName + '.gatewayPassword'" editModePrefix="Change" label="Device Admin Password">
        <ac-password [acModel]="http.gatewayPassword"
                     (acModelChange)="httpObject.gatewayPassword = $event"
                     [ngDisabled]="(!isEdit  && useDefaultHTTPSettings) || (isEdit && !editHttpSettings)">
        </ac-password>
    </ac-input-container>

    <ac-input-container [formFieldName]="httpObjectName + '.httpsConnection'" label="Communication Protocol">
        <ac-single-select [acModel]="http.httpsConnection"
                   (acModelChange)="http.httpsConnection = $event; httpsConnectionChanged()"
                   [items]="httpConnectionItems"
                   [ngDisabled]="(!isEdit  && useDefaultHTTPSettings) || (isEdit && !editHttpSettings)">
        </ac-single-select>
    </ac-input-container>

    <ac-checkbox *ngIf="http.verifyCertificate !== undefined && http.httpsConnection === 'ENABLED'"
                 labelText="HTTP device should verify certificate"
                 [acModel]="http.verifyCertificate"
                 (acModelChange)="http.verifyCertificate = $event"
                 [ngDisabled]="(!isEdit  && useDefaultHTTPSettings) || (isEdit && !editHttpSettings)">
    </ac-checkbox>
</ng-container>




