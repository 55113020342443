<div class="operator-connect-webhook-container">
    <mat-accordion>
        <ac-expansion-panel [classes]="'contact-container operator-connect-expansion-panel ' + (cardHasError ? 'cardHasError' : '')"
                            [expanded]="true"
                            [headerTemplate]="headerTemplate"
                            [descriptionTemplate]="removeButtonTemplate"
                            [descriptionObject]="{index: -1, type: 'webhook'}">
            <div class="flexColumnParent">
                <div>
                    <ac-input-container formFieldName="tenant.services.OC.info.webhook.method" label="Method">
                        <ac-single-select [acModel]="parentObject?.webhook?.method"
                                   (acModelChange)="parentObject?.webhook ? parentObject.webhook.method = $event : null"
                                   [items]="HTTPMethodTypeList"
                                   [ngDisabled]="!authorized"
                                   bindLabel="name" bindValue="name">
                        </ac-single-select>
                    </ac-input-container>
                </div>

                <div>
                    <ac-input-container formFieldName="tenant.services.OC.info.webhook.webhookUrl" label="URL">
                        <ac-textfield [acModel]="parentObject?.webhook?.webhookUrl"
                                      (acModelChange)="parentObject?.webhook ? parentObject.webhook.webhookUrl = $event : null"
                                      [ngDisabled]="!authorized"
                                      textFieldId="tenant-operatorConnectInfo-webhook-webhookUrl">
                        </ac-textfield>
                    </ac-input-container>
                </div>
            </div>
        </ac-expansion-panel>

        <ng-template #headerTemplate>
            <div class="oc-panel-header">
                {{'Webhook'}}
            </div>
        </ng-template>
    </mat-accordion>

    <div *ngIf="authorized" class="add-header-button">
        <ac-button (acClick)="addHeader()" [ngDisabled]="parentObject?.webhook === undefined || parentObject?.webhook?.headers.length === 20">Add Header</ac-button>
    </div>

    <ng-container *ngIf="parentObject?.webhook?.headers?.length > 0">
        <div class="contact-container" *ngFor="let header of parentObject.webhook.headers; let index=index">
            <mat-accordion>
                <ac-expansion-panel [classes]="'operator-connect-expansion-panel ' + (headerHasError && headerHasError[index] ? 'cardHasError' : '')"
                                    [expanded]="true"
                                    [headerTemplate]="headerTemplate"
                                    [descriptionTemplate]="descriptionTemplate">
                    <div class="flexRowParentEqualChildren">
                        <ac-input-container [formFieldName]="'tenant.services.OC.info.webhook.headers.' + index + '.name'" label="Header Name">
                            <ac-textfield [(acModel)]="header.name"
                                          [textFieldId]="'tenant-operatorConnectInfo-webhook-headers-name' + index">
                            </ac-textfield>
                        </ac-input-container>

                        <ac-input-container [formFieldName]="'tenant.services.OC.info.webhook.headers.' + index + '.value'" label="Header Value">
                            <ac-textfield [(acModel)]="header.value"
                                          [textFieldId]="'tenant-operatorConnectInfo-webhook-headers-value' + index">
                            </ac-textfield>
                        </ac-input-container>
                    </div>
                </ac-expansion-panel>

                <ng-template #headerTemplate>
                    <div class="oc-panel-header">
                        {{'Header ' + (index + 1)}}
                    </div>
                </ng-template>

                <ng-template #descriptionTemplate>
                    <div *ngIf="authorized" (click)="removeHeader(index);" (mousedown)="$event.stopPropagation()" class="remove-oc-element-button">
                        <i class="fa fa-trash"></i>
                    </div>
                </ng-template>
            </mat-accordion>
        </div>
    </ng-container>
</div>
