import {Injectable, Injector} from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    ActivationEnd,
    Data,
    Event,
    GuardsCheckEnd,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    Route,
    Router,
} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SessionStorageService} from '../../../services/session-storage.service';
import {AcTrackerService} from '../../../services/utilities/ac-tracker.service';

export class NavType {
    routeConfig?: Route;
    data?: Data;
    urlWithParams?: string;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AcNavAutoService {
    static readonly LAST_STATIC_ROUTE = 'lastStaticState';
    static readonly ROUTE_PREFIX = 'lastState_';
    public navs: NavType[] = [];
    changes = 0;
    lastGotoRoute;
    private allActivatedRoutes = [];

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private acTrackerService: AcTrackerService,
                private injector: Injector) {
        router.events
            .pipe(untilDestroyed(this))
            .subscribe((event: Event) => {
                if (event instanceof NavigationStart) {
                    this.allActivatedRoutes = [];
                } else if (event instanceof GuardsCheckEnd) {
                    this.checkIfGuardShouldPassAndFindAlternative(event);
                } else if (event instanceof ActivationEnd) {
                    if (!event.snapshot.routeConfig.loadChildren) {
                        this.allActivatedRoutes.push(event.snapshot);
                    }
                } else if (event instanceof NavigationCancel) {
                    if (this.lastGotoRoute) {
                        this.gotoRoute(this.lastGotoRoute, true);
                    }
                } else if (event instanceof NavigationEnd) {
                    this.lastGotoRoute = undefined;
                    const doChange = this.lastRoute(event);
                    if (doChange === true) {
                        this.acTrackerService.trackEvent('Route Changed', document.title + ' > ' + location.href);
                    }
                    this.createRoutes();
                }
            });
    }

    get lastStaticPage() {
        return SessionStorageService.getData(AcNavAutoService.LAST_STATIC_ROUTE) || '/';
    }

    removeRoute = (startsWith: string) => {
        Object.keys(sessionStorage)
            .filter((key) => key.startsWith(AcNavAutoService.ROUTE_PREFIX + startsWith))
            .forEach(function(key) {
                sessionStorage.removeItem(key);
            });
    };

    gotoLastStaticState(replaceUrl = false) {
        const saved = this.lastStaticPage;
        return this.router.navigateByUrl(saved || '', {replaceUrl});
    }

    tick() {
        this.changes++;
    }

    private createRoutes() {
        if (this.allActivatedRoutes.length === 0) {
            return;
        }

        this.allActivatedRoutes.reverse();
        this.navs = [];

        this.allActivatedRoutes.forEach((snapshot: ActivatedRouteSnapshot, index) => {
            const parentSnapshot = snapshot.parent;
            const isLazyRoute = !!parentSnapshot.routeConfig?.loadChildren;
            this.navs[index] = new NavType();
            this.navs[index].routeConfig = {
                ...snapshot.routeConfig,
                ...isLazyRoute && {path: parentSnapshot.routeConfig.path}
            };
            this.navs[index].data = snapshot.data;
            this.navs[index].urlWithParams = [
                ...(isLazyRoute ? parentSnapshot.url : []).map(x => x.path),
                ...snapshot.url.map(x => x.path),
            ].filter(url => url.length).join('/');
        });

        this.tick();
    }

    private lastRoute(event: NavigationEnd) {
        if (event.url.startsWith('/#')) {
            return this.gotoRoute('');
        }
        let routeConfig;
        let lastRoute;
        if (this.allActivatedRoutes[0].routeConfig.children) {
            lastRoute = false;
            routeConfig = this.allActivatedRoutes[0].routeConfig;
        } else if (this.allActivatedRoutes[0].routeConfig.path === '**') {
            lastRoute = false;
            routeConfig = this.allActivatedRoutes[1].routeConfig;
        } else {
            lastRoute = true;
            const route = this.allActivatedRoutes[0];
            routeConfig = route.routeConfig;
        }

        if (routeConfig.data && routeConfig.data.autoNavNoHistory) {
            return;
        }

        if (event.urlAfterRedirects !== '/' && (!this.allActivatedRoutes[1].routeConfig.data || !this.allActivatedRoutes[1].routeConfig.data.navAutoHidden)) {
            console.warn(event.urlAfterRedirects)
            SessionStorageService.setData(AcNavAutoService.LAST_STATIC_ROUTE, event.urlAfterRedirects);
        }

        if (lastRoute) {
            const all = event.urlAfterRedirects.split('/');
            while (all.length > 0) {
                const current = all.pop();
                SessionStorageService.setData(AcNavAutoService.ROUTE_PREFIX + (all.length === 1 ? '/' : all.join('/')), all.join('/') + '/' + current);
            }
        } else {
            if (Object.keys((this.activatedRoute.queryParams as any).value).length) {
                return this.gotoRoute(event.url.slice(0, event.url.lastIndexOf('?')));
            }

            const defaultRoute: any = this.findDefaultRoute(routeConfig);
            if (!defaultRoute || !this.router.getCurrentNavigation() || this.router.getCurrentNavigation().extras?.skipLocationChange) {
                return;
            }
            let defaultState = defaultRoute.path;
            if (defaultState) {
                const separator = event.urlAfterRedirects.endsWith('/') ? '' : '/';
                defaultState = event.urlAfterRedirects + separator + defaultState;
            }

            const lastState = SessionStorageService.getData(AcNavAutoService.ROUTE_PREFIX + event.urlAfterRedirects);

            let nextPath = lastState ? lastState : defaultState;
            let hasMoreRoutes = true;
            while (hasMoreRoutes) {
                const nextOptionalPath = SessionStorageService.getData(AcNavAutoService.ROUTE_PREFIX + nextPath);
                if (nextOptionalPath) {
                    nextPath = nextOptionalPath;
                } else {
                    hasMoreRoutes = false;
                }
            }

            const circularRequest = nextPath === event.url;
            const circularResolved = nextPath !== event.urlAfterRedirects;
            if (circularResolved && !circularRequest) {
                return this.gotoRoute(nextPath);
            } else if (circularRequest && defaultState) {
                return this.gotoRoute(defaultState);
            }
        }
        return true;
    }

    private gotoRoute(url: any, refresh = false) {
        this.lastGotoRoute = url;
        if (refresh) {
            this.router.onSameUrlNavigation = 'reload';
        }
        this.router.navigateByUrl(url, {replaceUrl: true});
        this.router.onSameUrlNavigation = 'ignore';

    }

    private checkIfGuardShouldPassAndFindAlternative(event: GuardsCheckEnd) {
        if (event.shouldActivate === false) {
            const parent = event.url.split('/').slice(0, -1).join('/') || '/';
            SessionStorageService.removeData('lastState_' + parent);
            this.gotoRoute(parent);
        }
    }

    private findDefaultRoute(routeConfig) {
        return routeConfig.children.find((child) => {
            let foundInvalidGuard = false;
            (child.canActivate || []).forEach(guard => {
                if (!foundInvalidGuard) {
                    const tGuard = this.injector.get(guard);
                    const guardAllowed = !!(tGuard.canActivate(child, this.router.routerState.snapshot, true));
                    if (!guardAllowed) {
                        foundInvalidGuard = true;
                    }
                }
            })
            if (foundInvalidGuard === false) {
                return child;
            }
        })
    }
}
