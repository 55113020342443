import {Component, ContentChild, EventEmitter, Input, Optional, Output, TemplateRef} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {ValidatorsService} from "../../services/utilities/validators.service";
import {AcInputContainerComponent} from "../ac-input-container/ac-input-container.component";

@Component({
    selector: 'ac-radio-buttons-group',
    templateUrl: './ac-radio-buttons-group.component.html',
    styleUrls: ['./ac-radio-buttons-group.component.less'],
})

export class AcRadioButtonsGroupComponent {
    @ContentChild('customTextTemplate') customTextTemplate: TemplateRef<any>;
    @Input() acModel: any;
    @Output() acModelChange = new EventEmitter<any>();
    @Input() items: any;
    @Input() radioButtonId = '';
    @Input() ngDisabled: boolean;

    constructor(@Optional() public acFormComponent: AcFormComponent,
                @Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public validatorsService: ValidatorsService) {
    }

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    modelChanged = (event) => {
        this.acModelChange.emit(event);
    };
}



