<ng-container *ngIf="systemCallStorage">
    <ng-container *ngFor="let entity of entitiesMap | keyvalue : generalService.noCompare">
        <allocation (spinnerChanged)="tenantCallStorage[entity.value.objName].maxEntities = $event;
                                spinnerChanged(entity.value, tenantCallStorage[entity.value.objName].maxEntities)"
                    [allocationBarWithInput]="true"
                    [formFieldName]="'tenantCallStorage.' + entity.value.objName + '.maxEntities'"
                    [barTotal]="systemCallStorage[entity.value.objName].maxEntities"
                    [barUsed]="entity.value | acFunc: getUsedEntitiesValue: tenantCallStorage[entity.value.objName].maxEntities"
                    [containerLabel]="entity.value.title"
                    [hideAllocTitle]="true"
                    [inputMaxValue]="entity.value | acFunc: getMaxEntityValue: tenantCallStorage[entity.value.objName].maxEntities"
                    [inputModel]="tenantCallStorage[entity.value.objName].maxEntities"
                    class="entity-allocation">
        </allocation>
    </ng-container>
</ng-container>

