<div>
<!--    <ac-form-group *ngIf="_type==='LC_CUSTOMER'" titleText="Filter By Field" class="filter-by-field">-->
<!--        <ac-radio-buttons-group [acModel]="lcCustomerFilter" class="filter-radio-buttons"-->
<!--                                (acModelChange)="lcCustomerFilterTypeChanged($event)"-->
<!--                                [items]="lcCustomerFilterTypes">-->
<!--        </ac-radio-buttons-group>-->
<!--    </ac-form-group>-->

    <ac-input-container [formFieldName]="formFieldName"
                        label="{{label | titlecase}}"
                        *ngIf="isVisible"
                        [fullWidthContent]="!!networkGroupsFilterComponent">
        <ac-select *ngIf="isMultiple"
                   [acModel]="model"
                   (acModelChange)="acChanged.emit($event)"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [items]="items"
                   [ngDisabled]="ngDisabled"
                   [sortItems]="sortItems"
                   [searchable]="true">
        </ac-select>

        <ac-single-select #singleSelect *ngIf="!isMultiple" [acModel]="model"
                   (acModelChange)="acChanged.emit($event)"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [items]="items"
                   [ngDisabled]="ngDisabled"
                   [sortItems]="sortItems">
        </ac-single-select>
    </ac-input-container>
</div>
