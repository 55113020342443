import {Component, Inject, ViewChild} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig, GeneralService, SchemaHelperService} from 'ac-infra';
import * as _ from 'lodash';

import {PMProfilesService} from '../../services/PMProfilesService';
import {DevicesRestService} from '../../../network/services/apis/devices-rest.service';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {ServerInfoService} from "../../../common/services/server-info.service";
import {TopologyTreeSelectComponent} from '../../../network/components/topology-tree-select/topology-tree-select.component';

@AcDialog({
    id: 'perf-monitoring-dialog',
    cancelButtonText: 'Close',
    height: 570,
})
@Component({
    selector: 'pm-profiles-dialog',
    templateUrl: './pm-profiles-dialog.component.html',
    styleUrls: ['./pm-profiles-dialog.component.less']
})
export class PmProfilesDialogComponent {
    @ViewChild('topologyTreeSelect', {static: false}) topologyTreeSelect: TopologyTreeSelectComponent;

    pmAddSchema = require('../../../../schema/post/pmprofilesprocessor.json');
    pmEditSchema = require('../../../../schema/put/pmprofilesprocessor.json');

    isAuthorized: any;
    schema: any;
    profile: any;
    validationObj: any;
    isTemplate: boolean;
    isEdit: boolean;
    isExistingDefaultProfile: boolean;
    checkedItemsObject: any;
    profileTopics: any;
    additionalSettings: any;
    serverInfoService = ServerInfoService;
    private useEntityIndex;
    private attachedDefaultDevices;
    private onSubmit: () => void;

    constructor(private pmProfilesService: PMProfilesService,
                private devicesRestService: DevicesRestService,
                private authorizationService: AuthorizationService,
                public generalService: GeneralService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.isTemplate = this.dialogConfig.dialogData.isTemplate;
        this.isAuthorized = this.isTemplate ? this.authorizationService.isSystemAdminOrOperatorLevel() : this.authorizationService.isAdminOrOperatorLevel();
        this.dialogConfig.title = 'Performance Monitoring ' + (this.isTemplate ? 'Template' : 'Profile');
        this.validationObj = {
            devices: (device) => this.pmProfilesService.isDeviceSupportPM(device)
        };


        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.schema = this.isEdit ? _.cloneDeep(this.pmEditSchema) : _.cloneDeep(this.pmAddSchema);
        this.dialogConfig.width = this.isTemplate ? 860 : 1024;

        if (this.isEdit) {
            this.schema.required = this.schema.required || [];
        }
        this.useEntityIndex = this.isTemplate;

        this.dialogConfig.preSubmit = this.preSubmit;
        this.onSubmit = this.dialogConfig.onSubmit;

        this.isExistingDefaultProfile = this.isEdit && this.dialogConfig.dialogData.entity.isDefault;

        this.attachedDefaultDevices = this.dialogConfig.dialogData.attachedDefaultDevices;

        this.initializeData(this.dialogConfig.dialogData.entity);
    }


    topologyTreeOnCheckFn = (topologyTreeSelection) => {
        this.profile.attachedDevices = [];

        this.updateSelectedTopologyDevices(topologyTreeSelection);
    };

    onTenantsSelectionChange = () => {
        if (this.notEmptyArray(this.profile.attachedDevices)) {
            this.profile.attachedDevices = [];
        }
    };

    preSubmit = () => {
        this.profile.profileTopics = _.cloneDeep(this.profileTopics);
    };

    onTopologyDropDownOpen($event) {
        $event &&this.topologyTreeSelect.resizeTree();
    }

    private initializeData = (profile) => {
        this.profile = profile;
        this.additionalSettings = _.cloneDeep(profile);

        if (this.isTemplate) {
            this.profile.tenantId = -1;
        }

        if (!this.isEdit) {
            _.forOwn(this.profile.profileTopics, (topic) => {
                _.forOwn(topic.parameters, (parameter) => {
                    parameter.isPolled = false;
                });
            });
        }

        this.profileTopics = _.cloneDeep(profile.profileTopics);

        this.checkedItemsObject = {
            device: this.validateArray(this.devicesRestService.getAllEntities(this.profile.attachedDevices))
        };
    };

    private updateSelectedTopologyDevices = (topologyArray) => {
        topologyArray.forEach((topologyItem) => {
            if (topologyItem.entity === 'device') {
                this.profile.attachedDevices.push(topologyItem.id);
            }

            if (topologyItem.children) {
                this.updateSelectedTopologyDevices(topologyItem.children);
            }
        });
    };

    private validateArray = (array) => this.notEmptyArray(array) ? array : [];

    private notEmptyArray = (array) => _.isArray(array) && array.length > 0;

}



