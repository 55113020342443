<div>
    <div *ngIf="viewType === 'radioButtons'">
        <ac-radio-buttons-group [(acModel)]="snmpVersion"
                                (acModelChange)="snmpVersionChanged($event)"
                                [items]="snmpRadioItems"
                                [ngDisabled]="ngDisabled"
                                class="device-dialog-snmp-selection" radioButtonId="snmp-version-selection">
        </ac-radio-buttons-group>
    </div>

    <ac-checkbox *ngIf="hasDefaultObject && !isEdit" [(acModel)]="useDefaultSNMPSettings"
                 [labelText]="useDefaultSettingsCheckboxTitle"
                 (acModelChange)="useDefaultSNMPSettings = $event; snmpVersionChanged(snmpVersion, useDefaultSNMPSettings)">
    </ac-checkbox>

    <div *ngIf="viewType === 'separators' || (viewType === 'radioButtons' && (snmpVersion === 'SNMP_VERSION_2C'))"
         id="snmp-v2-container">
        <ac-form-group *ngIf="viewType === 'separators'" class="snmp-version-title" titleText="SNMP V2"></ac-form-group>

        <ng-container *ngIf="(!isEdit && useDefaultSNMPSettings ? snmpOriginal : snmpObject) as snmp">
            <div class="flexRowParentEqualChildren">
                <ac-input-container [formFieldName]="snmpName + (snmpV2ProfileName ? ('.' + snmpV2ProfileName) : '') + '.snmpReadCommunity'"
                        *ngIf="componentType === 'tenant' || componentType === 'device' || componentType === 'system'"
                        editModePrefix="Change"
                        label="SNMP Read Community">
                    <ac-password [acModel]="'snmpReadCommunity' | acFunc: getSnmpV2Obj: snmp"
                                 [ngDisabled]="ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings)"
                                 passwordId="snmp-read"
                                 (acModelChange)="setSnmpV2Obj('snmpReadCommunity', $event, snmp)"
                    ></ac-password>
                </ac-input-container>

                <ac-input-container [formFieldName]="snmpName + (snmpV2ProfileName ? ('.' + snmpV2ProfileName) : '') + '.snmpWriteCommunity'"
                        *ngIf="componentType === 'tenant' || componentType === 'device' || componentType === 'system'"
                        editModePrefix="Change"
                        label="SNMP Write Community">
                    <ac-password [acModel]="'snmpWriteCommunity' | acFunc: getSnmpV2Obj: snmp"
                                 [ngDisabled]="ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings)"
                                 passwordId="snmp-write"
                                 (acModelChange)="setSnmpV2Obj('snmpWriteCommunity', $event, snmp)"
                    ></ac-password>
                </ac-input-container>
            </div>

            <ac-input-container [formFieldName]="snmpName + (snmpV2ProfileName ? ('.' + snmpV2ProfileName) : '') + '.' + snmpTrapFieldName"
                                *ngIf="componentType === 'tenant' || componentType === 'rule' || componentType === 'system'"
                                editModePrefix="Change"
                                label="Trap Community">
                <ac-password [acModel]="snmpTrapFieldName | acFunc: getSnmpV2Obj: snmp"
                             (acModelChange)="setSnmpV2Obj(snmpTrapFieldName, $event, snmp)"
                             [ngDisabled]="ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings)"
                             passwordId="snmp-trap-community">
                </ac-password>
            </ac-input-container>
        </ng-container>
    </div>

    <div *ngIf="viewType === 'separators' || (viewType === 'radioButtons' && (snmpVersion === 'SNMP_VERSION_3'))"
         id="snmp-v3-container">
        <ac-form-group *ngIf="viewType === 'separators'" class="snmp-version-title"
                           titleText="SNMP V3"></ac-form-group>

        <ac-checkbox *ngIf="hasCheckboxForV3Edit && isEdit && snmpOriginal.snmpVersion !== 'SNMP_VERSION_2C'"
                     [(acModel)]="editSNMPV3Settings" labelText="Edit SNMP v3 Settings"
                     (acModelChange)="editSNMPV3Settings = $event; manageSNMP3Object()"
                     [ngDisabled]="ngDisabled || snmpVersion === 'SNMP_VERSION_2C'"
                     checkboxId="snmp-edit-snmpv3-settings">
        </ac-checkbox>

        <ng-container *ngIf="((!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings) ? snmpOriginal : snmpObject) as snmp">
            <div class="flexRowParentEqualChildren">
                <ac-input-container [formFieldName]="snmpName + (snmpV3ProfileName ? '.' : '') + snmpV3ProfileName + '.userSecurityName'"
                                    label="Security Name">
                    <ac-textfield [(acModel)]="snmp && snmp[snmpV3ProfileName] && snmp[snmpV3ProfileName].userSecurityName"
                            [ngDisabled]="ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings)"
                            textFieldId="snmp-security-name">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container [formFieldName]="snmpName + (snmpV3ProfileName ? '.' : '') + snmpV3ProfileName + '.userSecurityLevel'"
                                    label="Security Level">
                    <ac-single-select
                            [acModel]="snmp && snmp[snmpV3ProfileName] && snmp[snmpV3ProfileName].userSecurityLevel"
                            (acModelChange)="snmp[snmpV3ProfileName].userSecurityLevel = $event; changeAuthPrivProtocol(snmp)"
                            [bindLabel]="'viewName'"
                            [bindValue]="'name'"
                            [items]="securityLevelOptionsList"
                            [ngDisabled]="ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings)">
                    </ac-single-select>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container [formFieldName]="snmpName + (snmpV3ProfileName ? '.' : '') + snmpV3ProfileName + '.authProtocol'"
                                    label="Authentication Protocol">
                    <ac-single-select
                            [acModel]="snmp && snmp[snmpV3ProfileName] && snmp[snmpV3ProfileName].authProtocol"
                            (acModelChange)="snmp[snmpV3ProfileName].authProtocol = $event; $event === 'NO_AUTH_PROTOCOL' && changAuthPassword()"
                            [bindLabel]="'viewName'"
                            [bindValue]="'name'"
                            [items]="authenticationProtocolOptionsList"
                            [ngDisabled]="isSecurityLevel('NO_AUTH_NO_PRIV') || ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings)">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container [formFieldName]="snmpName + (snmpV3ProfileName ? '.' : '') + snmpV3ProfileName + '.authPassword'"
                                    editModePrefix="Change" label="Authentication Key">
                    <ac-password
                            [(acModel)]="snmp && snmp[snmpV3ProfileName] && snmp[snmpV3ProfileName].authPassword"
                            [ngDisabled]="isAuthenticationProtocolSelected() || ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings)"
                            passwordId="snmp-authentication-key">
                    </ac-password>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container [formFieldName]="snmpName + (snmpV3ProfileName ? '.' : '') + snmpV3ProfileName + '.privProtocol'" label="Privacy Protocol">
                    <ac-single-select
                            [acModel]="snmp && snmp[snmpV3ProfileName] && snmp[snmpV3ProfileName].privProtocol"
                            (acModelChange)="snmp[snmpV3ProfileName].privProtocol = $event; $event === 'NO_PRIV_PROTOCOL' && changPrivPassword()"
                            [bindLabel]="'viewName'"
                            [bindValue]="'name'"
                            [items]="privacyProtocolOptionsList"
                            [ngDisabled]="!isSecurityLevel('AUTH_PRIV') || ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings)">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container [formFieldName]="snmpName + (snmpV3ProfileName ? '.' : '') + snmpV3ProfileName + '.privPassword'"
                                    editModePrefix="Change" label="Privacy Key">
                    <ac-password
                            [(acModel)]="snmp && snmp[snmpV3ProfileName] && snmp[snmpV3ProfileName].privPassword"
                            [ngDisabled]="isPrivacyProtocolSelected() || ngDisabled || (!isEdit && hasDefaultObject && useDefaultSNMPSettings) || (isEdit && !editSNMPV3Settings)"
                            passwordId="snmp-privacy-key">
                    </ac-password>
                </ac-input-container>
            </div>
        </ng-container>

    </div>
</div>
