<mat-expansion-panel *ngIf="authorized" [(expanded)]="expanded"
                     (closed)="closed.emit($event)"
                     (opened)="opened.emit($event)"
                     [disabled]="disabled"
                     #matExpansionPanel
                     #panel
                     class="{{classes}}">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span *ngIf="header">{{header}}</span>
            <ng-template [ngTemplateOutletContext]="{object: headerObject, expanded, panel}" [ngTemplateOutlet]="headerTemplate"></ng-template>
        </mat-panel-title>

        <mat-panel-description>
            <span *ngIf="description">{{description}}</span>
            <ng-template [ngTemplateOutletContext]="{object: descriptionObject, expanded}" [ngTemplateOutlet]="descriptionTemplate"></ng-template>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-content></ng-content>
</mat-expansion-panel>
