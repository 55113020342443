import {Component, Inject, ViewChild} from '@angular/core';
import {AcDialog, AcDialogService, CommonNotifiersService, DIALOG_CONFIG, DialogConfig, GeneralService, PromiseService, SchemaHelperService} from 'ac-infra';
import * as _ from 'lodash';
import {MetadataService} from '../../../metadata/metadata.service';
import {OperatorsActionsService} from '../../../system/administration/administration-actions/operators-actions.service';
import {AuthGroup} from '../../../common/utilities/session-helper.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {OperatorsRestService} from '../../../system/administration/administration-api/operators-rest.service';
import {TenantsRestService} from '../../services/apis/tenants-rest.service';
import {NetworkValidationsService} from '../../services/network-validations.service';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {GroupsActions} from '../../services/actions/groups-actions.service';
import {ServerInfoService} from "../../../common/services/server-info.service";
import {TenantsActionsService} from "../../services/actions/tenants-actions.service";
import {LiveCloudServicesTabComponent} from "./live-cloud-services-tab/live-cloud-services-tab.component";

@UntilDestroy()
@AcDialog({
    cancelButtonText: 'Close',
    width: 1100,
    height: 850,
    id: 'tenant-dialog',
    title: 'Tenant Details',
})
@Component({
    selector: 'network-tenant-dialog',
    templateUrl: './network-tenant-dialog.component.html',
    styleUrls: ['./network-tenant-dialog.component.less']
})
export class NetworkTenantDialogComponent {

    @ViewChild('liveCloudServicesTab', {static: false}) liveCloudServicesTab: LiveCloudServicesTabComponent;

    schema: any;
    schemaCallStorage: any;
    tenantCallStorageAddSchema = require('../../../../schema/post/callsmonitorsettingsprocessor.json');
    tenantCallStorageEditSchema = require('../../../../schema/put/callsmonitorsettingsprocessor.json');
    tenantAddSchema = require('../../../../schema/post/tenantsprocessor.json');
    tenantEditSchema = require('../../../../schema/put/tenantsprocessor.json');

    tenant: any = {};
    isDefaultOptions = [{value: true, text: 'True'}, {value: false, text: 'False'}];
    tenantOperatorsOption: any;
    generalLicense: any;
    LicenseOrderList;
    isEdit: boolean;

    originalTenantLicenseAllocation;
    originalTenantObject;
    firstOperatorsFetch;

    auxiliaryModel: any = {licenseAllocations: {}, tenantCallStorage: {}};

    allTenantAdminOperators;
    tenantAdminOperatorsIds;
    tenantOperators;
    operators;
    logoObject: any = {title: 'Tenant Logo', files: [], logoId: -1};
    additionalSettings: any;
    newOperatorId;
    oneLiveMode = ServerInfoService.oneLiveMode;
    colors;
    errors;
    serverInfoService = ServerInfoService;
    httpOperatorTitle = 'HTTP Operator';

    constructor(private tenantsRestService: TenantsRestService,
                private operatorsActionsService: OperatorsActionsService,
                private networkValidationsService: NetworkValidationsService,
                private operatorsRestService: OperatorsRestService,
                private tenantsActionsService: TenantsActionsService,
                public generalService: GeneralService,
                private acDialogService: AcDialogService,
                private groupsActions: GroupsActions,
                private authorizationService: AuthorizationService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
        this.colors = GeneralService.statusColors;
    }

    ngOnInit() {
        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.LicenseOrderList = MetadataService.getType('LicenseOrder', true);

        this.dialogConfig.ready = false;
        this.dialogConfig.width = this.oneLiveMode ? 1000 : 800;
        this.schema = this.dialogConfig.dialogData.isEdit ? _.cloneDeep(this.tenantEditSchema) : _.cloneDeep(this.tenantAddSchema);
        this.schemaCallStorage = this.dialogConfig.dialogData.isEdit ? _.cloneDeep(this.tenantCallStorageEditSchema) : _.cloneDeep(this.tenantCallStorageAddSchema);
        this.auxiliaryModel.licenseAllocations = this.dialogConfig.dialogData.licenseAllocations;
        this.auxiliaryModel.tenantCallStorage = this.dialogConfig.dialogData.tenantCallStorage;
        this.operators = this.dialogConfig.dialogData.operators;
        this.deleteUnnecessaryItems();
        this.originalTenantLicenseAllocation = _.cloneDeep(this.auxiliaryModel.licenseAllocations);
        this.originalTenantObject = _.cloneDeep(this.dialogConfig.dialogData.tenant);

        this.firstOperatorsFetch = true;

        this.allTenantAdminOperators = [{id: -1, name: ''}];

        this.tenant = this.dialogConfig.dialogData.entity;

        this.additionalSettings = _.cloneDeep(this.tenant);
        this.tenant.logoId = this.tenant.logoId ? this.tenant.logoId : (ServerInfoService.serverInfo?.ovocSystemFileLogoId || -1);
        this.logoObject.logoId = this.tenant.logoId;

        this.generalLicense = this.dialogConfig.dialogData.licenseObj;
        this.tenant.assignedOperators = this.tenant.assignedOperators || [];

        this.updateOperatorLists(this.operators);
        if(this.isEdit){
            this.dialogConfig.preSubmit = () => this.preSubmit();
        }

        if(this.tenant?.services?.OC?.info && Object.keys(this.tenant?.services?.OC?.info).length === 0){
            delete this.tenant.services.OC.info;
        }

        if(this.tenant?.services?.ZOOM?.info && Object.keys(this.tenant?.services?.ZOOM?.info).length === 0){
            delete this.tenant.services.ZOOM.info;
        }

        this.dialogConfig.ready = true;

        CommonNotifiersService.finishedDataAndFilteredUpdate$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.dialogConfig.ready && this.authorizationService.validFor(AuthGroup.admin)) {
                this.operatorsActionsService.getOperators().then((operators) => {
                    this.updateOperatorLists(operators);
                });
            }
        });
    }

    preSubmit = () => {
        const defer = PromiseService.defer();

        Promise.all([
            this.tenantsActionsService.updateLicenseAllocations(this.additionalSettings.id, this.auxiliaryModel.licenseAllocations),
            this.tenantsActionsService.updateTenantCallStorage(this.additionalSettings.id, this.auxiliaryModel.tenantCallStorage)
        ]).then(() => {
            defer.resolve();
        }).catch((err) => {
            defer.reject();
        });


        return defer.promise;
    };

    addNewOperator = () => {
        this.newOperatorId = undefined;

        const tenantDialogCallback = (response) => {
            this.newOperatorId = response.data.id;
        };
        this.operatorsActionsService.addOperator(false, tenantDialogCallback);
    };

    requiredsAuxiliaryForm = () => {
        const result = [];

        if(this.isEdit && this.additionalSettings.services && this.tenant.services){
            if(!this.additionalSettings.services.OC.enabled && this.tenant.services.OC.enabled){// originalOperatorConnectEnabled- this.additionalSettings.operatorConnectEnabled
                result.push('tenant.services.OC.info.appRegistrationClientSecret');
            }
        }

        return result;
    };

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        this.networkValidationsService.validateNetworkEntityName('tenant', this.additionalSettings.name)(errors, auxModel, formModel);
        this.tenantsRestService.executeValidateAllocAuxiliaryForm({errors, auxModel, formModel});

        if(this.isEdit){
            const snmpV2Profile = this.tenant.snmpProfile?.snmpV2DefaultProfile;
            this.generalService.cleanEmptyValues(snmpV2Profile, true);
            if(snmpV2Profile && Object.getOwnPropertyNames(snmpV2Profile).length === 0){
                delete this.tenant.snmpProfile.snmpV2DefaultProfile;
            }
        }

        if(this.tenant?.services?.OC?.info?.offers) {

            if(this.tenant.services.OC.info.offers.length === 0){
                errors.push(SchemaHelperService.buildErrorItem(
                    {
                        inputName: 'tenant.services.OC.info.offersError',
                        message: 'At least one offer is required'
                    }));
            }
        }

        if(this.tenant.internalMailServerName && !this.tenant.internalMailServer){
            errors.push(SchemaHelperService.buildErrorItem(
                {
                    inputName: 'tenant.internalMailServer',
                    message: 'field is dependent on \'Internal Mail Server Real Name\''
                }));
        }

        if(this.tenant.internalMailServer && !this.tenant.internalMailServerName){
            errors.push(SchemaHelperService.buildErrorItem(
                {
                    inputName: 'tenant.internalMailServerName',
                    message: 'field is dependent on \'Internal Mail Server From Address\''
                }));
        }

        if(this.auxiliaryModel?.tenantCallStorage){
            if(this.auxiliaryModel.tenantCallStorage.sipMessagesStorage.maxEntities > this.auxiliaryModel.tenantCallStorage.callsStorage.maxEntities){
                errors.push(SchemaHelperService.buildErrorItem(
                    {
                        inputName: 'tenantCallStorage.sipMessagesStorage.maxEntities',
                        message: 'field must be lower/equal to Total Calls'
                    }));
            }
        }



        this.errors = errors;

    };

    deleteUnnecessaryItems() {
        delete this.auxiliaryModel.licenseAllocations.licensePoolFeaturesAllocated;
        delete this.auxiliaryModel.licenseAllocations.url;
        delete this.auxiliaryModel.licenseAllocations.id;
    }

    zoomEnabledChanged = () => {
        if(this.tenant.services.ZOOM.enabled){
            this.tenant.services.ZOOM.info = {};
        }else{
            delete this.tenant.services.ZOOM.info;
        }
    };

    operatorConnectEnabledChanged = () => {
        const defaultOperatorConnectObject = {offers: [], operatorContacts: [], trustedProviderIssuers: []};
        if(!this.tenant.services.OC.enabled){
            if(!_.isEqual(defaultOperatorConnectObject, this.tenant.services.OC?.info)){
                const defer = PromiseService.defer();
                this.acDialogService.confirm('Disable operator connect will remove all data in this section, are you sure you want to proceed?', {
                    onSubmit: () => {
                        delete this.tenant.services.OC.info;
                        this.liveCloudServicesTab.operatorConnectMenu.calculateItemsInTreeNode('all');
                        defer.resolve();
                        return true;
                    },
                    onCancel: () => {
                        this.tenant.services.OC.enabled = true;
                        defer.reject();
                    }
                });
            }else{
                delete this.tenant.services.OC.info;
                this.liveCloudServicesTab.operatorConnectMenu.calculateItemsInTreeNode('all');
            }
        }else{
            this.tenant.services.OC.info = defaultOperatorConnectObject;
        }
    };

    private updateOperatorLists = (operators) => {
        this.tenantAdminOperatorsIds = [];
        this.tenantOperators = operators.filter((operator) => !operator.isSystemOperator);

        this.tenantOperatorsOption = this.tenantOperators ? this.tenantOperators.map((item) => ({value: item.id, text: item.name ? item.name : ''})) : [];

        if(this.newOperatorId && this.isEdit) {
            const operatorId = this.newOperatorId;
            this.newOperatorId = undefined;

            const success = (response) => {
                const operator = response.data;
                if(operator.assignedTenants.includes(this.additionalSettings.id)){
                    this.tenant.assignedOperators = [...this.tenant.assignedOperators, operatorId];
                }
            };
            this.operatorsRestService.getOperator(operatorId, success, ()=>{});
        }

        this.tenantAdminOperatorsIds.unshift({id: -1, name: ''});

        _.forOwn(this.tenantOperators, (operator) => {
            ['ADMIN', 'OPERATOR'].includes(operator.securityLevel) && this.tenantAdminOperatorsIds.push(operator);
        });

    };
}



