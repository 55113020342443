import {Injectable} from '@angular/core';

import {BuildFilterService} from './build-filter.service';
import {RestServerUrlService} from './rest-server-url.service';
import {BuildSortService} from './build-sort.service';

import {isNil, isObject} from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class BuildUrlsService {

    constructor(private buildFilterService: BuildFilterService,
                private restServerURLService: RestServerUrlService) {

    }

    public buildURL = (uri, parameters = undefined) => {
        if (uri.startsWith('/')) {
            throw new Error('URI HAS / IN THE BEGINING');
        }

        const url = this.restServerURLService.getRestServerURL() + uri;
        return url + this.addQueryParams(parameters);
    };

    public buildServletURL = (uri = undefined, addSlashToHost = true) => this.restServerURLService.getServletURL(addSlashToHost) + (uri ? uri : '');

    public restructureParamsObject = (parameters) => !this.isEmptyObject(parameters) ?
        this.rebuildParameterObject(parameters) : null;


    /** HELPER FUNCTIONS HERE **/
    public addQueryParams = (parameters) => this.isExists(parameters) ? this.parametersString(parameters) : '';

    private parametersString = (parameters) => {
        const params = [];

        Object.getOwnPropertyNames((parameters || {})).forEach((prop) => {
            if (prop === 'pager') {
                params.push(parameters[prop]);
            } else if(!isNil(parameters[prop])){
                params.push(prop + '=' + parameters[prop]);
            }
        });

        return '?' + params.join('&');
    };

    private rebuildParameterObject = (parameters) => {
        const resultObject = {};

        Object.getOwnPropertyNames((parameters || {})).forEach((prop) => {
            if (parameters[prop] === undefined) {
                return;
            }
            switch (prop) {
                case 'sort'   :
                    this.addSortFieldIfNeeded(resultObject, parameters, prop);
                    break;
                case 'filter' :
                    this.addFilterFieldIfNeeded(resultObject, parameters, prop);
                    break;
                case 'pager'  :
                    resultObject[prop] = this.addPagerFieldIfNeeded(parameters[prop]);
                    break;
                case 'limit'  :
                    this.addLimitFieldIfNeeded(resultObject, parameters, prop);
                    break;
                case 'search' :
                    break;
                default       :
                    resultObject[prop] = parameters[prop];
                    break;
            }
        });

        return resultObject;
    };

    private addSortFieldIfNeeded = (resultObject, parameters, prop) => {
        const createdObject = BuildSortService.buildSortQuery(parameters[prop]);

        if (this.isExists(createdObject)) {
            resultObject[prop] = createdObject;
        }
    };

    private addFilterFieldIfNeeded = (resultObject, parameters, prop) => {
        const createdObject = this.buildFilterService.buildFilterQuery(parameters[prop]);

        if (this.isExists(createdObject)) {
            resultObject[prop] = createdObject;
        }
    };

    private addPagerFieldIfNeeded = ({size: limit, ...args}) => {
        const pagerParams = {limit, ...args};

        return Object.getOwnPropertyNames(pagerParams)
            .map(pagerProp => {
                return pagerProp + '=' + pagerParams[pagerProp];
            }).join('&');
    };

    private addLimitFieldIfNeeded = (resultObject, parameters, prop) => {
        if (!parameters.pager) {
            resultObject[prop] = parameters[prop];
        }
    };


    // Common Functions
    private isEmptyObject = (value) => isObject(value) && JSON.stringify(value) === '{}';

    private isExists = (value) => !isNil(value) && value !== null;
}

