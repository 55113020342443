<div *ngIf="!collapsed; else collapsedLayout" [ngClass]="panelClass" class="layout-panel flexColumnParent">
    <!--conflict test-->
    <ng-container *ngIf="{isHeader: showHeader && (panelTitle || iconName || collapseDir || templates?.panelHeaderTemplate)} as templateVars">
        <div class="flexColumnParent flexItemBiggest">
            <header *ngIf="templateVars.isHeader" class="layout-panel-header flexItem" #header>
                <ac-svg *ngIf="iconName" [name]="iconName" class="layout-panel-icon left"></ac-svg>
                <ng-template *ngIf="templates?.panelHeaderTemplate"
                             [ngTemplateOutlet]="templates?.panelHeaderTemplate"
                             [ngTemplateOutletContext]="{$implicit: panelTitle, postfix: titlePostfix}"
                ></ng-template>
                <div acLabel *ngIf="!templates?.panelHeaderTemplate && panelTitle">{{panelTitle}}{{titlePostfix}}</div>
                <div class="header-content-space"></div>

                <div *ngIf="templates.panelActionsTemplate" class="ac-layout-panel-actions-container">
                    <div class="ac-layout-panel-actions" [attr.childs]="child | acFunc: actionDim: child.childElementCount" #child>
                        <ng-template [ngTemplateOutlet]="templates.panelActionsTemplate"></ng-template>
                    </div>
                </div>

                <ng-template [ngTemplateOutlet]="collapseArrow"></ng-template>
            </header>

            <div class="layout-panel-content {{contentCustomClass}}" >
                <div *ngIf="!templateVars.isHeader" class="ac-layout-panel-actions">
                    <ng-template [ngTemplateOutlet]="templates.panelActionsTemplate"></ng-template>
                    <ng-template [ngTemplateOutlet]="collapseArrow"></ng-template>
                </div>
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </div>
        </div>

        <div *ngIf="templates.footerActionsTemplate" class="layout-panel-footer flexItem" >
            <ng-template [ngTemplateOutlet]="templates.footerActionsTemplate"></ng-template>
        </div>
    </ng-container>

    <ac-progress *ngIf="loading" [center]="true" class="overlay-on-load"></ac-progress>
</div>

<ng-template #collapseArrow>
    <div *ngIf="collapseDir" (click)="onCollapse()" class="layout-panel-icon-container collapse-arrow">
        <ac-svg [mirrorHor]="collapseDir === 'right'" class="layout-panel-icon-toggle-collapse pointer" name="chevron"></ac-svg>
    </div>
</ng-template>

<ng-template #collapsedLayout>
    <div class="layout-panel flexColumnParent">
        <div (click)="onCollapse()" class="flexItem flexRowParent layout-panel-header collapse-arrow">
            <ac-svg [mirrorHor]="collapsed && collapseDir === 'left'"
                    class="layout-panel-icon-toggle-collapse pointer flexItemBiggest"
                    name="chevron"></ac-svg>
            <span class="layout-panel-title" style="width: 0">&nbsp;</span>
        </div>
        <div class="layout-panel-header rotated">
            <span *ngIf="panelTitle && panelTitle !== ' '" class="layout-panel-title">{{panelTitle | titlecase}}{{titlePostfix}}</span>
            <span class="layout-panel-content">
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
