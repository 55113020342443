import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GeneralService} from "../../services/general.service";

@Component({
    selector: 'ac-form-group',
    templateUrl: './ac-form-group.component.html',
    styleUrls: ['./ac-form-group.component.less'],
})

export class AcFormGroupComponent {

    @Input() isClickable = false;
    @Input() titles;
    @Input() titleText;
    @Input() size: 'small' | 'large' = 'small';
    @Input() borderMode = false;
    @Input() contentAsColumn = true;
    @Input() noSideGap = false;
    @Input() subTitleMode = false;
    @Input() titleBackground = true;

    @Output() acClick = new EventEmitter<any>();
    @Output() titleClick = new EventEmitter<any>();

    constructor(public generalService: GeneralService) {
    }
}



