import {ModuleWithProviders, NgModule, Provider} from '@angular/core';

import {DragulaModule} from 'ng2-dragula';
import {NGXS_PLUGINS, NgxsModule} from '@ngxs/store';
import {NgxsStoragePluginModule, StorageOption} from '@ngxs/storage-plugin';
import {clearStatePlugin} from './ngxs/plugins/clear-state.plugin';
import {InfraDirectivesModule} from './directives/infra.directives.module';
import {InfraPipesModule} from './pipes/infra.pipes.module';
import {InfraComponentsModule} from './components/infra-components.module';
import {PrototypesService} from './utils/prototypes.service';
import {AC_LIST_STATE_TOKEN, AcListState} from './components/ac-list/ac-list.state';
import {AcTableState} from './components/ac-table/state/ac-table.state';
import {environment} from './environments/environment';
import {AcTableDataState} from './components/ac-table/state/ac-table-data/ac-table-data.state';
import {AC_PROGRESS_LOADER_PATH, getProgressLoaderPathFactory} from './components/ac-progress/ac-progress.component';
import {AcProgressOptions} from './components/ac-progress/ac-progress.module';
import {LottieCacheModule, LottieModule} from 'ngx-lottie';

import player from 'lottie-web';

import {AC_TABLE_STATE_TOKEN} from './components/ac-table/state/ac-table-state.models';
import {AC_TABLE_CONFIG, AcTableConfig} from './components/ac-table/models/ac-table.interface';

export function playerFactory() {
    return player;
}

export interface InfraOptions {
    acProgressOptions?: AcProgressOptions;
    acTableConfig?: AcTableConfig;
    acTrackerIsActive?: boolean;
}

@NgModule({
    imports: [
        InfraDirectivesModule,
        InfraPipesModule,
        InfraComponentsModule,
    ],
    exports: [InfraDirectivesModule, InfraPipesModule, InfraComponentsModule],
})
export class InfraModule {
    static forRoot(infraOptions: InfraOptions = {}): ModuleWithProviders<ExtendedInfraModule> {
        return {
            ngModule: ExtendedInfraModule,
            providers: [
                PrototypesService,
                {
                    provide: NGXS_PLUGINS,
                    useValue: clearStatePlugin,
                    multi: true
                },
                {
                    provide: AC_PROGRESS_LOADER_PATH,
                    useFactory: getProgressLoaderPathFactory(infraOptions.acProgressOptions?.acProgressLoaderPath)
                },
                {
                    provide: AC_TABLE_CONFIG,
                    useValue: infraOptions.acTableConfig
                },
            ] as Array<Provider>
        } as ModuleWithProviders<ExtendedInfraModule>;
    }
}

export const NgxsModuleForRoot = NgxsModule.forRoot([AcTableState, AcTableDataState, AcListState], {developmentMode: !environment.production});
export const NgxsStoragePluginModuleForRoot = NgxsStoragePluginModule.forRoot({
    key: [
        AC_TABLE_STATE_TOKEN,
        AC_LIST_STATE_TOKEN
    ], storage: StorageOption.SessionStorage
});
export const DragulaModuleForRoot = DragulaModule.forRoot();
export const LottieModuleForRoot = LottieModule.forRoot({player: playerFactory});
export const LottieCacheModuleForRoot = LottieCacheModule.forRoot();


@NgModule({
    imports: [
        NgxsModuleForRoot,
        NgxsStoragePluginModuleForRoot,
        DragulaModuleForRoot,
        LottieModuleForRoot,
        LottieCacheModuleForRoot,
        InfraModule
    ],
    exports: [InfraModule],
})
export class ExtendedInfraModule {
}

