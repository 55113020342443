<div class="labelHeader" *ngIf="label">{{label}}</div>
<div #sliderContainer (click)="onClick()"
     (keydown)='onKeydown($event)'
     [class.hideSliderPointer]="hideSliderPointer"
     [class.hideValueBubble]="hideValueBubble"
     [class.disabled]="ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
     class="slider-container">
    <ng5-slider *ngIf="showSlider"
                [(value)]="acModel"
                (valueChange)="onChange()"
                [options]="_options">
    </ng5-slider>
</div>
