<div [class.fullScreen]="isFullScreen" [ngStyle]="{'height': height + 'px'}" class="chartContainer">
    <div *ngIf="hasTitle" class="chartTitleContainer flexRowParent">
        <div acLabel [class.large-title]="largeTitle" class="chartTitleText flexItemBiggest">{{chartTitle}}</div>
        <span (click)="handleChartResize()" class="chartTitleActions flexItem" *ngIf="!_disableFullScreen">
            <i *ngIf="!isFullScreen" aria-hidden="true" class="fa fa-expand"></i>
            <i *ngIf="isFullScreen" aria-hidden="true" class="fa fa-compress"></i>
        </span>
    </div>

    <div #contentWrapper [attr.id]="chartId" class="chartPlaceHolder"></div>

    <ac-progress *ngIf="!chart" size="small"></ac-progress>
</div>
