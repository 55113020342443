<div (click)="fileInput.click()" class="ac-file-picker-container">
    <ac-svg *ngIf="onlyIcon" [fillColor]="ngDisabled ? ngDisabledColor : 'black'" name="export" class="picker-icon-only" [class.disabled]="ngDisabled"></ac-svg>

    <div *ngIf="!onlyIcon" class="flexRowParent ac-file-picker">
        <ac-textfield *ngIf="displayTextBox" [class.hasValue]="clearable && fileName"
                      [(acModel)]="fileName"
                      [readOnly]="isReadOnly"
                      [ngDisabled]="ngDisabled"
                      class="file-name">
            <a *ngIf="clearable" (click)="$event.stopPropagation();$event.preventDefault(); clearContent($event);"
               class="flexItem clear-search-button">
                <i class="fa fa-times"></i>
            </a>
        </ac-textfield>

        <ng-container *ngIf="!acInputContainerComponent">
            <ng-container *ngTemplateOutlet="filePickerButton" ></ng-container>
        </ng-container>
    </div>

    <input #fileInput (change)="fileChange($event)" [attr.disabled]="ngDisabled ? ngDisabled : null"
           [attr.multiple]="multiple?true:null"
           accept="{{fileTypesString}}"
           hidden
           id="fileInputField"
           type="file">
</div>

<ng-template #filePickerButton>
    <ac-button [buttonId]="buttonId" (click)="acInputContainerComponent && fileInput.click()" [ngDisabled]="ngDisabled" class="load-button flexItem">
        <span *ngIf="filePickerButtonText">{{filePickerButtonText}}</span>
    </ac-button>
</ng-template>
