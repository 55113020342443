import {Injectable} from '@angular/core';
import {GroupsActions} from './actions/groups-actions.service';
import {GroupsRestService} from './apis/groups-rest.service';
import {AcDialogService, AcDropdownChild, GeneralService} from 'ac-infra';
import {TenantsRestService} from './apis/tenants-rest.service';
import {ChannelsRestService} from './apis/channels-rest.service';
import {AuthorizationService} from '../../common/services/authorization.service';
import {DevicesActionsService} from './actions/devices-actions.service';
import {ServerInfoService} from '../../common/services/server-info.service';
import {LCCustomersRestService} from './apis/lc-customers-rest.service';
import {cloneDeep} from 'lodash';
import {SelectTenantDialogComponent} from "../dialogs/select-tenant-dialog/select-tenant-dialog.component";
import {TenantsActionsService} from "./actions/tenants-actions.service";
import {ConstantStringsService} from "../../common/utilities/constant-strings.service";

@Injectable({providedIn: 'root'})
export class ServiceActionsMenuService {

    isSystemAdminOrOperator: Function;
    isServiceProvider: Function;
    isCustomerOperator: Function;
    isMonitor: Function;
    isChannelOrCustomerOperator: Function;
    editButtonText;
    editText;
    actionForLiveTeamCloudServiceOnly = false;
    actionsForSingleSelection = false;
    oneIsSmartTap = false;
    selection;
    lcCustomers;
    notEssentialAndNotOCBasic;
    isDeployStatusAllowed;
    changeCustomerTitle = 'Change Customer';
    oneSelectedNotMonitorNotChannelNotCustomerOperators;

    constructor(private groupsActions: GroupsActions,
                private groupsRestService: GroupsRestService,
                private tenantsRestService: TenantsRestService,
                private channelsRestService: ChannelsRestService,
                private lcCustomersRestService: LCCustomersRestService,
                private devicesActionsService: DevicesActionsService,
                private generalService: GeneralService,
                private acDialogService: AcDialogService,
                private tenantsActionsService: TenantsActionsService,
                private authorizationService: AuthorizationService) {
        this.updatePermissions();
    }

    updatePermissions = () => {
        this.isSystemAdminOrOperator = this.authorizationService.isSystemAdminOrOperatorLevel;
        this.isServiceProvider = this.authorizationService.validForServiceProvider;
        this.isCustomerOperator = this.authorizationService.validForCustomerOperator;
        this.isMonitor = this.authorizationService.isMonitor;
        this.isChannelOrCustomerOperator = () => {
            return this.authorizationService.validForChannel() || this.authorizationService.validForCustomerOperator();
        };
        this.oneSelectedNotMonitorNotChannelNotCustomerOperators = this.selection?.length === 1 && !this.isMonitor() && !this.isChannelOrCustomerOperator();
        this.editButtonText = (this.generalService.isViewMode ? 'View' : 'Edit') + ' Service';
        this.editText = (this.generalService.isViewMode ? 'View' : 'Edit') + ' Direct Routing Service';
    };

    updateServiceSelection = (selection) => {
        this.selection = selection;
        const deployStatus = this.selection?.length === 1 && this.selection[0]?.applicationsStatus?.management?.serviceDeployStatus;
        this.isDeployStatusAllowed = deployStatus && !['INPROGRESS_RESOURCE_ALLOCATION', 'INPROGRESS_ACCOUNT_CREATION', 'INPROGRESS_AWAIT_CONCENT', 'DELETING'].includes(deployStatus);
    };

    getServiceItems = (type): AcDropdownChild[] => {
        return [
            {
                displayName: this.editButtonText,
                enabled: !this.generalService.isViewMode && !this.isCustomerOperator() && !this.isMonitor() && this.selection?.length === 1 && this.selection[0].serviceSource === type && this.isDeployStatusAllowed,
                onClick: (lcCustomer) => this.groupsActions.serviceAction({lcCustomer, service: this.selection[0] , page: this.generalService.isViewMode ? 'overview' : 'edit', type})
            },
            {
                displayName: 'View Service',
                enabled: !this.isCustomerOperator() && this.selection?.length === 1 && this.selection[0].serviceSource === type,
                onClick: (lcCustomer) => this.groupsActions.serviceAction({lcCustomer, service: this.selection[0] , page: 'overview', type})
            },
            {
                displayName: 'Open Management Portal',
                enabled: !this.isCustomerOperator() && (!this.selection || this.selection.length === 0 || this.selection?.length === 1 && this.selection[0].serviceSource === type),
                onClick: (lcCustomer) => this.groupsActions.serviceAction({lcCustomer, service: this.selection[0] , page: 'management', type})
            },
            {
                displayName: 'Open Service Portal',
                enabled: this.selection?.length === 1 && this.selection[0].serviceSource === type && this.isDeployStatusAllowed,
                onClick: (lcCustomer) => this.groupsActions.serviceAction({lcCustomer, service: this.selection[0] , page: 'customer', type})
            },
            {
                displayName: this.changeCustomerTitle,
                enabled: this.oneSelectedNotMonitorNotChannelNotCustomerOperators,
                onClick: () => this.changeCustomer(this.selection[0], type),
                skipPreClick: true
            }
        ];
    };

    getDirectRoutingItems = (actionsForSingleSelection): AcDropdownChild[] => {
        this.oneIsSmartTap = this.selection?.some((service) => service.serviceSource === 'SMART_TAP');
        this.actionsForSingleSelection = actionsForSingleSelection;

        this.actionForLiveTeamCloudServiceOnly = this.actionsForSingleSelection ? (this.selection[0].licenseType !== 'OC_BASIC' && this.selection[0].licenseType !== 'ZOOM') : false;
        this.notEssentialAndNotOCBasic = this.actionsForSingleSelection ? this.selection[0].licenseType !== 'OC_BASIC' && this.selection[0].licenseType !== 'ESSENTIAL' : false;
        return [
            {
                displayName: 'Open UMP Page',
                enabled: this.isServiceProvider(),
                onClick: (lcCustomer) => this.openUMPPage(lcCustomer)
            },
            {
                displayName: this.editButtonText,
                enabled: !this.oneIsSmartTap && this.actionsForSingleSelection && this.selection[0].serviceSource === 'LTC' && this.actionForLiveTeamCloudServiceOnly,
                onClick: (lcCustomer) => this.groupsActions.editService(lcCustomer, undefined, this.selection[0])
            },
            {
                displayName: this.editButtonText + ' DIDs',
                enabled: !this.oneIsSmartTap && this.isCustomerOperator() && this.actionsForSingleSelection && this.actionForLiveTeamCloudServiceOnly,
                onClick: (lcCustomer) => this.groupsActions.editService(lcCustomer, 'did', this.selection[0])
            },
            {
                displayName: 'Backup Service',
                enabled: !this.oneIsSmartTap && !this.isMonitor() && this.isSystemAdminOrOperator() && this.actionsForSingleSelection && this.selection?.[0].serviceSource === 'LTC' && this.notEssentialAndNotOCBasic,
                onClick: () => this.backupServices(),
                skipPreClick: true
            },
            {
                displayName: 'Restore Service',
                enabled: !this.oneIsSmartTap && !this.isMonitor() && this.isSystemAdminOrOperator() && this.actionsForSingleSelection && this.selection?.[0].serviceSource === 'LTC' && this.notEssentialAndNotOCBasic,
                onClick: () => this.restoreServices(),
                skipPreClick: true
            },
            {
                displayName: 'Delete Teams',
                enabled: !this.oneIsSmartTap && !this.isCustomerOperator() && !this.isMonitor() && this.actionsForSingleSelection && this.selection[0].serviceSource === 'LTC' && this.actionForLiveTeamCloudServiceOnly,
                onClick: () => this.deleteTeamsSide(),
                skipPreClick: true
            },
            {
                displayName: 'Delete ' + ConstantStringsService.linkOrProviderSide,
                enabled: !this.oneIsSmartTap && !this.isCustomerOperator() && !this.isMonitor() && this.actionsForSingleSelection && this.selection[0].serviceSource === 'LTC' && this.actionForLiveTeamCloudServiceOnly,
                onClick: () => this.deleteServiceProvider(),
                skipPreClick: true
            },
            {
                displayName: this.changeCustomerTitle,
                enabled: this.oneSelectedNotMonitorNotChannelNotCustomerOperators,
                onClick: () => this.changeCustomer(this.selection[0], 'LTC'),
                skipPreClick: true
            }
        ];
    };

    getDeviceManagerItems = (actionsForSingleSelection): AcDropdownChild[] => {
        this.actionsForSingleSelection = actionsForSingleSelection;
        return [
            {// shay requested to change name from configure endpoints to Open Management Page and to be first.... gal approved
                displayName: 'Open Management Page',
                enabled: true,
                onClick: () => this.groupsActions.openGeneralDeviceManagerPage(this.selection?.length === 1 && this.selection[0].serviceSource === 'DM' && this.selection[0])
            },
            {
                displayName: 'Edit Service',
                enabled: !this.isMonitor() && !this.isCustomerOperator() && this.selection?.length === 1 && this.selection[0].serviceSource === 'DM',
                onClick: (lcCustomer) => this.addEditDeviceManagerService(lcCustomer, this.selection[0])
            },
            {
                displayName: 'Disable Service',
                enabled: !this.isMonitor() && !this.isCustomerOperator() && this.actionsForSingleSelection && this.selection[0].serviceSource === 'DM',
                onClick: () => this.groupsActions.disableService(this.selection[0]),
                skipPreClick: true
            },
            {
                displayName: this.changeCustomerTitle,
                enabled: this.oneSelectedNotMonitorNotChannelNotCustomerOperators,
                onClick: () => this.changeCustomer(this.selection[0], 'DM'),
                skipPreClick: true
            }
        ];
    };

    getHybridItems = (): AcDropdownChild[] => {
        return [
            {
                displayName: 'Edit Hybrid Entities',
                enabled: this.selection?.length === 1 && !this.authorizationService.isCustomerOperatorLevel() && this.selection[0].serviceSource === 'HYBRID_ENTITIES',
                onClick: () => this.groupsActions.editServiceSettings({service: this.selection[0], type: 'HYBRID_ENTITIES'}),
                skipPreClick: true
            },
            {
                displayName: 'Add AC Device',
                enabled: true,
                onClick: () => this.devicesActionsService.addDevice({deviceDialogType: 'AC',
                    isServiceAttached: true, serviceId: this.selection?.length === 1 ? this.selection[0].id : undefined}),
                skipPreClick: true
            },
            {
                displayName: 'Add Multiple AC Devices',
                enabled: true,
                onClick: () => this.devicesActionsService.addDevice({deviceDialogType: 'AC',
                    isMultiple: true, isServiceAttached: true, serviceId: this.selection?.length === 1 ? this.selection[0].id : undefined}),
                skipPreClick: true
            },
            {
                displayName: this.changeCustomerTitle,
                enabled: this.oneSelectedNotMonitorNotChannelNotCustomerOperators,
                onClick: () => this.changeCustomer(this.selection[0], 'HYBRID_ENTITIES'),
                skipPreClick: true
            }
        ];
    };

    getZoomItems = (serviceType): AcDropdownChild[] => {
        return [
            {
                displayName: serviceType === 'ZOOM' ? 'Open Qualified Leads Page' : 'Open Services Page',
                enabled: !this.isCustomerOperator(),
                onClick: () => {
                    this.chooseTenantAndPreformAction({pageName: 'openZoomQualifiedLeadsPage', type: serviceType});
                },
                skipPreClick: true
            },
            {
                displayName: 'Open Number Management Page',
                enabled: !this.isCustomerOperator(),
                onClick: () => {
                    this.chooseTenantAndPreformAction({pageName: 'openZoomNumbersPage', type: serviceType});
                },
                skipPreClick: true
            },
            {
                displayName: 'Open Customer Page',
                enabled: this.isCustomerOperator(),
                onClick: (lcCustomer) => this.groupsActions.openZoomPage({lcCustomer, pageName: 'customer', serviceType})
            },
            {
                displayName: this.changeCustomerTitle,
                enabled: this.oneSelectedNotMonitorNotChannelNotCustomerOperators,
                onClick: () => this.changeCustomer(this.selection[0], serviceType),
                skipPreClick: true
            }
        ];
    };

    openUMPPage = (lcCustomer) => {
        this.groupsActions.getUMPDeviceAndOpenSSOConnection('EMS_login', lcCustomer.tenantId);
    };

    deleteServiceProvider = () => {
        this.groupsActions.deleteProviderSide(this.selection[0]);
    };

    deleteTeamsSide = () => {
        this.groupsActions.deleteTeamsSide(this.selection[0]);
    };

    backupServices = () => {
        this.groupsRestService.serviceRestAction(() => {
        }, () => {
        }, this.selection, 'backupService');
    };

    restoreServices = () => {
        this.groupsRestService.serviceRestAction(() => {
        }, () => {
        }, this.selection, 'restoreService');
    };

    changeCustomer = (service, type) => {
        this.groupsActions.openSelectCustomersDialog(service, type);
    };

    addServiceSettings(type, lcCustomer = undefined) {
        this.groupsActions.addServiceSettings(type, lcCustomer);
    }

    customerSelection = (type, action, service = undefined) => {
        const prefix = (type.key === 'addService' ? type : action)?.displayName;
        const postFix = (type.key === 'addService' ? action : type)?.displayName;
        const dialogTitle = prefix && postFix ? (prefix + ' (' + postFix + ')') : 'Select Customer';
        let lcCustomers = cloneDeep(this.lcCustomers);
        service = service || (this.selection?.length === 1 && type.key !== 'addService' ? this.selection[0] : undefined);
        if(!lcCustomers || lcCustomers.length === 0){
            lcCustomers = this.groupsActions.getLCCustomersThatTheirServiceIsEnabled({serviceType: (type?.key !== 'addService' ? type.key : action?.key)});
        }

        this.groupsActions.getLCCustomer(lcCustomers, service, dialogTitle, (lcCustomer) => {
            action.onClick(lcCustomer);
        });
    };

    public addEditDeviceManagerService(lcCustomer = undefined, service = undefined) {
        const page = service ? 'edit_customer' : 'create_customer';
        let channelMsTenantId;
        if (page === 'create_customer' && this.authorizationService.validForChannel()) {
            const channels = this.channelsRestService.getAllEntities();
            channelMsTenantId = channels.length === 1 ? channels[0]?.msTenantId : undefined;
        }

        this.groupsActions.openDeviceManagerPage(page, lcCustomer.tenantId, service?.serviceId, channelMsTenantId, lcCustomer.id);
    }

    chooseTenantAndPreformAction = ({pageName, spAttachedTenant = undefined, type}) => {
        if (spAttachedTenant) {
            this.doActionByType(pageName, spAttachedTenant, type);
        }else {
            const tenants = this.tenantsRestService.getAllEntities().filter(tenant => tenant.services?.[type]?.enabled);
            const dialogData = {entity: {tenantId: undefined}, tenants};
            this.acDialogService.open(SelectTenantDialogComponent, {
                onSubmit: () => {
                    const tenant = this.tenantsRestService.getEntityById(dialogData.entity.tenantId);

                    this.doActionByType(pageName, tenant, type);
                },
                dialogData
            });
        }
    };

    doActionByType = (pageName, tenant, type) => {
        if (tenant?.services?.[type]?.enabled) {
            switch (pageName) {
                case 'editOperatorConnectJSON':
                    this.tenantsActionsService.editOperatorConnect(tenant);
                    break;
                case 'downloadOperatorConnectJSON':
                    this.tenantsActionsService.downloadOperatorConnectJSON(tenant);
                    break;
                case 'openZoomQualifiedLeadsPage':
                    this.groupsActions.openZoomPage({tenant, pageName: 'leads', serviceType: type});
                    break;
                case 'openZoomNumbersPage':
                    this.groupsActions.openZoomPage({tenant, pageName: 'numbers', serviceType: type});
                    break;
                case 'ocQualifyLeads':
                    this.groupsActions.getUMPDeviceAndOpenSSOConnection('ovoc_ovl_edit', tenant.id, 'ocQualifyLeads');
                    break;
            }
        } else {
            this.acDialogService.info('Operator Connect is not enabled for the assigned tenant');
        }
    };
}
