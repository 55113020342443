import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {AcNavAutoService} from '../ac-nav-auto/ac-nav-auto.service';
import {DynamicTabsService} from './dynamic-tabs.service';

@Injectable({
    providedIn: 'root'
})
export class DynamicTabGuard implements CanActivate {

    constructor(private acNavAutoService: AcNavAutoService, private dynamicTabsService: DynamicTabsService) {

    }

    canActivate(route: ActivatedRouteSnapshot) {
        const url = '/' + route.url.map(x => x.path).join('/');

        if (!this.dynamicTabsService.getByUrl(url)) {
            this.dynamicTabsService.addTab(url, route.params.id);
        }
        return true;
    }
}
