import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    ViewChild
} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ValidatorsService} from "../../services/utilities/validators.service";

@Component({
    selector: 'ac-password',
    templateUrl: './ac-password.component.html',
    styleUrls: ['./ac-password.component.less'],
})
export class AcPasswordComponent {
    @Input() acModel: string;
    @Output() acModelChange = new EventEmitter<string>();

    @Input() passwordId = '';
    @Input() placeHolderText = '';
    @Input() maxLength: number;
    @Input() ngDisabled = false;
    @Input() focusOnInput: boolean;
    @Input() showEye = false;
    @Input() autocomplete = 'new-password';
    @ViewChild('passFieldInput', {static: true}) passFieldInputElement: ElementRef;
    @ViewChild(MatFormFieldControl) matFormFieldControl;

    isEmptyAtInit = true;
    isFocus = false;
    showContent = false;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public validatorsService: ValidatorsService,
                @Optional() public acFormComponent: AcFormComponent,
                private cdRef: ChangeDetectorRef) {

    }

    ngOnInit() {
        this.passwordId = this.passwordId || (this.acInputContainerComponent?.acInputContainerId + '-password');
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }

        if (this.acModel !== undefined && this.acModel !== '') {
            this.isEmptyAtInit = false;
        } else if (this.acModel === '') {
            this.acModelChange.emit(undefined);
            this.cdRef.detectChanges();
        }

        setTimeout(() => {
            if (this.focusOnInput) {
                this.passFieldInputElement.nativeElement.focus();
            }
        }, 500);
    }

    ngAfterViewInit(){
        this.acInputContainerComponent?.initializeMaterialField(this.matFormFieldControl);
        this.cdRef.detectChanges();
    }

    onFocus() {
        this.isFocus = true;
    }

    onBlur() {
        this.isFocus = false;
    }

    modelChanged($event) {
        if ($event === '') {
            this.acModelChange.emit(undefined);
        } else {
            this.acModelChange.emit($event);
        }
    }
}
