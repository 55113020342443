<div class="flexRowParentEqualChildren">
    <ac-input-container
        formFieldName="audioCodesDevice.name"
        label="{{ isMultiple ? 'Name Prefix' : 'Name' }}">
        <ac-textfield
            [(acModel)]="audioCodesDevice.name"
            textFieldId="audio-codes-device-name"></ac-textfield>
    </ac-input-container>

    <ac-input-container
        formFieldName="audioCodesDevice.description"
        label="Description">
        <ac-textfield
            [(acModel)]="audioCodesDevice.description"
            textFieldId="audio-codes-device-description">
        </ac-textfield>
    </ac-input-container>
</div>

<network-tenant-region-selection
    *ngIf="!isServiceAttached"
    [model]="audioCodesDevice"
    [additionalSettings]="additionalSettings"
    [isEdit]="isEdit"
    [ngDisabledTenant]="isEdit"
    [ngDisabledRegion]="isEdit"
    [regionFormFieldName]="'audioCodesDevice.regionId'"
    [selectedTopologyItem]="dialogConfig.dialogData.selectedTopologyItem"
    (acTenantChanged)="tenantChanged($event)"
    [horizontalView]="true">
</network-tenant-region-selection>

<ng-container *ngIf="isServiceAttached">
    <ac-input-container
        label="Service"
        formFieldName="audioCodesDevice.serviceId">
        <ac-single-select
            [(acModel)]="audioCodesDevice.serviceId"
            (acModelChange)="audioCodesDevice.serviceId = $event"
            bindLabel="serviceName"
            bindValue="id"
            [items]="services">
        </ac-single-select>
    </ac-input-container>

    <ac-checkbox
        labelText="External Access Available"
        [(acModel)]="audioCodesDevice.externalAccessAvailable"
        [ngDisabled]="!isSystemAdminOrOperatorLevel">
    </ac-checkbox>

    <ac-input-container
        formFieldName="audioCodesDevice.externalAccessIp"
        label="External Access IP">
        <ac-textfield
            [(acModel)]="audioCodesDevice.externalAccessIp"
            textFieldId="external-access-ip"></ac-textfield>
    </ac-input-container>
</ng-container>

<ac-checkbox
    labelText="FQDN Enabled"
    [(acModel)]="audioCodesDevice.fqdnEnabled"
    [ngDisabled]="!isEdit && additionalSettings.configuredBy === 'deviceFqdn'">
</ac-checkbox>

<div
    *ngIf="isMultiple"
    id="device-configurations-container-multiple">
    <ac-input-container label="Configured Device By">
        <ac-single-select
            [(acModel)]="additionalSettings.configuredBy"
            (acModelChange)="additionalSettings.configuredBy = $event"
            [items]="configuredByMultipleItems">
        </ac-single-select>
    </ac-input-container>

    <div
        class="flexRowParentEqualChildren"
        *ngIf="additionalSettings.configuredBy === 'ipAddressRange'">
        <ac-input-container
            formFieldName="additionalSettings.fromIpAddress"
            label="From">
            <ac-textfield
                [(acModel)]="additionalSettings.fromIpAddress"
                textFieldId="add-multiple-audio-codes-devices-from-ip">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container
            formFieldName="additionalSettings.toIpAddress"
            class="multiple-radio-button"
            label="To">
            <ac-textfield
                [(acModel)]="additionalSettings.toIpAddress"
                textFieldId="add-multiple-audio-codes-devices-to-ip">
            </ac-textfield>
        </ac-input-container>
    </div>

    <ac-input-container
        formFieldName="additionalSettings.ipAddressList"
        label="IP Address List"
        *ngIf="additionalSettings.configuredBy === 'ipAddressList'">
        <ac-select
            [(acModel)]="additionalSettings.ipAddressList"
            [addCustomTags]="true"
            [bindLabel]="null"
            [bindValue]="null"
            selectionName="additionalSettings.ipAddressList">
        </ac-select>
    </ac-input-container>

    <ac-input-container
        formFieldName="additionalSettings.serialNumberList"
        *ngIf="additionalSettings.configuredBy === 'serialNumberList'"
        label="Serial Number List">
        <ac-select
            [(acModel)]="additionalSettings.serialNumberList"
            [addCustomTags]="true"
            [bindLabel]="null"
            [bindValue]="null"
            selectionName="additionalSettings.serialNumberList">
        </ac-select>
    </ac-input-container>
</div>

<div
    *ngIf="!isMultiple"
    [class.ac-disable]="isEdit"
    id="device-configurations-container">
    <ac-input-container
        label="Configured Device By"
        *ngIf="!isEdit">
        <ac-single-select
            [(acModel)]="additionalSettings.configuredBy"
            (acModelChange)="additionalSettings.configuredBy = $event; configuredByChanged($event)"
            [items]="configuredByItems">
        </ac-single-select>
    </ac-input-container>

    <ac-input-container
        formFieldName="audioCodesDevice.ipAddress"
        label="IP Address"
        *ngIf="(!isEdit && additionalSettings.configuredBy === 'ipAddress') || (isEdit && audioCodesDevice?.ipAddress)">
        <ac-textfield
            [(acModel)]="audioCodesDevice.ipAddress"
            [ngDisabled]="isEdit || additionalSettings.configuredBy !== 'ipAddress'"
            textFieldId="ip-device-sql-connection-port">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container
        formFieldName="audioCodesDevice.sbcInfo.deviceFqdn"
        label="FQDN"
        *ngIf="
            (!isEdit && additionalSettings.configuredBy === 'deviceFqdn') ||
            (isEdit && additionalSettings?.sbcInfo?.deviceFqdn)
        ">
        <ac-textfield
            [acModel]="
                (audioCodesDevice.sbcInfo && audioCodesDevice.sbcInfo.deviceFqdn) ||
                additionalSettings.sbcInfo?.deviceFqdn
            "
            (acModelChange)="audioCodesDevice.sbcInfo.deviceFqdn = $event"
            [ngDisabled]="additionalSettings.configuredBy !== 'deviceFqdn' || isEdit">
        </ac-textfield>
    </ac-input-container>

    <div
        class="flexRowParentEqualChildren"
        *ngIf="
            (!isEdit && additionalSettings.configuredBy === 'serialNum') ||
            (isEdit && audioCodesDevice?.sbcInfo?.serialNum)
        ">
        <ac-input-container
            formFieldName="audioCodesDevice.sbcInfo.serialNum"
            label="Serial Number 1">
            <ac-textfield
                [acModel]="audioCodesDevice.sbcInfo?.serialNum"
                (acModelChange)="audioCodesDevice?.sbcInfo ? (audioCodesDevice.sbcInfo.serialNum = $event) : null"
                [ngDisabled]="isEdit || additionalSettings.configuredBy !== 'serialNum'"
                textFieldId="audio-codes-device-serial-number-1">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container
            formFieldName="audioCodesDevice.sbcInfo.secondSerialNum"
            label="Serial Number 2">
            <ac-textfield
                [acModel]="audioCodesDevice.sbcInfo?.secondSerialNum"
                (acModelChange)="audioCodesDevice?.sbcInfo ? (audioCodesDevice.sbcInfo.secondSerialNum = $event) : null"
                [ngDisabled]="isEdit || additionalSettings.configuredBy !== 'serialNum'"
                textFieldId="audio-codes-device-serial-number-2">
            </ac-textfield>
        </ac-input-container>
    </div>
</div>
<ac-input-container
    formFieldName="audioCodesDevice.location"
    label="Address">
    <ac-geo-location
        (acChanged)="audioCodesDevice.location = $event"
        [acModel]="audioCodesDevice.location"></ac-geo-location>
</ac-input-container>

<ac-input-container
    formFieldName="audioCodesDevice.generalInfo"
    label="General Information">
    <ac-textfield
        [(acModel)]="audioCodesDevice.generalInfo"
        textFieldId="audio-codes-device-general-info">
    </ac-textfield>
</ac-input-container>
