import {DateTime} from 'luxon';

export enum FormatterType {
    date = 'date',
    time = 'time',
    timeNoSec = 'timeNoSec',
    timeChart = 'timeChart',
    dateAndTime = 'dateAndTime',
    dateAndTimeWithMilliseconds = 'dateAndTimeWithMilliseconds',
    dateAndTimeNoSec = 'dateAndTimeNoSec',
    dateAndTimeNoYearNoSec = 'dateAndTimeNoYearNoSec',
}

type DateTimeFormatters = { [key in FormatterType]: string };

export const DateFormat: DateTimeFormatters = {
    [FormatterType.date]: 'dd-MMM-yy',
    [FormatterType.time]: 'HH:mm:ss',
    [FormatterType.timeNoSec]: 'HH:mm',
    [FormatterType.timeChart]: '%H:%M:%S',
    [FormatterType.dateAndTime]: 'dd-MMM-yy HH:mm:ss',
    [FormatterType.dateAndTimeWithMilliseconds]: 'dd-MMM-yy HH:mm:ss.u',
    [FormatterType.dateAndTimeNoSec]: 'dd-MMM-yy HH:mm',
    [FormatterType.dateAndTimeNoYearNoSec]: 'dd-MMM HH:mm',
};

export const dateTimeFormatter = (dateTime: any, formatterType: FormatterType | string = FormatterType.date) => {
    let dateTimeFromFunc: any = DateTime.fromMillis;
    if (typeof dateTime === 'string') {
        if (dateTime.indexOf('Z') === -1 && dateTime.indexOf('+') === -1 && dateTime.indexOf('-') === -1) {
            dateTime += 'Z';
        }
        dateTimeFromFunc = DateTime.fromISO;
    } else if (dateTime instanceof Date) {
        dateTimeFromFunc = DateTime.fromJSDate;
    }
    return dateTimeFromFunc(dateTime).toFormat(DateFormat[formatterType] || FormatterType.date);
};
