<ng-container [ngSwitch]="buttonType">
    <button *ngSwitchCase="'icon-button'"
            mat-icon-button
            [attr.id]="buttonId ? buttonId : null"
            [ngClass]="classesByType[buttonType]"
            [disabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [class.ngDisabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            class="ac-button-icon {{color}}"
            [attr.type]="type"
            (click)="!_ngDisabled && onClick($event)">
        <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </button>

    <button *ngSwitchCase="(buttonType === 'fab' ||  buttonType === 'mini-fab')"
            mat-fab
            [attr.id]="buttonId ? buttonId : null"
            [ngClass]="classesByType[buttonType]"
            [disabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [class.ngDisabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [attr.type]="type"
            (click)="!_ngDisabled && onClick($event)"
            class="{{color}}">
        <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </button>

    <button *ngSwitchCase="'stroked-button'"
            mat-stroked-button
            [attr.id]="buttonId ? buttonId : null"
            [ngClass]="classesByType[buttonType]"
            [disabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [class.ngDisabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [attr.type]="type"
            (click)="!_ngDisabled && onClick($event)"
            class="ac-button prevent-text-selection {{color}}">
        <span class="ac-button-content" #contentWrapper>
            {{_buttonValue}}
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </span>
    </button>

    <button *ngSwitchDefault
            mat-button
            [attr.id]="buttonId ? buttonId : null"
            [ngClass]="classesByType[buttonType]"
            [disabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [class.ngDisabled]="_ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"
            [attr.type]="type"
            (click)="!_ngDisabled && onClick($event)"
            class="ac-button prevent-text-selection {{color}}">
        <span class="ac-button-content" #contentWrapper>
            {{_buttonValue}}
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </span>
    </button>
</ng-container>

<ng-template #contentTemplate>
    <ng-content></ng-content>
</ng-template>
