import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {AuthGroup, AuthGroupOption, SessionHelperService} from '../utilities/session-helper.service';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    static tenantScopeSubject = new BehaviorSubject<number | null>(null);
    static isGlobalScope$ = AuthorizationService.tenantScopeSubject.asObservable().pipe(map(x => x === null));

    private static _authGroups = SessionHelperService.authGroups;

    static set authGroups(authGroups) {
        AuthorizationService._authGroups = authGroups;
    }

    private static _oneLiveMode;

    static get oneLiveMode() {
        return AuthorizationService._oneLiveMode;
    }

    static set oneLiveMode(value) {
        AuthorizationService._oneLiveMode = value;
    }

    static get tenantScope() {
        return AuthorizationService.tenantScopeSubject.getValue();
    }

    static set tenantScope(value) {
        AuthorizationService.tenantScopeSubject.next(value);
    }

    _securityLevel: AuthGroupOption;

    get securityLevel(): AuthGroupOption {
        return this._securityLevel;
    }

    set securityLevel(securityLevel: any) {
        this._securityLevel = securityLevel;
    }

    static isGlobalScope() {
        return AuthorizationService.tenantScope === null;
    }

    validationAccordingToMode = ({ovoc, oneLive}): boolean => {
        return this.validFor(AuthorizationService._oneLiveMode ? oneLive : ovoc);
    };

    validFor = (securityGroups: any): boolean => {
        if (!SessionService.activeSession) {
            return false;
        }

        if (securityGroups === 'none') {
            return false;
        }

        if (securityGroups === 'all') {
            return true;
        }

        if (Array.isArray(securityGroups)) {
            return securityGroups.some(securityGroup => {
                if (Array.isArray(securityGroup)) {
                    return securityGroup.some(subSecurityGroup => this.validateSecurityLevel(subSecurityGroup));
                }

                return this.validateSecurityLevel(securityGroup);
            });
        }

        return this.validateSecurityLevel(securityGroups);
    };

    validateSecurityLevel = (securityGroup): boolean => {
        return AuthorizationService._authGroups[securityGroup].includes(this._securityLevel);
    };

    public isSameUserConnected = (name, authType) => {
        if (!name || !authType) {
            return false;
        }
        const loggedUser = SessionService.activeSession;

        return loggedUser.name === name && loggedUser.operatorAuthenticationMode === authType;
    };

    validForMonitorLinkOrOneLiveTenantUserOrGroupUsers = () => {
        return this.validationAccordingToMode({
            ovoc: AuthorizationService._authGroups.TENANT_MONITORING_LINKS,
            oneLive: [
                AuthorizationService._authGroups.tenant_user,
                AuthorizationService._authGroups.customer_operator,
                AuthorizationService._authGroups.channel_operator
            ]
        });
    };

    validForSystemOperatorOrTenantOperatorInStandardMode = () => {
        return this.validationAccordingToMode({
            ovoc: AuthorizationService._authGroups.admin_operator,
            oneLive: AuthorizationService._authGroups.system_admin_operator
        });
    };

    validForServiceProvider = (): boolean => {
        return this.validationAccordingToMode({ovoc: 'none', oneLive: AuthorizationService._authGroups.tenant_user});
    };

    validForChannel = () => {
        return this.validationAccordingToMode({ovoc: 'none', oneLive: AuthorizationService._authGroups.channel_operator});
    };

    validForCustomerOperator = () => {
        return this.validationAccordingToMode({ovoc: 'none', oneLive: AuthorizationService._authGroups.customer_operator});
    };

    isMonitor = () => this.validFor(AuthGroup.monitor);
    isTenantUser = () => this.validFor(AuthGroup.tenant_user);
    isAdminOrOperatorLevel = () => this.validFor(AuthGroup.admin_operator);
    isSystemAdminOrOperatorLevel = () => this.validFor(AuthGroup.system_admin_operator);
    isSystemLevel = () => this.validFor(AuthGroup.system_users);
    isCustomerOperatorLevel = () => this.validFor(AuthGroup.customer_operator);
    isChannelLevel = () => this.validFor(AuthGroup.channel_operator);
}
