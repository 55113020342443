import {Component, Inject} from '@angular/core';
import * as _ from 'lodash';

import {AcDialog, DIALOG_CONFIG, DialogConfig, SchemaHelperService} from 'ac-infra';

@AcDialog({
    cancelButtonText: 'Close',
    id: 'general-device-dialog',
    title: 'Generic Device Details',
    width: 500
})
@Component({
    selector: 'generic-device-dialog',
    templateUrl: './generic-device-dialog.component.html'
})
export class GenericDeviceDialogComponent {

    schema: any = {};
    generalDevice: any = {};
    isEdit: boolean;
    deviceAddSchema = require('../../../../schema/post/devicesprocessor.json');
    deviceEditSchema = require('../../../../schema/put/devicesprocessor.json');
    additionalSettings;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.schema = _.cloneDeep(this.dialogConfig.dialogData.isEdit ? this.deviceEditSchema : this.deviceAddSchema);
        this.schema.required = this.schema.required || [];

        this.isEdit = this.dialogConfig.dialogData.isEdit;

        this.generalDevice = this.dialogConfig.dialogData.entity;
        this.additionalSettings = _.cloneDeep(this.generalDevice);
        this.additionalSettings.tenantId = undefined;

        this.dialogConfig.preSubmit = () => {
            // device schema(for all types of devices) doesn't approve empty ipAddress
            // generic device is the only device type that can edit ipaddress
            // after opening OVOC-10003 we found that a user cannot delete generic ipAddress in edit mode.
            // Gal Erez solution was to send 0.0.0.0 if ipAddress is not insert by user or removed by user manually in edit mode.

            if(!this.generalDevice.ipAddress){
                this.generalDevice.ipAddress = '0.0.0.0';
            }
        };
    }

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        if (this.generalDevice.ipAddress && !SchemaHelperService.validateIPAddress(this.generalDevice.ipAddress, 'both')) {
            const error = SchemaHelperService.buildErrorItem({
                inputName: 'generalDevice.ipAddress',
                keyword: 'IPv4or6Aux'
            });
            errors.unshift(error);
        }
    };
}





