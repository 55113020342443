import {inject} from '@angular/core';
import {Rest, RestResponseSuccess} from './rest';
import * as _ from 'lodash';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';
import {SessionService} from '../services/session.service';
import {AuthorizationService} from '../services/authorization.service';
import {ArrayUtil, RequestOptions} from 'ac-infra';
import {WsEntitiesService} from '../services/communication/ws-entities.service';
import {ServerInfoService} from "../services/server-info.service";

export class NetworkRest extends Rest {

    protected wsEntitiesService = inject(WsEntitiesService);
    protected sessionService = inject(SessionService);
    private networkFilterFormatter = inject(NetworkFilterFormatterService);

    setURL = (url) => {
        this.url = url;
    };

    getFilteredEntitiesForTable = (requestOptions: RequestOptions, success, failure, entityType?) => {
        const type = entityType || this.entityType;
        const onSuccess = (response) => {
            if (response.status === 200) {
                response.data && response.data[type] && response.data[type].forEach((entity, idx) => {
                    const wsEntity = _.cloneDeep(this.wsEntitiesService.getEntityByEntityType(type, entity.id));
                    response.data[type][idx] = _.extend(wsEntity, entity);
                });
            }
            success(response);
        };
        this.get({...requestOptions, extendParameters: true}).then(onSuccess).catch(failure);
    };

    getEntitiesWithFilter = (success, failure, parameters, url = undefined) => {
        this.get({url, parameters, extendParameters: true, skipPopulateFilter: true}).then(success).catch(failure);
    };

    // WS
    getAllEntities = (ids?: any[], forceGlobalScope = false, entityType = undefined) => {
        if (AuthorizationService.tenantScope && this.entityType === 'tenants' && !forceGlobalScope) {
            const tenant = this.getEntityById(AuthorizationService.tenantScope);
            if (ids && !ids.includes(tenant.id)) {
                return [];
            }
            return [tenant];
        }

        const entities = this.wsEntitiesService.getEntitiesArray(entityType || this.entityType, ids || null);
        if (AuthorizationService.isGlobalScope() || forceGlobalScope) {
            return entities;
        }

        return entities.filter((entity) => entity.tenantId === AuthorizationService.tenantScope);
    };

    getEntitiesByTenantId = (tenantId: number, {hashed = false, entityType = null}: { hashed?: boolean; entityType?: string } = {}) => {
        tenantId = AuthorizationService.tenantScope || tenantId;
        const entitiesList = this.getEntitiesListByTenantId(tenantId, entityType);

        if (!hashed) {
            return entitiesList;
        }

        return ArrayUtil.arrayToObject(entitiesList, (acc, curr) => {
            acc[curr.id] = curr;
        });
    };

    getAllEntitiesHashed = (entityType: string = null) => {
        const entities = this.wsEntitiesService.getEntitiesByEntityType(entityType || this.entityType);

        return AuthorizationService.isGlobalScope() ? entities : this.getHashedEntitiesByScope(AuthorizationService.tenantScope, entities);
    };

    getHashedEntitiesByScope = (tenantScope, entities) => {
        if (this.entityType === 'tenants') {
            return {[tenantScope]: this.getEntityById(tenantScope)};
        } else {
            const result = {};

            _.forOwn(entities, (value, key) => {
                if (value.tenantId === AuthorizationService.tenantScope) {
                    result[key] = value;
                }
            });

            return result;
        }
    };

    getFilterIds = () => this.wsEntitiesService.getFilteredIds(this.entityType);

    getFilteredEntities = () => {
        const filteredIds = this.wsEntitiesService.getFilteredIds(this.entityType);

        return this.getEntitiesByIds(filteredIds);
    };

    getEntitiesByIds = (listOfIds) => this.wsEntitiesService.getEntitiesArray(this.entityType, listOfIds);

    getEntityById = (entityId) => this.wsEntitiesService.getEntityByEntityType(this.entityType, entityId);

    getFilteredEntitiesHashed = (ids?) => this.wsEntitiesService.getFilteredEntitiesHashed(this.entityType, ids);

    // Statistics
    getMonitorStatisticsByUnitID = ({success, failure = () => null, uri = 'entities', ...requestOptions}: RequestOptions) => {
        return this.get({
            ...requestOptions,
            url: 'callsMonitor/statistics/' + uri,
            extendParameters: true,
            addScopeIdFilter: true,
            requestAPI: 'entities',
            useCachedConnection: true,
            filteredEntity: uri
        }).then(success).catch(failure);
    };

    getMonitorStatisticsByFilter = (success, failure, parameters?, uri?) => {
        return this.get({
            parameters,
            url: 'callsMonitor/statistics/' + (uri || (this.entityType + 'Entities')),
            extendParameters: true,
            addScopeIdFilter: true,
            requestAPI: 'entities',
            useCachedConnection: true,
            filteredEntity: this.entityType + 'Entities'
        }).then(success).catch(failure);
    };

    getTopologyStatistics = (success, failure, parameters?, entityType?, filteredEntity?) => {
        return this.get({
            parameters,
            url: 'topology/statistics/' + (entityType || this.entityType),
            extendParameters: true,
            filteredEntity,
            requestAPI: 'entities',
            useCachedConnection: true
        }).then(success).catch(failure);
    };

    getEntitiesStatisticsForDynamicTab = (success, failure, parameters) => {
        this.get({
            parameters,
            url: 'callsMonitor/statistics/entities',
            extendParameters: true,
            addScopeIdFilter: true,
            requestAPI: 'entities',
            useCachedConnection: true,
            skipPopulateFilter: true
        }).then(success).catch(failure);
    };

    public getActiveAlarmsSeveritySummaryForNetwork = (success, failure, parameters, isSite) => {//  OVOC-2877
        const onSuccess = (response: RestResponseSuccess) => {
            success(response.status === 204 ? {data: {}} : response);
        };

        return this.get({
            parameters,
            url: 'alarms/statistics/active/severitySum',
            filteredEntity: isSite ? 'siteAlarmsSummary' : 'networkAlarmsSummary',
            build204JSON: false,
            ...Rest.SKIP_PAYLOAD_TO_LARGE,
        }).then(onSuccess).catch(failure);
    };

    // LockedURL
    getLockedURL = (uri = undefined) => (ids, requestFiltered, fields?) => {
        const parameters = this.createParams(ids, requestFiltered, fields);
        const query: any = this.buildURLs.restructureParamsObject(parameters);

        if (!query.filter && requestFiltered) {
            return null;
        }

        return this.addFilterToURL(parameters, uri);
    };

    createParams(ids: any, requestFiltered: any, fields?: any, limit?: number) {
        const parameters: any = {detail: 1, limit: limit || 100000};
        if (fields) {
            parameters.fields = fields;
        }
        const timeFilter: any = this.networkFilterFormatter.getTimeFilter(this.networkFilterFormatter.getAggregatedFilter('NetworkFilter'));

        if (timeFilter !== null && this.entityType !== 'channels') {
            parameters.filter = parameters.filter || {};
            parameters.filter = timeFilter;
        }

        if (ids) {
            parameters.filter = parameters.filter || {};
            if (this.entityType === 'groups') {
                parameters.filter.id = ids;
            } else {
                parameters.filter.specificId = ids;
            }
        }

        if (requestFiltered && this.entityType !== 'tenants' && this.entityType !== 'regions') {
            parameters.fields = ['id'];
            this.populateFilterPropertyIfNeeded(parameters, this.entityType);
        }
        return parameters;
    }

    private getEntitiesListByTenantId = (tenantId: number, entityType: string = null) => {
        entityType = entityType || this.entityType;
        const isTenantType = entityType === 'tenants';
        const entities = this.wsEntitiesService.getEntitiesArray(entityType, isTenantType ? tenantId : null);

        if (!tenantId || isTenantType) {
            return entities;
        }

        return entities.filter((entity: any) => entity.tenantId === tenantId);
    };
}
