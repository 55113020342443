<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="sqlConnection"
         [formModel]="lyncDevice"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         formModelName="lyncDevice">

    <div id="lync-device-dialog-content">

        <div class="flexRowParentEqualChildren">
            <ac-input-container formFieldName="lyncDevice.name" label="Name">
                <ac-textfield [(acModel)]="lyncDevice.name"
                              textFieldId="lync-device-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="lyncDevice.lyncInfo.FQDN" label="FQDN">
                <ac-textfield [(acModel)]="lyncDevice.lyncInfo.FQDN"
                              textFieldId="lync-device-fqdn">
                </ac-textfield>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="lyncDevice"
                                         [additionalSettings]="additionalSettings"
                                         [isEdit]="isEdit"
                                         [ngDisabledTenant]="isEdit"
                                         [ngDisabledRegion]="isEdit"
                                         [regionFormFieldName]="'lyncDevice.regionId'"
                                         (acTenantChanged)="tenantChanged($event)"
                                         [horizontalView]="true">
        </network-tenant-region-selection>

        <ac-input-container formFieldName="lyncDevice.location" label="Address">
            <ac-geo-location (acChanged)="lyncDevice.location=$event"
                             [acModel]="lyncDevice.location"></ac-geo-location>
        </ac-input-container>

        <ac-input-container formFieldName="lyncDevice.productType" label="Device Type">
            <ac-single-select [sortItems]="true"
                       [acModel]="lyncDevice.productType || additionalSettings.productType"
                       (acModelChange)="lyncDevice.productType = $event; productTypeChanged()"
                       [bindLabel]="'viewName'"
                       [bindValue]="'name'"
                       [ngDisabled]="isEdit"
                       [items]="lyncProductTypesList">
            </ac-single-select>
        </ac-input-container>

        <div *ngIf="!isFrontEndType">
            <ac-input-container formFieldName="lyncDevice.lyncInfo.reportedFrontEndId" label="Reporting Device">
                <ac-single-select [(acModel)]="lyncDevice.lyncInfo.reportedFrontEndId"
                           [bindLabel]="'name'"
                           [bindValue]="'id'"
                           [items]="FEDevicesList">
                </ac-single-select>
            </ac-input-container>
        </div>


        <div *ngIf="isFrontEndType" class="dbDetailsContainer">
            <ac-form-group titleText="SQL SERVER DB" [noSideGap]="true">

                <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbIp" label="IP Address">
                    <ac-textfield [(acModel)]="lyncDevice.lyncInfo.monitorDbIp"
                                  [ngDisabled]="!isFrontEndType"
                                  textFieldId="lync-device-sql-server-ip">
                    </ac-textfield>
                </ac-input-container>

                <div class="flexRowParentEqualChildren">
                    <ac-input-container label="SQL Mode">
                        <ac-single-select [(acModel)]="sqlConnection.sqlMethodBy"
                                   (acModelChange)="sqlConnection.sqlMethodBy = $event"
                                   [items]="sqlConnectionItems"
                                   [ngDisabled]="!isFrontEndType || isEdit">
                        </ac-single-select>
                    </ac-input-container>

                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbPort" label="Port" *ngIf="sqlConnection.sqlMethodBy === 'monitorDbPort'">
                        <ac-num-spinner [(acModel)]="lyncDevice.lyncInfo.monitorDbPort"
                                        [minValue]="0"
                                        [ngDisabled]="sqlConnection.sqlMethodBy === 'monitorDbInstanceName' || !isFrontEndType"
                                        numSpinnerId="lync-port-sql-connection-port">
                        </ac-num-spinner>
                    </ac-input-container>

                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbInstanceName" label="Instance Name" *ngIf="sqlConnection.sqlMethodBy === 'monitorDbInstanceName'">
                        <ac-textfield [(acModel)]="lyncDevice.lyncInfo.monitorDbInstanceName"
                                      [ngDisabled]="sqlConnection.sqlMethodBy === 'monitorDbPort' || !isFrontEndType"
                                      textFieldId="lync-device-sql-connection-instance">
                        </ac-textfield>
                    </ac-input-container>
                </div>

                <div class="flexRowParentEqualChildren">
                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbUserAuthType" label="Connection Mode">
                        <ac-single-select [(acModel)]="lyncDevice.lyncInfo.monitorDbUserAuthType"
                                   (acModelChange)="updateMonitorDbUserDomain()"
                                   [items]="monitorDbUserAuthTypeItems"
                                   [ngDisabled]="!isFrontEndType">
                        </ac-single-select>
                    </ac-input-container>

                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbWinUserDomain" label="Domain">
                        <ac-textfield [(acModel)]="lyncDevice.lyncInfo.monitorDbWinUserDomain"
                                      [ngDisabled]="!isFrontEndType || lyncDevice.lyncInfo.monitorDbUserAuthType === monitorDbUserAuthTypeItems[0].value"
                                      textFieldId="lync-device-sql-server-domain">
                        </ac-textfield>
                    </ac-input-container>
                </div>

                <div class="flexRowParentEqualChildren">
                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbUser" label="Username">
                        <ac-textfield [(acModel)]="lyncDevice.lyncInfo.monitorDbUser"
                                      [ngDisabled]="!isFrontEndType"
                                      textFieldId="lync-device-sql-server-user">
                        </ac-textfield>
                    </ac-input-container>

                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbPassword" editModePrefix="Change" label="Password">
                        <ac-password [(acModel)]="lyncDevice.lyncInfo.monitorDbPassword"
                                     [ngDisabled]="!isFrontEndType"
                                     passwordId="lync-device-sql-server-password">
                        </ac-password>
                    </ac-input-container>
                </div>

                <div class="flexRowParentEqualChildren">
                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbsslConnection" label="SSL">
                        <ac-single-select [(acModel)]="lyncDevice.lyncInfo.monitorDbsslConnection"
                                   (acModelChange)="monitorDbsslConnectionChanged($event)"
                                   [bindLabel]="'viewName'"
                                   [bindValue]="'name'"
                                   [items]="sslOptionsList"
                                   [ngDisabled]="!isFrontEndType">
                        </ac-single-select>
                    </ac-input-container>

                    <ac-input-container formFieldName="lyncDevice.lyncInfo.monitorDbcertificateFileID" label="Certificate File">
                        <ac-single-select [(acModel)]="lyncDevice.lyncInfo.monitorDbcertificateFileID"
                                   [bindLabel]="'name'"
                                   [bindValue]="'id'"
                                   [items]="certificateFileOptionsList"
                                   [ngDisabled]="!isFrontEndType || lyncDevice.lyncInfo.monitorDbsslConnection !== 'USING_CERTIFICATE'">
                        </ac-single-select>
                    </ac-input-container>
                </div>
            </ac-form-group>
        </div>
    </div>

</ac-form>
