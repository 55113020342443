export enum SvgModelName {
    Private = 'private',
    Public = 'public',
    Settings = 'settings',
    SettingsBlue = 'settingsBlue',
    Download = 'download',
    Lock = 'lock',
    Unlock = 'unlock',
    Logout = 'logout',
    Key = 'key',
    Debug = 'debug',
    About = 'about',
    Delete = 'delete',
    Range = 'range',
    reportBar = 'reportBar',
    reportLine = 'reportLine',
    reportStackBar = 'reportStackBar',
    reportPie = 'reportPie',
    reportDynamic = 'reportDynamic',
    NotAllowed = 'notAllowed',
    Scheduled = 'scheduled',
    Save = 'save',
    Duplicate = 'duplicate',
    Export = 'export',
    Import = 'import',
    Bell = 'bell',
    BellRing = 'bellRing',
    Magnify = 'magnify',
    AudioCodesLogo = 'audiocodes_logo',
    call = 'call',
    correlatedCall = 'correlated-call',
    callConnectionTypeUnknown = 'call_connection_type_unknown',
    callConnectionTypeWired = 'call_connection_type_wired',
    callConnectionTypeWifi = 'call_connection_type_wifi',
    callConnectionTypeMobile = 'call_connection_type_mobile',
    callConnectionTypeTunnel = 'call_connection_type_tunnel',
    callConnectionTypeTeamsGroup = 'call_connection_type_teams_group',
    callConnectionTypeTeamsGroupOriginator = 'call_connection_type_teams_group_originator',
    Monitoring = 'monitoring',
    Resize = 'resize',
    Counter = 'counter',
    Arrow = 'arrow',
    Clear = 'clear'
}

export enum ToastType {
    success = 'success',
    failure = 'failure',
    alarm = 'alarm',
    event = 'event',
    task = 'task',
}

