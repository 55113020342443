import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {cloneDeep, extend} from 'lodash';
import {ConnectionService} from './connection.service';
import {PromiseService, RequestOptions} from 'ac-infra';
import {SessionService} from '../session.service';

@Injectable({
    providedIn: 'root'
})
export class CachedConnection {
    cacheOptions = {timeToLive: 60000};
    timeoutsObject = {};
    cachePool = {};


    constructor(private http: HttpClient,
                private connection: ConnectionService,
                private sessionService: SessionService) {

    }

    get hasUnlockedActiveSession() {
        return this.sessionService.activeSession && !this.sessionService.activeSession.locked;
    }

    copyResponse(outsidePromise) {
        const deferred = PromiseService.defer();
        outsidePromise.then(
            (success) => {
                const successClone = {...success};
                successClone.data = cloneDeep(success.data);
                deferred.resolve(successClone);
            },
            (fail) => deferred.reject(fail)
        );
        return deferred.promise;
    }

    public get = (url, group: string, filterChanged = false, requestOptions?: RequestOptions) => {

        const callback = (response) => {
            if (response.data === null) {
                response.data = {};
                response.data[group] = [];
            }
            if (this.cachePool[url]) {
                if (response.status.toString()[0] !== '2') { // '2**' response status
                    delete this.cachePool[url];
                }
                this.resetTimer(url);
            }
        };

        if (!this.sessionService.activeSession) {
            return Promise.reject();
        }
        if (!this.cachePool[url] || filterChanged) {

            this.cachePool[url] = {
                promise: this.connection.get({uri: url, ...requestOptions}),
                group
            };
            this.cachePool[url].promise.then(callback, callback);
        }
        return this.copyResponse(this.cachePool[url].promise);
    }

    public forceRefreshLockedUrl = (groups) => {
        groups = Array.isArray(groups) ? groups : [groups];

        groups.forEach((group) => {
            this.clearCacheForName(group);
        });
    }

    clearCacheForName = (group) => {
        Object.getOwnPropertyNames(this.cachePool).forEach((url) => {
            if (this.cachePool[url].group === group) {
                delete this.cachePool[url];
            }
        });
    }

    updateCacheOptions = (newOptions) => {
        this.cacheOptions = extend(this.cacheOptions, newOptions);
    }

    clearCache = () => {
        Object.getOwnPropertyNames(this.timeoutsObject).forEach((prop) => {
            clearTimeout(this.timeoutsObject[prop]);
        });

        this.timeoutsObject = {};
        this.cachePool = {};
    }

    removeTimer = (url: string) => {
        if (!this.timeoutsObject) {
            return;
        }
        clearTimeout(this.timeoutsObject[url]);
    }

    resetTimer = (url) => {
        if (!this.hasUnlockedActiveSession) {
            return;
        }

        const resetURL = () => {
            delete this.timeoutsObject[url];
            delete this.cachePool[url];
        };

        if (!this.timeoutsObject[url]) {
            this.timeoutsObject[url] = setTimeout(resetURL, this.cacheOptions.timeToLive);
        }
    }

}
