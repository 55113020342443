<div class="display-box-container flexColumnParent" [class.isClickable]="isClickable"
     [class.flexItem]="isFlexItem"
     (click)="isClickable && acClick.emit()"
     (mouseover)="hover=true"
     (mouseleave)="hover=false">

    <div *ngIf="_upperValue !== undefined"
         #upperValue
         class="display-upper-value {{upperSize}}"
         [style.color]="color || 'black'">
        {{_upperValue}}
    </div>
    <div *ngIf="_lowerValue !== undefined"
         #lowerValue
         class="display-lower-value {{lowerSize}}"
         [style.color]="color || 'black'">
        <span [style.color]="loweValueColor" [innerHTML]="_lowerValue"></span>
        <span *ngIf="lowerValueSuffix" class="lower-value-suffix">({{ lowerValueSuffix }})</span>
    </div>
    <div class="display-box-background" [class.lighterOpacity]="color"
         [style.background-color]="color"
         [class.hoverWithColor]="isClickable && color && hover"
         [class.grey-background]="showGreyBackground"
         [class.hoverDefault]="isClickable && !color && hover"></div>
</div>
