import {Injectable} from '@angular/core';
import {ConstantStringsService} from '../../../common/utilities/constant-strings.service';
import {Rest} from '../../../common/server-actions/rest';
import {NetworkFilterFormatterService} from "../../../network/services/network-filter-formatter.service";
import {RequestOptions} from 'ac-infra';
import {ServerInfoService} from "../../../common/services/server-info.service";

@Injectable({providedIn: 'root'})
export class CallsStorageRestService extends Rest {

    static statisticsSliderData;
    static statisticsStatusSlidersData: any;
    static callsStatusSlidersData = {
        calls: {objName: 'callsStorage', title: 'Total Calls'},
        callsWithCallFlow: {objName: 'sipMessagesStorage', title: 'Calls with Call Flow'},
    };

    static callsSuccessTableObj = {
        successGoodRule: {
            status: 'status-ok',
            title: 'Good Quality',
            values: {toggle: false, flow: false, trend: false}
        },
        successFairPoorRule: {
            status: 'status-warning',
            statusSecondary: 'status-error',
            title: 'Fair',
            titleSecondary: 'Poor Quality',
            values: {toggle: false, flow: false, trend: false}
        },
        successUnknownRule: {
            status: 'status-unmonitored',
            title: 'Unknown Quality',
            values: {toggle: false, flow: false, trend: false}
        }
    };
    static callsFailedTableObj = {
        failGoodRule: {
            status: 'status-ok',
            title: 'Good Quality',
            values: {toggle: false, flow: false, trend: false}
        },
        failFairPoorRule: {
            status: 'status-warning',
            statusSecondary: 'status-error',
            title: 'Fair',
            titleSecondary: 'Poor Quality',
            values: {toggle: false, flow: false, trend: false}
        },
        failUnknownRule: {
            status: 'status-unmonitored',
            title: 'Unknown Quality',
            values: {toggle: false, flow: false, trend: false}
        }
    };
    static getTimeOptions =  () => {
        return {
            floor: 1,
            ceil: 365,
            tickStep: 182,
            showSelectionBar: true,
            showTicksValues: true,
            animate: false,
            getLegend: (value: number) => {
                if (value === 1) {
                    return '1 Day';
                } else if (value === 183) {
                    return '6 Months';
                } else if (value === 365) {
                    return '1 Year';
                }
            }
        };
    };

    static callsStorageLevelOptions: any = [
        {value: 'MINIMAL', text: 'Minimal'},
        {value: 'RECOMMENDED', text: 'Recommended'},
        {value: 'MAXIMAL', text: 'Maximal'},
        {value: 'CUSTOM', text: 'Custom'}
    ];

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'callsMonitor/settings/system', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter'});
        CallsStorageRestService.statisticsStatusSlidersData = {
            device: {objName: 'deviceStatisticsStorage', title: ConstantStringsService.deviceOrTeamsSideMany + ' Statistics'},
            links: {objName: 'linksStatisticsStorage', title: ConstantStringsService.linkOrProviderSideMany + ' Statistics'},
            sites: {objName: 'sitesStatisticsStorage', title: 'Sites Statistics'},
            endpoints: {objName: 'endpointsStatisticsStorage', title: 'Endpoints Statistics'},
            users: {objName: 'userStatisticsStorage', title: 'Users Statistics'},
            uriStatisticsStorageInfo: {objName: 'uriStatisticsStorage', title: 'URI Statistics'},
            locationStatisticsStorageInfo: {objName: 'locationStatisticsStorage', title: 'Location Statistics'},
        };

        CallsStorageRestService.statisticsSliderData = [
            {name: 'deviceStatisticsStorage', title: ConstantStringsService.deviceOrTeamsSideMany + ' Statistics'},
            {name: 'linksStatisticsStorage', title: ConstantStringsService.linkOrProviderSideMany + ' Statistics'},
            {name: 'sitesStatisticsStorage', title: 'Sites Statistics'},
            {name: 'endpointsStatisticsStorage', title: 'Endpoints Statistics'},
            {name: 'userStatisticsStorage', title: 'Users Statistics'},
        ];

        if(ServerInfoService.oneLiveMode){
            CallsStorageRestService.statisticsStatusSlidersData.customer = {objName: 'customerStatisticsStorage', title: 'Customer Statistics'};
            CallsStorageRestService.statisticsStatusSlidersData.service = {objName: 'serviceStatisticsStorage', title: 'Service Statistics'};

            CallsStorageRestService.statisticsSliderData.push({name: 'serviceStatisticsStorage', title: 'Service Statistics'});
            CallsStorageRestService.statisticsSliderData.push({name: 'customerStatisticsStorage', title: 'Customer Statistics'});
        }

    }

    public getSystemCallsStorageSettings(success, failure) {
        this.get({skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    }

    public getSystemCallsStorageInfo(success, failure) {
        this.get({skipPopulateFilter: true, build204JSON: false, url: 'callsMonitor/info/system'}).then(success).catch(failure);
    }

    public getSingleTenantCallsStorageInfo(success, failure, id) {
        this.getById({skipPopulateFilter: true, build204JSON: false, url: 'callsMonitor/info/tenants', id}).then(success).catch(failure);
    }

    public getTenantsCallsStorageInfo({success, failure = () => null, ...requestOptions}: RequestOptions) {
        this.get({...requestOptions, extendParameters: true, build204JSON: false, url: 'callsMonitor/info/tenants', filteredEntity: 'tenantCallStorageTable'}).then(success).catch(failure);
    }

    public getTenantsCallsStorageSettings(success, failure, parameters?) {
        this.get({parameters, extendParameters: true, build204JSON: false, url: 'callsMonitor/settings/tenants', filteredEntity: 'tenantCallStorageTable'}).then(success).catch(failure);
    }

    public getSingleTenantCallsStorageSettings(success, failure, id, parameters?) {
        this.getById({parameters, skipPopulateFilter: true, build204JSON: false, url: 'callsMonitor/settings/tenants', id}).then(success).catch(failure);
    }

    public addCallsStorageTenantsSettings(success, failure, sentObject) {
        this.add(success, failure, sentObject, 'callsMonitor/settings/tenants');
    }

    public updateCallsStorageTenantsSettings(success, failure, sentObject, id) {
        this.put(success, failure, sentObject, 'callsMonitor/settings/tenants/' + id);
    }

    public updateSystemCallsStorageSettings(serverCallsStorage: any) {
        const onSuccess = (value) => {
            const message = value.description || 'Calls Storage Settings updated successfully';
            this.acDialogService.info(message);
        };

        this.put(onSuccess, () => {}, serverCallsStorage);
    }

    public calcPercentage = (used, total, onlyUsed = false) => {
        const percentageNumber: number = parseFloat(((used / total) * 100).toFixed(2));
        const usedPercentage: string = percentageNumber.toString();
        const freePercentage: string = (100 - percentageNumber).toFixed(2).toString();

        if(onlyUsed){
            return usedPercentage;
        }

        return 'Used: ' + usedPercentage + '%, Free: ' + freePercentage + '%';
    };

}









