import {inject} from '@angular/core';
import {AcTreeService, ArrayUtil, ThrottleClass, WSMessage} from 'ac-infra';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {Subject} from 'rxjs';
import {untilDestroyed} from '@ngneat/until-destroy';
import {cloneDeep} from 'lodash';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';

export type NodeId = string | number;

export interface FlatNode {
    originalEntity: any;
    children?: Array<NodeId>;
    parent?: { id: NodeId, entityType: string };
}

export interface TreeFilterOptions {
    entitiesToInclude?: any;
    filterEntityTree?: ((entity: any) => boolean);
}

export abstract class TopologyTreeBase {


    protected acTreeService = inject(AcTreeService);
    protected tenantsRestService = inject(TenantsRestService);
    protected wsEntitiesService = inject(WsEntitiesService);
    protected flatTreeMap: { [key: NodeId]: FlatNode };
    protected updateNodesQueue = [];
    protected treeNodesUpdateSubject = new Subject();
    treeNodesUpdate$ = this.treeNodesUpdateSubject.asObservable().pipe(untilDestroyed(this));
    throttledUpdate$ = new ThrottleClass({
        callback: () => {
            this.treeNodesUpdateSubject.next(this.updateNodesQueue);
            this.updateNodesQueue = [];
        },
        destroyComponentOperator: untilDestroyed(this),
        maxRecurrentTime: 3000,
        debounce: 200,
        maxDebounceTime: 600
    });

    protected constructor() {
        this.wsEntitiesService.WSEntitiesUpdateFinished$.pipe(untilDestroyed(this)).subscribe((message: WSMessage) => {
            if (!message || !this.flatTreeMap) {
                return;
            }
            this.onWSEntitiesUpdate(message);
        });
    }

    get initialized(): boolean {
        return !!this.flatTreeMap;
    }

    buildFlatTree(flatMap: any, entities: any | any[], parent = null) {
        if (!entities) {
            return;
        }

        ArrayUtil.oneToMany(entities).forEach(entity => {
            flatMap[entity.id] = {originalEntity: entity};

            this.setParentToFlatTreeMap(flatMap, entity, parent);
            if (this.hasChildren(entity, false)) {
                flatMap[entity.id].originalEntity.hasChildren = true;
                flatMap[entity.id].children = entity.children.map(({id}) => id);

                this.buildFlatTree(flatMap, entity.children, entity);
            }
            delete entity.children;
        });
    }

    updateTreeTenant(topologyTreeData: any, updatedTenantId: NodeId, filterOptions: TreeFilterOptions = {}) {
        const tenantIndex = topologyTreeData.findIndex((treeTenant: any) => treeTenant.id === updatedTenantId);
        const oldTenant = topologyTreeData[tenantIndex];

        if (!oldTenant) { // new tenant
            topologyTreeData.push(cloneDeep(this.flatTreeMap[updatedTenantId].originalEntity))
            return;
        }

        const flatTenant = this.flatTreeMap[oldTenant.id];
        if (!flatTenant) {
            topologyTreeData.splice(tenantIndex, 1);
            return;
        }

        const newTenant = cloneDeep(this.flatTreeMap[oldTenant.id].originalEntity);
        this.recreateNodeChildrenFromOld(newTenant, oldTenant, filterOptions);
        topologyTreeData[tenantIndex] = newTenant;
    }

    setTreeNodes(treeNodes: any[]) {
        if (!treeNodes || this.initialized) {
            return;
        }
        this.flatTreeMap = {};
        this.buildFlatTree(this.flatTreeMap, treeNodes);
        this.emitTreeNodesUpdate();
    }

    getEntityChildren(entityId: NodeId, treeFilterOptions: TreeFilterOptions = {}) {
        const flatNode = this.flatTreeMap[entityId];
        if (!flatNode) {
            return;
        }

        return this.mapChildrenIdsToOriginalEntity(flatNode.children, treeFilterOptions);
    }

    mapChildrenIdsToOriginalEntity(childrenIds: Array<NodeId>, {entitiesToInclude, filterEntityTree}: TreeFilterOptions = {}) {
        return childrenIds?.map((childId: NodeId) => {
            let child = this.flatTreeMap[childId].originalEntity;
            const entityType = child.artificial ? child.entityType : child.entityType.slice(0, -1);
            const allowed = filterEntityTree ? filterEntityTree(child) : true;
            if (entitiesToInclude && !entitiesToInclude[entityType] || !allowed) {
                return;
            }
            child = cloneDeep(child);
            this.filterChildren(child, filterEntityTree);
            return child;
        }).filter((child) => !!child);
    }

    filterChildren(node: any, filterEntityTree: (entity: any) => boolean) {
        if (!filterEntityTree) {
            return
        }

        const children = this.getEntityChildren(node.id, {filterEntityTree})
        const filteredChildren = children?.filter(filterEntityTree);

        if (filteredChildren && filteredChildren.length === 0) {
            node.hasChildren = false;
            node.children = null;
        }
    }

    getTreeNode(nodeId, {recursive = true, entitiesToInclude = null, filterEntityTree = null}: {
        recursive?: boolean,
        entitiesToInclude?: any,
        filterEntityTree?: ((entity: any) => boolean)
    } = {}) {
        const node = cloneDeep(this.flatTreeMap[nodeId]?.originalEntity);

        if (recursive) {
            this.recreateNodeChildren(node, {filterEntityTree});
        } else {
            this.filterChildren(node, filterEntityTree);
        }

        return node;
    }

    getTreeNodes(topologySelection, treeFilterOptions?: TreeFilterOptions) {
        if (!this.flatTreeMap) {
            return;
        }

        const tenantIds = Object.keys(this.tenantsRestService.getAllEntitiesHashed());
        const tenantsAsParents = {};

        Object.entries(topologySelection || []).map(([entityType, topologyNodes]) => {
            if (!Array.isArray(topologyNodes)) {
                return;
            }

            topologyNodes.forEach(node => {
                const rootParent = this.findParent(node);
                tenantsAsParents[rootParent.id] = true;
            });
        });

        return tenantIds.map(tenantId => {
            return this.getTreeNode(tenantId, {recursive: !!tenantsAsParents[tenantId], ...treeFilterOptions});
        });
    }

    clearTreeNodes() {
        this.updateNodesQueue = [];
        this.flatTreeMap = null;
    }

    findParent(node, recursive = true) {
        if (!node) {
            return null;
        }
        let parent = this.flatTreeMap[node.id].parent;

        if (recursive) {
            if (!parent) {
                return node;
            }
            return this.findParent(this.flatTreeMap[parent.id]?.originalEntity);
        }
        return parent ? this.flatTreeMap[parent.id]?.originalEntity : null;
    }

    protected abstract onWSEntitiesUpdate(message: WSMessage): void;

    protected emitTreeNodesUpdate = () => this.throttledUpdate$.tick();

    protected setParentToFlatTreeMap(flatMap: any, entity: any, parent: any = null) {
        if (!parent) {
            return;
        }
        flatMap[entity.id].parent = {id: parent.id, entityType: parent.entityType};
    }

    protected recreateNodeChildrenFromOld(newNode: any, oldNode: any, filterOptions: TreeFilterOptions = {}) {
        const newNodeChildren = this.getEntityChildren(newNode.id, filterOptions);
        if (this.hasChildren(oldNode, false) && this.hasChildren(newNode)) {
            const oldChildrenHash = oldNode.children.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {});

            newNode.children = newNodeChildren;
            newNode.children?.forEach((child: any) => this.recreateNodeChildrenFromOld(child, oldChildrenHash[child.id], filterOptions));
        }
        newNode.hasChildren = newNodeChildren?.length > 0;
    }

    protected recreateNodeChildren(node: any, {entitiesToInclude, filterEntityTree}: TreeFilterOptions = {}) {
        if (!node || !node.hasChildren) {
            return;
        }
        const children = this.getEntityChildren(node.id, {entitiesToInclude, filterEntityTree});

        node.children = children?.length > 0 ? children : null;
        node.hasChildren = children?.length > 0;
        node.children?.forEach((child: any) => this.recreateNodeChildren(child, {entitiesToInclude, filterEntityTree}));
    }

    protected updateOriginalEntity(oldFlatNode, newNode) {
        newNode.hasChildren = oldFlatNode.originalEntity.hasChildren;
        this.flatTreeMap[oldFlatNode.originalEntity.id].originalEntity = newNode;
    }

    protected deleteFlatNodes(deletedIds: number[], nodesForUpdate: any = undefined) {
        deletedIds.forEach((id) => {
            if (!this.flatTreeMap[id]) {
                return;
            }
            const {originalEntity}: any = {...this.flatTreeMap[id]};
            const tenantId = originalEntity.tenantId || originalEntity.id;

            if (!this.updateNodesQueue.includes(tenantId)) {
                this.updateNodesQueue.push(tenantId);
            }

            const parent = this.findParent(originalEntity, false);
            if (parent) {
                this.deleteNode(this.flatTreeMap[parent.id], originalEntity.id);
            }
            delete this.flatTreeMap[id];
        });
    }

    protected addNodeToFlatParentChildren(flatParentNode: FlatNode, node: any) {
        if (!flatParentNode) {
            return;
        }
        flatParentNode.children = flatParentNode.children || [];
        flatParentNode.originalEntity.hasChildren = true;

        this.setParentToFlatTreeMap(this.flatTreeMap, node, flatParentNode.originalEntity);

        if (!flatParentNode.children.includes(node.id)) {
            flatParentNode.children.push(node.id);
        }
    }

    protected deleteNode(parentFlatNode: FlatNode, idToDelete: NodeId) {
        if (!idToDelete || !parentFlatNode || !parentFlatNode.children) {
            return;
        }
        const index = parentFlatNode.children.findIndex(childId => childId === idToDelete);
        parentFlatNode.children.splice(index, 1);

        if (parentFlatNode.children?.length === 0) {
            delete parentFlatNode.children;
            parentFlatNode.originalEntity.hasChildren = false;

            if (parentFlatNode.originalEntity.artificial) {
                delete this.flatTreeMap[parentFlatNode.originalEntity.id];
                const parentId = parentFlatNode.parent.id;
                this.deleteNode(this.flatTreeMap[parentId], parentFlatNode.originalEntity.id);
            }
        }
    }

    private hasChildren(entity, flat = true) {
        if (!entity) {
            return false;
        }
        if (flat) {
            return !!(this.flatTreeMap[entity.id] && this.flatTreeMap[entity.id].children);
        }
        return entity.children && entity.children.length > 0;
    }
}
