import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CallsStorageRestService} from '../../../configuration-api/calls-storage-rest.service';

@Component({
    selector: 'calls-storage-period',
    templateUrl: './calls-storage-period.component.html',
    styleUrls: ['./calls-storage-period.component.less'],
})

export class CallsStoragePeriodComponent {

    @Input() serverCallsStorage: any;
    @Input() uriEnabled = false;
    @Output() uriEnabledChange = new EventEmitter<boolean>();
    @Input() locationEnabled = false;
    @Output() locationEnabledChange = new EventEmitter<boolean>();

    timeOptions: any = CallsStorageRestService.getTimeOptions();
    statisticsSliderData: any = CallsStorageRestService.statisticsSliderData;
}



