<ac-form *ngIf="dialogConfig.ready" #tenantForm
         [auxiliaryModel]="auxiliaryModel"
         [formModel]="tenant"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         formModelName="tenant"
         class="tenant-dialog-form">


    <ac-tabs tabsId="tenant-tab-set">
        <div *acTab="'General';  id:'general-tenant-section-tab'">

            <div class="flexRowParentEqualChildren">
                <ac-input-container formFieldName="tenant.name" label="Tenant Name">
                    <ac-textfield [(acModel)]="tenant.name"
                                  textFieldId="tenant-name">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container formFieldName="tenant.description" label="Description">
                    <ac-textfield [(acModel)]="tenant.description"
                                  textFieldId="tenant-description">
                    </ac-textfield>
                </ac-input-container>
            </div>


            <div class="flexRowParentEqualChildren">
                <ac-input-container *ngIf="!serverInfoService.serverInfo.saasMode" formFieldName="tenant.isDefault"
                                    label="Is Default">
                    <ac-single-select [(acModel)]="tenant.isDefault"
                                      [items]="isDefaultOptions">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container formFieldName="tenant.licensePoolOperatorId" *ngIf="tenantAdminOperatorsIds"
                                    [label]="httpOperatorTitle">
                    <ac-single-select [(acModel)]="tenant.licensePoolOperatorId"
                                      [bindLabel]="'name'"
                                      [bindValue]="'id'"
                                      [items]="tenantAdminOperatorsIds">
                    </ac-single-select>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container formFieldName="tenant.uriRegExp" label="Users URI Regexp">
                    <ac-textfield [(acModel)]="tenant.uriRegExp"
                                  placeholderText="None"
                                  textFieldId="tenant-uriRegExp">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container formFieldName="tenant.msTenantId" label="Azure Tenant ID">
                    <ac-textfield [acModel]="tenant.msTenantId"
                                  (acModelChange)="tenant.msTenantId = $event"
                                  textFieldId="tenant-msTenantId">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <ac-input-container *ngIf="!serverInfoService.serverInfo.saasMode"
                                formFieldName="tenant.tenantAddressSpace.subnetMasks"
                                [ignoreRequired]="true"
                                label="Subnet (CIDR Notation)">
                <ac-select [acModel]="tenant.tenantAddressSpace?.subnetMasks"
                           (acModelChange)="tenant?.tenantAddressSpace ? tenant.tenantAddressSpace.subnetMasks = $event : null"
                           [addCustomTags]="true"
                           [bindLabel]="null" [bindValue]="null"
                           selectionName="tenant.tenantAddressSpace.subnetMasks">
                </ac-select>
            </ac-input-container>

            <ac-input-container *ngIf="serverInfoService.serverInfo.saasMode" formFieldName="tenant.natIp"
                                label="NAT IP">
                <ac-textfield [acModel]="tenant.natIp"
                              (acModelChange)="tenant.natIp = $event"
                              textFieldId="tenant-natIp">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="tenant.domain" label="Domain">
                <ac-textfield [acModel]="tenant.domain"
                              (acModelChange)="tenant.domain = $event"
                              textFieldId="tenant-domain">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="tenant.internalMailServer"
                                label="Internal Mail Server From Address">
                <ac-textfield [(acModel)]="tenant.internalMailServer"
                              textFieldId="tenant-internalMailServer">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="tenant.internalMailServerName" label="Internal Mail Server Real Name">
                <ac-textfield [(acModel)]="tenant.internalMailServerName"
                              textFieldId="tenant-internalMailServerName">
                </ac-textfield>
            </ac-input-container>

            <logo-selection (logoIdChanged)="tenant.logoId = $event"
                            label="Tenant Logo"
                            [logoObject]="logoObject"
                            [tenantId]="additionalSettings.id">
            </logo-selection>

            <ac-checkbox labelText="Privacy Mode"
                         [(acModel)]="tenant.privacyMode"
                         checkboxId="tenant-privacy-mode">
            </ac-checkbox>

            <ac-input-container formFieldName="tenant.maskedDigitsNumber" label="Masked Digits Number">
                <ac-num-spinner [(acModel)]="tenant.maskedDigitsNumber"
                                [ngDisabled]="!tenant.privacyMode"
                                [minValue]="0"
                                numSpinnerId="range-start-maskedDigitsNumber">
                </ac-num-spinner>
            </ac-input-container>

            <ac-input-container *ngIf="oneLiveMode" formFieldName="tenant.dmSourceEmail" label="Source Email">
                <ac-textfield [(acModel)]="tenant.dmSourceEmail"
                              [textFieldId]="'tenant.dmSourceEmail'">
                </ac-textfield>
            </ac-input-container>
        </div>

        <ng-container *ngIf="oneLiveMode">
            <live-cloud-services-tab #liveCloudServicesTab *acTab="'Services';  id:'services-tenant-section-tab'"
                                     [entity]="tenant"
                                     [isEdit]="isEdit"
                                     entityName="tenant"
                                     [operatorConnectEnabledChanged]="operatorConnectEnabledChanged"
                                     [zoomEnabledChanged]="zoomEnabledChanged"
                                     [tenantForm]="tenantForm"
                                     [errors]="errors">
            </live-cloud-services-tab>

            <network-contact-information *acTab="'Contact Info';  id:'network-contact-information-tenant-section-tab'"
                                         [contacts]="tenant.contacts"
                                         modelName="tenant">
            </network-contact-information>
        </ng-container>

        <snmp *acTab="'SNMP';  id:'snmp-section-tab'"
              [isEdit]="isEdit"
              [snmpObject]="tenant.snmpProfile"
              (acChanged)="tenant.snmpProfile = $event"
              componentType="tenant"
              [hasDefaultObject]="true"
              [hasCheckboxForV3Edit]="true"
              snmpName="tenant.snmpProfile"
              snmpTrapFieldName="snmpTrapCommunity"
              snmpV2ProfileName="snmpV2DefaultProfile"
              snmpV3ProfileName="snmpV3DefaultProfile"
              viewType="separators">
        </snmp>
        <http-connectivity *acTab="'HTTP'; id:'http-section-tab'"
                           [httpObject]="tenant.httpProfile"
                           (acChanged)="tenant.httpProfile = $event"
                           httpObjectName="tenant.httpProfile"
                           componentType="tenant"
                           [hasDefaultObject]="true"
                           [deleteWholeHTTPObject]="true"
                           [hasEditHttpCheckbox]="true"
                           [isEdit]="isEdit">
        </http-connectivity>

        <div *acTab="'Operators';  id:'Operators-section-tab'">
            <div *ngIf="!oneLiveMode || isEdit" class="operator-container flexRowParent">
                <ac-input-container formFieldName="tenant.assignedOperators" class="assignedOperators flexItemBiggest"
                                    elementType="ac-select" label="Local Authentication: Assigned Operators"
                                    [isMaterial]="false">
                    <ac-select [(acModel)]="tenant.assignedOperators"
                               [items]="tenantOperatorsOption"
                               selectionName="tenant.assignedOperators">
                    </ac-select>
                </ac-input-container>

                <ac-button (acClick)="addNewOperator()" buttonType="icon-button" class="addNewOperator flexItem">
                    <ac-svg name="operator"
                            title="Add New Operator"
                            [hoverFillColor]="colors.darkPrimary">
                    </ac-svg>
                </ac-button>

            </div>
            <ac-input-container formFieldName="tenant.ldapGroupName" label="AD Authentication: Group Name">
                <ac-textfield [(acModel)]="tenant.ldapGroupName"
                              textFieldId="audio-codes-tenant-group-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="tenant.adGroupId" label="Azure AD Group: Object ID">
                <ac-textfield [(acModel)]="tenant.adGroupId"
                              textFieldId="audio-codes-tenant-adGroupId">
                </ac-textfield>
            </ac-input-container>
        </div>

        <ng-container *ngIf="oneLiveMode">
            <div *acTab="'App Registration';  id:'app-reg-tenant-section-tab'">
                <azure-app-registration [appRegistrationObject]="tenant?.appRegistrationSettings"
                                        formFieldNamePrefix="tenant.appRegistrationSettings"
                                        [liveHubIsEnabled]="tenant?.services?.LIVEHUB?.enabled"
                                        [additionalSettings]="additionalSettings"
                                        [authorizedToUpdate]="true"
                ></azure-app-registration>
            </div>
        </ng-container>

        <tenant-allocation *acTab="'License';  id:'License-section-tab'"
                           [originalTenantLicenseAllocation]="originalTenantLicenseAllocation"
                           [ovocMainLicense]="generalLicense"
                           [tenantLicenseAllocation]="auxiliaryModel.licenseAllocations">
        </tenant-allocation>

        <div *acTab="'Calls Storage Entities';  id:'call-storage-entities-tenant-section-tab'">
            <calls-storage-entities *ngIf="auxiliaryModel.tenantCallStorage"
                                    [tenantCallStorage]="auxiliaryModel.tenantCallStorage">
            </calls-storage-entities>
        </div>

    </ac-tabs>

</ac-form>
