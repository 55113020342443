import {isFunction, orderBy} from 'lodash';

export class ArrayUtil {
    static oneToMany<T = any>(item: any): Array<T | any> {
        if (typeof item === 'string') {
            return [item];
        }
        // tree nodes are observableArray, Array.isArray is false
        return item.concat && isFunction(item.concat) ? item.concat() : [item];
    }

    static arrayToObject = (source: any[], transformFunc: (acc, curr: any) => any, target = {}) => {
        return source?.reduce((acc, curr) => {
            const res: any = transformFunc(acc, curr);
            return res || acc;
        }, target) || target;
    };

    static arrayValuesToObjectMap = (source: any[], key: string = undefined, value = undefined) => {
        const haveValue = value !== undefined
        return ArrayUtil.arrayToObject(source, (acc, curr) => {
            if (key && curr && !Array.isArray(curr) && typeof curr === 'object') {
                acc[curr[key]] = haveValue ? value : curr;
            } else {
                acc[curr] = haveValue ? value : true;
            }
        });
    }

    static removeItemsFromIndex = (source: any[], removeIndex = 0, deleteCount = 1) => {
        return source?.splice(removeIndex, deleteCount);
    }

    static findInArrDelimitered(items: any[], key: string, terms: string) {
        const term_arr = terms.toString().toLowerCase().split(' ').filter(term => !!term)
        if (term_arr.length === 0) {
            return items;
        }
        return items?.filter(item => {
            return term_arr.every(term => item[key]?.toLowerCase().includes(term));
        }) || [];
    }

    static sort(items: any[],key, dir: 'asc' | 'desc' = 'asc') {
        return orderBy(items, [item => item[key].toLowerCase()], [dir])
    }

    static isEmpty(item) {
        return item !== null && item !== undefined;
    }
}
