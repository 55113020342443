import {Component, forwardRef, Input} from '@angular/core';

import * as _ from 'lodash';

import {GeneralService} from 'ac-infra';
import {AcFilterComponent} from '../../../common/components/ac-filter/ac-filter.component';
import {AcFilterType} from '../../../common/components/ac-filter/modals/ac-filter-type.class';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';
import {ServerInfoService} from "../../../common/services/server-info.service";
import {ConstantStringsService} from "../../../common/utilities/constant-strings.service";

@Component({
    selector: 'network-groups-filter',
    templateUrl: './network-groups-filter.component.html',
    providers: [{provide: AcFilterType, useExisting: forwardRef(() => NetworkGroupsFilterComponent)}]
})
export class NetworkGroupsFilterComponent extends AcFilterType {

    @Input() pageName: string;

    filterTitle = 'Groups'
    filterName = 'Groups';

    topologyGroups = {options: [], value: []};

    endpointsGroups = {options: [], value: []};

    serviceGroups = {options: [], value: []};

    lcCustomers = {options: [], value: []};

    siteLocations = {options: [], value: []};

    fieldNameMapper = {
        endpointsGroups: 'endpoints groups',
        topologyGroups: 'topology groups',
        serviceGroups: 'services',
        lcCustomers: 'customers',
        siteLocations: ConstantStringsService.linkOrProviderSideMany,
    };

    oneLiveMode = ServerInfoService.oneLiveMode;

    constructor(private wsEntitiesService: WsEntitiesService, public acFilterComponent: AcFilterComponent) {
        super();

        if (this.oneLiveMode) {
            this.filterTitle = 'Live Cloud';
            this.filterName = 'Services';
            this.lastItem_default = {serviceGroups: [], lcCustomers: [], siteLocations: []};
        } else {
            this.lastItem_default = {endpointsGroups: [], topologyGroups: []};
        }

        this.showPinnable = true;
        this.pinnable = true;
        this.pinned = true;
    }

    hasChanged = () => {
        let hasChanged;

        if (this.oneLiveMode) {
            hasChanged = !_.isEqual(this.serviceGroups.value, this.lastItem.serviceGroups) || !_.isEqual(this.lcCustomers.value, this.lastItem.lcCustomers)
                || !_.isEqual(this.siteLocations.value, this.lastItem.siteLocations);
        } else {
            hasChanged = !_.isEqual(this.endpointsGroups.value, this.lastItem.endpointsGroups) || !_.isEqual(this.topologyGroups.value, this.lastItem.topologyGroups);
        }

        return hasChanged;
    };

    createItem = () => {
        let item;

        if (this.oneLiveMode) {
            item = {serviceGroups: this.serviceGroups.value, lcCustomers: this.lcCustomers.value, siteLocations: this.siteLocations.value};
        } else {
            item = {endpointsGroups: this.endpointsGroups.value, topologyGroups: this.topologyGroups.value};
        }

        return _.isEqual(item, this.lastItem_default) ? undefined : item;
    };

    setStateForItem = (item) => {

        if (this.oneLiveMode) {
            this.serviceGroups.value = item && item.serviceGroups ? item.serviceGroups : [];
            this.lcCustomers.value = item && item.lcCustomers ? item.lcCustomers : [];
            this.siteLocations.value = item && item.siteLocations ? item.siteLocations : [];

            this.lastItem = {
                serviceGroups: this.serviceGroups.value.slice(),
                lcCustomers: this.lcCustomers.value.slice(),
                siteLocations: this.siteLocations.value.slice()
            };
        } else {
            this.topologyGroups.value = item && item.topologyGroups ? item.topologyGroups : [];
            this.endpointsGroups.value = item && item.endpointsGroups ? item.endpointsGroups : [];

            this.lastItem = {
                endpointsGroups: this.endpointsGroups.value.slice(),
                topologyGroups: this.topologyGroups.value.slice(),
            };
        }

    };

    getTitleByField = (field, filter) => {
        if (filter[field]?.length === 1 && ['endpointsGroups', 'topologyGroups', 'serviceGroups', 'lcCustomers', 'siteLocations'].includes(field)) {

            if(this.oneLiveMode){
                switch (field){
                    case 'serviceGroups':
                        return this.wsEntitiesService.getEntityByEntityType('services', filter[field][0]).name;
                    case 'lcCustomers':
                        return this.wsEntitiesService.getEntityByEntityType('lcCustomers', filter[field][0]).name;
                    case 'siteLocations':
                        return this.wsEntitiesService.getEntityByEntityType('links', filter[field][0]).name;
                }
            }else{
                return this.wsEntitiesService.getEntityByEntityType('groups', filter[field][0]).name;
            }
        }
    };
}
