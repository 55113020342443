<ac-form *ngIf="dialogConfig.ready"
         [formModel]="service"
         [formSchema]="schema"
         [isEdit]="isEdit"
         formModelName="service">

    <div id="service-details-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Name" [displayAsInfo]="isEdit" formFieldName="service.serviceName">
                <ac-textfield [(acModel)]="service.serviceName"
                              [ngDisabled]="isEdit" textFieldId="service-name">
                </ac-textfield>
            </ac-input-container>


            <ac-input-container label="Service Source" [displayAsInfo]="true" formFieldName="additionalSettings.serviceType">
                <ac-textfield [(acModel)]="additionalSettings.serviceType"
                              [ngDisabled]="true"
                              textFieldId="service-serviceType">
                </ac-textfield>
            </ac-input-container>
        </div>

        <ac-input-container label="Customer" formFieldName="service.lcCustomerId">
            <ac-single-select [(acModel)]="service.lcCustomerId"
                              [ngDisabled]="isEdit"
                              bindValue="id"
                              bindLabel="name"
                              [items]="lcCustomers">
            </ac-single-select>
        </ac-input-container>

        <ac-input-container label="Description" formFieldName="service.description">
            <ac-textfield [(acModel)]="service.description"
                         textFieldId="service-description">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container label="License Type" formFieldName="service.licenseType">
            <ac-single-select [(acModel)]="service.hybridParams.licenseType"
                              [items]="licenseTypeList">
            </ac-single-select>
        </ac-input-container>
    </div>
</ac-form>
