import {Injectable} from '@angular/core';
import {BuildUrlsService, ConfirmDialogData, PromiseService} from 'ac-infra';
import {GroupsRestService} from '../apis/groups-rest.service';
import * as _ from 'lodash';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {DevicesRestService} from '../apis/devices-rest.service';
import {DeviceSSOActionsService} from './device-SSO-actions.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {Actions} from '../../../common/server-actions/actions';
import {LinksRestService} from '../apis/links-rest.service';
import {
    EntitySelectionDialogComponent,
    EntitySelectionDialogObject
} from '../../dialogs/entity-selection-dialog/entity-selection-dialog.component';
import {ChannelsRestService} from '../apis/channels-rest.service';
import {
    ExternalApplicationsActionsService
} from '../../../system/configuration/configuration-actions/external-applications-actions.service';
import {
    ExternalApplicationsRestService
} from '../../../system/configuration/configuration-api/external-applications-rest.service';
import {SessionService} from '../../../common/services/session.service';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {NetworkGroupDialogComponent} from '../../dialogs/network-group-dialog/network-group-dialog.component';
import {NetworkServiceDialogComponent} from '../../dialogs/network-service-dialog/network-service-dialog.component';
import {NavigationService} from '../../../common/utilities/navigation.service';
import {ServerInfoService} from '../../../common/services/server-info.service';
import {
    SelectLCCustomerDialogComponent
} from '../../dialogs/select-lc-customer-dialog/select-lc-customer-dialog.component';
import {LCCustomersRestService} from '../apis/lc-customers-rest.service';
import {ConstantStringsService} from '../../../common/utilities/constant-strings.service';
import {CellTemplatesService} from '../../../common/utilities/cell-templates.service';
import {MetadataService} from '../../../metadata/metadata.service';
import {CommonColumnTemplatesService} from '../../../common/utilities/common-column-templates.service';
import {AuthGroup} from '../../../common/utilities/session-helper.service';
import {NetworkDataService} from '../data/network-data.service';

@Injectable({providedIn: 'root'})
export class GroupsActions extends Actions {

    visibleFieldsForEndpointsGroupInfo = ['description', 'tenantId', 'ldapGroupName', 'elementsCount'];

    visibleFieldsForTopologyGroupInfo = ['description', 'tenantId', 'elementsCount'];

    visibleFieldsForServiceGroupInfo = ['applicationsStatus.management.serviceDeployStatus', 'fullName', 'lcCustomerId', 'serviceSource', 'licenseType', 'channelName', 'serviceMsTenantId',
        'isEnabled', 'disabledTime', 'serviceProviderDetails', 'serviceTeamsDetails', 'freeDIDs', 'deviceManagerParams.serviceDeviceUrl', 'deviceManagerParams.maxDevicesMeeting',
        'deviceManagerParams.maxDevicesPersonal', 'deviceManagerParams.devicesCount.personal', 'deviceManagerParams.devicesCount.meeting', 'parameters'];

    visibleFieldsForSmartTapService = [
        'smartTapParams.licenseType',
        'smartTapParams.storage',
        'smartTapParams.licenseCount',
    ];

    pmStatusMap = {
        Fail: 'status-error',
        Initializing: 'status-unmonitored',
        'Not Polling': 'status-unmonitored',
        Success: 'status-ok',
        'Not Supported': 'status-unmonitored'
    };

    deployStatusMap = {
        DRAFT: 'status-unmonitored',
        UNKNOWN: 'status-unmonitored',
        WARNING: 'status-warning',
        SUBMITTED: 'status-warning',
        DEPLOYING: 'status-warning',
        IN_PROGRESS: 'status-warning',
        INPROGRESS_RESOURCE_ALLOCATION: 'status-warning',
        INPROGRESS_ACCOUNT_CREATION: 'status-warning',
        INPROGRESS_AWAIT_CONSENT: 'status-warning',
        WAIT_FOR_CUSTOMER: 'status-warning',
        WAIT_FOR_SERVICE: 'status-warning',
        WAIT_FOR_PROVIDER: 'status-warning',
        READY_FOR_DEPLOYMENT: 'status-warning',
        READY_FOR_REMOVE: 'status-warning',
        REMOVING: 'status-warning',
        DELETING: 'status-warning',
        REMOVED: 'status-warning',
        ERROR: 'status-error',
        FAILED: 'status-error',
        CREATE_FAILED: 'status-error',
        DELETE_FAILED: 'status-error',
        NOT_EXIST: 'status-error',
        CONNECTION_ERROR: 'status-error',
        DEPLOYED: 'status-ok',
        READY: 'status-ok',
        COMPLETED: 'status-ok',
        CREATED: 'status-ok',
        DELETED: 'status-ok',
    };
    isGlobalScope;

    constructor(private groupsRestService: GroupsRestService,
                private devicesRestService: DevicesRestService,
                private deviceSSOActionsService: DeviceSSOActionsService,
                private linksRestService: LinksRestService,
                private networkDataService: NetworkDataService,
                private channelsRestService: ChannelsRestService,
                private sessionService: SessionService,
                private navigationService: NavigationService,
                private buildUrlsService: BuildUrlsService,
                private externalApplicationsRestService: ExternalApplicationsRestService,
                private authorizationService: AuthorizationService,
                private cellTemplatesService: CellTemplatesService,
                private commonColumnTemplatesService: CommonColumnTemplatesService,
                private lcCustomersRestService: LCCustomersRestService,
                private tenantsRestService: TenantsRestService) {
        super({entityName: 'group', entityService: groupsRestService, isWsEntity: true});
    }

    networkGroupsFetchFn = (parameters, isService, type, callback, lcCustomerId?) => {
        let url;
        const wrappedParameters: any = {pager: parameters.paging, sort: parameters.sorting, detail: 1};
        if (isService) {
            url = 'topology/services';
        } else {
            wrappedParameters.filter = {type};
        }

        if (lcCustomerId) {
            wrappedParameters.filter = wrappedParameters.filter || {};
            wrappedParameters.filter.lcCustomerId = lcCustomerId;
        }

        this.networkDataService.getGroupsForGrid(wrappedParameters, type, callback, url);
    };

    getServicesColumns = () => {
        this.isGlobalScope = AuthorizationService.isGlobalScope();
        const serviceSourceMapper = MetadataService.getType('ServiceSource');
        const licenseTypesList = MetadataService.getType('ServiceLicenseType');
        const isAuthorizedAccordingToMode = this.authorizationService.validationAccordingToMode({
            ovoc: 'all',
            oneLive: AuthGroup.system_users
        });

        return [
            {field: 'fullName', title: 'Full Name', widthByHeader: true, headerSort: false},
            {field: 'name', title: 'Name', minWidth: 140, headerSort: true},
            {
                field: 'serviceSource', title: 'Service Type', widthByHeader: true, headerSort: true,
                formatter: this.cellTemplatesService.iconAndTextTemplateWithMapper({
                    mapper: serviceSourceMapper,
                    componentInputs: {textClass: 'service-source-'}
                })
            },
            this.commonColumnTemplatesService.getCustomerColumn({minWidth: 120}),
            this.commonColumnTemplatesService.getStatusColumn({widthByHeader: true, headerSort: true}),
            {
                field: 'serviceDeployStatus',
                title: 'Deploy status',
                widthByHeader: true,
                headerSort: false,
                formatter: this.cellTemplatesService.differentNameTemplateFn('applicationsStatus.management.serviceDeployStatus', this.cellTemplatesService.statusTemplateFn, this.deployStatusMap),
            },
            {
                field: 'licenseType',
                title: 'License Type',
                widthByHeader: true,
                headerSort: false,
                formatter: this.cellTemplatesService.complexDictionaryTemplateFn(licenseTypesList, 'viewName')
            },
            {
                field: 'isEnabled',
                title: 'Enabled',
                widthByHeader: true,
                headerSort: false,
                formatter: this.cellTemplatesService.booleanTemplateFn()
            },
            this.commonColumnTemplatesService.getTenantColumn({isActive: isAuthorizedAccordingToMode}),
            {
                field: 'attachedLinks',
                title: ConstantStringsService.linkOrProviderSideMany,
                widthByHeader: true,
                headerSort: false,
                isActive: !this.isGlobalScope,
                formatter: this.cellTemplatesService.nameTemplateFromService({restService: this.linksRestService})
            },
            {
                field: 'attachedDevices',
                title: ConstantStringsService.deviceOrTeamsSide,
                widthByHeader: true,
                headerSort: false,
                isActive: !this.isGlobalScope,
                formatter: this.cellTemplatesService.nameTemplateFromService({restService: this.devicesRestService})
            }
        ];
    };

    editGroup = (group) => {
        const success = (response: RestResponseSuccess) => this.openGroupDetailsDialog(response.data);
        const failure = () => {
        };
        this.groupsRestService.getById({success, failure, id: group.id});
    };

    openGroupDetailsDialog = (group, type?) => {
        const tenants = this.tenantsRestService.getAllEntities();
        if (tenants === undefined || (tenants && tenants.length === 0)) {
            this.acDialogService.info('Can not add groups when there are no tenants.');
            return;
        }

        const isEdit = !_.isEqual(group, {});
        const id = group.id;

        const emptyGroup: any = {name: '', type};
        if (type === 'TOPOLOGY') {
            emptyGroup.attachedDevices = [];
            emptyGroup.attachedLinks = [];
            emptyGroup.attachedSites = [];
        }

        const endpointsGroupToSend = isEdit ? _.cloneDeep(group) : emptyGroup;

        if (isEdit) {
            this.cleanEmptyValues(endpointsGroupToSend);
        }

        const dialogData = {entity: endpointsGroupToSend, isEdit};
        const serverCallback = (onSuccess, onFailure) => {
            if (isEdit) {
                this.groupsRestService.edit(onSuccess, onFailure, dialogData.entity, id);
            } else {
                this.groupsRestService.add(onSuccess, onFailure, dialogData.entity);
            }
        };
        this.genericAction({
            dialogComponentType: NetworkGroupDialogComponent,
            serverCallback,
            dialogData,
        });
    };

    editService = (lcCustomer, pageName = undefined, service = undefined) => {
        this.getUMPDeviceAndOpenSSOConnection('ovoc_ovl_edit', lcCustomer.tenantId, pageName, lcCustomer.id, service);
    };

    addServiceSettings = (type, lcCustomer = undefined) => {
        this.openServiceDetailsDialog(undefined, type, lcCustomer);
    };

    editServiceSettings = ({service, type}) => {
        const serviceURL = 'topology/services';
        const success = (response: RestResponseSuccess) => {
            this.openServiceDetailsDialog(response.data, type);
        };
        this.groupsRestService.getById({
            url: serviceURL,
            success,
            extendParameters: true,
            skipPopulateFilter: true,
            id: service.id
        });
    };

    openServiceDetailsDialog = (service = undefined, type, lcCustomer = undefined) => {
        const isEdit = !!service;
        const serviceURL = isEdit ? 'topology/services' : 'topology/actions/createService';
        const id = service?.id;
        const lcCustomers = this.getLCCustomersThatTheirServiceIsEnabled({serviceType: type});
        const dialogData: any = {
            entity: (service || {
                serviceName: '',
                operatorName: this.sessionService.activeSession.name,
                hybridParams: {licenseType: 'ACM'}
            }), lcCustomers, type, isEdit
        };

        if(isEdit){
            dialogData.entity.hybridParams = {licenseType: service.licenseType};
        }

        if(lcCustomer){
            dialogData.lcCustomer = lcCustomer;
        }

        const serverCallback = (onSuccess, onFailure) => {
            if (ServerInfoService.oneLiveMode && !ServerInfoService?.serverInfo?.meteringEnabled) {
                delete dialogData.entity?.meteringTag;
            }

            if (isEdit) {
                this.groupsRestService.edit(onSuccess, onFailure, dialogData.entity, id, serviceURL);
            } else {
                this.groupsRestService.add(onSuccess, onFailure, dialogData.entity, serviceURL);
            }
        };
        const dialogComponentType = NetworkServiceDialogComponent;

        this.genericAction({serverCallback, dialogData, dialogComponentType});
    };

    deleteServices = (selection) => {
        this.delete({
            entityArray: selection, isDeleteSingle: false,
            confirmMessage: 'This operation will delete service’s devices, entire statistics history and it is non - reversible. Are you sure?',
            url: 'topology/services'
        });
    };

    deleteProviderSide = (service) => {
        const siteLocations = this.linksRestService.getAllEntities(service.attachedLinks);
        const dialogData: EntitySelectionDialogObject = {
            entity: {id: -1, name: ''},
            items: siteLocations,
            selectLabel: ConstantStringsService.linkOrProviderSide
        };

        if (!siteLocations || siteLocations.length === 0) {
            this.acDialogService.info('Selected service doesn\'t have any ' + ConstantStringsService.linkOrProviderSideMany);
        } else {
            this.acDialogService.open(EntitySelectionDialogComponent, {
                onSubmit: () => {
                    const selectedSiteLocation = dialogData.entity?.id > 0 && this.linksRestService.getEntityById(dialogData.entity.id);
                    if (selectedSiteLocation?.serviceInfo?.umpSiteLocationId) {
                        this.groupsRestService.deleteById({
                            id: dialogData.entity.id,
                            url: 'topology/services/' + service.id + '/serviceSiteLocations'
                        });
                    }
                },
                dialogData,
                id: 'select-service-location-dialog',
                title: 'Select ' + ConstantStringsService.linkOrProviderSide
            });
        }
    };

    deleteTeamsSide = (service) => {
        const teams = service.attachedDevices && service.attachedDevices.length > 0 ? this.devicesRestService.getEntityById(service.attachedDevices[0]) : undefined;

        if (!teams) {
            this.acDialogService.info('Selected service doesn\'t have any teams attached');
        } else if (teams) {
            this.groupsRestService.remove(() => {
            }, () => {
            }, 'topology/services/' + service.id + '/serviceTeamsDevice');
        }
    };

    openSelectCustomersDialog = (service, type) => {
        const customers = this.lcCustomersRestService.getAllEntities().filter((customer) => customer.tenantId === service.tenantId && customer?.services?.[type]);

        const dialogData: EntitySelectionDialogObject = {
            entity: {id: service.lcCustomerId || -1, name: ''},
            items: customers,
            selectLabel: 'Customers'
        };
        this.acDialogService.open(EntitySelectionDialogComponent, {
            onSubmit: ({entity}) => {
                entity?.id && this.updateServiceCustomer(service, entity.id);
            },
            dialogData,
            id: 'select-customers-dialog',
            title: 'Select Customer'
        });
    };

    updateServiceCustomer = (service, lcCustomerId) => {
        this.groupsRestService.edit(() => {
        }, () => {
        }, {lcCustomerId}, service.id, 'topology/services');
    };

    getLCCustomer = (lcCustomers, service, dialogTitle, callback) => {
        if (lcCustomers.length === 0) {
            this.acDialogService.info('No compatible customers found.');
            return;
        }

        if (service || lcCustomers.length === 1) {
            const lcCustomerId = service && service.lcCustomerId || lcCustomers[0].id;
            const lcCustomer = lcCustomerId && this.lcCustomersRestService.getEntityById(lcCustomerId);
            lcCustomer && callback(lcCustomer);
        } else {
            this.openCustomerDialog(lcCustomers, dialogTitle, callback);
        }
    };

    openCustomerDialog = (lcCustomers, dialogTitle, callback) => {
        const dialogData = {entity: {id: undefined}, lcCustomers, title: dialogTitle};
        this.acDialogService.open(SelectLCCustomerDialogComponent, {
            onSubmit: () => {
                if (dialogData.entity.id) {
                    const lcCustomer = this.lcCustomersRestService.getEntityById(dialogData.entity.id);
                    lcCustomer && callback(lcCustomer);
                }
            },
            dialogData
        });
    };

    getUMPDeviceAndOpenSSOConnection = (actionName, selectedTenantId, pageName = undefined, lcCustomerId = undefined, service = undefined) => {
        const filter = {familyType: ['UMP'], tenantId: [selectedTenantId]};
        this.getDeviceWithFilter(filter).then((UMPDevice: any) => {
            if (UMPDevice) {
                this.deviceSSOActionsService.startEntityServlet(UMPDevice, (value) => {
                    let partialUrl = UMPDevice.ssoUrl + (actionName || '') + '/';
                    const params: any = {page: pageName, customerId: service?.serviceId};
                    if(lcCustomerId){
                        params.lcCustomerId = lcCustomerId;
                    }

                    partialUrl = partialUrl +  this.buildUrlsService.addQueryParams(params);

                    const url = this.deviceSSOActionsService.buildSSOUrl(partialUrl);
                    this.deviceSSOActionsService.openEntityInNewTab(url);
                });
            } else {
                this.acDialogService.info('No UMP Device found for this tenant');
            }
        });
    };

    getDeviceWithFilter = (filter) => {
        const deferred = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            deferred.resolve(response.data.devices[0]);
        };

        this.devicesRestService.getEntitiesWithFilter(onSuccess, () => {
            deferred.resolve();
        }, {filter});

        return deferred.promise;
    };


    serviceAction({lcCustomer = undefined, service = undefined, page = 'create', type}: {
        lcCustomer?: any;
        service?: any;
        page: 'create' | 'overview' | 'edit' | 'delete' | 'management' | 'customer';
        type: 'MEETING_INSIGHTS' | 'STNG';
    }) {
        const filter = {productType: [type], tenantId: [lcCustomer.tenantId]};
        this.getDeviceWithFilter(filter).then((device: any) => {
            const onSuccess = (response: RestResponseSuccess) => {
                if (device) {
                    const access_token = response.data.accessToken;
                    const refresh_token = response.data.refreshToken;
                    const code = this.sessionService.activeSession.sessionId;
                    const parameters: any = {access_token, refresh_token, code, lcCustomerId: lcCustomer.id};

                    let url = device.ssoUrl;

                    if (page !== 'customer') {
                        url = url + 'tenant-management';
                    }

                    if (['overview', 'edit', 'delete'].includes(page)) {
                        url = url + '/' + service.serviceId + '/' + page;
                    } else if (page === 'create') {
                        url = url + '/' + page;
                    }

                    this.navigationService.openNewWindow(url, parameters);
                } else {
                    this.acDialogService.info('No Device found for this tenant');
                }
            };

            this.externalApplicationsRestService.getIAMToken(onSuccess, () => null, type, device?.id);
        });
    }

    openGeneralDeviceManagerPage(service = undefined) {
        const tenants = this.tenantsRestService.getAllEntities();
        const services = this.groupsRestService.getAllEntities(undefined, false, 'services');

        const tenantId = (tenants?.length === 1 ? tenants[0].id : undefined);
        const serviceId = service?.serviceId || (services?.length === 1 ? services[0].serviceId : undefined);

        this.openDeviceManagerPage('general', tenantId, serviceId);
    }

    openDeviceManagerPage = (page, tenantId = undefined, serviceId = undefined, channelMsTenantId = undefined, lcCustomerId = undefined) => {
        const params: any = {page, code: this.sessionService.activeSession.sessionId, tenantId, lcCustomerId, customerId: serviceId, channelMsTenantId};

        const deviceManagerURL = ExternalApplicationsActionsService.getDeviceManagerURL() +  this.buildUrlsService.addQueryParams(params);

        console.log('SSO URL-', deviceManagerURL);// don't remove - for QA and UI use
        window.open(deviceManagerURL);
    };

    openZoomPage = ({tenant = undefined, lcCustomer = undefined, pageName = 'leads', serviceType = 'ZOOM'}) => {
        let channelId;

        this.externalApplicationsRestService.getZoomConfiguration((response) => {
            const zoomApp = response.data.applicationStatus;
            const sessionId = this.sessionService.activeSession.sessionId;
            let url = zoomApp.ssoUrl;

            const params: any = {
                page: pageName,
                code: sessionId,
                serviceType,
                tenantId: (lcCustomer?.tenantId || tenant?.id),
                lcCustomerId: lcCustomer?.id && pageName !== 'numbers' ? lcCustomer.id : undefined
            };

            if (this.authorizationService.validForChannel()) {
                const channels = this.channelsRestService.getAllEntities();
                channelId = channels.length === 1 ? channels[0]?.id : undefined;
                params.channelId = channelId;
            }

            url = url + this.buildUrlsService.addQueryParams(params);
            this.deviceSSOActionsService.openEntityInNewTab(url);
        },
        () => {});
    };

    disableService = (service) => {
        const confirmMessage = 'Endpoints will no longer be able to be assigned to a disabled service, Are you sure?';

        const onSuccessCallback = (response) => {
            if (response.status === 202) {
                this.acDialogService.info(response.data.description);
            }
        };

        const serverCallback = (onSuccess, onFailure) => {
            this.groupsRestService.serviceRestAction(onSuccess, onFailure, [service], 'disableService');
        };
        const dialogData: ConfirmDialogData = {entitiesArray: [service], confirmMessage, onSuccessCallback};
        this.genericConfirmAction({serverCallback, dialogData, dialogConfig: {submitButtonText: 'disable'}});
    };

    getLCCustomersThatTheirServiceIsEnabled = ({lcCustomers = [], service = undefined, serviceType}) => {
        if (service) {
            lcCustomers = [this.lcCustomersRestService.getEntityById(service.lcCustomerId)];
        } else {
            lcCustomers = (lcCustomers?.length > 0 ? lcCustomers : this.lcCustomersRestService.getAllEntities()).filter((lcCustomer) => {
                return lcCustomer?.services?.[serviceType];
            });
        }

        return lcCustomers;
    };
}
