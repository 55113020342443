import {cloneDeep, isArray, isEmpty, isEqual} from 'lodash';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {inject} from '@angular/core';
import {AuthorizationService} from '../services/authorization.service';

export class FilterFormatter {

    public filterState = inject(FilterState);
    public authorizationService = inject(AuthorizationService);
    setFilter: (resultFilterObject, filterObject, callingApi, saveToFile) => void;
    setFilterForDynamicTab: (resultFilterObject, filterObject, callingApi) => void;

    constructor({setFilter, setFilterForDynamicTab}) {
        this.setFilter = setFilter;
        this.setFilterForDynamicTab = setFilterForDynamicTab;
    }

    getFilterParameter = (callingApi, eventName, saveToFile?) => {
        return this.format(this.getAggregatedFilter(eventName), callingApi, saveToFile);
    };

    format = (filterObject, callingApi, saveToFile = false) => {
        const resultFilterObject = {};
        filterObject = this.initFilterObject(filterObject);

        if (callingApi.startsWith('dynamicTab')) {
            this.setFilterForDynamicTab(resultFilterObject, filterObject, callingApi);
        } else {
            this.setFilter(resultFilterObject, filterObject, callingApi, saveToFile);
        }

        return this.checkIfResultFilterIsEmptyObject(resultFilterObject);
    };

    initFilterObject = (filterObject) => {
        if (!this.filterParameterExists(filterObject)) {
            filterObject = {};
        }

        return filterObject;
    };

    addTimeAndDateFilter = (filterObject, resultFilterObject, fromFieldName = 'timestamp', toFieldName?) => {
        const timeFilter = filterObject && filterObject.timeRange;

        if (timeFilter) {
            if (toFieldName !== undefined) {
                resultFilterObject[fromFieldName] = {operator: '>=', value: timeFilter.from};
                resultFilterObject[toFieldName] = {operator: '<', value: timeFilter.to};
            } else {
                resultFilterObject[fromFieldName] = {operators: ['>=', '<'], values: [timeFilter.from, timeFilter.to]};
            }
        }
    };

    getAggregatedFilter = (eventName: string) => {
        const filterFromState = this.filterState.get(eventName);
        return cloneDeep(FilterFormatter.aggregateFilterObject(filterFromState));
    };

    checkIfResultFilterIsEmptyObject = (resultFilterObject) => !isEqual(resultFilterObject, {}) ? resultFilterObject : null;

    assignStringToFilter = (resultObject, field, array, operator = '~') => {
        if (isArray(array) && array.length > 0) {
            resultObject[field] = {operator, value: array.map((item) => '\'' + item + '\'')};
        }
    };

    assignToFieldIfArray = ({
        resultFilterObject,
        field,
        array,
        saveToFile = undefined,
        mapByProperty = 'id',
        mapEnumObject = undefined
    }) => {
        const fieldDest = saveToFile !== undefined ? this.generateFieldName(field, saveToFile) : field;

        if (isArray(array) && array.length > 0) {
            const result = mapEnumObject ? array.map((item) => mapEnumObject[field][item]) :
                this.mapByProperty(array, (mapByProperty === 'none' ? undefined : mapByProperty));

            if(resultFilterObject[fieldDest]){
                resultFilterObject[fieldDest].concat(result);
            }else{
                resultFilterObject[fieldDest] = result;
            }
        }
    };


    assignArrayToFilterById = (resultObject, field, array, operator?) => {
        if (isArray(array) && array.length > 0) {
            const mappedArray = this.mapByProperty(array, 'id');

            resultObject[field] = {value: mappedArray, operator};
        }
    };

    appendToFilter = (resultObject, field, array, property = undefined) => {
        if (isArray(array) && array.length > 0) {
            const mappedArray = this.mapByProperty(array, property);

            resultObject[field] = resultObject[field] || [];
            resultObject[field].push(resultObject[field], mappedArray);
        }
    };

    addTopologyOrServiceGroupIdsFilter = (filterObject: any, resultFilterObject: any, callingApi?) => {
        if (['endpointGroups', 'channel'].includes(callingApi)) {
            return;
        }

        const groupIdsFilter = filterObject.Groups && filterObject.Groups.topologyGroups;
        if (groupIdsFilter && groupIdsFilter.length > 0) {
            const objectName = callingApi && callingApi === 'topologyGroups' ? 'id' : 'groupId';
            resultFilterObject[objectName] = groupIdsFilter;
        }
    };

    addStringFilterParameter = (filterObject, filterField, resultFilterObject, serverFilterField, parent) => {
        const stringFilter = filterObject[parent] && filterObject[parent][filterField];

        if (!isEmpty(stringFilter)) {
            resultFilterObject[serverFilterField] = {operator: ':', value: '\'' + stringFilter + '\''};
        }
    };

    addLiveCloudTopologyFilter = (filterObject: any, resultFilterObject: any, callingApi: string, entitiesList: any[], isQOE = false, isAlarm = false) => {
        const liveCloudTopologyFilter = filterObject.LiveCloudTopology;

        if(liveCloudTopologyFilter){
            if(this.authorizationService.isSystemLevel() || this.authorizationService.isTenantUser()){
                this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: liveCloudTopologyFilter.tenant});
            }

            if(['networkTenants', 'tenantDeviceBackup', 'tenantAllocations', 'tenantCallStorageTable', 'clusters', 'downtimes'].includes(callingApi)){
                return;
            }

            entitiesList.forEach((entityType) => {
                if(entityType === 'channel' && this.authorizationService.isCustomerOperatorLevel()) {
                    return;
                }

                if (liveCloudTopologyFilter[entityType] && liveCloudTopologyFilter[entityType].length > 0) {
                    let fieldName = entityType === 'service' ? 'groupId' : (callingApi.includes(entityType) ? 'id' : entityType + 'Id');

                    if(entityType === 'link'){
                        if(callingApi.includes('endpoints')){
                            return;
                        }

                        fieldName = this.checkLiveLinkEntityFilter(fieldName, callingApi, isQOE, isAlarm);
                    }

                    this.assignToFieldIfArray({resultFilterObject, field: fieldName, array: liveCloudTopologyFilter[entityType]});
                }

            });
        }
    };

    checkLiveLinkEntityFilter = (fieldName, callingApi, isQOE, isAlarm) => {
        if(callingApi.includes('siteLocation')){
            fieldName = 'id';
        } else if(isQOE){
            fieldName = 'siteLocationId';
        } else if(isAlarm){
            fieldName = 'unitId';
        }

        return fieldName;
    };

    static aggregateFilterObject = (filterObject) => {
        const aggregatedFilterObject = {};

        Object.getOwnPropertyNames((filterObject || {})).forEach((type) => {
            const unpinned = filterObject[type].unpinned;
            const pinned = filterObject[type].pinned;

            aggregatedFilterObject[type] = unpinned !== undefined ? unpinned : pinned;
        });
        return aggregatedFilterObject;
    };

    mapByProperty = (array, property) => array.map((entity) => property ? entity[property] : entity);


    addArrayFilterParameter = (filterObject, filterField, resultFilterObject, serverFilterField, parent) => {
        const arrayFilter = filterObject[parent] && filterObject[parent][filterField];

        if (!isEmpty(arrayFilter)) {
            resultFilterObject[serverFilterField] = arrayFilter.map((value) => value);
        }
    };

    private generateFieldName = (field, saveToFile) => {
        const property = saveToFile ? field + 's' : field + 'Id';
        return saveToFile ? (property.charAt(0).toUpperCase() + property.slice(1)) : property;
    };

    private filterParameterExists = (filterObject) => filterObject !== undefined && filterObject !== null && !isEqual(filterObject, {});
}
