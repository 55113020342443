import {Injectable} from '@angular/core';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {cloneDeep, isFunction} from 'lodash';
import {ChannelsRestService} from '../apis/channels-rest.service';
import {LCCustomersRestService} from '../apis/lc-customers-rest.service';
import {LinksRestService} from '../apis/links-rest.service';
import {ArrayUtil, StringUtils} from 'ac-infra';
import {GroupsRestService} from '../apis/groups-rest.service';
import {ConstantStringsService} from "../../../common/utilities/constant-strings.service";
import {TreeFilterOptions} from '../topology/topology-tree-base';


export type TreeEntityType = 'tenant' | 'channel' | 'lcCustomer' | 'service' | 'siteLocation' | 'link';

export interface FlatEntities {
    [id: string | number]: any;
}

export type FlatEntitiesByType = {
    [entity in TreeEntityType]?: FlatEntities;
}

export interface TreeEntityInstruction {
    parents?: TreeEntityType[];
    artificialParentType?: TreeEntityType;
    artificialName?: string | (() => string);
    getEntities?: (tenantId: number) => FlatEntities;
    iconNameField?: string;
}

export type TreeEntitiesInstructions = {
    [entity in TreeEntityType]?: TreeEntityInstruction;
};

export const entityTypesListLiveCloud: TreeEntityType[] = ['tenant', 'channel', 'lcCustomer', 'service', 'link'];

@Injectable({providedIn: 'root'})
export class NetworkLiveCloudDataService {

    static WS_ENTITY_TYPE_MAP = {
        customer: 'lcCustomer',
    };

    treeEntitiesInstructions: TreeEntitiesInstructions;

    constructor(private tenantsRestService: TenantsRestService,
                private channelsRestService: ChannelsRestService,
                private lcCustomersRestService: LCCustomersRestService,
                private linksRestService: LinksRestService,
                private groupsRestService: GroupsRestService) {

        this.treeEntitiesInstructions = {
            tenant: {
                getEntities: (tenantId) => this.tenantsRestService.getEntitiesByTenantId(tenantId, {hashed: true})
            },
            channel: {
                parents: ['tenant'],
                artificialParentType: 'tenant',
                getEntities: (tenantId) => this.channelsRestService.getEntitiesByTenantId(tenantId, {hashed: true})
            },
            lcCustomer: {
                parents: ['tenant', 'channel'],
                artificialParentType: 'tenant',
                artificialName: 'Customers',
                getEntities: (tenantId) => this.lcCustomersRestService.getEntitiesByTenantId(tenantId, {hashed: true})
            },
            service: {
                parents: ['lcCustomer'],
                iconNameField: 'serviceSource',
                getEntities: (tenantId) => this.groupsRestService.getEntitiesByTenantId(tenantId, {hashed: true, entityType: 'services'}),
            },
            link: {
                parents: ['service'],
                artificialParentType: 'service',
                artificialName: () => ConstantStringsService.linkOrProviderSideMany,
                getEntities: (tenantId) => this.linksRestService.getEntitiesByTenantId(tenantId, {hashed: true})
            },
        }
    }

    getTopologyTreeData = (entitiesType?: TreeEntityType[], tenantId = null, treeFilterOptions: TreeFilterOptions = {}) => {
        if (!entitiesType) {
            entitiesType = entityTypesListLiveCloud;
        }

        const entitiesCache = ArrayUtil.arrayToObject(entitiesType, (cache, entityType) => {
            const {getEntities} = this.treeEntitiesInstructions[entityType];
            cache[entityType] = cloneDeep(getEntities(tenantId));
        });

        entitiesType.forEach((entityType) => {
            const {parents} = this.treeEntitiesInstructions[entityType];
            if (!parents) {
                return;
            }
            const entities = entitiesCache[entityType];
            const parentEntities = ArrayUtil.arrayToObject(parents, (cache, parentType) => {
                return {...cache, ...entitiesCache[parentType]};
            });
            this.fillParents(parentEntities, entities, entityType, treeFilterOptions);
        });

        const tenants = Object.values(entitiesCache.tenant);
        if (!treeFilterOptions.filterEntityTree) {
            return tenants;
        }

        return tenants.filter((entity) => treeFilterOptions.filterEntityTree(entity));
    }

    fillParents = (parents: FlatEntities, children: FlatEntities, entityType: TreeEntityType, {filterEntityTree}: TreeFilterOptions = {}) => {
        if (!children || !parents) {
            return;
        }

        const flatArtificialParents = {};
        const artificialParentType = this.treeEntitiesInstructions[entityType].artificialParentType;

        Object.values(children).forEach((child) => {
            if (filterEntityTree && !filterEntityTree(child)) {
                return;
            }

            const parentId = this.getNativeParentId(child);
            let parent = parents[parentId];

            if (!parent) {
                return;
            }

            if (!parent.children) {
                parent.children = [];
            }

            if (artificialParentType === parent.entityType) {
                let artificialParent = flatArtificialParents[parent.id];

                if (!artificialParent) {
                    artificialParent = this.getBaseArtificialParent(parent, child);
                    flatArtificialParents[parent.id] = artificialParent
                    parent.children.push(artificialParent)
                }
                artificialParent.haveHybridEntities = artificialParent.haveHybridEntities || this.isEntityHybrid(child);
                artificialParent.children.push(child);
            } else {
                parent.children.push(child);
            }
        });
    }

    getBaseArtificialParent = (parent: any, child: any) => {
        const entityType = child.entityType + 's';
        const id = `${parent.entityType}-${parent.id}-${entityType}`;

        let artificialName = this.treeEntitiesInstructions[child.entityType].artificialName || entityType;
        if (isFunction(artificialName)) {
            artificialName = artificialName();
        }

        return {
            artificial: true,
            id,
            entityType,
            name: StringUtils.toTitleCase(artificialName, {onlyLeadLetter: true}),
            children: [],
            haveHybridEntities: false,
        }
    }

    getNativeParentId = (entity: any): number | string => {
        switch (entity.entityType) {
            case 'link': {
                const [serviceId] = entity.assignedGroups || [];
                return serviceId;
            }
            case 'channel': {
                return entity.tenantId;
            }
            case 'lcCustomer': {
                if (!!entity.channelId && entity.channelId >= 0) {
                    return entity.channelId;
                }
                return entity.tenantId;
            }
            case 'service': {
                return entity.lcCustomerId;
            }
        }
    }

    isEntityHybrid = (entity: any): boolean => {
        switch (entity.entityType) {
            case 'tenant': {
                return !!(entity.services?.HYBRID_ENTITIES.enabled);
            }
            case 'channel': {
                return !!(entity.services?.HYBRID_ENTITIES.enabled);
            }
            case 'lcCustomer': {
                return !!(entity.services?.HYBRID_ENTITIES);
            }
            case 'service': {
                return entity.serviceSource === 'HYBRID_ENTITIES';
            }
        }
        return false;
    }

    getIconFieldMap() {
        return ArrayUtil.arrayToObject(Object.entries(this.treeEntitiesInstructions), (acc, [key, value]) => {
            if (value.iconNameField) {
                acc[key] = value.iconNameField;
            }
        });
    }

    needArtificialParent = (entityType: TreeEntityType, parentEntityType: TreeEntityType = null) => {
        return this.treeEntitiesInstructions[entityType].artificialParentType === parentEntityType;
    }

    onlyHybridFilter = (entity: any) => {
        if (entity.artificial) {
            return entity.haveHybridEntities;
        }

        switch (entity.entityType) {
            case 'channel': {
                return !!(entity.services?.HYBRID_ENTITIES.enabled);
            }
            case 'lcCustomer': {
                return entity.services?.HYBRID_ENTITIES;
            }
            case 'service': {
                return entity.serviceSource === 'HYBRID_ENTITIES';
            }
        }
        return true;
    }
}
