import {Injectable} from '@angular/core';
import {FilterObjectCacheService} from './filter-object-cache.service';
import {PromiseService, RequestOptions} from 'ac-infra';
import {Rest, RestResponseFailure, RestResponseSuccess} from '../server-actions/rest';

declare let window: any;

@Injectable({providedIn: 'root'})
export class FilterObjectService extends Rest {


    private METHODS_MAP = {
        get: 'get',
        put: 'update',
        post: 'add',
        delete: 'remove'
    };

    constructor(public filterObjectCacheService: FilterObjectCacheService) {
        super({url: ''});
    }

    getShortenURL = (requestUrl) => {
        const filter = this.extractFilterFromURL(requestUrl);
        const decoded = window.decodeURIComponent(filter);
        const filterID = this.filterObjectCacheService.getFilterById(decoded);
        const deferred = PromiseService.defer();

        if (filterID) {
            deferred.resolve(this.replaceFilterWIthFilterId(requestUrl, filterID));
        } else {
            this.addNewFilterOnServer(requestUrl, decoded, deferred);
        }

        return deferred.promise;
    };

    addNewFilterOnServer = (requestUrl, filter, deferred) => {
        const onSuccess = (response: RestResponseSuccess) => {
            this.manageFetchedFilterObject(requestUrl, filter, response.data, deferred.resolve);
        };
        const onFailure = (error: RestResponseFailure) => {
            if (deferred.reject) {
                deferred.reject(error);
            }
        };

        this.add(onSuccess, onFailure, {filter}, 'filters');
    };

    manageFetchedFilterObject = (requestUrl, filter, filterObject, resolve) => {
        this.filterObjectCacheService.setFilter({filter, id: filterObject.id});
        resolve(this.replaceFilterWIthFilterId(requestUrl, filterObject.id));
    };


    addNewFilterAddRequestAgain = (requestOptions) => {
        const filter = this.getLastFilterById(requestOptions.url);
        const decoded = window.decodeURIComponent(filter);

        const onSuccess = (response: RestResponseSuccess) => {
            this.reRunRequest(requestOptions.url, filter, this.METHODS_MAP[requestOptions.method], response.data, requestOptions.defer, requestOptions);
        };
        const onFailure = (error: RestResponseFailure) => {
            if (requestOptions.defer.reject) {
                requestOptions.defer.reject(error);
            }
        };

        this.add(onSuccess, onFailure, {filter: decoded}, 'filters');
    };

    reRunRequest = (requestUrl, filter, methodName, filterObject, defer, requestOptions: RequestOptions) => {
        const onSuccess = (value) => {
            defer.resolve({data: value.data, status: value.status});
        };
        const onFailure = (error) => {
            defer.reject({data: error.data, status: error.status});
        };
        requestOptions.url = this.replaceFilterWIthFilterId(requestUrl, filterObject.id);
        this.filterObjectCacheService.setFilter({filter, id: filterObject.id});
        this.connection[methodName](requestOptions).then(onSuccess).catch(onFailure);
    };


    extractFilterFromURL = (url) => {
        const urlArray = url.split('?');
        const urlParameters = urlArray.length > 0 ? urlArray[1].split('&') : [];
        const filterParameter = urlParameters.find(this.byFilterParameter) || '';

        return filterParameter.slice(8, filterParameter.length - 1);
    };

    getLastFilterById = (url) => {
        const urlArray = url.split('?');
        const urlParameters = urlArray.length > 0 ? urlArray[1].split('&') : [];
        const filterIdParameter = urlParameters.find(this.byFilterIdParameter) || -1;
        const filterId = this.getFilterIdValue(filterIdParameter);

        return this.filterObjectCacheService.getFilterById(filterId);
    };

    getFilterIdValue = (filterIdParameter) => {
        const filterIdParts = filterIdParameter.split('=');

        return filterIdParts.length > 0 ? parseInt(filterIdParts[1], 10) : -1;
    };


    replaceFilterWIthFilterId = (url, filterId) => {
        const urlArray = url.split('?');
        const urlParameters = urlArray.length > 0 ? urlArray[1].split('&') : [];
        const parametersMark = urlParameters.length > 0 ? '?' : '';
        const index = urlParameters.findIndex(this.byFilterParameter);

        if (index !== -1) {
            urlParameters[index] = 'filterId=' + filterId;
        }

        return [urlArray[0], parametersMark, urlParameters.join('&')].join('');
    };


    byFilterParameter = (parameter) => parameter.indexOf('filter') === 0;

    byFilterIdParameter = (parameter) => parameter.indexOf('filterId') === 0;
}
