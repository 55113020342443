import {Component, EventEmitter, Host, Input, Optional, Output, ViewChild} from '@angular/core';
import {AcSingleSelectComponent, SessionStorageService} from 'ac-infra';
import {NetworkGroupsFilterComponent} from '../network-groups-filter/network-groups-filter.component';
import {GroupsRestService} from '../../services/apis/groups-rest.service';
import {cloneDeep} from 'lodash';
import {LCCustomersRestService} from '../../services/apis/lc-customers-rest.service';
import {LinksRestService} from '../../services/apis/links-rest.service';
import {ConstantStringsService} from "../../../common/utilities/constant-strings.service";

export type NetworkSelectionType = 'TOPOLOGY' | 'ENDPOINT' | 'SERVICE' | 'LC_CUSTOMER' | 'SITE_LOCATION';

@Component({
    selector: 'network-selection',
    styleUrls: ['./network-selection.component.less'],
    templateUrl: './network-selection.component.html',
    })
export class NetworkSelectionComponent {

    @Input() isMultiple = true;
    @Input() isVisible = true;
    @Input() ngDisabled = false;
    @Input() sortItems = false;
    @Input() model: any;
    @Input() items: any;
    @Input() formFieldName: string;
    @Input() label: string;
    @Output() acChanged = new EventEmitter<any>();
    @ViewChild('singleSelect', {static: false}) singleSelectEl: AcSingleSelectComponent;

    itemsInitializeInternally = false;

    _type: NetworkSelectionType;

    // lcCustomers;
    // lcCustomerFilter;
    // lcCustomerFilterTypes = [
    //     {value: 'fullName', text: 'Full Name'},
    //     {value: 'msTenantId', text: 'MS Tenant ID'},
    // ];

    @Input() set type(type: NetworkSelectionType) {
        this._type = type;
        this.setTitle();

        if (this.itemsInitializeInternally) {
            this.getItems();
        }
    }

    constructor(private groupsRestService: GroupsRestService,
                private lcCustomersRestService: LCCustomersRestService,
                private linksRestService: LinksRestService,
                @Host() @Optional() public networkGroupsFilterComponent: NetworkGroupsFilterComponent) {
        // this.lcCustomerFilter = SessionStorageService.getData('lcCustomerFilter') || 'fullName';
    }

    ngOnInit() {
        !this.label && this.setTitle();

        if (this.items === undefined) {
            this.itemsInitializeInternally = true;
            this.getItems();
        }

        // if (this._type === 'LC_CUSTOMER') {
        //     this.lcCustomers = cloneDeep(this.items);
        //     this.lcCustomerFilterTypeChanged(this.lcCustomerFilter);
        // }
    }

    ngAfterViewInit(){
        this.setTitle();
    }

    getItems = () => {
        if (this._type === 'LC_CUSTOMER') {
            this.items = this.items.length > 0 ? this.items : cloneDeep(this.lcCustomersRestService.getAllEntities());
        } else if (this._type === 'SITE_LOCATION') {
            this.items = cloneDeep(this.linksRestService.getAllEntities());
        } else {
            this.items = cloneDeep(this.groupsRestService.getAllEntities(undefined, false, (this._type === 'SERVICE' ? 'services' : 'groups')).filter((group) => group.type === this._type));
        }
    };

    setTitle = () => {
        let label = this._type.toLowerCase();

        if (['TOPOLOGY', 'ENDPOINT'].includes(this._type)) {
            label += ' Group';
        }

        if (this._type === 'LC_CUSTOMER') {
            this.label = this.isMultiple ? 'Customers' : 'Select Customer';
        } else if (this._type === 'SITE_LOCATION') {
            this.label = ConstantStringsService.linkOrProviderSideMany;
        }else {
            this.label = label + (this.isMultiple ? 's' : '');
        }
    };

    // lcCustomerFilterTypeChanged = ($event: any) => {
    //     this.lcCustomerFilter = $event;
    //     SessionStorageService.setData('lcCustomerFilter', $event);
    //     const lcCustomers = cloneDeep(this.lcCustomers?.length > 0 ? this.lcCustomers : this.lcCustomersRestService.getAllEntities());
    //     lcCustomers.forEach((lcCustomer) => {
    //         if (lcCustomer[this.lcCustomerFilter]) {
    //             lcCustomer.name = lcCustomer.name + ' (' + lcCustomer[this.lcCustomerFilter] + ')';
    //         }
    //     });
    //
    //     this.items = lcCustomers;
    //
    //     if(this.singleSelectEl){
    //         setTimeout(() => {
    //             this.singleSelectEl.modelChanged(this.model);
    //         });
    //     }
    // };
}
