import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {OperatorsRestService} from '../administration-api/operators-rest.service';
import {LoginRestService} from '../../../login/services/login-rest.service';
import {TenantsRestService} from '../../../network/services/apis/tenants-rest.service';
import {ConfirmDialogData, PromiseService} from 'ac-infra';
import {LinksRestService} from '../../../network/services/apis/links-rest.service';
import {DevicesRestService} from '../../../network/services/apis/devices-rest.service';
import {GroupsRestService} from '../../../network/services/apis/groups-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {Actions} from '../../../common/server-actions/actions';
import {OperatorDialogComponent} from '../../dialogs/operator-dialog/operator-dialog.component';
import {SessionService} from '../../../common/services/session.service';

@Injectable({providedIn: 'root'})
export class OperatorsActionsService extends Actions{

    operatorEmptyObj = require('../../dialogs/operator-dialog/operator.empty.json');
    constructor(private operatorsRestService: OperatorsRestService,
                private loginRestService: LoginRestService,
                private tenantsRestService: TenantsRestService,
                private linksRestService: LinksRestService,
                private groupsRestService: GroupsRestService,
                private devicesRestService: DevicesRestService,
                private sessionService: SessionService) {
        super({entityName: 'operator', entityService: operatorsRestService});
    }

    public getOperators = () => {
        const defer = PromiseService.defer();
        const onSuccess = (response) => {
            defer.resolve(response.data.operators);
        };

        const onFailure = () => {
            defer.reject();
        };

        this.operatorsRestService.getOperators(onSuccess, onFailure);

        return defer.promise;
    };

    public deleteOperator = (operators) => this.delete({entityArray: operators, fakeMultipleDelete: true});

    public addOperator = (isSystemOperator, tenantDialogCallback?) => {
        const dialogRef = this.acDialogService.open();
        const operator = _.cloneDeep(this.operatorEmptyObj);
        operator.securityLevel = 'MONITORING';
        operator.isSystemOperator = isSystemOperator;
        this.initializeOperator(operator, dialogRef, false, tenantDialogCallback);
    };

    public editOperator = (operatorId) => {
        const dialogRef = this.acDialogService.open();
        const onSuccess = (response: RestResponseSuccess) => {
            const operator = response.data;
            operator.password = '';
            operator.confirmPassword = '';
            this.initializeOperator(operator, dialogRef, true);
        };
        this.operatorsRestService.getOperator(operatorId, onSuccess, ()=>{});
    };

    initializeOperator = (operator, dialogRef, isEdit, tenantDialogCallback?) => {
        if(operator.isSystemOperator){
            this.openOperatorDialog(dialogRef,  {operator, isEdit});
        }else{
            const dataObject = {
                tenants: this.tenantsRestService.getAllEntities(),
                links: this.linksRestService.getAllEntities(),
                devices: this.devicesRestService.getAllEntitiesHashed(),
                groups: this.groupsRestService.getAllEntities().filter((group) => group.type === 'ENDPOINT')
            };

            this.openOperatorDialog(dialogRef, {dataObject, operator, isEdit, tenantDialogCallback});
        }
    };

    openOperatorDialog = (dialogRef, {dataObject = undefined, operator = undefined, isEdit = false, tenantDialogCallback = undefined}) => {
        const id = operator.id;
        const operatorBeforeEdit = _.cloneDeep(operator);

        const dialogData = {
            isEdit,
            ...dataObject,
            entity: operator,
            onSuccessCallback: tenantDialogCallback,
            successMessage: isEdit && this.messagesService.buildSuccessMessage(operator.name, isEdit, 'Operator')
        };

        const serverCallback = (onSuccess, onFailure) => {
            if(!isEdit){
                this.operatorsRestService.add(onSuccess, onFailure, operator);
            }else if (isEdit && this.getUserId() === operatorBeforeEdit.id && operator.securityLevel !== 'ADMIN') {
                const confirmDialogMessage = 'Are you sure you want to change your security level?<br>changing your security level will navigate you to login page.';

                const onConfirm = () => {
                    this.operatorsRestService.edit(onSuccess, onFailure, operator, id);
                    this.sessionService.endSession();
                };
                const confirmDialogData: ConfirmDialogData = {message: confirmDialogMessage};
                this.genericConfirmAction({serverCallback: onConfirm, dialogData: confirmDialogData, dialogConfig: {submitButtonText: 'Update'}});
            } else {
                this.operatorsRestService.edit(onSuccess, onFailure, operator, id);
            }
        };

        this.genericAction({serverCallback, dialogRef, dialogData, dialogComponentType: OperatorDialogComponent});
    };

    public unblockOperator = (operator) => {
        const serverCallback = (onSuccess, onFailure) => this.operatorsRestService.edit(onSuccess, onFailure, {blockedStatus: false}, operator.id);
        this.genericConfirmAction({serverCallback, dialogData: {entitiesArray: [operator]}, dialogConfig: {submitButtonText: 'unblock'}});
    };

    public suspendOrReleaseOperator = (operator, suspend) => {
        const serverCallback = (onSuccess, onFailure) => this.operatorsRestService.edit(onSuccess, onFailure, {suspendStatus: suspend}, operator.id);
        this.genericConfirmAction({serverCallback, dialogData: {entitiesArray: [operator]}, dialogConfig: {submitButtonText: suspend ? 'suspend' : 'release', }});
    };

    public forcePasswordChangeOperator = (operator) => {
        const serverCallback = (onSuccess, onFailure) => this.operatorsRestService.edit(onSuccess, onFailure, {changePasswordOnNextLogin: true}, operator.id);
        this.genericConfirmAction({serverCallback, dialogData: {entitiesArray: [operator], confirmAlternativeText: 'force a password change for'}, dialogConfig: {submitButtonText: 'Force Password Change', }});
    };

    public forceLogoutOperator = (operator) => {
        const serverCallback = (onSuccess, onFailure) => this.loginRestService.forceLogoutOperator(operator.id, onSuccess, onFailure);
        this.genericConfirmAction({serverCallback, dialogData: {entitiesArray: [operator], confirmAlternativeText: 'force logout'}, dialogConfig: {submitButtonText: 'Force Operator Logout'}});
    };

    private getUserId = () => this.sessionService.activeSession && this.sessionService.activeSession.id || -1;
}

