import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AcLayoutComponent} from './ac-layout.component';
import {AcLayoutPanelComponent} from './ac-layout-panel/ac-layout-panel.component';
import {AcLayoutSectionComponent} from './ac-layout-section/ac-layout-section.component';

import {AcLayoutSectionChildDirective} from './directives/ac-layout-section-child.directive';
import {AcLayoutPanelDirective} from './directives/ac-layout-panel.directive';
import {AcLayoutSectionHeaderComponent} from './ac-layout-section/ac-layout-section-header/ac-layout-section-header.component';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcProgressModule} from '../ac-progress/ac-progress.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';


@NgModule({
    imports: [
        CommonModule,
        AcIconsModule,
        InfraPipesModule,
        InfraDirectivesModule,
        AcProgressModule,
    ],
    declarations: [
        AcLayoutComponent,
        AcLayoutPanelComponent,
        AcLayoutSectionComponent,
        AcLayoutSectionHeaderComponent,

        AcLayoutSectionChildDirective,
        AcLayoutPanelDirective,
    ],
    exports: [
        AcLayoutComponent,
        AcLayoutPanelComponent,
        AcLayoutSectionComponent,
        AcLayoutSectionHeaderComponent,

        AcLayoutSectionChildDirective,
        AcLayoutPanelDirective,
    ]
})
export class AcLayoutModule {
}
