<ac-form *ngIf="dialogConfig.ready" #operatorConnectForm
         [formModel]="tenant"
         [formSchema]="operatorConnectSchema"
         [isEdit]="true"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="tenant">

    <div class="flexColumnParent oc-form-content">

        <operator-connect-menu class="flexItemBiggest"
                               [model]="tenant"
                               [tenantFormSettings]="operatorConnectForm.formSettings"
                               [errors]="errors"
                               [availableNodesList]="['operatorContacts', 'offers']">
        </operator-connect-menu>
    </div>

</ac-form>
