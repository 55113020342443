import {Injectable} from '@angular/core';
import {StringUtils, WSMessage} from 'ac-infra';

import {UntilDestroy} from '@ngneat/until-destroy';
import {cloneDeep} from 'lodash';
import {TopologyTreeBase} from './topology-tree-base';

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class TopologyTreeService extends TopologyTreeBase {

    readonly ENTITY_TYPE_MAP = {
        "tenant": true,
        "region": true,
        "device": true,
        "link": true,
        "site": true
    }

    protected onWSEntitiesUpdate({messageType, entityType, entityTypeName, entitiesIds}: WSMessage) {
        entityType = entityType?.toLowerCase();
        if (!this.ENTITY_TYPE_MAP[entityType]) {
            return;
        }

        switch (messageType) {
            case 'Create': {
                this.createFlatNodes(entitiesIds, entityTypeName, entityType);
                break;
            }
            case 'Update': {
                this.updateFlatNodes(entitiesIds, entityTypeName, entityType);
                break;
            }
            case 'Delete': {
                this.deleteFlatNodes(entitiesIds);
                break;
            }
            default : {
                return;
            }
        }
        this.emitTreeNodesUpdate();
    }

    private getArtificialNodeId = (entity) => {
        if (!entity) {
            return;
        }
        return entity.entityType + 's' + entity.regionId;
    };

    private createDefaultArtificial(entityType, regionId) {
        entityType = entityType + 's';
        const artificialId = entityType + regionId;
        const artificialNode = {
            artificial: true,
            entityType: entityType,
            id: artificialId,
            name: StringUtils.toTitleCase(entityType, {onlyLeadWord: false}),
        };
        const flatRegion = this.flatTreeMap[regionId];

        this.buildFlatTree(this.flatTreeMap, artificialNode, flatRegion);
        this.addNodeToFlatParentChildren(flatRegion, artificialNode);

        return this.flatTreeMap[artificialNode.id];
    }

    private createFlatNodes(entitiesIds: any[], entityTypeName, entityType) {
        const newNodes = cloneDeep(this.wsEntitiesService.getEntitiesArray(entityTypeName, entitiesIds));
        newNodes.forEach(newNode => {
            newNode.entityType = entityType;
            this.buildFlatTree(this.flatTreeMap, newNode);

            switch (entityType) {
                case 'region': {
                    const flatTenant = this.flatTreeMap[newNode.tenantId];

                    this.addNodeToFlatParentChildren(flatTenant, newNode);
                    break;
                }
                case 'site':
                case 'device':
                case 'link': {
                    let flatArtificial = this.flatTreeMap[this.getArtificialNodeId(newNode)];

                    if (!flatArtificial) {
                        flatArtificial = this.createDefaultArtificial(entityType, newNode.regionId);
                    }
                    this.addNodeToFlatParentChildren(flatArtificial, newNode);
                    break;
                }
            }
            this.updateNodesQueue.push(newNode.tenantId || newNode.id);
        });
    }

    private updateFlatNodes(entitiesIds: any[], entityTypeName, entityType) {
        if (!['tenant', 'region'].includes(entityType)) {
            this.deleteFlatNodes(entitiesIds);
            this.createFlatNodes(entitiesIds, entityTypeName, entityType);
            return;
        }

        const newNodes = cloneDeep(this.wsEntitiesService.getEntitiesArray(entityTypeName, entitiesIds));
        newNodes.forEach((newNode) => {
            const oldNode = this.flatTreeMap[newNode.id]?.originalEntity;

            if (!oldNode) {
                return;
            }

            newNode.entityType = entityType;
            newNode.hasChildren = oldNode.hasChildren;

            Object.getOwnPropertyNames(oldNode).forEach((prop) => {
                delete oldNode[prop];
            });
            Object.assign(oldNode, newNode);

            this.updateNodesQueue.push(newNode.tenantId || newNode.id);
        });
    }
}
