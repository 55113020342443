import {Component, ContentChildren, ElementRef, Input, QueryList, ViewChild} from '@angular/core';

import * as _ from 'lodash';

import {FilterState} from './services/ac-filter-state.service';

import {AcFilterType} from './modals/ac-filter-type.class';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcDropDownComponent, AcTrackerService, statuses, WSMessage} from 'ac-infra';
import {FilterItemService} from './services/ac-filter-item.service';
import {CachedConnection} from '../../services/communication/cached-connection.service';
import {WsEntitiesService} from '../../services/communication/ws-entities.service';
import {NetworkLiveCloudDataService} from '../../../network/services/data/network-live-cloud-data.service';

@UntilDestroy()
@Component({
    selector: 'ac-filter',
    templateUrl: './ac-filter.component.html',
    styleUrls: ['./ac-filter.component.less'],
})
export class AcFilterComponent {

    @Input() eventName = '';
    @Input() updateEventName = '';
    @Input() filterClass = '';
    @Input() readOnly = false;

    @Input() selfLoadingFilter = false;
    @Input() fireFilterChangedOnInit = false;
    @Input() savePinned = true;
    @Input() hiddenFiltersList = [];
    @Input() externalAcFilters = [];

    /**
     * @deprecated use filterTypes instead
     */
    types = [];
    @ContentChildren(AcFilterType, {descendants: true}) filterTypes: QueryList<AcFilterType>;
    @ViewChild('visibleFilterItems', {static: true}) visibleFilterItems: ElementRef;
    @ViewChild('filterDropDown', {static: false}) filterDropDown: AcDropDownComponent;
    filterTypesArr;
    isEdit = false;


    unAuthorizedFilters = [];

    filter: any;
    filterChangeDebounce;
    first: any;

    constructor(private cachedConnection: CachedConnection,
                public filterState: FilterState,
                private acTrackerService: AcTrackerService,
                private filterItemService: FilterItemService,
                private wsEntitiesService: WsEntitiesService) {

        this.filterState.filterTypeChanged$.pipe(untilDestroyed(this))
            .subscribe((a) => {
                this.updateTypesRef();
            });
    }

    ngOnInit() {
        this.unAuthorizedFilters = this.unAuthorizedFilters.concat(this.hiddenFiltersList);

        this.filter = this.selfLoadingFilter ? {} : _.cloneDeep(this.filterState.get(this.updateEventName || this.eventName));
        this.fillExternalFilterState();

        this.filterState.setFilterUpdate$.pipe(untilDestroyed(this)).subscribe((filter) => {
            this.updateFilterObject(_.cloneDeep(filter));
            this.updateTypesRef();
            filter && this.fireFilterChangedEvent(!filter.specificAlarm && !filter.specificUnitIdAlarm);
        });

        this.updateFilterTopologyEntity('Topology');
        this.updateFilterTopologyEntity('LiveCloudTopology');

        this.wsEntitiesService.WSEntitiesUpdateFinished$.pipe(untilDestroyed(this)).subscribe((message: WSMessage) => {
            if (message.messageType === statuses.Update || message.messageType === statuses.Delete) {
                const entityType = message.entityType.toLowerCase()
                this.updateFilterTopologyEntity('Topology', entityType);
                this.updateFilterTopologyEntity('LiveCloudTopology', NetworkLiveCloudDataService.WS_ENTITY_TYPE_MAP[entityType] || entityType);
            }
        });
    }

    ngAfterViewInit() {
        this.filterState.currentFilterDropdown = this.filterDropDown;

        this.updateFilterObject();
        this.fireFilterChangedOnInit && this.fireFilterChangedEvent(false);
    }

    ngAfterContentInit() {
        this.filterTypes.forEach((type: AcFilterType) => this.registerType(type));
    }

    updateFilterObject = (filter?) => {
        if (filter && this.readOnly) {
            this.filter = _.cloneDeep(filter);
        } else {
            this.filterTypesArr = this.filterTypes?.toArray() || [];
            this.filter = _.pickBy((filter || this.filter), (value: any, key: string) => {
                return this.filterTypesArr.some((filterType) => {
                    return filterType.filterName.startsWith('metrics-') || filterType.filterName === key;
                });
            }) || {};
        }
        this.fillExternalFilterState();
    };

    setFilterTopologyState = (TopologyFilter, entityType) => {
        let entities = TopologyFilter[entityType];

        if (entityType !== 'endpoint') {
            const ids = TopologyFilter[entityType].map((entity: any) => entity.id);
            entities = this.wsEntitiesService.getEntitiesArray(entityType + 's', ids);
        }

        if (entities.length > 0) {
            TopologyFilter[entityType] = entities;
        } else {
            delete TopologyFilter[entityType];
            this.updateFilterRef();
        }
    };

    updateFilterTopologyEntity = (topologyType: 'Topology' | 'LiveCloudTopology', topologyEntityType?) => {
        if (!this.filter[topologyType]) {
            return;
        }

        [FilterState.UNPINNED_STATE, FilterState.PINNED_STATE].forEach((pinType) => {
            const topologyFilterByPinType = this.filter?.[topologyType]?.[pinType];
            if (!topologyFilterByPinType) {
                return;
            }

            if (!topologyEntityType) {
                Object.getOwnPropertyNames(topologyFilterByPinType).forEach((entityType) => {
                    this.setFilterTopologyState(topologyFilterByPinType, entityType);
                });
            } else {
                topologyFilterByPinType[topologyEntityType] && this.setFilterTopologyState(topologyFilterByPinType, topologyEntityType);
            }

            if (Object.getOwnPropertyNames(topologyFilterByPinType).length === 0) {
                delete this.filter[topologyType][pinType];
                this.fireFilterChangedEvent();
            }
        });
        this.updateFilterRef();
    };

    updateFilterRef = () => this.filter = {...this.filter};
    updateTypesRef = () => this.types = [];

    openFilter = (editedTypeName?, pinned?) => {

        const typeByName = this.getTypeByName(editedTypeName);
        if (typeByName && typeByName.notEditable) {
            return;
        }

        const typeToExpand = editedTypeName ? this.openFilterForEdit(editedTypeName, pinned) : this.openFilterForAdd();
        this.expandType(typeToExpand);
        this.isEdit = !!editedTypeName;

        this.filterTypes.forEach(type => {
            type.previouslyPinned = type.pinned;
        });

        this.updateTypesRef();

        setTimeout(() => {
            this.filterDropDown.open();
            this.filterState.filterOpenedBefore = true;
        }, 0);
    };

    expandType = (typeToExpand) => {
        this.filterTypes.forEach((type) => {
            type.expanded = type.filterName === typeToExpand.filterName;
            type.expanded && type.onExpand();
        });
    };

    hasTypeChanged = (changedType) => {
        if (!this.filterDropDown?.isOpen) {
            return false;
        }
        return changedType.hasChanged() || this.typePinningChanged(changedType);
    };

    isTypeValid = (type) => !!type && _.isFunction(type.isValid) ? type.isValid() : true;

    applyDisabled = (changedType) => {
        return !this.hasChanges() || !this.isAllTypesAreValid()
    }

    hasChanges = () => this.filterTypes
        .map(this.hasTypeChanged)
        .reduce((changed1, changed2) => changed1 || changed2, false);

    isAllTypesAreValid = () => this.filterTypes
        .map(this.isTypeValid)
        .reduce((isValid1, isValid2) => isValid1 && isValid2, true);

    removeType = (filterState, alarmName) => {
        const oldAlarms = this.filterState.getStorageData(filterState);
        if (oldAlarms) {
            delete oldAlarms[alarmName];
        }
        this.filterState.setStorageData(filterState, oldAlarms);

        delete this.filter[alarmName];

        this.updateTypesRef();
        this.updateFilterRef();
    };

    showType = (type) => !(this.unAuthorizedFilters && this.unAuthorizedFilters.includes(type.filterName));

    applyFilterChanges = () => {
        this.filterTypes.forEach((type) => {
            const item = this.filter[type.filterName];
            const typeChanged = type.hasChanged();
            const typePinningChanged = this.typePinningChanged(type);

            if (typeChanged || typePinningChanged && type.isValid()) {
                if (type.pinned && item) {
                    delete item.unpinned;
                }
                this.filter[type.filterName] = {
                    ...item,
                    [type.pinned ? 'pinned' : 'unpinned']: type.createItem()
                };


                // MATOMO
                const fields = this.filter[type.filterName]?.unpinned || this.filter[type.filterName]?.pinned || {};
                _.forOwn(fields, (value, key) => {
                    this.filterItemService.getItemTitle(type, key, value).then(success => {
                        if (success) {
                            this.acTrackerService.trackEvent('FilterChanged', (this.filter[type.filterName]?.unpinned ? 'unpinned' : 'pinned') + ': ' + type.filterName + '/' + key);
                        }
                    });
                });
            }
        });

        this.externalAcFilters.forEach((externalAcFilter: AcFilterComponent) => {
            externalAcFilter && _.merge(externalAcFilter.filter, this.filter);
        });

        this.cleanUpEmptyFilters();
        this.fireFilterChangedEvent();
    };

    fillExternalFilterState = () => {
        const oldPinned = this.filterState.getStorageData(FilterState.PINNED_STATE);

        Object.getOwnPropertyNames(oldPinned).forEach(oldProp => {
            if (!this.filter[oldProp] || !this.filter[oldProp].pinned) {
                this.filter[oldProp] = {...this.filter[oldProp], pinned: oldPinned[oldProp]};
            }
        });

        const oldUnpinned = this.filterState.getStorageData(FilterState.UNPINNED_STATE, (this.updateEventName || this.eventName));

        Object.getOwnPropertyNames(oldUnpinned).forEach(oldProp => {
            if (!this.filter[oldProp] || !this.filter[oldProp].unpinned) {
                this.filter[oldProp] = {...this.filter[oldProp], unpinned: oldUnpinned[oldProp]};
            }
        });
    };

    removeItem = (typeName, pinType) => {
        this.acTrackerService.trackEvent('FilterRemoved', pinType + ': ' + typeName);

        if (_.isFunction(this.getTypeByName(typeName).onRemoveItem)) {
            this.getTypeByName(typeName).onRemoveItem();
        }

        delete this.filter[typeName][pinType];
        this.getTypeByName(typeName).setStateForItem(this.filter[typeName][pinType]);
        if (_.isEmpty(this.filter[typeName])) {
            delete this.filter[typeName];
        }

        this.fireFilterChangedEvent();
    };

    hideRemoveItem = (typeName) => !this.getTypeByName(typeName).disableRemove;

    getFilterTypeName = (typeName) => {
        const type = this.getTypeByName(typeName);
        return type.filterTitle || type.filterName;
    };

    getItemContainerClass = (typeName) => {
        const typeNameId = typeName.replace(/[A-Z][a-zA-Z]/g, ' $&').toLowerCase().trim().replace(/\s+/g, '-');
        return 'filter-item-' + typeNameId + '-title-container';
    };

    checkIfItemIsNotEmpty = (item, type) => {
        let notMoreFilters = !type.filterName.includes('moreFilters');
        if (!notMoreFilters) {
            notMoreFilters = Object.keys(_.pickBy(item, (value, key) => type.fieldNameMapper[key])).length > 0;
        }

        return notMoreFilters && (type.checkIfTypeExist ? type.checkIfTypeExist(item) : (item && (!_.isEmpty(item) || _.isNumber(item))));
    };

    registerType = (typeToRegister: AcFilterType) => {
        typeToRegister.expanded = false;
        typeToRegister.pinned = typeToRegister.pinned || false;

        // this.types = [...this.types, typeToRegister]; // .push(typeToRegister);

        if (!!typeToRegister.initializeState && !this.filter[typeToRegister.filterName]) {
            this.filter[typeToRegister.filterName] = {unpinned: typeToRegister.initializeState};

            if (this.filterChangeDebounce) {
                clearTimeout(this.filterChangeDebounce);
            }
            this.filterChangeDebounce = setTimeout(() => {
                this.fireFilterChangedEvent(false);
            }, 100);

            this.updateFilterRef();
        }
    };


    // ## Helper functions ##
    openFilterForEdit = (editedFilterName, pinned) => {
        let typeToExpand: any = {};

        this.filterTypes.forEach((type) => {
            const item = this.filter[type.filterName] || {};

            let state = item.pinned;
            if (type.filterName === editedFilterName) {
                if (!pinned && item.unpinned) {
                    state = item.unpinned;
                }
                type.pinningDisabled = !pinned && !!(type.pinnable && item && item.pinned);
                typeToExpand = type;
            } else {
                if (item.unpinned) {
                    state = item.unpinned;
                }
                type.pinningDisabled = false;
            }

            type.setStateForItem(state);
        });

        typeToExpand.pinned = pinned;


        return typeToExpand;
    };

    openFilterForAdd = () => {
        this.filterTypes.forEach((type) => {
            type.pinned = type.pinnable && !this.filter[type.filterName]?.pinned;

            type.pinningDisabled = false;
            type.setStateForItem();
        });

        return this.filterTypes.first;
    };

    typePinningChanged = (type) => {
        if (type.pinned === type.previouslyPinned) {
            return false;
        }
        return !!this.typeHasPreviouslyPinnedItem(type);
    };

    typeHasPreviouslyPinnedItem = (type) => {
        const item = this.filter && this.filter[type.filterName] || {};
        return type.previouslyPinned ? item.pinned : item.unpinned;
    };

    cleanUpEmptyFilters = () => {
        this.filter = _.pickBy(this.filter, (filter) => {
            return !_.isUndefined(filter.pinned) || !_.isUndefined(filter.unpinned);
        });
    };

    fireFilterChangedEvent = (pageReset = true) => {
        if (pageReset) {
            this.removeType(FilterState.UNPINNED_STATE + 'AlarmsFilter', 'specificAlarm');
            this.removeType(FilterState.UNPINNED_STATE + 'AlarmsFilter', 'specificUnitIdAlarm');
        }

        this.filterState.setFilterToStorage(this.updateEventName || this.eventName, this.filter, this.savePinned);
        this.filterState.executeFilterChanged({
            type: this.updateEventName || this.eventName,
            filter: _.cloneDeep(this.filter)
        });
        this.filterState.executeFilterUpdate(null);
    };

    getTypeByName = (typeName) => {
        return this.filterTypes.find((type) => type.filterName === typeName);
    };

    onFilterDialogStatus = (isOpen: boolean) => {
        if (isOpen) {
            setTimeout(() => {
                this.updateFilterObject();
            });
        }
    };
}
