import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcFormComponent} from './ac-form.component';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {ChildFormsErrorComponent} from './forms-has-error/child-forms-error.component';
import {InfraPipesModule} from "../../pipes/infra.pipes.module";

const components = [
    AcFormComponent,
    ChildFormsErrorComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcButtonModule,
        InfraPipesModule
    ],
    declarations: [...components],
    exports: [...components]
})
export class AcFormModule {
}
