import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {SchemaHelperService} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class MultipleDeviceConverterService {

    convertMultipleACLDevicesToServer = (deviceToFormat, additionalSettings) => {
        const deviceToServer = {devices: []};

        if(additionalSettings.configuredBy === 'ipAddressRange'){
            this.createDeviceListFromIpRange(additionalSettings);
            additionalSettings.configuredBy = 'ipAddressList';
        }

        additionalSettings[additionalSettings.configuredBy].forEach((item) => {
            const deviceToAdd = _.cloneDeep(deviceToFormat);

            deviceToAdd.name += '_' + item;

            if(additionalSettings.configuredBy === 'serialNumberList'){
                deviceToAdd.sbcInfo.serialNum = item;
            }else{
                deviceToAdd.ipAddress = item;

            }

            deviceToServer.devices.push(deviceToAdd);
        });

        return deviceToServer;
    };

    private createDeviceListFromIpRange = (additionalSettings) => {
        const ipv4 = SchemaHelperService.validateIPAddress(additionalSettings.fromIpAddress, 'ipv4');
        const split = SchemaHelperService.split(additionalSettings.fromIpAddress, additionalSettings.toIpAddress, ipv4 ? '.' : ':');

        const fromInt = parseInt(split.fromEnd, ipv4 ? 10: 16);
        const toInt = parseInt(split.toEnd, ipv4 ? 10: 16);
        additionalSettings.ipAddressList = [];
        for (let i=fromInt; i <= toInt; i++) {
            const tmpIpAddress = split.fromStart + (ipv4 ? '.' : ':') + (ipv4 ? i : i.toString(16));
            additionalSettings.ipAddressList.push(tmpIpAddress);
        }
    };
}
