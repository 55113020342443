import {Component, Input} from '@angular/core';
import * as _ from 'lodash';
import {CallsStorageRestService} from '../../../configuration-api/calls-storage-rest.service';
import {MetadataService} from '../../../../../metadata/metadata.service';

@Component({
    selector: 'calls-storage-settings',
    templateUrl: './calls-storage-settings.component.html',
    styleUrls: ['./calls-storage-settings.component.less'],
})

export class CallsStorageSettingsComponent {

    @Input() serverCallsStorage: any;
    callsSuccessTableObj = CallsStorageRestService.callsSuccessTableObj;
    callsFailedTableObj = CallsStorageRestService.callsFailedTableObj;

    callsStorageLevelOptions: any = CallsStorageRestService.callsStorageLevelOptions;
    storageFixedTypesLevelsObj: any;
    firstLoad = true;

    callsServerEnumsToValuesList: any = {
        YES_CALLS_YES_QTRENDS_YES_SIPM: {toggle: true, trend: true, flow: true},
        YES_CALLS_NO_QTRENDS_YES_SIPM: {toggle: true, trend: false, flow: true},
        YES_CALLS_YES_QTRENDS_NO_SIPM: {toggle: true, trend: true, flow: false},
        YES_CALLS_NO_QTRENDS_NO_SIPM: {toggle: true, trend: false, flow: false},
        NO_CALLS_NO_QTRENDS_NO_SIPM: {toggle: false, trend: false, flow: false}
    };

    ngOnInit(){
        this.storageFixedTypesLevelsObj = MetadataService.getType('CallStorageRules');
        this.handleCallStorageLevelChange();
    }

    handleCallStorageLevelChange() {
        if (this.serverCallsStorage.sbcCallsStorageAlgorithm !== 'CUSTOM') {
            this.fillTablesObject(this.storageFixedTypesLevelsObj[this.serverCallsStorage.sbcCallsStorageAlgorithm]);
        } else {
            this.fillTablesObject(this.serverCallsStorage.sbcCallsStorageRule);
        }

        setTimeout(() => {
            if (this.firstLoad) {
                this.firstLoad = false;
            }
        });
    }

    fillTablesObject = (objectToCheck) => {
        _.forOwn(objectToCheck, (type, level) => {
            if (level !== 'name') {
                if (level.startsWith('success')) {
                    this.callsSuccessTableObj[level].values = Object.assign({}, this.callsSuccessTableObj[level].values, this.fromServerCallTypes(type));
                } else {
                    this.callsFailedTableObj[level].values = Object.assign({}, this.callsFailedTableObj[level].values, this.fromServerCallTypes(type));
                }
            }
        });
    };

    fromServerCallTypes(type) {
        return this.callsServerEnumsToValuesList[type];
    }

    handleTableChange() {
        if (!this.firstLoad) {
            this.serverCallsStorage.sbcCallsStorageAlgorithm = 'CUSTOM';
        }
    }
}



