import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ITreeOptions} from '@circlon/angular-tree-component';
import {ITreeNode} from '@circlon/angular-tree-component/lib/defs/api';

import {AcTreeComponent} from '../ac-tree.component';
import {AcTreeService} from '../ac-tree.service';
import {ArrayUtil} from '../../../utils/array-util';
import {Observable} from "rxjs/internal/Observable";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'ac-tree-navigation',
    templateUrl: './ac-tree-navigation.component.html',
    styleUrls: ['../ac-tree-navigation.less'],
})
export class AcTreeNavigationComponent implements OnInit {

    @Input() customTemplate: TemplateRef<any>;

    @ViewChild('acTree', {static: true}) acTree: AcTreeComponent;
    @ViewChild('flatNavigationTemplate', {static: true}) flatNavigationTemplate: TemplateRef<any>;
    @ViewChild('routingTemplate', {static: true}) routingTemplate: TemplateRef<any>;

    @Input() treeId = 'navigation-tree';
    @Input() displayField = 'name';
    @Input() treeNodes;
    @Input() searchPlaceHolder;
    @Input() doSort = true;
    @Input() formSettings;
    @Input() hasSearchBar = false;
    @Input() disableUpperCase = false;
    @Input() extractDataFromNode = true;
    @Input() sortCompare: (src, trg, displayField) => number;
    @Input() checkTreeAfterValidation$: Observable<any>;
    @Output() activeChange = new EventEmitter<any[]>();
    @Output() initialized = new EventEmitter<any[]>();
    @Output() updateData = new EventEmitter<any>();

    @Input() set errors(errors) {
        this.treeNodes.forEach((node) => {
            if (errors?.length > 0 && this.formSettings.touched) {
                const withError = errors.some((error) => node.dataPaths.some((path) => error.instancePath.includes(path)));
                node.isValid = !withError;
            } else {
                node.isValid = true;
            }
        });
    }

    search: string;
    extendTreeOptions: ITreeOptions;

    constructor(private acTreeService: AcTreeService) {
    }

    ngOnInit() {

        this.extendTreeOptions = {
            displayField: this.displayField,
            animateSpeed: 10,
            animateAcceleration: 1.5,
            animateExpand: true,
            useVirtualScroll: false,
        };

        this.checkTreeAfterValidation$ && this.checkTreeAfterValidation$.pipe(untilDestroyed(this)).subscribe((skipDebounce) => {
            this.treeNodes.forEach((node) => {
                if(node.formSettings && node.formSettings.touched){
                    node.isValid = node.formSettings.touched && node.formSettings.valid;
                }

            })
        });
    }

    replaceSpaces = (id: string) => {
        return id.toString().replace(/\s+/g, '_');
    };

    onActiveNodes(nodes: ITreeNode[]) {
        nodes.forEach((node: ITreeNode) => this.setExpand(node, true, true));

        this.activeChange.emit(this.extractDataFromNode ? nodes.map(node => node.data) : nodes);
    }

    onUpdateData($event) {
        this.updateData.next($event);
    }

    onInitialized($event) {
        this.initialized.next($event);
    }

    setActive(nodes: Array<any> | any, state = true) {
        if (!nodes) {
            return;
        }

        const activeNodeIds = {};
        ArrayUtil.oneToMany<any>(nodes).forEach((node) => activeNodeIds[node.id] = state);

        this.acTree.setActive(activeNodeIds);
    }

    setExpand(node: ITreeNode, state = true, loadFromState = false, propagate = true) {
        const parentsIds = {};

        if (propagate) {
            this.acTreeService.propagateExpandToParent(node, state, parentsIds);
        }
        this.acTree.setExpand(parentsIds, loadFromState);
    }
}
