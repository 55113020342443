export const lessVariables = {
    redStatus: '#f05656',
    orangeStatus: '#FF9C08',
    yellowStatus: '#EFC900',
    greenStatus: '#20CA7B',
    blueStatus: '#33A3DC',
    grayStatus: '#666666',
    purpleStatus: '#A98DE2',
    Analytics: '#1782AA',
    DeviceManager: '#2196F3',
    OVOCUpdate: '#124680',
    Security: '#f05656',
    Management: '#20CA7B',
    QOE: '#FF9C08',
    highlightColor: '#db7bff',
    primary: '#2196F3',
    darkPrimary: '#2e75b7',
    systemGreen: '#26BC06',
    systemRed: '#F44336',
    timeColor: '#cef2df',
    gray: '#353A48',
    lightGray: '#8E8EA9',
    text: '#505D6F',
    textSecondary: '#8E8EA9',
    hover: '#F2F2F2',
    selected: '#e3f2fd',
    controllerBackground: '#F5F5F5',
    systemBackground: '#FDFDFE',
    systemBorder: '#F6F6F9',
    systemBorderDark: '#CCCCCC',
    lightBG: '#F9F9F9',
    disabled: '#f9f9fb',
    disabledDark: '#C6CED4',
    teams: '#7B83EB',
};
