<div class="operator-tenants-multi-select">
    <ac-input-container formFieldName="operator.assignedTenants" *ngIf="!serverInfoService.oneLiveMode ;else singleTenantMode" label="Assigned Tenants:">
        <ac-select [(acModel)]="operator.assignedTenants"
                   (acModelChange)="assignedTenantsChanged()"
                   [items]="tenants"
                   [itemTemplate]="labelTemplate"
                   [selectFirstAfterSort]="false"
                   bindLabel="name"
                   bindValue="id">
        </ac-select>

        <ng-template #labelTemplate let-item>
            <span *ngIf="item.name">{{item.name}}</span>
            <span *ngIf="!item.name" title="Missing privileges to view this tenant">******</span>
        </ng-template>
    </ac-input-container>

    <ng-template #singleTenantMode>
        <ac-input-container formFieldName="additionalSettings.assignedTenant" label="Assigned Tenant:">
            <ac-single-select [sortItems]="true"
                       [(acModel)]="additionalSettings.assignedTenant"
                       (acModelChange)="assignedTenantsChanged()"
                       [selectFirstAfterSort]="false"
                       [items]="tenants"
                       bindLabel="name"
                       bindValue="id">
            </ac-single-select>
        </ac-input-container>
    </ng-template>


    <ac-input-container formFieldName="operator.assignedLinks" *ngIf="operator.securityLevel === 'MONITORING_LINKS' && !serverInfoService.oneLiveMode"
                        label="Assigned Links:">
        <ac-select [(acModel)]="operator.assignedLinks"
                   (acModelChange)="updateLinkList()"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [hideFilterButtons]="true"
                   [items]="linksItems"
                   [ngDisabled]="operator.assignedTenants === undefined || operator.assignedTenants.length === 0">
        </ac-select>
    </ac-input-container>

    <ac-checkbox labelText="Restrict Endpoints Actions Except For These Groups"
                 *ngIf="tenantOperatorList.includes(operator.securityLevel) && !serverInfoService.oneLiveMode"
                 [(acModel)]="operator.isTenantOperatorWithEndpointGroups"
                 (acModelChange)="groupsOperatorChanged()">
    </ac-checkbox>

    <ac-input-container formFieldName="operator.assignedGroups"
                        *ngIf="tenantOperatorList.includes(operator.securityLevel) && operator.isTenantOperatorWithEndpointGroups && !serverInfoService.oneLiveMode"
                        label="Assigned Endpoints groups:">
        <ac-select [(acModel)]="operator.assignedGroups"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [groupBy]="groupByTenantName"
                   [hideFilterButtons]="true"
                   [items]="groupsItems"
                   [ngDisabled]="operator.assignedTenants === undefined || operator.assignedTenants.length === 0"
                   [titleForSelectedItemsCallback]="groupByTenantName">
        </ac-select>
    </ac-input-container>
</div>
