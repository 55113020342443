<ac-input-container formFieldName="operatorType" label="Operator Type" [displayAsInfo]="true">
    <ac-textfield [acModel]="operatorType"
                  [ngDisabled]="true" textFieldId="operator-basic-info-type">
    </ac-textfield>
</ac-input-container>

<ac-input-container formFieldName="operator.name" label="Operator Name">
    <ac-textfield [acModel]="operator.name || additionalSettings.name"
                  (acModelChange)="operator.name = $event"
                  [ngDisabled]="isEdit"
                  textFieldId="operator-form-user-name">
    </ac-textfield>
</ac-input-container>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.password" editModePrefix="Change" label="Password">
        <ac-password [(acModel)]="operator.password" [showEye]="true"
                     passwordId="operator-form-password">
        </ac-password>
    </ac-input-container>

    <ac-input-container formFieldName="additionalSettings.confirmPassword" label="Confirm Password">
        <ac-password [(acModel)]="additionalSettings.confirmPassword" [showEye]="true"
                     passwordId="operator-form-confirm-password">
        </ac-password>
    </ac-input-container>
</div>

<ac-checkbox labelText="Change Password on Next Login"
             [(acModel)]="operator.changePasswordOnNextLogin"
             checkboxId="operator-form-change-password">
</ac-checkbox>

<ac-input-container formFieldName="operator.securityLevel" label="Security Level">
    <ac-single-select [(acModel)]="operator.securityLevel"
               (acModelChange)="securityLevelChanged();"
               [bindLabel]="'viewName'"
               [bindValue]="'name'"
               [items]="securityLevelOptions">
    </ac-single-select>
</ac-input-container>

<ac-checkbox labelText="HTTP Rest operator"
             *ngIf="oneLiveMode && operator.isSystemOperator && operator.securityLevel === 'OPERATOR'"
             [(acModel)]="operator.isUMP">
</ac-checkbox>

<ac-input-container formFieldName="operator.loginFromIps" label="Valid IPs to Login From">
    <ac-select [(acModel)]="operator.loginFromIps"
               [addCustomTags]="true"
               [bindLabel]="null" [bindValue]="null"
               selectionName="operator.loginFromIps">
    </ac-select>
</ac-input-container>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.fullName" label="Full Name">
        <ac-textfield [(acModel)]="operator.fullName"
                      textFieldId="operator-form-full-name">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container formFieldName="operator.phone" label="Phone">
        <ac-textfield [(acModel)]="operator.phone"
                      textFieldId="operator-form-phone">
        </ac-textfield>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.mail" label="Email">
        <ac-textfield [(acModel)]="operator.mail"
                      textFieldId="operator-form-mail">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container formFieldName="operator.description" label="Description">
        <ac-textfield [(acModel)]="operator.description"
                      textFieldId="operator-form-description">
        </ac-textfield>
    </ac-input-container>
</div>



