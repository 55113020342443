<mat-accordion>
    <ac-expansion-panel [classes]="'operator-connect-expansion-panel ' + (cardHasError ? 'cardHasError' : '')"
                        [expanded]="true"
                        [headerTemplate]="headerTemplate"
                        [descriptionTemplate]="removeButton"
                        [descriptionObject]="{index: index, type: 'offers'}">
        <div class="flexRowParentEqualChildren">
            <ac-input-container [formFieldName]="'tenant.services.OC.info.offers.' + index + '.offerType'"
                                label="Offer Type">
                <ac-single-select [(acModel)]="offerObject.offerType"
                                  (acModelChange)="offerTypeWasSelected.emit($event)"
                                  [items]="offersTypesList"
                                  bindLabel="viewName" bindValue="name">
                </ac-single-select>
            </ac-input-container>

            <ac-input-container [formFieldName]="'tenant.services.OC.info.offers.' + index + '.allowed'"
                                label="Offer Markets">
                <ac-single-select [(acModel)]="offerObject.allowed"
                                  [items]="allowedList">
                </ac-single-select>
            </ac-input-container>
        </div>


        <ac-input-container [formFieldName]="'tenant.services.OC.info.offers.' + index + '.countryCodes'"
                            label="Markets">
            <ac-select [(acModel)]="offerObject.countryCodes" [sortItems]="true"
                       [items]="countryCodesTypeList"
                       bindLabel="viewName" bindValue="name">
            </ac-select>
        </ac-input-container>
    </ac-expansion-panel>

    <ng-template #headerTemplate>
        <div class="oc-panel-header">
            {{'Offer ' + (index + 1)}}
        </div>
    </ng-template>
</mat-accordion>
