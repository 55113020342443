import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    ViewChild
} from '@angular/core';
import {isNil} from 'lodash';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ValidatorsService} from "../../services/utilities/validators.service";

@Component({
    selector: 'ac-num-spinner',
    templateUrl: './ac-num-spinner.component.html',
    styleUrls: ['./ac-num-spinner.component.less'],
})

export class AcNumSpinnerComponent {

    @Input() acModel: number;
    @Output() acModelChange = new EventEmitter<number>();

    @Input() step = 1;
    @Input() numSpinnerId = '';
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() ignoreMinMax = false;
    @Input() cyclic: boolean;
    @Input() ngDisabled = false;
    @Input() isFloatNumber = false;
    @ViewChild('numSpinnerInput', {static: true}) numSpinnerInput: ElementRef;
    @ViewChild(MatFormFieldControl) matFormFieldControl;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                @Optional() public acFormComponent: AcFormComponent,
                @Optional() public validatorsService: ValidatorsService,
                @Optional() public acInputContainerComponent: AcInputContainerComponent) {
    }

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    ngAfterViewInit(){
        this.numSpinnerId = this.numSpinnerId || (this.acInputContainerComponent?.acInputContainerId + '-num-spinner');
        this.acInputContainerComponent?.initializeMaterialField(this.matFormFieldControl);
    }

    onKeypress(event) {
        const isNumber = !isNaN(parseFloat(event.key)) || (this.isFloatNumber && event.key === '.' && !this.acModel.toString().includes('.'));

        if (!isNumber) {
            event.preventDefault();
        }
    }

    limitSpinner = (limit) => this.cyclic ? null : (!isNil(limit) && !isNil(this.acModel) ? limit : null);

    focusOut = () => {
        if (isNil(this.acModel)) {
            this.acModel = this.minValue;
            this.acModelChange.emit(this.minValue);
        }
    };

    ngOnChanges() {
        this.changeDetectorRef.detectChanges();

        if (isNil(this.acModel)) {
            return;
        }
    }

    onBlur = () => {
        if (!this.ignoreMinMax) {
            setTimeout(() => {
                if (this.acModel > this.maxValue) {
                    if (this.cyclic) {
                        this.acModelChange.emit(this.minValue);
                    } else {
                        this.acModelChange.emit(this.maxValue);
                    }

                } else if (this.acModel < this.minValue) {
                    if (this.cyclic) {
                        this.acModelChange.emit(this.maxValue);
                    } else {
                        this.acModelChange.emit(this.minValue);
                    }
                }
            }, 10);
        }
    };

    modelChanged($event) {
        if (this.numSpinnerInput.nativeElement.value.startsWith('0')) {
            this.numSpinnerInput.nativeElement.value = '0';
        }

        if(this.cyclic){
            setTimeout(() => {
                if (this.acModel < this.minValue) {
                    this.acModelChange.emit(this.maxValue);
                }else if (this.acModel > this.maxValue) {
                    this.acModelChange.emit(this.minValue);
                }else{
                    this.acModelChange.emit($event);
                }
            });
        }else{
            this.acModelChange.emit($event);
        }
    }


}



