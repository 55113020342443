import {Injectable} from '@angular/core';
import {Actions} from '../../../common/server-actions/actions';
import {CallsStorageRestService} from '../configuration-api/calls-storage-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {
    NetworkTenantCallStorageDialogComponent
} from '../../../network/dialogs/network-tenant-call-storage-dialog/network-tenant-call-storage-dialog.component';
import * as _ from 'lodash';
import {CommonNotifiersService} from "ac-infra";

@Injectable({providedIn: 'root'})
export class CallsStorageActionsService extends Actions{

    constructor(private callsStorageRestService: CallsStorageRestService) {
        super({entityService: CallsStorageRestService});
    }

    editCallStorageSettings = (tenantId) => {
        const success = (response: RestResponseSuccess) => {
            const tenantCallStorage = response.data;
            const id = tenantCallStorage.id;
            delete tenantCallStorage.url;
            const dialogData = {entity: tenantCallStorage, uriEnabled: tenantCallStorage.uriStatisticsStorage.maxDays !== 0, locationEnabled: tenantCallStorage.locationStatisticsStorage.maxDays !== 0};
            const serverCallback = (onSuccess, onFailure) => {
                delete dialogData.entity.id;
                const toServerCallStorage = this.prepareCallStorageSettingsForServer(dialogData.entity);

                const success = () => {
                    CommonNotifiersService.updateFinishedDataAndFiltered();
                    onSuccess();
                }
                this.callsStorageRestService.updateCallsStorageTenantsSettings(success, onFailure, toServerCallStorage, id);
            };

            this.genericAction({
                dialogComponentType: NetworkTenantCallStorageDialogComponent,
                serverCallback,
                dialogData,
            });
        };
        this.callsStorageRestService.getSingleTenantCallsStorageSettings(success, () => {}, tenantId);

    };

    prepareCallStorageSettingsForServer = (serverCallsStorage) => {
        if (serverCallsStorage.sbcCallsStorageAlgorithm !== 'CUSTOM') {
            delete serverCallsStorage.sbcCallsStorageRule;
        }

        const toServer = _.cloneDeep(serverCallsStorage);
        delete toServer.callToSipConversionRate;
        delete toServer.callToSipCallConversionRate;
        toServer.uriStatisticsStorage.maxDays = toServer.uriStatisticsStorage.maxDays > 0 ? toServer.uriStatisticsStorage.maxDays : 0;
        toServer.locationStatisticsStorage.maxDays = toServer.locationStatisticsStorage.maxDays > 0 ? toServer.locationStatisticsStorage.maxDays : 0;

        return toServer;
    };

    submit(serverCallsStorage) {
        const toServerCallStorage = this.prepareCallStorageSettingsForServer(serverCallsStorage);

        _.forOwn(toServerCallStorage, (value) =>{
            if(typeof value === 'object'){
                delete value?.maxEntities;
            }
        });

        this.callsStorageRestService.updateSystemCallsStorageSettings(toServerCallStorage);
    }
}
