<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="additionalSettings"
         [formModel]="audioCodesDevice"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="audioCodesDevice">
    <ac-tabs tabsId="audio-codes-device-tab-set" id="audio-codes-device-dialog-content">


        <ac-device-general-tab *acTab="'General'; id:'general-section-tab'"
                                [audioCodesDevice]="audioCodesDevice"
                                [additionalSettings]="additionalSettings"
                                [isMultiple]="isMultiple"
                                [isServiceAttached]="isServiceAttached"
                                [isEdit]="isEdit"
                                [dialogConfig]="dialogConfig"
                                [softwareFileList]="softwareFileList"
                                [initializeFirmwareVersionAndSupportingProducts]="initializeFirmwareVersionAndSupportingProducts"
                                [initializeOptionListArrays]="initializeOptionListArrays"
                                [updatePreProvInfoProperties]="updatePreProvInfoProperties"
                                [selectedTenantFullObject]="selectedTenantFullObject"
                                [httpElement]="httpElement"
                                (firmwareFileOptionsChanged)="firmwareFileOptionsList = $event"
                                (configurationFileOptionsChanged)="configurationFileOptionsList = $event"
                                [services]="services">
        </ac-device-general-tab>

        <snmp *acTab="'SNMP'; id:'snmp-section-tab'; if: !isEdit || (isEdit && audioCodesDevice.snmpInfo)"
              [isEdit]="isEdit"
              [snmpObject]="audioCodesDevice.snmpInfo"
              (acChanged)="audioCodesDevice.snmpInfo = $event;snmpChanged()"
              [hasDefaultObject]="true"
              [hasCheckboxForV3Edit]="true"
              componentType="device"
              snmpName="audioCodesDevice.snmpInfo"
              snmpV3ProfileName="snmpUserProfileInfo"
              viewType="radioButtons"
              [selectedTenantFullObject]="selectedTenantFullObject">
        </snmp>

        <http-connectivity *acTab="'HTTP'; id:'http-section-tab'" #httpElement
                           [httpObject]="audioCodesDevice.sbcInfo"
                           [hasDefaultObject]="true"
                           [isEdit]="isEdit"
                           componentType="device"
                           httpObjectName="audioCodesDevice.sbcInfo">
        </http-connectivity>

        <ng-container *acTab="'SBA'; id:'sba-section-tab'; if: !isMultiple">


            <ac-checkbox labelText="Enable SBA" [(acModel)]="additionalSettings.sbaEnabled"
                         (acModelChange)="sbaEnabledChanged($event)"
                         [ngDisabled]="isEdit">
            </ac-checkbox>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="SBA Configured By">
                    <ac-single-select [(acModel)]="additionalSettings.sbaConfiguredBy"
                                      (acModelChange)="additionalSettings.sbaConfiguredBy = $event; sbaConfiguredByChanged($event)"
                                      [ngDisabled]="!additionalSettings.sbaEnabled"
                                      [items]="deviceSBATypesItems">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container formFieldName="audioCodesDevice.sbcInfo.SBAsInfo.ipAddress" label="IP Address" *ngIf="additionalSettings.sbaConfiguredBy === 'ipAddress'">
                    <ac-textfield
                            [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.ipAddress"
                            [ngDisabled]="additionalSettings.sbaConfiguredBy | acFunc: isSbaCanBeConfigured: 'ipAddress' : additionalSettings.sbaEnabled"
                            textFieldId="audio-codes-device-sba-ip-address-text"
                            (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.ipAddress = $event : null"
                    ></ac-textfield>
                </ac-input-container>

                <ac-input-container formFieldName="audioCodesDevice.sbcInfo.SBAsInfo.fqdnName" label="FQDN Name" *ngIf="additionalSettings.sbaConfiguredBy === 'fqdnName'">
                    <ac-textfield [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.fqdnName"
                                  (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.fqdnName = $event : null"
                                  [ngDisabled]="additionalSettings.sbaConfiguredBy | acFunc: isSbaCanBeConfigured: 'fqdnName' : additionalSettings.sbaEnabled"
                                  textFieldId="audio-codes-device-fdqn-name">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container formFieldName="audioCodesDevice.sbcInfo.SBAsInfo.snmpReadCommunity" editModePrefix="Change" label="SNMP Read Community">
                    <ac-password [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.snmpReadCommunity"
                                 (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.snmpReadCommunity = $event : null"
                                 [ngDisabled]="!additionalSettings.sbaEnabled"
                                 passwordId="audio-codes-device-sba-read-community">
                    </ac-password>
                </ac-input-container>

                <ac-input-container formFieldName="audioCodesDevice.sbcInfo.SBAsInfo.snmpWriteCommunity" editModePrefix="Change" label="SNMP Write Community">
                    <ac-password [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.snmpWriteCommunity"
                                 (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.snmpWriteCommunity = $event : null"
                                 [ngDisabled]="!additionalSettings.sbaEnabled"
                                 passwordId="audio-codes-device-sba-write-community">
                    </ac-password>
                </ac-input-container>
            </div>

            <ac-input-container formFieldName="audioCodesDevice.sbcInfo.SBAsInfo.description" label="Description">
                <ac-textfield [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.description"
                              (acModelChange)="audioCodesDevice.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.description = $event : null"
                              [ngDisabled]="!additionalSettings.sbaEnabled"
                              textFieldId="audio-codes-device-sba-description">
                </ac-textfield>
            </ac-input-container>
        </ng-container>

        <div *acTab="'First Connection'; id:'first-connection-section-tab'" id="first-connection-section-container">

            <ac-checkbox labelText="Enable Initial Connection Provisioning"
                         [(acModel)]="additionalSettings.initialConnectionEnabled"
                         [ngDisabled]="isFirstConnectionCheckboxDisabled"
                         (acModelChange)="initialConnectionEnabledChanged()"
            ></ac-checkbox>

            <ac-input-container formFieldName="audioCodesDevice.sbcInfo.preProvInfo.iniFileId" label="Configuration File (INI/CLI/CONF)">
                <ac-single-select [acModel]="audioCodesDevice.sbcInfo?.preProvInfo?.iniFileId"
                                  bindLabel="viewName"
                                  bindValue="id"
                                  [items]="configurationFileOptionsList"
                                  [ngDisabled]="isFirstConnectionInputDisabled()"
                                  (acModelChange)="iniFileIdChanged($event)"
                ></ac-single-select>
            </ac-input-container>

            <ac-input-container formFieldName="audioCodesDevice.sbcInfo.preProvInfo.cmpFileId" label="Firmware File (CMP/RMS/RMT)">
                <ac-single-select [acModel]="audioCodesDevice.sbcInfo?.preProvInfo?.cmpFileId"
                                  bindLabel="viewName"
                                  bindValue="id"
                                  [items]="firmwareFileOptionsList"
                                  [ngDisabled]="isFirstConnectionInputDisabled()"
                                  (acModelChange)="cmpFileIdChanged($event)"
                ></ac-single-select>
            </ac-input-container>

            <ac-input-container label="Firmware Version" [displayAsInfo]="true" *ngIf="additionalSettings.swVersion">
                <div>{{additionalSettings.swVersion}}</div>
            </ac-input-container>

            <ac-input-container label="Supported Products" [displayAsInfo]="true" *ngIf="supportingProducts?.length > 0">
                <div>
                    <ul class="ac-device-dialog-supporting-products">
                        <li *ngFor="let item of supportingProducts" class="supported-product-item">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </ac-input-container>

            <ac-input-container *ngIf="isEdit && audioCodesDevice.sbcInfo?.preProvInfo?.state" label="Status" [displayAsInfo]="true">
                <div class="statusLabel {{preProvStatuses[audioCodesDevice.sbcInfo?.preProvInfo?.state]?.class}}">
                    {{preProvStatuses[audioCodesDevice.sbcInfo?.preProvInfo?.state]?.viewName}}
                </div>
            </ac-input-container>
        </div>
    </ac-tabs>


</ac-form>
