<operator-suspend-field [operator]="operator"
                        [additionalSettings]="additionalSettings"
                        [globalAutoSuspendDays]="ovocAuthentication?.globalAutoSuspendDays"
                        [isEdit]="isEdit"
></operator-suspend-field>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.accountInactivityPeriodDays" label="Account Inactivity Period (Days)">
        <ac-num-spinner [(acModel)]="operator.accountInactivityPeriodDays"
                        [minValue]="0" numSpinnerId="operator-form-account-inactivity-days">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container formFieldName="operator.sessionInactivityPeriodMin" label="Session Timeout Period (Minutes)">
        <ac-num-spinner [(acModel)]="operator.sessionInactivityPeriodMin"
                        [minValue]="0" numSpinnerId="operator-form-session-inactivity-minutes">
        </ac-num-spinner>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.sessionLeasingDurationHours" label="Session Leasing Period (Hours)">
        <ac-num-spinner [(acModel)]="operator.sessionLeasingDurationHours"
                        [minValue]="0" numSpinnerId="operator-form-session-leasing">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container formFieldName="operator.pswdUpdateMinPeriodHours" label="Password Update Min Period (Hours)">
        <ac-num-spinner [(acModel)]="operator.pswdUpdateMinPeriodHours"
                        [minValue]="0" numSpinnerId="operator-form-password-update">
        </ac-num-spinner>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.pswdValidityMaxPeriodDays" label="Password Validity Max Period (Days)">
        <ac-num-spinner [(acModel)]="operator.pswdValidityMaxPeriodDays"
                        [minValue]="0" numSpinnerId="operator-form-password-validity">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container formFieldName="operator.pswdWarningMaxPeriodDays" label="Password Warning Max Period (Days)">
        <ac-num-spinner [(acModel)]="operator.pswdWarningMaxPeriodDays"
                        [minValue]="0" numSpinnerId="operator-form-password-warning">
        </ac-num-spinner>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container formFieldName="operator.allowedLoginAttempts" label="Allowed Login Attempts">
        <ac-num-spinner [(acModel)]="operator.allowedLoginAttempts"
                        [minValue]="0" numSpinnerId="operator-allowedLoginAttempts">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container formFieldName="operator.maxNumOfSimultaneousSessions" label="Max Simultaneous Login Sessions">
        <ac-num-spinner [(acModel)]="operator.maxNumOfSimultaneousSessions"
                        [minValue]="0"
                        numSpinnerId="operator-globalMaxNumOfSimultaneousSessions">
        </ac-num-spinner>
    </ac-input-container>
</div>

<ac-input-container formFieldName="operator.sessionNotificationDuration" label="Notifications display time (sec)">
    <ac-num-spinner [(acModel)]="operator.sessionNotificationDuration"

                    [minValue]="0"
                    numSpinnerId="operator-sessionNotificationDuration">
    </ac-num-spinner>
</ac-input-container>
