import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MetadataService} from "../../../../../metadata/metadata.service";
import {cloneDeep} from 'lodash';

@Component({
    selector: 'calls-storage-quality',
    templateUrl: './calls-storage-quality.component.html',
    styleUrls: ['./calls-storage-quality.component.less'],
})

export class CallsStorageQualityComponent {

    objectKeys = Object.keys;
    @Input() tableObj: any;
    @Input() serverCallsStorage: any;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    storageFixedTypesLevelsObj;

    ngOnInit() {
        this.storageFixedTypesLevelsObj = MetadataService.getType('CallStorageRules');
    }

    toggleChanged(item, key) {
        if (!item.toggle) {
            item.flow = false;
            item.trend = false;
        }

        this.updateServerObject(item, key);
        this.acChanged.emit();
    }

    checkBoxChanged(item, key) {
        this.updateServerObject(item, key);
        this.acChanged.emit();
    }

    updateServerObject = (item, key) => {
        if(this.serverCallsStorage.sbcCallsStorageAlgorithm !== 'CUSTOM'){
            this.serverCallsStorage.sbcCallsStorageRule = cloneDeep(this.storageFixedTypesLevelsObj[this.serverCallsStorage.sbcCallsStorageAlgorithm]);
            delete this.serverCallsStorage.sbcCallsStorageRule.name;
        }

        this.serverCallsStorage.sbcCallsStorageRule[key] = this.getYesOrNO(item.toggle) + '_CALLS_' + this.getYesOrNO(item.trend) + '_QTRENDS_' + this.getYesOrNO(item.flow) + '_SIPM';
    };

    getYesOrNO = (value) => {
        return value ? 'YES' : 'NO';
    };
}



