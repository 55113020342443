<div class="grid-2-resize">
    <ac-slider (acModelChange)="serverCallsStorage.callsStorage.maxDays = $event"
               label="Total Calls"
               [acModel]="serverCallsStorage.callsStorage.maxDays"
               [options]="timeOptions"
               [showTooltip]="true"
               tooltipText="Drag or Type Number">
    </ac-slider>

    <ac-slider (acModelChange)="serverCallsStorage.sipMessagesStorage.maxDays = $event"
               label="Calls with Call Flow"
               [acModel]="serverCallsStorage.sipMessagesStorage.maxDays"
               [options]="timeOptions"
               [showTooltip]="true"
               tooltipText="Drag or Type Number">
    </ac-slider>


    <ac-slider *ngFor="let field of statisticsSliderData"
               (acModelChange)="serverCallsStorage[field.name].maxDays = $event"
               label="{{field.title}}"
               [acModel]="serverCallsStorage[field.name].maxDays"
               [options]="timeOptions"
               [showTooltip]="true"
               tooltipText="Drag or Type Number">
    </ac-slider>

    <div></div>

    <div>
        <ac-checkbox labelText="Enable URI Statistics"
                     [(acModel)]="uriEnabled"
                     [fullLine]="true"
                     (acModelChange)="uriEnabledChange.emit(uriEnabled);serverCallsStorage.uriStatisticsStorage.maxDays=0"
                     class="labelHeader"></ac-checkbox>
        <ac-slider (acModelChange)="serverCallsStorage.uriStatisticsStorage.maxDays = $event"
                   *ngIf="uriEnabled"
                   [acModel]="serverCallsStorage.uriStatisticsStorage.maxDays"
                   [options]="timeOptions"
                   [showTooltip]="true"
                   tooltipText="Drag or Type Number">
        </ac-slider>
    </div>

    <div>
        <ac-checkbox labelText="Enable Locations Statistics"
                     [fullLine]="true"
                     [(acModel)]="locationEnabled"
                     (acModelChange)="locationEnabledChange.emit(locationEnabled);serverCallsStorage.locationStatisticsStorage.maxDays=0"
                     class="labelHeader"></ac-checkbox>
        <ac-slider (acModelChange)="serverCallsStorage.locationStatisticsStorage.maxDays = $event"
                   *ngIf="locationEnabled"
                   [acModel]="serverCallsStorage.locationStatisticsStorage.maxDays"
                   [options]="timeOptions"
                   [showTooltip]="true"
                   tooltipText="Drag or Type Number">
        </ac-slider>
    </div>
</div>

