import {NgModule} from '@angular/core';

import {DateTimeFormatterPipe} from './date-time-formatter.pipe';
import {SafePipe} from './safe.pipe';
import {AcFuncPipe} from './func.pipe';
import {numberToKPipe} from './number-to-k.pipe';
import {IPDisplayPipe} from './ip-display.pipe';
import {ByStringPipe} from './by-string.pipe';
import {ArrayLengthPipe} from './array-length.pipe';
import {ConsoleLogPipe} from './console-log.pipe';

const pipes = [
    ArrayLengthPipe,
    ConsoleLogPipe,
    DateTimeFormatterPipe,
    SafePipe,
    AcFuncPipe,
    numberToKPipe,
    IPDisplayPipe,
    ByStringPipe
];

@NgModule({
    declarations: [...pipes],
    exports: [...pipes],
    providers: [...pipes],
})
export class InfraPipesModule {
}

