import {Component, Inject, SkipSelf} from '@angular/core';
import * as _ from 'lodash';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig, GeneralService} from 'ac-infra';
import {ServerInfoService} from '../../../common/services/server-info.service';
import {MetadataService} from "../../../metadata/metadata.service";

@AcDialog({
    id: 'service-dialog',
    title: 'Service Details',
    cancelButtonText: 'Close',
    width: 600,
    })
@Component({
    selector: 'network-service-dialog',
    templateUrl: './network-service-dialog.component.html',
    })
export class NetworkServiceDialogComponent {

    service: any = {};
    schema: any = {};
    isEdit;
    serviceAddSchema = require('../../../../schema/post/serviceprocessor.json');
    serviceEditSchema = require('../../../../schema/put/serviceprocessor.json');
    additionalSettings;

    licenseTypeList = [{value: 'ACM', text: 'ACM'}, {value: 'LOCAL_BREAKOUT', text: 'Local Breakout'}];

    ServerInfoService = ServerInfoService;
    lcCustomers;
    selectedLCCustomer;
    type;
    serviceSourceMapper;

    constructor(@SkipSelf() private acDialogComponent: AcDialogComponent,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                public generalService: GeneralService)  {}

    ngOnInit() {
        this.serviceSourceMapper = MetadataService.getType('ServiceSource');
        this.service = this.dialogConfig.dialogData.entity;
        this.lcCustomers = this.dialogConfig.dialogData.lcCustomers;
        this.selectedLCCustomer = this.dialogConfig.dialogData.lcCustomer;
        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.schema = _.cloneDeep(this.isEdit ? this.serviceEditSchema : this.serviceAddSchema);
        this.service.lcCustomerId = this.selectedLCCustomer?.id || this.service.lcCustomerId || this.lcCustomers?.[0]?.id;
        this.service.serviceSource = this.service.serviceSource || this.dialogConfig.dialogData.type;
        this.additionalSettings = _.cloneDeep(this.service);

        this.additionalSettings.serviceType = this.serviceSourceMapper[this.service.serviceSource]?.viewName || '';
    }

}





