import {Injectable} from '@angular/core';
import {Rest} from '../../common/server-actions/rest';
import {AlarmsFilterFormatterService} from '../services/alarms-filter-formatter.service';
import {RequestOptions} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class AlarmsRestService extends Rest {

    constructor(alarmsFilterFormatter: AlarmsFilterFormatterService) {
        super({url: 'alarms/fwdRules', entityFilterFormatter: alarmsFilterFormatter, filterEventName: 'AlarmsFilter'});
    }

    public getAlarms = (moduleName, {skipPopulateFilter = true, success, failure = () => null, ...requestOptions}: RequestOptions = {}) => {
        const url = moduleName === 'journal' ? 'journal/activities' : 'alarms/' + moduleName;
        this.get({
            ...requestOptions,
            skipPopulateFilter,
            url,
            extendParameters: true,
            requestAPI: moduleName,
            filteredEntity: moduleName
        }).then(success).catch(failure);
    };

    public getAlarmsForwardingRule = (fwdRuleId, requestOptions?: RequestOptions) => this.getById({id: fwdRuleId, skipPopulateFilter: true, build204JSON: false, ...requestOptions});

    public acknowledgeActiveAlarms = (success, failure, parameters) => {
        const sentObject = {alarms: parameters.map((item) => item.id), acknowledge: true};
        this.add(success, failure, sentObject, 'alarms/actions/acknowledge');
    };

    public clearActiveAlarms = (success, failure, parameters) => {
        const sentObject = {alarms: parameters.map((item) => item.id)};
        this.add(success, failure, sentObject, 'alarms/actions/clear');
    };

    protected handleSuccess = (requestAPI, response) => {
        const defaultObject = {data: {}};
        if (requestAPI === 'fwdRules') {
            defaultObject.data = {fwdRules: [], pages: {total: 0, totalElements: 0}};
        } else {
            const fieldName = requestAPI === 'journal' ? 'journal' : 'alarms';
            defaultObject.data = {cursor: {before: -1, after: -1}};
            defaultObject.data[fieldName] = [];
        }

        return response.status === 204 ? defaultObject : response;
    };
}
