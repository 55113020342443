import {Component, Input} from '@angular/core';
import {CallsStorageRestService} from '../../../configuration-api/calls-storage-rest.service';
import {RestResponseSuccess} from '../../../../../common/server-actions/rest';
import * as _ from 'lodash';
import {GeneralService} from 'ac-infra';
import {DecimalPipe} from '@angular/common';

interface MaxCallsSettings {
    current: number;
    original: number;
    options?: any;
    updatingState: boolean;
    debounceTimeout?: any;
}

@Component({
    selector: 'calls-storage-call-flow',
    templateUrl: './calls-storage-call-flow.component.html',
    styleUrls: ['./calls-storage-call-flow.component.less'],
})
export class CallsStorageCallFlowComponent {

    @Input() tenantCallStorage: any;
    @Input() additionalSettings: any;
    originalTenantCallStorage;
    tenantInfoCallStorage;

    maxCalls: MaxCallsSettings = {
        current: 0,
        original: 0,
        updatingState: false,
    };
    maxCallsWidthCallFlow: MaxCallsSettings = {
        current: 0,
        original: 0,
        updatingState: false,
    };

    maxCallRatio: number;

    constructor(private callsStorageRestService: CallsStorageRestService,
                private decimalPipe: DecimalPipe,
                public generalService: GeneralService) {
    }

    ngOnInit() {
        this.originalTenantCallStorage = _.cloneDeep(this.tenantCallStorage);
        const onSystemInfoSuccess = (response: RestResponseSuccess) => {
            this.tenantInfoCallStorage = response.data;

            this.initializeMaxCallsRatio();
        };

        this.callsStorageRestService.getSingleTenantCallsStorageInfo(onSystemInfoSuccess, {}, this.additionalSettings.id);
    }

    maxCallsChange($event: number) {
        this.maxCalls.current = $event;
        if (!this.maxCallsWidthCallFlow.updatingState) {
            this.maxCalls.updatingState = true;
            const deltaMaxCalls = this.maxCalls.current - this.maxCalls.original;
            this.maxCallsWidthCallFlow.current = this.maxCallsWidthCallFlow.original - Math.round(deltaMaxCalls / this.maxCallRatio);

            this.setMaxCallsDebounceTimeout(this.maxCalls);
        }
    }

    maxCallsWithCallFlowChange($event: number) {
        this.maxCallsWidthCallFlow.current = $event;
        const maxCallsDifference = this.maxCallsWidthCallFlow.current - this.maxCallsWidthCallFlow.original;
        this.setMaxCallsFactor(maxCallsDifference);
        if (!this.maxCalls.updatingState) {
            this.maxCallsWidthCallFlow.updatingState = true;
            this.maxCalls.current = this.maxCalls.original - (maxCallsDifference * this.maxCallRatio);

            this.setMaxCallsDebounceTimeout(this.maxCallsWidthCallFlow);
        }
    }

    private setMaxCallsDebounceTimeout(maxCallSettings) {
        clearTimeout(maxCallSettings.debounceTimeout);
        maxCallSettings.debounceTimeout = setTimeout(() => {
            maxCallSettings.updatingState = false;
        }, 200);
    }

    private setMaxCallsFactor(maxCallsDifference: number) {
        const baseFactor = this.maxCallsWidthCallFlow.original - this.tenantCallStorage.sipMessagesStorage.maxEntities;
        this.tenantCallStorage.callStorageChangeFactor = baseFactor + maxCallsDifference;
    }

    private getMaxCallsOptions(floor, ceil, takeRate = false) {
        const options: any = {
            floor,
            ceil,
            showSelectionBar: true,
            showTicksValues: true,
            animate: false,
            tickStep: this.getMaxCallsTickStep(floor, ceil),
            translate: (value: number, label): string => this.decimalPipe.transform(value),
            getLegend: (value) => this.decimalPipe.transform(value)
        };

        if(takeRate){
            options.step = this.maxCallRatio;
        }

        return options;
    }

    private getMaxCallsTickStep(floor, ceil) {
        const tickStep = Math.floor((ceil - floor) / 2);
        return (tickStep > 0) ? tickStep : 1;
    }

    private initializeMaxCallsRatio() {
        this.maxCallRatio = this.additionalSettings.callToSipConversionRate || this.additionalSettings.callToSipCallConversionRate;
        if (!this.maxCallRatio) {
            return;
        }

        this.maxCalls.original = this.tenantCallStorage.callsStorage.maxEntities;
        this.maxCallsWidthCallFlow.original = this.tenantCallStorage.sipMessagesStorage.maxEntities;

        this.maxCalls.current = this.tenantInfoCallStorage.callsStorage.maxEntities;
        this.maxCallsWidthCallFlow.current = this.tenantInfoCallStorage.sipMessagesStorage.maxEntities;

        const maxFactor = Math.floor((this.maxCalls.original - this.maxCallsWidthCallFlow.original) / (this.maxCallRatio + 1));
        const maxCallsFloor = this.tenantCallStorage.callsStorage.maxEntities - maxFactor * this.maxCallRatio;
        const maxCallsCeil = this.maxCalls.original + (this.maxCallsWidthCallFlow.original * this.maxCallRatio);
        const maxCallsWidthCallFlowCeil = this.tenantCallStorage.sipMessagesStorage.maxEntities + maxFactor;

        this.maxCalls.options = this.getMaxCallsOptions(maxCallsFloor, maxCallsCeil, true);
        this.maxCallsWidthCallFlow.options = this.getMaxCallsOptions(0, maxCallsWidthCallFlowCeil);
    }
}



