import {Injectable} from '@angular/core';
import {AcGeoEventsService} from '../../components/ac-geo/services/ac-geo-events.service';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';


@Injectable({providedIn: 'root'})
export class GroupsRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'topology/groups', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter', entityType: 'groups'});
    }

    public serviceRestAction = (success, failure, selectedServices, actionName) => { // disable\backup\restore
        const services = selectedServices.map((item) => item.id);
        AcGeoEventsService.executeCloseOpenTooltipsOnMap();
        this.add(success, failure, {services}, 'topology/actions/' + actionName);
    };

}
