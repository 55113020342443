import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {AcTableActions, DynamicTabsService, GeneralService, SessionStorageService} from 'ac-infra';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {Store} from '@ngxs/store';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {


    private navbarController: any;

    constructor(private sessionStorageService: SessionStorageService,
                private router: Router,
                private store: Store,
                private generalService: GeneralService,
                private dynamicTabsService: DynamicTabsService,
                private filterState: FilterState) {

    }

    goTo = (stateName, extras?: NavigationExtras | any) => {
        this.router.navigate([stateName], extras);
    };

    private navigationCheckers = {};
    setNavigationChecker = (key, canDeactivate) => {
        this.navigationCheckers[key] = {canDeactivate: canDeactivate, isBlocked: true};
    }
    removeNavigationChecker = (key) => {
        delete this.navigationCheckers[key];
    }
    isCheckerBlocking = (key) => {
        return this.navigationCheckers[key]?.isBlocked;
    }
    canDeactivate = async () => {
        const checkers: any[] = Object.values(this.navigationCheckers);
        for (const checker of checkers) {
            checker.isBlocked = !await checker.canDeactivate();
            if (checker.isBlocked) {
                return false;
            }
        }
        return true;
    }

    goToWithFilter = (uri, filter) => {
        this.store.dispatch(new AcTableActions.SetResetPagingOnLoad());
        this.filterState.stateStartEventHandler(uri, filter);

        // Reload page to make table refresh after filter change.
        this.redirectTo(uri);
    };

    reload = () => {
        return this.redirectTo(this.router.url);
    };

    redirectTo(uri: string) {
        return this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([uri]);
        });
    }

    goToDynamicTab = (pathsFragments: any | any[], title?, icon?) => {
        if (!Array.isArray(pathsFragments)) {
            pathsFragments = [pathsFragments];
        }
        this.dynamicTabsService.addTab(pathsFragments[0], title, icon);
        this.redirectTo(pathsFragments.join('/'));
    };

    registerNavigationBar = (navbarCtrl) => {
        this.navbarController = navbarCtrl;
    };

    openNewWindow = (url, params) => {
        const form: any = document.createElement('form');
        form.method = 'post';
        form.target = '_blank';
        form.action = url;
        form.style = 'display:none';
        for (const [key, value] of Object.entries(params)) {
            let input: any = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }

        console.log('SSO URL-', url);// don't remove - for QA and UI use
        console.log('SSO parameters-', params);// don't remove - for QA and UI use

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }
}
