import {Injectable} from '@angular/core';

export enum AuthGroup {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    SYSTEM_OPERATOR = 'SYSTEM_OPERATOR',
    SYSTEM_MONITORING = 'SYSTEM_MONITORING',
    TENANT_ADMIN = 'TENANT_ADMIN',
    TENANT_OPERATOR = 'TENANT_OPERATOR',
    TENANT_MONITORING = 'TENANT_MONITORING',
    TENANT_MONITORING_LINKS = 'TENANT_MONITORING_LINKS',
    GROUP_MONITORING = 'GROUP_MONITORING',
    GROUP_OPERATOR = 'GROUP_OPERATOR',
    MULTIGROUP_ADMIN = 'MULTIGROUP_ADMIN',
    admin = 'admin',
    system_admin_operator = 'system_admin_operator',
    system_users = 'system_users',
    operator = 'operator',
    tenant_operator = 'tenant_operator',
    tenant_user = 'tenant_user',
    admin_operator = 'admin_operator',
    monitor = 'monitor',
    channel_operator = 'channel_operator',
    customer_operator = 'customer_operator',
}

export type AuthGroupOption = keyof typeof AuthGroup;

export type AuthGroups = {
    [key in AuthGroupOption]: AuthGroupOption[];
};

@Injectable({providedIn: 'root'})
export class SessionHelperService {

    static authGroups: AuthGroups = {
        SYSTEM_ADMIN: [AuthGroup.SYSTEM_ADMIN],
        SYSTEM_OPERATOR: [AuthGroup.SYSTEM_OPERATOR],
        SYSTEM_MONITORING: [AuthGroup.SYSTEM_MONITORING],
        TENANT_ADMIN: [AuthGroup.TENANT_ADMIN],
        TENANT_OPERATOR: [AuthGroup.TENANT_OPERATOR],
        TENANT_MONITORING: [AuthGroup.TENANT_MONITORING],
        TENANT_MONITORING_LINKS: [AuthGroup.TENANT_MONITORING_LINKS],
        GROUP_MONITORING: [AuthGroup.GROUP_MONITORING],
        GROUP_OPERATOR: [AuthGroup.GROUP_OPERATOR],
        MULTIGROUP_ADMIN: [AuthGroup.MULTIGROUP_ADMIN],
        admin: [AuthGroup.SYSTEM_ADMIN, AuthGroup.TENANT_ADMIN],
        system_admin_operator: [AuthGroup.SYSTEM_ADMIN, AuthGroup.SYSTEM_OPERATOR],
        system_users: [AuthGroup.SYSTEM_ADMIN, AuthGroup.SYSTEM_OPERATOR, AuthGroup.SYSTEM_MONITORING],
        operator: [AuthGroup.SYSTEM_OPERATOR, AuthGroup.TENANT_OPERATOR],
        tenant_operator: [AuthGroup.TENANT_ADMIN, AuthGroup.TENANT_OPERATOR],
        tenant_user: [AuthGroup.TENANT_ADMIN, AuthGroup.TENANT_OPERATOR, AuthGroup.TENANT_MONITORING],
        admin_operator: [AuthGroup.SYSTEM_ADMIN, AuthGroup.TENANT_ADMIN, AuthGroup.SYSTEM_OPERATOR, AuthGroup.TENANT_OPERATOR],
        monitor: [AuthGroup.SYSTEM_MONITORING, AuthGroup.TENANT_MONITORING, AuthGroup.TENANT_MONITORING_LINKS, AuthGroup.GROUP_MONITORING],
        channel_operator: [AuthGroup.MULTIGROUP_ADMIN],
        customer_operator: [AuthGroup.GROUP_MONITORING, AuthGroup.GROUP_OPERATOR],
    };

    static BASE_SECURITY_LEVEL_MAP = {
        SYSTEM_ADMIN: 'System Administrator',
        SYSTEM_OPERATOR: 'System Operator',
        SYSTEM_MONITORING: 'System Monitoring',
        TENANT_ADMIN: 'Tenant Administrator',
        TENANT_OPERATOR: 'Tenant Operator',
        TENANT_MONITORING: 'Tenant Monitoring',
    };

    static OVOC_SECURITY_LEVEL_MAP = {
        TENANT_MONITORING_LINKS: 'Tenant Monitoring Links',
    };

    static ONE_LIVE_SECURITY_LEVEL_MAP = {
        GROUP_MONITORING: 'Customer Monitoring',
        GROUP_OPERATOR: 'Customer Operator',
        MULTIGROUP_ADMIN: 'Channel',
        TENANT_MONITORING: 'Service Provider Monitoring',
        TENANT_OPERATOR: 'Service Provider Operator',
        TENANT_ADMIN: 'Service Provider Administrator',
    };

    static SECURITY_LEVEL_MAP = {
        ...SessionHelperService.BASE_SECURITY_LEVEL_MAP,
    };
    static AUTHENTICATION_MAP = {
        LOCAL_AUTH: 'Local Authentication',
        RADIUS: 'RADIUS Authentication',
        LDAP: 'LDAP Authentication',
        AAD: 'Azure Authentication',
        SAML: 'SAML Authentication'
    };

    static setSecurityLevels = (oneLive = false) => {
        SessionHelperService.SECURITY_LEVEL_MAP = {
            ...SessionHelperService.BASE_SECURITY_LEVEL_MAP,
            ...(oneLive ? SessionHelperService.ONE_LIVE_SECURITY_LEVEL_MAP : SessionHelperService.OVOC_SECURITY_LEVEL_MAP)
        };
    };
}

