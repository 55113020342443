import {Component, Inject, Input} from '@angular/core';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig, GeneralService} from 'ac-infra';
import {LogoSelectionService} from '../../common/components/logo-selection/logo-selection.service';
import * as _ from 'lodash';

@AcDialog({
    id: 'choosing-customer-dialog-id',
    cancelButtonText: 'Logout',
    noSubmitButton: true,
    width: 500
})
@Component({
    selector: 'choosing-customer-or-channel-dialog',
    templateUrl: './choosing-customer-or-channel-dialog.component.html',
    styleUrls: ['./choosing-customer-or-channel-dialog.component.less'],
})
export class ChoosingCustomerOrChannelDialogComponent {

    @Input() showTree = true;

    entities;
    logos;
    searchedEntities;

    statusColors = GeneralService.statusColors;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private acDialogComponent: AcDialogComponent,
                private logoSelectionService: LogoSelectionService) {}

    ngOnInit() {
        this.entities = this.dialogConfig.dialogData.entities;
        this.dialogConfig.title = this.dialogConfig.dialogData.type === 'customers' ? 'Choose Customer' : 'Choose Channel';

        this.logos = {};

        const fieldName = this.dialogConfig.dialogData.type === 'customers' ? 'customerName' : 'channelName';
        this.entities = _.orderBy(this.entities, [item => item[fieldName].toLowerCase()], ['asc']);

        this.entities.forEach((entity) => {
            if(this.dialogConfig.dialogData.type === 'customers'){
                this.getTenantLogo(entity);
                if(entity?.customerLogoId > 0){
                    this.fetchLogo(entity, 'customerLogoId');
                }else{
                    this.getTenantLogo(entity);
                }
            }else{
                if(entity?.channelLogoId > 0){
                    this.fetchLogo(entity, 'channelLogoId');
                }else{
                    this.getTenantLogo(entity);
                }
            }
        });
    }

    getTenantLogo = (entity) => {
        if(entity.tenantLogoId === -1){
            this.logos[entity.customerId || entity.channelId] = -1;
        }else{
            this.fetchLogo(entity);
        }
    };

    fetchLogo = (entity, logoPropertyName = 'tenantLogoId') => {
        this.logoSelectionService.getLogoImage(entity, logoPropertyName).then((responseImage) => {
            if(logoPropertyName === 'channelLogoId' && responseImage === -1){
                this.getTenantLogo(entity);
            }else{
                this.logos[entity.customerId || entity.channelId] = responseImage;
            }
        });
    };

    entitySelectionChanged = (selection) => {
        this.dialogConfig.dialogData.entity = _.cloneDeep(selection);

        this.acDialogComponent.onSubmit();
    };

    searchEvent = (search) => {
        this.searchedEntities = search ? this.entities.filter((entity) => {
            return (entity.customerName || entity.channelName).toLowerCase().includes(search.toLowerCase()) || entity.tenantName.toLowerCase().includes(search.toLowerCase());
        }) : this.entities;
    };
}


