import {AcLayoutSectionComponent} from '../ac-layout-section/ac-layout-section.component';
import {Component, ContentChild, EventEmitter, HostBinding, Injector, Input, Optional, Output, TemplateRef} from '@angular/core';
import {GeneralService} from '../../../services/general.service';
import {forkJoin, Subscription} from 'rxjs';

@Component({
    selector: 'ac-layout-panel',
    templateUrl: './ac-layout-panel.component.html',
    styleUrls: ['./ac-layout-panel.component.less'],
    host: {
        '[class]': 'classNames'
    }
})
export class AcLayoutPanelComponent {

    @Input() templates: { [key: string]: TemplateRef<any> } = {};
    @Input() @HostBinding('class.collapsed') collapsed = false;
    @Input() collapseDir = '';
    @Input() panelTitle = '';
    @Input() titlePostfix = '';
    @Input() iconName = '';
    @Input() panelClass = '';
    @Input() contentCustomClass = 'flexItemBiggest';
    @Input() loading = false;
    @Input() showHeader = true;
    @Output() collapse = new EventEmitter();
    classNames: string;
    isMainSection = false;
    actionMinWidth = 0;

    constructor(public injector: Injector,
                private generalService: GeneralService,
                @Optional() public acLayoutSectionComponent: AcLayoutSectionComponent) {
    }

    @ContentChild('panelHeader') set panelHeaderTemplate(panelHeaderTemplate: TemplateRef<any>) {
        panelHeaderTemplate && this.setTemplate({panelHeaderTemplate});
    }

    @ContentChild('panelActions') set panelActionsTemplate(panelActionsTemplate: TemplateRef<any>) {
        panelActionsTemplate && this.setTemplate({panelActionsTemplate});
    }

    @ContentChild('footerActions') set footerActionsTemplate(footerActionsTemplate: TemplateRef<any>) {
        footerActionsTemplate && this.setTemplate({footerActionsTemplate});
    }

    actionDimension  = {
        minWidth: 0,
        height: 0,
    }

    ngOnInit() {
        if (this.acLayoutSectionComponent) {
            this.isMainSection = this.acLayoutSectionComponent.section === 'main';
        }
        this.classNames = (this.panelTitle ? this.generalService.generateIdForText(this.panelTitle) : 'default') + '-layout-panel';
    }

    setTemplate(template) {
        this.templates = {...this.templates, ...template};
    }

    onCollapse = () => {
        this.collapseDir && this.collapse.emit();
    };

    actionDim = (htmlElement: HTMLElement, key: string, childCount) => {
        setTimeout(() => {
            this.actionMinWidth = htmlElement.offsetWidth;
        });
        return childCount;
    };

    promisesForLoading: Promise<any>[] = [];
    sub: Subscription;
    finishLoading = () => {
        this.promisesForLoading = [];
        this.loading = false;
    };
    queueLoading = (newPromise) => {
        if (!newPromise) {
            return;
        }
        this.loading = true;
        this.promisesForLoading.push(newPromise);
        this.sub?.unsubscribe();

        this.sub = forkJoin(this.promisesForLoading).subscribe({
            next: this.finishLoading,
            error: this.finishLoading,
        });
    }
}



