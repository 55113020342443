<div class="ac-form-container" [class.flexColumnParent]="!isDialog">
    <form #formElement
          [class.flexItemBiggest]="!isDialog"
          name="{{formName}}"
          novalidate>
<!--          [ngFormOptions]="{ allowInvalid: true }"-->
        <div class="ac-form-content">
            <ng-content></ng-content>
        </div>
    </form>

    <ng-template *ngIf="!acLayoutPanelComponent && (_canSubmit || forceFooterLayout)" [ngTemplateOutlet]="acFormButtons"></ng-template>

    <ng-template #acFormButtons>
        <div *ngIf="!isDialog" [class.visibilityHidden]="!_canSubmit && !forceFooterLayout" [class.flexItem]="!isDialog" class="submit-button-wrapper">
            <ng-template *ngIf="additionalFormActionsTemplate" [ngTemplateOutlet]="additionalFormActionsTemplate"
                         [ngTemplateOutletContext]="{form: this}"></ng-template>

            <ac-button *ngIf="!hideSubmitButton && !isViewMode"
                       (acClick)="onSubmit()"
                       [buttonId]="(formName || formModelName || 'button') | acFunc : generalService.generateIdForText: '-form-submit'"
                       [ngDisabled]="disableOkButton || false"
                       buttonValue="{{submitButtonValue}}"
                       class="ac-dialog-btn submit-button">
            </ac-button>
        </div>
    </ng-template>


</div>

