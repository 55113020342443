import * as _ from 'lodash';
import {AcDeferredPromise, AcDialogRef, AcDialogService, ArrayUtil, BuildUrlsService, RequestOptions, ResponseType} from 'ac-infra';
import {AcGeoEventsService} from '../../network/components/ac-geo/services/ac-geo-events.service';
import {ConnectionService} from '../services/communication/connection.service';
import {CachedConnection} from '../services/communication/cached-connection.service';
import {AuthorizationService} from '../services/authorization.service';
import {inject} from '@angular/core';

// export interface ResDataArray<T = any> {
//     [key: string]: T[];
// }
//
// type ResData<T> = ResDataArray<T> & { pages?: { size: string, total: string, current: string, totalElements: string } };

export class RestResponseSuccess<T = any> {
    data: T;
    status: number;
}

export class RestResponseFailure {
    error: any;
    status: number;
}

export type RestResponse<T = any> = RestResponseSuccess<T> | RestResponseFailure;

export interface RestConstructorArgs {
    url?;
    entityFilterFormatter?;
    entityType?: string;
    urlToFilterCallingApi?;
    addSearchToParams?;
    filterEventName?;
}

export class Rest {
    static SKIP_PAYLOAD_TO_LARGE = {skipDefaultErrorOnSpecificStatusCodes: [413]};

    public buildURLs = inject(BuildUrlsService);
    public connection = inject(ConnectionService);
    public cachedConnection = inject(CachedConnection);
    protected acDialogService = inject(AcDialogService);

    protected url: string;
    protected entityFilterFormatter: any;
    protected urlToFilterCallingApi: any;
    protected entityType: string;

    private addSearchToParams: any;
    private filterEventName: any;

    constructor({url, entityFilterFormatter, entityType, urlToFilterCallingApi, addSearchToParams, filterEventName}: RestConstructorArgs) {
        this.url = url;
        this.entityType = entityType;
        this.urlToFilterCallingApi = urlToFilterCallingApi;
        this.addSearchToParams = addSearchToParams;
        this.entityFilterFormatter = entityFilterFormatter;
        this.filterEventName = filterEventName;
    }

    extractObject = (value) => value.status === 204 ? {data: {}} : value;

    get = (
        {
            success = undefined,
            failure = undefined,
            parameters = {} as any,
            url = undefined,
            requestAPI = undefined,
            useCachedConnection = false,
            extendParameters = false, // extend with details 1
            build204JSON = true,
            getOnlyRequestUrl = false,
            authMandatory = true,
            ...requestOptions
        }: RequestOptions = {}
    ): Promise<RestResponse> => new Promise<RestResponse>((resolve, reject) => {

        requestOptions.abortRequestId && this.connection.abortRequestSubject.next(requestOptions.abortRequestId);

        if (extendParameters || parameters.fields) {
            parameters.detail = 1;
        }

        if (requestOptions.addScopeIdFilter && AuthorizationService.tenantScope === null) {
            resolve(this.handleSuccess(requestAPI || this.entityType, {status: 204}));
            return;
        } else if (AuthorizationService.tenantScope) {
            if (!parameters.filter) {
                parameters.filter = {};
            }

            parameters.filter.scopeId = AuthorizationService.tenantScope;
        }

        const urlToServer = this.buildURL((url || this.url), {parameters, ...requestOptions});
        if (getOnlyRequestUrl) {
            resolve({data: this.connection.addProtocolToUri(urlToServer), status: 200});
            return;
        }

        const onSuccess = (value: RestResponseSuccess) => {
            value = build204JSON ? this.handleSuccess(requestAPI || this.entityType, value) : value;
            resolve(value);
            success && success(value);
        };
        const onFailure = (error: RestResponseFailure) => {
            reject(error);
            failure && failure(error);
        };

        if (useCachedConnection) {
            this.cachedConnection.get(urlToServer, this.entityType, false, requestOptions).then(onSuccess).catch(onFailure);
        } else {
            this.connection.get({uri: urlToServer, authMandatory, ...requestOptions}).then(onSuccess).catch(onFailure);
        }
    });

    public getById = (
        {
            success,
            failure = () => null,
            id,
            url = undefined,
            build204JSON = true,
            authMandatory = true,
            ...requestOptions
        }: ({ id } & RequestOptions)) => {
        const externalUrl = (url || this.url) + '/' + id;
        return this.get({
            url: externalUrl,
            build204JSON,
            authMandatory,
            ...requestOptions
        }).then(success).catch(failure);
    };

    public getServlet = (success, failure, url) => {
        const externalUrl = this.buildURLs.buildServletURL(url);
        const onSuccess = () => {
            success();
        };

        this.connection.get({uri: externalUrl}).then(onSuccess).catch(failure);
    };

    public add = (success, failure, dataObject?, url?, basicAuth?, defer?, authMandatory = true) => {
        const externalUrl = this.buildURLs.buildURL((url || this.url), null);
        this.connection.add({
            uri: externalUrl,
            data: dataObject,
            basicAuth,
            authMandatory
        }).then(this.onSuccess(success, defer)).catch(this.onFailure(failure, defer));
    };

    public edit = (success, failure, dataObject, id, url?, defer?) => {
        delete dataObject.id;

        const externalUrl = this.buildURLs.buildURL((url || this.url) + '/' + id, null);
        return this.connection.update({
            uri: externalUrl,
            data: dataObject
        }).then(this.onSuccess(success, defer)).catch(this.onFailure(failure, defer));
    };

    public put = (success, failure, dataObject, url?, basicAuth?, config?: any) => {
        const externalUrl = this.buildURLs.buildURL(url || this.url, null);
        return this.connection.update({
            uri: externalUrl,
            data: dataObject,
            basicAuth,
            ...config
        }).then(this.onSuccess(success)).catch(this.onFailure(failure));
    };

    public download = (success, failure, dataObject, url?, isPut = false, responseType: ResponseType = 'text') => {
        const externalUrl = this.buildURLs.buildURL(url || this.url, null);
        this.connection.download(isPut, {uri: externalUrl, data: dataObject, responseType}).then(success).catch(failure);
    };

    public upload = (
        {
            success,
            failure,
            dialogConfig,
            responseType,
            isTextFilesWithData = true,
            fileContent = undefined,
            files = undefined,
            model = undefined,
            url = undefined,
            showSuccessMessage = true
        }) => {
        const externalUrl = this.buildURLs.buildURL(url || this.url);
        let infoRef;

        if (dialogConfig) {
            infoRef = this.acDialogService.info('File is uploading, please wait...', {
                cancelButtonText: 'upload in background',
                onCancel: () => {
                    // subscription && subscription.unsubscribe();
                    this.acDialogService.closeAllDialogs();
                    dialogConfig.submitButtonProcessing = false;
                }
            });
        }

        const onSuccess = (value) => {
            infoRef && this.closeDialog(infoRef);
            showSuccessMessage && this.acDialogService.info('File uploaded successfully');

            success(value);
        };

        const onFailure = (error) => {
            infoRef && this.closeDialog(infoRef);
            failure(error);
        };

        this.connection.upload({
            uri: externalUrl, model, isTextFilesWithData, files: fileContent ? [fileContent] : files,
            responseType, success: onSuccess, failure: onFailure
        });
    };

    closeDialog = (dialogRef: AcDialogRef) => {
        if (!dialogRef.dialogConfig) {
            return;
        }
        dialogRef.dialogConfig.onCancel = undefined;
        this.acDialogService.closeDialog(dialogRef);
    };

    public deleteById = ({success = undefined, failure = undefined, id, url = undefined, params = undefined}) => {
        const parameters = !_.isNil(params) ? params : {};
        const externalUrl = this.buildURLs.buildURL((url || this.url) + '/' + id, _.extend(parameters, {detail: 1}));

        AcGeoEventsService.executeCloseOpenTooltipsOnMap();

        const promise = this.connection.remove(externalUrl);
        promise.then(this.onSuccess(success)).catch(this.onFailure(failure));
        return promise;
    };

    public deleteMultiple = (success, failure, entitiesIds, url?, params?) => {
        const parameters = params || {};
        entitiesIds = ArrayUtil.oneToMany(entitiesIds);
        const externalUrl = this.addFilterToURL({filter: {id: entitiesIds, ...parameters}}, url);

        AcGeoEventsService.executeCloseOpenTooltipsOnMap();

        this.connection.remove(externalUrl).then(this.onSuccess(success)).catch(this.onFailure(failure));
    };

    public remove = (success, failure, url, isServlet?) => {
        const externalUrl = isServlet ? this.buildURLs.buildServletURL((url || this.url)) : this.buildURLs.buildURL((url || this.url));
        this.connection.remove(externalUrl).then(success).catch(failure);
    };

    // HELPERS
    protected buildURL = (uri, {parameters, filteredEntity, skipPopulateFilter, ignoreUrlMapping, addScopeIdFilter}: RequestOptions) => {
        if (!skipPopulateFilter && ((this.urlToFilterCallingApi && this.urlToFilterCallingApi[uri]) || filteredEntity)) {
            let type;
            if (this.urlToFilterCallingApi && !ignoreUrlMapping) {
                type = this.urlToFilterCallingApi[uri];
            }

            this.populateFilterPropertyIfNeeded(parameters, filteredEntity || type);
        }

        return this.addFilterToURL(parameters, uri);
    };

    protected addFilterToURL = (parameters, url?) => {
        const query = this.buildURLs.restructureParamsObject(parameters);
        return this.buildURLs.buildURL(url || this.url, query);
    };

    protected populateFilterPropertyIfNeeded = (parameters, type) => {
        const filter = this.entityFilterFormatter.getFilterParameter(type, this.filterEventName);

        if (!_.isNil(filter)) {
            parameters.filter = parameters.filter ? _.extend(parameters.filter, filter) : filter;
        }

        if (this.addSearchToParams && parameters.search && _.isString(parameters.search)) {
            const searchObject: any = {ipAddress: {operator: ':', value: '\'' + parameters.search + '\''}};

            parameters.filter = _.extend((parameters.filter || {}), searchObject);
            delete parameters.search;
        }
    };

    protected handleSuccess = (requestAPI, response): RestResponseSuccess => {
        const defaultObject: RestResponseSuccess = new RestResponseSuccess();
        if (requestAPI === this.entityType) {
            defaultObject.data = {pages: {total: 0, totalElements: 0}, hash: {}};
            defaultObject.data[this.entityType] = [];
        } else {
            defaultObject.data = {entityStatistics: [], summaryStatistics: []};
        }
        return (response.status === 204 ? defaultObject : response);
    };

    private onFailure = (failure, defer?: AcDeferredPromise) => (error) => {
        failure && failure(error);
        defer?.reject(error);
    };

    private onSuccess = (success, defer?: AcDeferredPromise) => (value) => {
        if (this.entityType === 'endpoints') {
            this.cachedConnection.forceRefreshLockedUrl('endpoints');
        }
        success && success(value);
        defer?.resolve(value);
        return value;
    };
}
