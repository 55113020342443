<!-- isEdit = false on purpose don't want yellow background on this form-->
<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="auxModel"
         [formModel]="selectedEntities"
         [formSettings]="formSettings"
         [isEdit]="false"
         [requiredsAuxiliaryForm]="['selectedEntities.regionId']"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="selectedEntities">

    <div id="move-device-dialog-content">
        <p *ngIf="isPerformedDevice" style="color: red">
            Moving device with pre-provisioning configuration to a different tenant is forbidden
        </p>

        <network-tenant-region-selection [model]="selectedEntities"
                                         [additionalSettings]="additionalSettings"
                                         [ngDisabledTenant]="isPerformedDevice"
                                         [isEdit]="true"
                                         (acTenantChanged)="tenantChanged($event)"
                                         [regionFormFieldName]="'selectedEntities.regionId'">
        </network-tenant-region-selection>

        <ac-checkbox *ngIf="hasTenantsChanged"
                     labelText="Are you sure you want to move device/s between tenants? Some configurations will be changed and historical data will be lost."
                     [(acModel)]="auxModel.confirmTenantChange">
        </ac-checkbox>
        <ac-input-container formFieldName="auxModel.confirmTenantChange" [displayOnlyError]="true">
            <div></div>
        </ac-input-container>
    </div>
</ac-form>
