import {Subject} from 'rxjs';

export interface OriginalServerInfo {
    // Do not add any param that not includes in the server
    branding?: string;
    saasMode: boolean;
    meteringEnabled: boolean;
    operatorAuthenticationMode: string;
    ovocMode: string;
    ovocVersion: string;
    restVersion: string;
    status: string;
    allowIntegrationWithUxApp?: boolean;
    whiteLabeling?: any;
    serviceAvailabilityEnabled?: boolean;
    ovocSystemFileLogoId: number;
}

export class ServerInfoService {
    static serverInfoChangedSubject = new Subject();
    static serverInfoChanged$ = ServerInfoService.serverInfoChangedSubject.asObservable();
    static serverInfoPromise: any;

    private static _serverInfo: OriginalServerInfo;
    static updateServerInfoFields(serverInfo: Partial<OriginalServerInfo>) {
        ServerInfoService.serverInfo = {...ServerInfoService._serverInfo, ...serverInfo};
    }
    static set serverInfo(serverInfo: OriginalServerInfo) {
        ServerInfoService._serverInfo = serverInfo;
        ServerInfoService.serverInfoChangedSubject.next(ServerInfoService.serverInfo || {});
    }
    static get serverInfo() {
        return Object.freeze(ServerInfoService._serverInfo);
    }
    static oneLiveMode: boolean;
    static isHybridOperator: boolean;
    static systemType: string;
}
