import {Injectable} from '@angular/core';
import {assign} from 'lodash';

import {AcToastComponent} from './ac-toast.component';
import {GeneralService} from '../../services/general.service';
import {WSMessage} from '../../utils/ws-messages';
import {ToastType} from '../ac-icons/ac-svg/ac-svg.models';

export class toastStatus {
    static readonly critical = new toastStatus(8, GeneralService.statusColors.redStatus, '#fff');
    static readonly major = new toastStatus(7, GeneralService.statusColors.orangeStatus, '#000');
    static readonly minor = new toastStatus(5, GeneralService.statusColors.yellowStatus, '#000');
    static readonly warning = new toastStatus(4, GeneralService.statusColors.blueStatus, '#fff');
    static readonly indeterminate = new toastStatus(2, GeneralService.statusColors.grayStatus, '#ffffff');
    static readonly cleared = new toastStatus(1, GeneralService.statusColors.greenStatus, '#ffffff');

    static readonly failure = new toastStatus(6, '#E21B1B', '#fff');
    static readonly success = new toastStatus(3, '#6AC259', '#fff');
    static readonly task = new toastStatus(0, GeneralService.statusColors.blueStatus, '#fff');
    static readonly none = new toastStatus(-1, 'transparent', 'transparent');

    private constructor(readonly severityIdx: number, readonly color: string, readonly textColor: string) {
    }
}
export class Toast {
    title;
    id;
    content;
    type: ToastType;
    status: toastStatus;
    timeout;
    originalMessage: WSMessage;
    read = false;

    constructor(id?, title?, content?, type?: ToastType, status?: toastStatus, originalMessage?: WSMessage) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.type = type;
        this.status = status;
        this.originalMessage = originalMessage;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AcToastService {

    toastComponent: AcToastComponent;
    private _notificationDuration;

    constructor() {
        // setInterval(() => {
        //     this.show(new Toast(undefined, 'asdasd', 'xcvxcv', ToastType.failure, toastStatus.warning, new WSMessage()));
        // }, 500);
    }

    registerToast(acToast: AcToastComponent) {
        this.toastComponent = acToast;
    }

    show(toast: Toast, notificationDuration?: number) {
        if (!this.toastComponent) {
            return;
        }
        this.toastComponent.show(toast, notificationDuration === undefined ? this.notificationDuration : notificationDuration);
    }

    showById(newToast: Toast) {
        const oldToast = this.findByIdAndModule(newToast.id, newToast.originalMessage.module);
        if (oldToast) {
            assign(oldToast, newToast);
        } else {
            this.show(newToast);
        }
    }

    close(toast: Toast) {
        clearTimeout(toast && toast.timeout);
        this.toastComponent && this.toastComponent.toasts.splice(this.toastComponent.toasts.indexOf(toast), 1);
    }

    CloseAll() {
        this.toastComponent && this.toastComponent.closeAll();
    }

    findByIdAndModule(id: string, type: string) {
        return this.toastComponent && this.toastComponent.toasts.find((oldToast) => oldToast.id === id && oldToast.originalMessage.module === type);
    }

    setNotificationDuration(notificationDuration: number) {
        this._notificationDuration = notificationDuration;
    }

    get notificationDuration() {
        return this._notificationDuration || 5000;
    }

    unRegister(toast: AcToastComponent) {
        if (this.toastComponent === toast) {
            this.toastComponent = null;
        }
    }
}
