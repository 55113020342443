import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, ViewChild, ViewEncapsulation} from '@angular/core';
import {SvgModelName} from './ac-svg.models';
import $ from 'jquery';
import {PromiseService} from '../../../services/promise.service';
import {ArrayUtil} from '../../../utils/array-util';
import {GeneralService} from '../../../services/general.service';

interface SVGPath {
    names: string[];
    url: string;
}

@Component({
    selector: 'ac-svg',
    templateUrl: './ac-svg.component.html',
    styleUrls: ['./ac-svg.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AcSvgComponent {

    static SVGsCache: any = {};
    static externalSVGsPaths: { [key: string]: SVGPath } = {};
    @ViewChild('svgParent', {static: false}) svgParent: ElementRef;

    @Input() height: any = '100%';
    @Input() width: any = '100%';
    @Input() mirrorHor = false;
    @Input() mirrorVer = false;
    @Input() rotate = 0;
    @Input() hoverFillColor;
    @Input() updatable = true;
    @Input() text;
    svgModel;

    _name: SvgModelName | string;
    _fillColor = '';

    @Input() set name(name: SvgModelName | string) {
        this._name = name;
        this.setModel();
    }

    @Input() set fillColor(fillColor: string) {
        this._fillColor = GeneralService.statusToColorMap[fillColor] || fillColor;
        this.changeFillColor();
    }

    constructor(public cdRef: ChangeDetectorRef, private zone: NgZone) {
    }

    setModel() {
        this.getSvgModelByName(this._name).then((svg) => {
            this.svgModel = svg;
             this.cdRef.detectChanges();
        });

        this.updatable && this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.cdRef.detectChanges();
            });
        });
    }

    ngOnInit() {
        this.setModel();
    }

    ngAfterViewInit() {
        this.changeFillColor();
    }

    getSvgModelByName = (svgModelName: SvgModelName | string) => {
        const defer = PromiseService.defer();

        if (!svgModelName) {
            defer.resolve(undefined);
        }

        let svg = AcSvgComponent.SVGsCache[svgModelName];
        if (svg) {
            defer.resolve(svg);
        }else{
            const path: SVGPath = AcSvgComponent.externalSVGsPaths[svgModelName] || {
                url: `assets/svgs/${svgModelName}.svg`,
                names: [svgModelName]
            };

            $.ajax({
                url: path.url,
                success: (result) => {
                    svg = result && result.documentElement && result.documentElement.outerHTML || '';
                    defer.resolve(svg);
                },
                error: () => {
                    defer.resolve(undefined);
                },
                async: false
            });

            path.names.forEach(name => {
                AcSvgComponent.SVGsCache[name] = defer.promise;
            });
        }

        return defer.promise;
    };

    changeFillColor(fillColor?: string) {
        this._fillColor && this.svgParent && this.svgParent.nativeElement.children[0]?.setAttribute('fill', fillColor || this._fillColor);
    }

    changeFillColorOnHTML = (html) => !this._fillColor && html || html && html.replace('<svg ', '<svg fill="' + this._fillColor + '" ');

    getTransformStyle = (rotate, mirrorHor, mirrorVer) => {
        return this.getMirrorStyle(mirrorHor, mirrorVer) + this.getRotateStyle(rotate);
    };

    private getMirrorStyle(mirrorHor, mirrorVer) {
        if (!mirrorHor && !mirrorVer) {
            return '';
        }
        return 'scale(' + (mirrorHor ? '-1' : '1') + ', ' + (mirrorVer ? '-1' : '1') + ') ';
    }

    private getRotateStyle(rotate) {
        return rotate ? 'rotate(' + rotate + 'deg)' : '';
    }

    static addPaths(names: any, url) {
        const namesArr: string[] = ArrayUtil.oneToMany(names);
        namesArr.forEach((name: string) => {
            AcSvgComponent.externalSVGsPaths[name] = {url, names: namesArr};
        });
    }
}
