import {Component, Input} from '@angular/core';
import {CallsStorageRestService} from '../../../configuration-api/calls-storage-rest.service';
import {RestResponseSuccess} from '../../../../../common/server-actions/rest';
import * as _ from 'lodash';
import {GeneralService, SchemaHelperService} from 'ac-infra';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {TenantsRestService} from "../../../../../network/services/apis/tenants-rest.service";

@UntilDestroy()
@Component({
    selector: 'calls-storage-entities',
    templateUrl: './calls-storage-entities.component.html',
    styleUrls: ['./calls-storage-entities.component.less'],
})
export class CallsStorageEntitiesComponent {

    @Input() tenantCallStorage: any;
    originalTenantCallStorage;
    systemCallStorage;
    entitiesMap: any;

    constructor(private callsStorageRestService: CallsStorageRestService,
                private tenantsRestService: TenantsRestService,
                public generalService: GeneralService) {
        this.entitiesMap = {
            ...CallsStorageRestService.callsStatusSlidersData,
            ...CallsStorageRestService.statisticsStatusSlidersData
        };
    }

    ngOnInit() {
        this.originalTenantCallStorage = _.cloneDeep(this.tenantCallStorage);
        const onSystemInfoSuccess = (response: RestResponseSuccess) => {
            this.systemCallStorage = response.data;
        };

        this.callsStorageRestService.getSystemCallsStorageInfo(onSystemInfoSuccess, {});

        this.tenantsRestService.validateAllocAuxiliaryForm$.pipe(untilDestroyed(this)).subscribe(({errors, auxModel, formModel}) => {
            this.systemCallStorage && this.validateAuxiliaryForm(errors, auxModel, formModel);
        });
    }

    spinnerChanged = (entity, value) => {
        if (!Number.isInteger(value)) {
            this.tenantCallStorage[entity.objName].maxEntities = 1;
        }
    };

    getUsedEntitiesValue = (entity) => {
        const systemAllocatedEntities = this.systemCallStorage[entity.objName].allocatedEntities;
        const currentInputValue = this.tenantCallStorage[entity.objName].maxEntities;
        const originalInputValue = this.originalTenantCallStorage[entity.objName].maxEntities;

        return (systemAllocatedEntities + currentInputValue - originalInputValue) || currentInputValue;
    };

    getMaxEntityValue = (entity) => (this.systemCallStorage[entity.objName] && this.systemCallStorage[entity.objName].maxEntities) ? Math.max(0, this.calculateMaxEntityValue(entity)) : 0;

    validateAuxiliaryForm = (errors, auxAllocModel, formModel) => {
        _.forOwn(this.entitiesMap, (entity) => {
            if (this.tenantCallStorage[entity.objName].maxEntities > this.getMaxEntityValue(entity)) {
                errors.push(SchemaHelperService.buildErrorItem({
                    inputName: 'tenantCallStorage.' + entity.objName + '.maxEntities', keyword: 'valueExceeded'}));
            }
        });
    };

    private calculateMaxEntityValue = (entity) => (this.systemCallStorage[entity.objName].maxEntities - this.systemCallStorage[entity.objName].allocatedEntities + this.originalTenantCallStorage[entity.objName].maxEntities);
}



