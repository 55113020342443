<mat-calendar class="calendar"
              [headerComponent]="acDatePickerHeader"
              [selected]="_selectedRange"
              (selectedChange)="onSelect($event)"
              #calendar>
</mat-calendar>

<div class="calendar-footer flexColumnParent">
    <div class="ac-range-calendar-time-picker flexRowParent">
        <ac-time-picker label="Start"
                        [selectedTime]="_startTimeState"
                        (selectedTimeChanged)="onStartTimeChange($event)"
                        [ignoreViewMode]="true"
                        #startTime/>

        <ac-time-picker label="End"
                        [selectedTime]="_endTimeState"
                        (selectedTimeChanged)="onEndTimeChange($event)"
                        [ignoreViewMode]="true"
                        [minTime]="_startTimeState | acFunc: isMinDateNeeded"
                        #endTime/>
    </div>
    <div class="ac-calendar-actions flexRowParent">
        <ac-button class="clear" (click)="cancel.emit()">Cancel</ac-button>
        <ac-button (click)="applyClick(startTime, endTime)">Apply</ac-button>
    </div>
</div>


