import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcRadioButtonsGroupComponent} from './ac-radio-buttons-group.component';
import {MatRadioModule} from '@angular/material/radio';
import {InfraPipesModule} from "../../pipes/infra.pipes.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatRadioModule,
        InfraPipesModule
    ],
    declarations: [
        AcRadioButtonsGroupComponent,
    ],
    entryComponents: [
        AcRadioButtonsGroupComponent,
    ],
    exports: [
        AcRadioButtonsGroupComponent,
    ]
})
export class AcRadioButtonsGroupModule {
}
