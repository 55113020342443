import { Injectable } from "@angular/core";
import { NetworkFilterFormatterService } from "../network-filter-formatter.service";
import { NetworkRest } from "../../../common/server-actions/network-rest";


@Injectable({providedIn: 'root'})
export class ServicesRestService extends NetworkRest {
    static UCassServicesSource = ['LIVE_CX', 'ZOOM', 'WEBEX_LGW', 'WEBEX_CLOUD_CONNECT', 'GENERIC'];
    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'topology/services', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter', entityType: 'services'});
    }
}
