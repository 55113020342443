import {Component, Input} from '@angular/core';
import {dateTimeFormatter, FormatterType} from 'ac-infra';
import {Operator} from '../../operator.interface';
import {DateTime} from 'luxon';

@Component({
    selector: 'operator-suspend-field',
    templateUrl: './operator-suspend-field.component.html',
    styleUrls: ['./operator-suspend-field.component.less'],
})
export class OperatorSuspendFieldComponent {

    @Input() operator: Operator;
    @Input() isEdit = false;
    @Input() additionalSettings: any;
    suspendSince;
    minSuspendedDate = DateTime.now().startOf('hour').plus({hour: 1});
    initiallyEmpty = false;
    suspendedOptions = [
        {value: 'notSuspended', text: 'Not Suspended'},
        {value: 'suspended', text: 'Suspended'},
        {value: 'suspendedDate', text: 'Future Suspension'},
    ];

    @Input() set globalAutoSuspendDays(globalAutoSuspendDays: number) {
        this.initializeFutureSuspendDate(globalAutoSuspendDays);

        if (!this.isEdit && globalAutoSuspendDays > 0) {
            this.additionalSettings.suspendedState = this.suspendedOptions[2].value;
            this.onSuspendedStateChange(this.additionalSettings.suspendedState);
        }
    }

    ngOnInit() {
        this.additionalSettings.suspendedState = this.suspendedOptions[0].value;
        if (this.operator.suspendStatus) {
            this.additionalSettings.suspendedState = this.suspendedOptions[1].value;
        } else if (this.operator.autoSuspendTime) {
            this.additionalSettings.suspendedState = this.suspendedOptions[2].value;
        }

        this.filterSuspendTimeIfNeeded();
    }

    initializeFutureSuspendDate(daysToSuspension) {
        let suspendedDate: DateTime = DateTime.now().plus({day: daysToSuspension});
        if (this.isEdit && this.operator.autoSuspendTime) {
            suspendedDate = DateTime.fromISO(this.operator.autoSuspendTime);
        }

        suspendedDate.startOf('hour');

        if (daysToSuspension === 0) {
            this.initiallyEmpty = this.isEdit && !this.operator.autoSuspendTime || !this.isEdit;
            this.additionalSettings.suspendedDate = null;
        }

        if (this.initiallyEmpty) {
            return;
        }

        this.additionalSettings.suspendedDate = suspendedDate;

    }

    onSuspendedStateChange(suspendedState: any) {
        this.additionalSettings.suspendedState = this.suspendedOptions.find(suspendedOption => suspendedOption.value === suspendedState).value;
        switch (this.additionalSettings.suspendedState) {
            case 'suspended': {
                this.operator.suspendStatus = true;
                this.operator.autoSuspendTime = null;
                break;
            }
            case 'suspendedDate': {
                this.onSuspendDateChange(this.additionalSettings.suspendedDate);
                this.operator.suspendStatus = false;
                break;
            }
            default: {
                this.operator.suspendStatus = false;
                this.operator.autoSuspendTime = null;
            }
        }
    }

    onSuspendDateChange(suspendDate: DateTime, isPickerEvent = false) {
        if (!suspendDate) {
            return;
        }
        if (isPickerEvent) {
            this.initiallyEmpty = false;
        }
        suspendDate = suspendDate.startOf('hour');
        this.additionalSettings.suspendedDate = suspendDate;
        this.operator.autoSuspendTime = suspendDate ? suspendDate.toUTC().toISO() : null;
    }

    private filterSuspendTimeIfNeeded = () => {
        if (this.isEdit && this.operator.suspendTime && this.operator.suspendTime !== '') {
            this.suspendSince = dateTimeFormatter(this.operator.suspendTime, FormatterType.dateAndTime);
        }
    };
}


