import {Injectable} from '@angular/core';
import {isNil} from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {

    ipAddressRegEx =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

    emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    public isValidIPAddress = (ipAddress) => this.isValueNotEmpty(ipAddress) && this.ipAddressRegEx.test(ipAddress);

    public isValidEmailAddress = (email) => this.isValueNotEmpty(email) && this.emailRegEx.test(email);

    public isFieldDisabled = (ngDisabled, inputIsDisabled, formIsDisabled, readOnly = undefined) => {
        return (readOnly || ngDisabled || inputIsDisabled || formIsDisabled)?true:null;
    }

    private isValueNotEmpty(value) {
        return !isNil(value) && value !== null && value !== '';
    }
}
