<div *ngIf="serverInfoService.serverInfo"
     [class.PDFMode]="generalService.PDFMode"
     [class.OneLiveMode]="serverInfoService.serverInfo && serverInfoService.oneLiveMode"
     class="app-container">

    <div class="loading-system"
         *ngIf="generalService.systemInitialized === false"
         [class.pointerEventsNone]="generalService.systemInitialized === true"
         [class.hidden]="generalService.systemInitialized === null">

        <ac-splash-screen [ready]="generalService.systemInitialized !== true"
                          [pageTitle]="'LOADING, PLEASE WAIT... ' + (generalService.loading | acFunc : loading)"
                          [logoTemplate]="logoTemplate"></ac-splash-screen>

        <ng-template #logoTemplate>
            <logo-display></logo-display>
        </ng-template>
    </div>

    <router-outlet></router-outlet>
</div>

