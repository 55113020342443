<div class="tenant-and-region-container" [class.flexRowParentEqualChildren]="horizontalView">
    <div class="entity-selection-container flexRowParent">
        <ac-input-container class="flexItemBiggest"
                            [formFieldName]="tenantFormFieldName || 'tenantId'" [label]="tenantLabel || 'Tenant'"
                            [class.flexItemRowEquals]="horizontalView">
            <ac-single-select [sortItems]="sortTenants"
                              [acModel]="model.tenantId || additionalSettings.tenantId"
                              [items]="tenants"
                              bindLabel="name"
                              bindValue="id"
                              [ngDisabled]="ngDisabledTenant"
                              [overrideSystemViewMode]="overrideSystemViewMode"
                              (acModelChange)="onTenantChange($event);">
            </ac-single-select>
        </ac-input-container>

        <ac-button *ngIf="showAddTenantButton" (acClick)="addNewTenant()" buttonType="icon-button" class="add-new-entity-button flexItem">
            <ac-svg name="duplicate"
                    title="Add New Tenant"
                    [hoverFillColor]="colors.darkPrimary">
            </ac-svg>
        </ac-button>
    </div>

    <div class="entity-selection-container flexRowParent" *ngIf="showRegion">
        <ac-input-container class="flexItemBiggest"
                            [formFieldName]="regionFormFieldName"
                            label="Region"
                            [class.flexItemRowEquals]="horizontalView">
            <ac-single-select [sortItems]="true"
                              [acModel]="model.regionId || additionalSettings.regionId"
                              [items]="regionsByTenant"
                              (acModelChange)="model.regionId = $event; acRegionChanged.emit($event)"
                              bindLabel="name"
                              bindValue="id"
                              [ngDisabled]="ngDisabledRegion">
            </ac-single-select>
        </ac-input-container>

        <ac-button *ngIf="showAddRegionButton" (acClick)="addNewRegion()" buttonType="icon-button" class="add-new-entity-button flexItem">
            <ac-svg name="duplicate"
                    title="Add New Region"
                    [hoverFillColor]="colors.darkPrimary">
            </ac-svg>
        </ac-button>
    </div>

</div>

