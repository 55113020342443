import {Component, Input} from '@angular/core';
import * as _ from 'lodash';
import {GeneralService} from 'ac-infra';
import {TenantsRestService} from '../../../../network/services/apis/tenants-rest.service';
import {MetadataService} from '../../../../metadata/metadata.service';
import {ServerInfoService} from "../../../../common/services/server-info.service";


@Component({
    selector: 'operator-topology-tab',
    templateUrl: './operator-topology-tab.component.html',
})
export class OperatorTopologyTabComponent {

    @Input() operator;
    @Input() tenants;
    @Input() groups;
    @Input() links;
    @Input() devices;
    @Input() additionalSettings;

    linksObject: any;

    tenantOperatorList = ['ADMIN', 'OPERATOR'];
    linksItems = [];
    groupsItems = [];
    serverInfoService = ServerInfoService;

    constructor(public generalService: GeneralService,
                private tenantsRestService: TenantsRestService) {

    }

    ngOnInit() {

        if (this.operator.assignedTenants || !this.operator.isSystemOperator) {
            if(ServerInfoService.oneLiveMode && this.operator.assignedTenants.length === 1){
                this.additionalSettings.assignedTenant = this.operator.assignedTenants[0];
            }

            this.assignedTenantsChanged();
        }
        this.linksObject = {};

        _.forOwn(this.links, (link) => {
            this.linksObject[link.id] = link;
        });
    }

    assignedTenantsChanged = () => {
        this.updateGroupList(); // for tenant operator/admin
        this.updateLinkList(); // for tenant link operator/admin/monitor
    };

    updateGroupList = () => {
        if (!this.tenantOperatorList.includes(this.operator.securityLevel)) {
            delete this.operator.assignedGroups;
            this.operator.isTenantOperatorWithEndpointGroups = false;
            return;
        }

        this.groupsItems = [];
        if (this.operator.assignedTenants && this.operator.assignedTenants.length > 0) {
            _.forOwn(this.operator.assignedTenants, (assignedTenant) => {
                this.groupsItems = this.groupsItems.concat(this.groups.filter((group) => group.tenantId === assignedTenant));
            });
        } else {
            this.operator.assignedGroups = [];
        }

        this.removeItemsNotInList(this.groupsItems, 'assignedGroups');

    };

    updateLinkList = () => {
        if (this.operator.securityLevel !== 'MONITORING_LINKS' || ServerInfoService.oneLiveMode) {
            delete this.operator.assignedLinks;
            return;
        }

        this.linksItems = [];
        if (this.operator.assignedTenants && this.operator.assignedTenants.length > 0) {
            _.forOwn(this.operator.assignedTenants, (assignedTenant) => {
                this.linksItems = this.linksItems.concat(this.links.filter((link) => this.isLinkValid(link, assignedTenant)));
            });
        } else {
            this.operator.assignedLinks = [];
        }

        this.removeItemsNotInList(this.linksItems, 'assignedLinks');
    };

    groupsOperatorChanged = () => {
        if (this.operator.isTenantOperatorWithEndpointGroups) {
            this.operator.assignedGroups = [];
        } else {
            delete this.operator.assignedGroups;
        }
    };

    isLinkValid = (link, assignedTenant) => {
        const linkTenantIdIncludesInAssignedTenants = link.tenantId === assignedTenant;
        const linkLinkDestinationIncludesInAssignedTenants = this.operator.assignedTenants.includes(link.dstTenantId);

        return linkTenantIdIncludesInAssignedTenants && linkLinkDestinationIncludesInAssignedTenants;
    };

    groupByTenantName = (item) => 'Tenant: ' + this.tenantsRestService.getEntityById(item.tenantId).name;

    private removeItemsNotInList(listItems, entityName) {
        // remove from assignedLinks , links that are not in linksItems
        const newAssignedItems = [];

        _.forOwn(this.operator[entityName], (itemId) => {
            const foundItem = listItems.find((listItem) => listItem.id === itemId);
            if (foundItem) {
                newAssignedItems.push(foundItem.id);
            }
        });
        this.operator[entityName] = newAssignedItems;
    }
}


