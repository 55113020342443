import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {MetadataService} from '../../metadata/metadata.service';
import {dateTimeFormatter, FormatterType, GeneralService} from 'ac-infra';
import {RestResponseSuccess} from '../server-actions/rest';

@Injectable({providedIn: 'root'})
export class SaveDataToFileService {

    alarmsColumnsNameMapList;
    FileSaver;

    constructor(private generalService: GeneralService) {

        this.alarmsColumnsNameMapList = MetadataService.getType('AlarmsColumnsNameMap', true);
        this.FileSaver = require('file-saver');
    }

    createCSVFile = (moduleName, moduleFilter, data, orderBy) => {
        const resultStringObject = {csvData: ''};

        orderBy = orderBy ? orderBy : [];

        this.buildCSVFileInfo(resultStringObject, moduleName, moduleFilter, data.length);
        this.buildCSVTitle(resultStringObject, orderBy);
        this.addAlarmsDataToFile(resultStringObject, data, orderBy);
        this.downloadCSVFile(resultStringObject, moduleName);
    };

    createZIPDataFile = (fileName, data) => {
        this.FileSaver.saveAs(data, fileName);
    };

    createCSVDataFile = (fileName, data) => {
        const fileNameWithPrefix = fileName.indexOf('.csv') >= 0 ? fileName : fileName + '.csv';
        const fileType = {type: 'text/csv;charset=utf-8'};
        const blob = new Blob([data], fileType);

        this.FileSaver.saveAs(blob, fileNameWithPrefix);
    };

    createDynamicFile = ({blobFile, fileName = '', fileNamePrefix = undefined, extensionName = undefined, addDateTimePostFix = false}) => {
        if (fileNamePrefix) {
            fileName = fileNamePrefix + fileName;
        }

        if (addDateTimePostFix) {
            fileName += this.addDataToFileName();
        }

        if (extensionName) {
            fileName += '.' + extensionName;
        }

        const fileType = {type: 'octet/stream'};
        const blob = new Blob([blobFile], fileType);

        this.FileSaver.saveAs(blob, fileName);
    };

    createJsonDataFile = (dataObject, id?) => {
        const idPrefix = id ? id + '-' : '';
        const fileName = idPrefix + this.addDataToFileName() + '.json';
        const fileType = {type: 'data:text/json;charset=utf-8'};
        const blob = new Blob([JSON.stringify(dataObject)], fileType);

        this.FileSaver.saveAs(blob, fileName);
    };

    downloadDataToCSVFile = (response: RestResponseSuccess) => {
        const fileName = this.extractNameFromResponse(response);
        const usageData = response.data;

        this.createCSVDataFile(fileName, usageData);
    };

    private buildCSVFileInfo = (stringObject, moduleName, moduleFilter, dataCount) => {
        stringObject.csvData += this.createFileType(moduleName);
        // stringObject.csvData += "# of Requested Records:,1000\n";
        stringObject.csvData += '# of Fetched Records:,' + dataCount + '\n';
        stringObject.csvData += 'Filters:\n';

        if (this.exists(moduleFilter)) {
            Object.getOwnPropertyNames(moduleFilter).forEach((fieldName) => {
                this.addFilterToFile(stringObject, moduleFilter, fieldName);
            });
        } else {
            stringObject.csvData += 'None';
        }

        stringObject.csvData += '\n\n';
    };

    private createFileType = (moduleName) => {
        switch (moduleName) {
            case 'active'   :
            case 'history'          :
                return 'File:,Alarms and Events\n';
            case 'journal'          :
                return 'File:,Journal Activities\n';
            case 'callsList'        :
                return 'File:,Calls List\n';
            case 'fixedLicensePool' :
                return 'File:,Fixed License Pool List\n';
        }
    };

    private addFilterToFile = (stringObject, moduleFilter, fieldName) => {
        stringObject.csvData += fieldName + ',' + this.addFilterValue(moduleFilter[fieldName], fieldName) + '\n';
    };

    private addFilterValue = (filterValue: any, fieldName) => {
        if (Array.isArray(filterValue)) {
            return filterValue.join(',');
        } else if (_.isObject(filterValue)) {
            // @ts-ignore
            if (Array.isArray(filterValue?.value)) {
                // @ts-ignore
                return filterValue?.value.join(',');
            } else {
                return this.formatObjectFilter(filterValue, fieldName);
            }
        } else {
            return filterValue;
        }
    };

    private formatObjectFilter = (filterValue, fieldName) => {
        const timeFilterFields = ['alarmReceivedTime', 'createDateTime', 'timestamp'];

        return timeFilterFields.indexOf(fieldName) >= 0 ?
            this.buildTimeFilter(filterValue) :
            dateTimeFormatter(filterValue.values, FormatterType.dateAndTime);
    };

    private buildTimeFilter = (filterValue) => [
        'From: ', dateTimeFormatter(filterValue.values[0], FormatterType.dateAndTime),
        ' To: ', dateTimeFormatter(filterValue.values[1], FormatterType.dateAndTime)
    ].join(',');

    private buildCSVTitle = (stringObject, orderBy) => {
        const columnsTitle = [];

        orderBy.forEach((item) => {
            columnsTitle.push((this.alarmsColumnsNameMapList[item] && this.alarmsColumnsNameMapList[item].viewName) || item);
        });

        stringObject.csvData += columnsTitle.join(',');
        stringObject.csvData += '\n';
    };

    private addAlarmsDataToFile = (stringObject, alarms, orderBy) => {
        alarms.forEach((alarm) => {
            stringObject.csvData += this.convertObjectToString(alarm, orderBy);
            stringObject.csvData += '\n';
        });
    };

    private downloadCSVFile = (stringObject, moduleName) => {
        const fileType = {type: 'text/csv;charset=utf-8'};
        const fileName = this.createFileName(moduleName);
        const fileData = [stringObject.csvData];
        const blob = new Blob(fileData, fileType);

        this.FileSaver.saveAs(blob, fileName);
    };

    private createFileName = (moduleName) => {
        const titleTimeStamp = this.addDataToFileName();

        switch (moduleName) {
            case 'active'           :
                return 'ActiveAlarms_' + titleTimeStamp + '.csv';
            case 'history'          :
                return 'HistoryAlarms_' + titleTimeStamp + '.csv';
            case 'journal'          :
                return 'JournalEvents_' + titleTimeStamp + '.csv';
            case 'callsList'        :
                return 'CallsList_' + titleTimeStamp + '.csv';
            case 'fixedLicensePool' :
                return 'FixedLicensePool_' + titleTimeStamp + '.csv';
        }
    };

    private addDataToFileName = () => {
        const currentDate = new Date(Date.now());

        return [
            currentDate.getDate(),
            currentDate.getMonth() + 1,
            currentDate.getFullYear()
        ].join('_');
    };

    private decryptPCAPEncodedString = (pcapEncodedStr) => {
        const binaryString = window.atob(pcapEncodedStr);
        const binaryStringLength = binaryString.length;
        const bytesArray = new Uint8Array(binaryStringLength);

        for (let i = 0; i < binaryStringLength; i++) {
            bytesArray[i] = binaryString.charCodeAt(i);
        }

        return bytesArray;
    };

    private convertObjectToString = (alarm, orderBy) => orderBy
        .map(this.removeCommaFromValue(alarm))
        .join(',');

    private removeCommaFromValue = (alarm) => (propName) => {
        const newValue = this.exists(alarm[propName]) ? alarm[propName] : '';

        return (newValue.toString()).replace(/,/g, '');
    };

    private exists = (value) => !_.isNil(value) && value !== null;

    private extractNameFromResponse = (response) => {
        const contentDisposition = response.headers.get('content-disposition');

        return contentDisposition ? this.extractName(contentDisposition) : 'SaveUsageReportData';
    };

    private extractName = (contentDisposition) => {
        const fileNameIndex = contentDisposition.indexOf('filename=');

        return contentDisposition.substring(fileNameIndex + 9);
    };
}
