import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';
import {of} from 'rxjs';
import {RequestOptions} from 'ac-infra';


@Injectable({providedIn: 'root'})
export class ExternalApplicationsRestService extends Rest {

    constructor() {
        super({url: 'settings/externalApplications/arm/settings', entityType: 'externalApplications'});
    }

    public getLockedURL = (uri, parameters) => this.addFilterToURL(parameters, uri ? uri : 'status/externalApplications');

    public getArmIp = (success, failure) => {
        this.get({skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public editArmIp = (armConnectionData, success, failure) => {
        this.put(success, failure, armConnectionData);
    };

    public getArmServerToken = (success, failure?) => {
        this.get({url: 'settings/externalApplications/arm/token', skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public getIAMToken = (success, failure, type, unitId) => {
        this.add(success, failure, {type, unitId}, 'settings/externalApplications/iam/token');
    };

    public getMasterScopeConfiguration = (success, failure) => {
        this.get({url: 'settings/externalApplications/masterScope/settings', skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public editMasterScopeConfiguration = (masterScopeData, success, failure) => {
        this.put(success, failure, masterScopeData, 'settings/externalApplications/masterScope/settings');
    };

    public getZoomConfiguration = (success, failure) => {
        this.get({url: 'settings/externalApplications/zoom/settings', skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public editZoomConfiguration = (zoomConfiguration, success, failure) => {
        this.put(success, failure, zoomConfiguration, 'settings/externalApplications/zoom/settings');
    };

    public getApplicationsStatuses = (success, failure) => of(this.get({
        url: 'status/externalApplications',
        skipPopulateFilter: true,
        build204JSON: false,
    }).then(success).catch(failure));

    public getApplicationsStatusesById = (ids, requestOptions: RequestOptions) => {
        const parameters = {filter: {nodeId: ids}};
        return this.get({url: 'status/externalApplications', skipPopulateFilter: true, extendParameters: true, build204JSON: false, parameters, ...requestOptions});
    };
}
