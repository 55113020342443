<div *ngIf="tenantInfoCallStorage && maxCallRatio" class="grid-2-resize">
    <ac-slider *ngIf="maxCalls.options"
               (acModelChange)="maxCallsChange($event)"
               label="Max Calls"
               [acModel]="maxCalls.current"
               [options]="maxCalls.options"
               [showTooltip]="true"
               tooltipText="Drag or Type Number">
    </ac-slider>


    <ac-slider *ngIf="maxCallsWidthCallFlow.options"
               (acModelChange)="maxCallsWithCallFlowChange($event)"
               label="Max Calls With Call Flow"
               [acModel]="maxCallsWidthCallFlow.current"
               [options]="maxCallsWidthCallFlow.options"
               [showTooltip]="true"
               tooltipText="Drag or Type Number">
    </ac-slider>
</div>

