<ac-input-container formFieldName="serverCallsStorage.sbcCallsStorageAlgorithm" class="storageLevelSelect"
                    label="Calls Storage level:">
    <ac-single-select [(acModel)]="serverCallsStorage.sbcCallsStorageAlgorithm"
                      (acModelChange)="handleCallStorageLevelChange()"
                      [items]="callsStorageLevelOptions">
    </ac-single-select>
</ac-input-container>

<mat-divider></mat-divider>

<div class="SuccessfulCallsLabel">Successful Calls</div>

<calls-storage-quality
    (acChanged)="handleTableChange()"
    [tableObj]="callsSuccessTableObj"
    [serverCallsStorage]="serverCallsStorage">
</calls-storage-quality>

<mat-divider></mat-divider>

<div class="FailedCallsLabel">Failed Calls</div>

<calls-storage-quality
    (acChanged)="handleTableChange()"
    [tableObj]="callsFailedTableObj"
    [serverCallsStorage]="serverCallsStorage">
</calls-storage-quality>
