<ac-search *ngIf="hasSearchBar"
           [(search)]="search"
           [componentId]="treeId + '-search'"
           [placeHolder]="searchPlaceHolder"
           class="flexItem">
</ac-search>

<ac-tree #acTree
             class="flexItemBiggest ac-tree"
             [customTreeNodeWrapperTemplate]="customTemplate || flatNavigationTemplate"
             [extendOptions]="extendTreeOptions"
             [search]="search"
             [treeId]="treeId"
             [doSort]="doSort"
             [sortCompare]="sortCompare"
             [treeNodes]="treeNodes"
             (activeChange)="onActiveNodes($event)"
             (initialized)="onInitialized($event)"
             (updateData)="onUpdateData($event)"
></ac-tree>


<ng-template #flatNavigationTemplate let-index="index" let-node>
    <div (click)="node.mouseAction('click', $event);"
         [class.node-content-wrapper-active]="node.isActive"
         [class.disable-upper-case]="disableUpperCase"
         [class.ac-disabled]="node?.data?.disabled"
         [class.hasError]="node?.data?.isValid === false"
         [id]="treeId + '-node-' + (node.data.id | acFunc : replaceSpaces)"
         class="node-wrapper flexRowParent parent parent-slim">

        <div class="node-content-wrapper flexItemBiggest">
            <div class="node-content flexRowParent">
                <div acLabel class="flexItemBiggest">
                    <tree-node-content [index]="index" [node]="node"></tree-node-content>
                </div>

                <div *ngIf="node?.data?.icon && node?.data?.icon.visible !== false" class="flexItem">
                    <ac-svg *ngIf="node?.data?.icon.name"
                            [title]="node?.data?.icon?.title"
                            [name]="node?.data?.icon?.name"
                            [fillColor]="node?.data?.icon?.fillColor || (node.isActive ? 'white' : 'black')"
                            width="18px" height="18px">
                    </ac-svg>
                    <mat-icon *ngIf="node?.data?.icon.matName" class="tree-nav-mat-icon"
                              [title]="node?.data?.icon?.title"
                              [fontIcon]="node?.data?.icon.matName">
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>
</ng-template>
