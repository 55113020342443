import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ConstantStringsService {
    static linkOrProviderSide: "Link" | "SIP Connection" = 'Link';
    static deviceOrTeamsSide = 'Device';

    static get linkOrProviderSideMany() {
        return ConstantStringsService.linkOrProviderSide.replace(' Side', '') + 's';
    }

    static get deviceOrTeamsSideMany() {
        const entityName = ConstantStringsService.deviceOrTeamsSide.replace(' Side', '');
        return entityName + (entityName.toLowerCase() === 'devices' ? '' : 's');
    }
}

