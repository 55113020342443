import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import * as _ from 'lodash';

import {DeviceDetailsDataFetcherService} from '../../services/device-details-data-fetcher.service';
import {AcDialogService, AcTableState, DateTimeFormatterPipe, DynamicTabsService, RefreshTableProperties, WSMessage, AcTableActionsService} from 'ac-infra';
import {PMProfilesService} from '../../../statistics/services/PMProfilesService';
import {combineLatest, Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import {DeviceDetailsService} from '../device-details.service';
import {DynamicTabDetailsService} from '../../services/dynamic-tab-details.service';
import {NetworkFilterFormatterService} from '../../../network/services/network-filter-formatter.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngxs/store';
import {RestResponseFailure} from '../../../common/server-actions/rest';
import {PerformanceMeasurementsActionsService} from '../../../statistics/services/performance-measurements-actions.service';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';

@UntilDestroy()
@Component({
    selector: 'device-details-statistics',
    templateUrl: './device-details-statistics.component.html',
    styleUrls: ['./device-details-statistics.component.less'],
})
export class DeviceDetailsStatisticsComponent {

    static readonly FILTER_SESSION_KEY = 'deviceDetailsDynamicTab_';
    readonly PM_STATISTICS_TABLE_ID_PREFIX = 'device-pm-statistics-grid-';
    deviceId = '';

    storeKey: any;
    dataReadyObject: any = {fetchedDevice: false, fetchedProfile: false, fetchedDictionaries: false};
    deviceDetails: any = {};
    pageDetails: any;
    productType: any;
    deviceVersion: any;

    existingFilters: any = {};
    hasFilter = false;
    inProgressSelectionFlag = true;
    inProgressFilterFlag = true;
    itemsSelectedFlag = false;
    deviceStatisticsGridData;
    deviceStatisticsChartData: any = {};

    devicePMParametersMap: any = {};
    devicePMDictionariesMap: any = {};

    isGroupCollapsed = false;
    currentFilter;
    selection;
    refresh$ = new Subject<RefreshTableProperties>();

    constructor(private deviceDetailsDataFetcherService: DeviceDetailsDataFetcherService,
                private deviceDetailsService: DeviceDetailsService,
                private dateTimeFormatterPipe: DateTimeFormatterPipe,
                private networkFilterFormatterService: NetworkFilterFormatterService,
                private pmProfilesService: PMProfilesService,
                private activatedRoute: ActivatedRoute,
                private dynamicTabsService: DynamicTabsService,
                private dynamicTabDetailsService: DynamicTabDetailsService,
                private acDialogService: AcDialogService,
                private wsEntitiesService: WsEntitiesService,
                private router: Router,
                private performanceMeasurementsActionsService: PerformanceMeasurementsActionsService,
                private acTableActionsService: AcTableActionsService,
                private store: Store) {
    }

    ngOnInit() {


        combineLatest([this.activatedRoute.params, this.activatedRoute.data])
            .pipe(first())
            .subscribe({
                next: ([params, data]) => {

                    this.deviceId = params.id;
                    this.storeKey = DeviceDetailsStatisticsComponent.FILTER_SESSION_KEY + this.deviceId;

                    this.wsEntitiesService.WSEntitiesUpdateFinished$.pipe(untilDestroyed(this)).subscribe((message: WSMessage) => {
                        if (message.messageType === 'Delete' && message.entitiesIds.includes(Number(this.deviceId))) {
                            this.dynamicTabDetailsService.openFailDialog(this.deviceId, 'device');
                        }
                    });


                    this.initializeDeviceDetailsPageData(data.device).then(() => {
                        this.store.select(AcTableState.selection(this.PM_STATISTICS_TABLE_ID_PREFIX + this.deviceId))
                            .pipe(untilDestroyed(this))
                            .subscribe((selection) => {
                                if (_.isEmpty(selection)) {
                                    this.inProgressSelectionFlag = false;
                                    this.itemsSelectedFlag = false;
                                    return;
                                }

                                const isAllItemsOnSameDay = !selection.some((selected) => selected.timeInterval !== selection[0].timeInterval);
                                if (!isAllItemsOnSameDay) {
                                    this.itemsSelectedFlag = true;
                                    this.inProgressSelectionFlag = true;
                                    this.displayErrorAndPreventSelection('Can not select parameters from different dates');
                                } else {
                                    this.dataReadyObject.fetchedDevice && this.fetchDataForSelectedItems(selection);
                                    this.selection = selection;
                                }
                            });
                    });
                }
            });
    }

    displayErrorAndPreventSelection = (failureMsg) => {
        const onClose = () => {

            if (!this.selection) {
                this.acTableActionsService.clearSelection(this.PM_STATISTICS_TABLE_ID_PREFIX + this.deviceId);
            } else {
                console.log('this.selection', this.selection);

                this.refresh$.next({
                    selection: this.selection
                });
            }
        };
        this.acDialogService.info(failureMsg, {title: 'Advanced Quality Package missing', onClose});
    };

    deviceStatisticsPMFilterEventChanged = (filter) => {
        if (this.currentFilter) {
            this.acTableActionsService.clearSelection(this.PM_STATISTICS_TABLE_ID_PREFIX + this.deviceId);
            this.acTableActionsService.setCollapseGroupsState(this.PM_STATISTICS_TABLE_ID_PREFIX + this.deviceId, {});
        }
        this.currentFilter = filter;
        this.inProgressFilterFlag = true;
        // this.inProgressSelectionFlag = true;

        this.existingFilters = {};
        this.hasFilter = false;

        if (filter && !_.isEmpty(filter.timeRange) && !_.isEmpty(filter.deviceSingularPMs) && this.deviceDetails?.tenantId > 0) {
            this.hasFilter = true;

            this.deviceDetailsDataFetcherService.fetchNetworkPMGridDataForDevice(filter, this.storeKey, this.deviceDetails?.tenantId, (dataObject) => {
                setTimeout(() => {
                    this.deviceStatisticsGridData = dataObject || {};
                    this.inProgressFilterFlag = false;
                });
            }, (response: RestResponseFailure) => {
                if (response.status === 413) {
                    this.acDialogService.fail(response.error.description + '.<br/>The full data can only be saved as an XML file.', {
                        submitButtonText: 'Save Device PM Data',
                        elementsColorPalette: {submitButtonColor: 'blue'},
                        onSubmit: () => {
                            this.performanceMeasurementsActionsService.exportDevicePMData(this.currentFilter, this.deviceDetails);
                        }
                    });
                }
            });
        } else {
            this.inProgressFilterFlag = false;
            this.inProgressSelectionFlag = false;
            this.itemsSelectedFlag = false;
            this.existingFilters.timeRange = (!filter || !_.isEmpty(filter.timeRange));
            this.existingFilters.parameters = (!filter || !_.isEmpty(filter.deviceSingularPMs));
        }
    };

    toggleGroupsCollapseState = () => {
        this.acTableActionsService.setAllCollapsedGroups(this.PM_STATISTICS_TABLE_ID_PREFIX + this.deviceId, !this.isGroupCollapsed);
    };

    initializeDeviceDetailsPageData = async (device) => {
        if (!device) {
            return;
        }
        this.deviceDetails = _.extend(this.deviceDetails, device);
        this.pageDetails = {
            tooltip: this.buildDeviceInfoFor('tooltip'),
            text: this.buildDeviceInfoFor('text')
        };
        await this.initializeDictionaries();
        await this.initializeProfileParameters();

        this.dataReadyObject.fetchedDevice = device;
    };

    initializeDictionaries = () => {
        return this.deviceDetailsDataFetcherService.fetchPMDictionariesForDevice((dataObject) => {
            this.dataReadyObject.fetchedDictionaries = this.notEmptyArray(dataObject) ? dataObject[0] : {dictionaryTopics: []};
            this.devicePMParametersMap = this.createParametersMap(this.dataReadyObject.fetchedDictionaries);
            this.devicePMDictionariesMap = this.createDictionaryMap(this.dataReadyObject.fetchedDictionaries);
        });
    };

    createDictionaryMap = (dictionaryArray) => {
        const dictionaryMap = {};

        dictionaryArray.dictionaryTopics.forEach((item) => {
            dictionaryMap[item.id] = item;
        });

        return dictionaryMap;
    };

    createParametersMap = (dictionaryArray) => {
        const parametersMap = {};

        dictionaryArray.dictionaryTopics.forEach((dictionaryItem) => {
            dictionaryItem.parameters.forEach((parameter) => {
                parametersMap[parameter.id] = parameter;
            });
        });

        return parametersMap;
    };

    initializeProfileParameters = () => {
        const profileId = this.getDeviceProfileId();

        return this.deviceDetailsDataFetcherService.getDeviceProfile(profileId, (dataObject) => {
            if (!_.isUndefined(dataObject) && dataObject !== null) {
                this.dataReadyObject.fetchedProfile = dataObject;
            } else {
                this.dataReadyObject.fetchedProfile = {};
            }
        });
    };

    buildDeviceInfoFor = (mode) => {
        const pmInfo = this.deviceDetails.sbcInfo && this.deviceDetails.sbcInfo.pmInfo;
        const lastSampledTime = this.dateTimeFormatterPipe.transform(pmInfo.lastSuccessfulPollingInterval) || 'Never Sampled';
        const lastPollingStatus = this.pmProfilesService.getPMStatus(this.deviceDetails);

        if (mode === 'tooltip') {
            return [
                'Profile: ' + pmInfo.profileName,
                'Status: ' + lastPollingStatus,
                'Last Sampled at: ' + lastSampledTime,
                'Number of metrics in last sample: ' + (pmInfo.lastSuccessfulPollingParams || 0)
            ].join('\n');
        }

        return {
            profileName: pmInfo.profileName,
            lastPollingStatus,
            lastSampledTime,
            lastSuccessfulPollingParams: (pmInfo.lastSuccessfulPollingParams || 0)
        };
    };

    getDeviceProfileId = () => this.deviceDetails &&
        this.deviceDetails.sbcInfo &&
        this.deviceDetails.sbcInfo.pmInfo &&
        this.deviceDetails.sbcInfo.pmInfo.profileId;


    fetchDataForSelectedItems = (selection) => {
        const parameters = this.buildParameterObjectFromSelection(selection);

        this.itemsSelectedFlag = true;
        this.inProgressSelectionFlag = true;
        this.deviceDetailsDataFetcherService.fetchNetworkDataCharts(parameters, (dataObject) => {
            setTimeout(() => {
                this.deviceStatisticsChartData = dataObject;
                this.inProgressSelectionFlag = false;
            });
        });
    };

    buildParameterObjectFromSelection = (selection) => {
        const defaultDate = {timeRange: {from: selection[0].from, to: selection[0].to}};
        const parameters = {
            pairs: [],
            interval: '15_minutes',
            pmDataType: 'Singular',
            deviceId: this.dataReadyObject.fetchedDevice.id
        };

        selection.forEach((item) => {
            parameters.pairs.push({
                paramId: item.paramId,
                index: item.paramIdx
            });
        });

        this.networkFilterFormatterService.addTimeAndDateFilter(defaultDate, parameters, 'timestamp');

        return parameters;
    };
    notEmptyArray = (testArray) => Array.isArray(testArray) && testArray.length > 0;

    updateGroupCollapsedState(isAllCollapsed) {
        this.isGroupCollapsed = isAllCollapsed;
    }
}
