<div class="ac-password-container">
    <input #passFieldInput matInput
           (blur)="onBlur()"

           (focus)="onFocus()"

           (ngModelChange)="modelChanged($event)"
           [disabled]="ngDisabled | acFunc : validatorsService.isFieldDisabled : acInputContainerComponent?.isDisabled : acFormComponent?.isDisabled"

           [id]="passwordId ? passwordId : null"

           [attr.placeholder]="placeHolderText"
           [maxlength]="maxLength"

           [ngModel]="acModel"
           [attr.autocomplete]="autocomplete"
           class="ac-password"
           [type]="showEye && showContent ? 'text' : 'password'"
    />
    <i *ngIf="showEye && !ngDisabled" (click)="showContent = !showContent; $event.stopPropagation()" class="eyeIcon fa fa-eye{{showContent ? '' : '-slash'}}"></i>
</div>
