import {Component, ElementRef, EventEmitter, Input, Optional, Output, SkipSelf, TemplateRef, ViewChild} from '@angular/core';

import {cloneDeep, forOwn, isEmpty, isEqual, isNil} from 'lodash';
import * as AjvI18n from 'ajv-i18n';
import {localize_en} from './ajv-errors/schema-errors-en';
import addFormats from 'ajv-formats';

import {Observable, Subject, throttleTime} from 'rxjs';

import {SchemaHelperService} from '../../services/schema-helper.service';
import {GeneralService} from '../../services/general.service';
import {AcDialogComponent} from '../ac-dialog/ac-dialog.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcTrackerService} from '../../services/utilities/ac-tracker.service';
import {StringUtils} from '../../utils/string-utils';
import {debounceTime} from 'rxjs/operators';
import {AcLayoutPanelComponent} from "../ac-layout/ac-layout-panel/ac-layout-panel.component";

@UntilDestroy()
@Component({
    selector: 'ac-form',
    templateUrl: './ac-form.component.html',
    styleUrls: ['./ac-form.component.less'],
})
export class AcFormComponent {
    /**
     * @deprecated The method should not be used
     */
    @Input() isEditMode = true;// for arm only we change our isEditMode to isEdit to align naming with the rest of the dialogs


    @Input() formName = '';
    @Input() formModelName = '';
    @Input() formSchema: any = {};
    @Input() formSettings: any = {touched: false, valid: false};
    @Input() submitButtonValue = '';
    @Input() formValidator: any = {};
    @Input() isEdit;
    @Input() validateForm$: Observable<any>; // run manually the validation
    @Input() forceFooterLayout = false;
    @Input() isViewMode = false;
    @Input() ignoreViewMode = false;
    @Input() disableOkButton = false;
    @Input() borderAppearance = false;
    @Input() validateAuxiliaryForm: Function;
    @Input() requiredsAuxiliaryForm: any;
    @Input() formModel: any;
    @Input() allowNullProperties: any = ['latitude', 'longitude'];
    @Input() deleteEmptyStringProperties: any[] = [];
    @Input() ignoreRequiredPaths: any[] = [];
    @Input() auxiliaryModel: any;
    @Input() checkIfDirty = false;
    @Input() performForcedValidation$: Observable<any>;
    @Input() additionalFormActionsTemplate: TemplateRef<any> = undefined;
    @Output() submitForm: EventEmitter<any> = new EventEmitter();

    @Input() hideSubmitButton = false;
    @Input() set canSubmit(canSubmit: boolean) {// canSubmit hide buttons and disable all forms inputs while hideSubmitButton only hide the submit button
        this._canSubmit = canSubmit;
        this.canSubmitFunc();
        this.handleFooterTemplate();
    }

    @ViewChild('formElement', {static: true}) formElementObj: ElementRef;
    @ViewChild('acFormButtons', {static: true}) acFormButtons: TemplateRef<any>;
    @Output() onFormValidationFinished = new EventEmitter<any>();

    formValidationFinished$ = new Subject<void | number>();
    formValidationStarting$ = new Subject<void>();

    isDisabled;
    isDirty = false;
    firstIteration = true;
    originalModelAfterFirstIteration: any;
    previousFormModel: any;
    isDialog: boolean;
    previousAuxiliaryModel: any;
    requiredFieldsPath: any = [];
    debounceTimer: any;
    _canSubmit: boolean;
    formModelForValidation: any;

    cycle = 0;
    private touchInit = false;
    private originalModel: any;
    private ajv: any;
    private compiledAjv: any;
    private systemLang: any;
    private localize: any;
    private overrideOriginalLocalize: any;
    private acLocalize: any;

    constructor(private schemaHelperService: SchemaHelperService,
                private acTrackerService: AcTrackerService,
                public generalService: GeneralService,
                @SkipSelf() @Optional() public parentAcFormComponent: AcFormComponent,
                @Optional() public acLayoutPanelComponent: AcLayoutPanelComponent,
                @Optional() private acDialogComponent: AcDialogComponent) {
        this.generalService.systemLanguage$.pipe(untilDestroyed(this)).subscribe((lang) => {
            this.systemLang = lang;
            this.handleValidation();
        });
    }

    scopeDestroyForBroadcasts = () => {
    };

    ngOnDestroy() {
        this.scopeDestroyForBroadcasts();
    }

    ngAfterViewInit(){
       this.handleFooterTemplate();
    }

    ngOnInit() {
        this.acTrackerService.trackEvent('FormOpen', this.getTrackingName('opened'));
        if (!this.ignoreViewMode && this.generalService.isViewMode) {
            this.isViewMode = true;
        }

        if (this.acDialogComponent) {
            this.acDialogComponent.addForm(this);
        }

        this.isEdit = this.isEdit !== undefined ? this.isEdit : this.isEditMode;

        this.localize = AjvI18n;
        this.overrideOriginalLocalize = {
            en: localize_en
        };
        this.acLocalize = this.generalService.customLocalize;
        this.canSubmitFunc();

        if (!this.compiledAjv) {
            this.ajv = this.schemaHelperService.ajvInstance;
            addFormats(this.ajv);
            this.formSchema && this.compileNewSchema(this.formSchema);
        }

        this.updateOriginalModelAndRunInitValidation();

        if (this.performForcedValidation$) {
            this.performForcedValidation$.pipe(untilDestroyed(this)).subscribe(() => {
                this.formSettings.touched = true;
                this.handleValidation();
            });
        }

        this.validateForm$ && this.validateForm$.pipe(untilDestroyed(this)).subscribe((skipDebounce) => {
            this.handleValidation(skipDebounce);
        });

        this.formValidationFinished$.pipe(untilDestroyed(this)).subscribe(() => {
            this.onFormValidationFinished.emit();
        });
    }

    private propagateTouchedToParentForms = (form, parentForm) => {
        form.formSettings.touched = parentForm.formSettings.touched;
        if (parentForm?.parentAcFormComponent) {
            this.propagateTouchedToParentForms(parentForm, parentForm?.parentAcFormComponent);
        }
    }

    doCheckSubject = new Subject();
    doCheckSub = this.doCheckSubject.pipe(throttleTime(100)).subscribe(() => this.doCheck());

    ngDoCheck() {
        this.doCheckSubject.next(null);
    }

    doCheck = () => {
        if (this.parentAcFormComponent && this.parentAcFormComponent.formSettings.touched && !this.touchInit) {
            this.touchInit = true;
            this.propagateTouchedToParentForms(this, this.parentAcFormComponent);
        }

        if (!this.formSchema || (this.formSchema && Object.getOwnPropertyNames(this.formSchema).length === 0 && !this.validateAuxiliaryForm && !this.requiredsAuxiliaryForm)) {
            return;
        }

        const formModelChanged = !isEqual(this.formModel, this.previousFormModel);
        const auxModelChanged = !isEqual(this.auxiliaryModel, this.previousAuxiliaryModel);
        this.isDirty = this.checkIfDirty && !isEqual(this.originalModelAfterFirstIteration, this.formModel);
        if (formModelChanged || auxModelChanged) {
            if (formModelChanged) {
                this.previousFormModel = cloneDeep(this.formModel);
            }
            if (auxModelChanged) {
                this.previousAuxiliaryModel = cloneDeep(this.auxiliaryModel);
            }

            this.handleValidation();
        }
    }

    handleFooterTemplate = () => {
        if(this.acLayoutPanelComponent && (this._canSubmit || this.forceFooterLayout)){
            this.acLayoutPanelComponent.footerActionsTemplate = this.acFormButtons;
        }else{
            delete this.acLayoutPanelComponent?.footerActionsTemplate;
        }
    }

    compileNewSchema = (schema) => {
        this.formSchema = schema;
        this.compiledAjv = this.ajv.compile(this.formSchema);
    };

    canSubmitFunc = () => {
        this.isDisabled = this._canSubmit === undefined ? false : (!this._canSubmit && !this.forceFooterLayout);
        this.isDialog = !!this.acDialogComponent;
    };

    getTrackingName = (type) => {
        return 'Form: "' + (this.formName || this.formModelName) + '" ' + type + ' for: ' + (this.isEdit ? 'Edit' : 'Add');
    };

    onSubmit = () => {
        this.formSettings.touched = true;

        this.handleValidation(true);

        if (this.formSettings.valid) {
            this.acTrackerService.trackEvent('FormSubmitted', this.getTrackingName('submitted'));
            this.submitForm.emit();
        }
    };

    handleValidation = (skipDebounce = undefined) => {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        if (skipDebounce) {
            this.doValidation();
        } else {
            this.debounceTimer = setTimeout(() => {
                this.doValidation();
            }, 100);
        }
    };

    doValidation = () => {
        this.setRequiredFields();
        this.deleteRequiredFieldsFromModel();
        this.deleteEmptyProperties(this.formModel);
        this.formModelForValidation = cloneDeep(this.formModel);

        this.formSettings.valid = this.handleFormErrors();
    };

    deleteRequiredFieldsFromModel = () => {
        if (this.formModel && Object.keys(this.formModel).length > 0) {
            this.requiredFieldsPath.forEach((field) => {
                const namePath = field.name.replace(this.formModelName + '.', '');
                this.deletePropertyPath(this.formModel, namePath, true);
            });
        }
    };

    deleteEmptyProperties = (parentObj, prefix = '') => {
        if (parentObj?.isLuxonDateTime) {
            return;
        }

        parentObj && forOwn(parentObj, (obj, key) => {
            if (!isNil(obj) && (typeof obj) === 'object') {
                if (Array.isArray(parentObj)) {
                    key = '*';
                }
                this.deleteEmptyProperties(obj, prefix + key + '.');
            } else if (obj === null && !this.allowNullProperties.includes(prefix + key)) {// || parentObj[property] === "" needs to be removed if want to return minLength to req
                delete parentObj[key];
            } else if (obj === '' && this.deleteEmptyStringProperties.includes(prefix + key)) {
                delete parentObj[key];
            }
        });
    };

    setRequiredFields = () => {
        this.requiredFieldsPath = [];
        if (!this.compiledAjv || !this.formModel) {
            return this.requiredFieldsPath;
        }

        forOwn(this.formValidator, (item) => {
            item.isRequired = false;
        });

        let tempObjectToValidate;
        if (Object.keys(this.formModel).length === 0) {
            tempObjectToValidate = this.originalModel;
        } else {
            tempObjectToValidate = this.formModel;
        }

        const modelToCheck = cloneDeep(tempObjectToValidate);
        this.checkValidation(modelToCheck);// check model before deletion
        const allFlattenedFields = this.flattenObject(modelToCheck);

        if (allFlattenedFields && allFlattenedFields.length > 0) {
            forOwn(allFlattenedFields, (requiredPath) => {
                const modelWithDeletedRequiredProperty = cloneDeep(tempObjectToValidate);

                this.deletePropertyPath(modelWithDeletedRequiredProperty, requiredPath.replace('.', ''));
                this.checkValidation(modelWithDeletedRequiredProperty);

            });
        }

        if (this.requiredsAuxiliaryForm) {
            let allAuxRequiredFields = this.checkIfRequiredIsFuncOrArray();
            allAuxRequiredFields = allAuxRequiredFields ? allAuxRequiredFields : [];

            forOwn(allAuxRequiredFields, (requiredInputName) => {
                this.addToRequiredFields(requiredInputName, true);
            });
        }
    };

    checkIfRequiredIsFuncOrArray = () => {
        if (this.requiredsAuxiliaryForm) {
            return Array.isArray(this.requiredsAuxiliaryForm) ? this.requiredsAuxiliaryForm : this.requiredsAuxiliaryForm();
        }

        return [];
    };

    checkValidation = (modelWithDeletedRequiredProperty) => {
        if (!this.compiledAjv(modelWithDeletedRequiredProperty)) {

            this.compiledAjv.errors = this.compiledAjv.errors !== null ? this.compiledAjv.errors : [];
            const errorsObj = this.getErrorObjectsArray(this.compiledAjv.errors);

            forOwn(errorsObj, (errors: any[]) => {
                forOwn(errors, (tError, key) => {
                    if (tError.keyword === 'required' && !tError.isArray) {// || (tError.keyword === "minLength" && tError.params && tError.params.limit === 1)){
                        this.addToRequiredFields(tError.inputName);
                    }
                });
            });
        }
    };

    addToRequiredFields = (inputName, isRequiredAux = false) => {
        this.formValidator[inputName] = this.formValidator[inputName] || {};
        this.formValidator[inputName].isRequired = true;

        const obj = {name: inputName, type: this.formValidator[inputName].type, isRequiredAux};
        if (!this.requiredFieldsPath.some(item => isEqual(item, obj))) {
            this.requiredFieldsPath.push(obj);
        }
    };

    flattenObject = (obj, parentsPath = undefined) => {
        let toReturn = [];

        forOwn(obj, (value, key) => {
            if (this.ignoreRequiredPaths.length > 0) {
                const noNums = ((parentsPath || '') + '.' + key).replace(/(?<=\.)\d+(?=\.)/g, '$'); // replace numbers between two dots
                if (this.ignoreRequiredPaths.some((path) => (noNums.startsWith(path)))) {
                    return;
                }
            }
            if ((value || value === '' || value === 0) && (value.isLuxonDateTime === false || isNil(value.isLuxonDateTime))) {
                if ((typeof value) === 'object') {
                    toReturn = toReturn.concat(this.flattenObject(value, (parentsPath || '') + '.' + key));
                }
                toReturn.push((parentsPath || '') + '.' + key);
            }
        });

        return toReturn;
    };

    handleFormErrors = () => {
        if (!this.compiledAjv) {
            return false;
        }

        this.formValidationStarting$.next();

        forOwn(this.formValidator, (field, key) => {
            field.isChanged = false;
            const modelPath = key.split('.').splice(1).join('.');

            const originalModelValue = StringUtils.byString(this.originalModel, modelPath);
            const currentModelValue = StringUtils.byString(this.formModel, modelPath);

            // change - conditions
            const equals = isNil(originalModelValue) && isNil(currentModelValue) ? true : isEqual(originalModelValue, currentModelValue);

            const isEmptyObjects = ((originalModelValue === undefined && (currentModelValue === '' || currentModelValue === -1)) ||
                (currentModelValue === undefined && (originalModelValue === '' || originalModelValue === -1)));

            if (this.isEdit) {
                field.isChanged = !equals && !isEmptyObjects;
            }

            field.isValid = true;
            field.errors = {};
        });

        this.compiledAjv(this.formModel); // JUST FOR CLEANING additionalProperties FROM THE ORIGINAL MODEL
        this.compiledAjv(this.formModelForValidation);

        if (this.firstIteration) {
            this.originalModelAfterFirstIteration = cloneDeep(this.formModel);
        }

        this.compiledAjv.errors = this.compiledAjv.errors !== null ? this.compiledAjv.errors : [];
        if (this.validateAuxiliaryForm) {
            this.validateAuxiliaryForm(this.compiledAjv.errors, this.auxiliaryModel, this.formModelForValidation);
        }

        this.generateRequiredErrors(this.compiledAjv.errors, this.auxiliaryModel, this.formModelForValidation);

        if (this.localize[this.systemLang]) {
            this.localize[this.systemLang](this.compiledAjv.errors);
        }
        if (this.overrideOriginalLocalize[this.systemLang]) {// ajv overrides messages
            this.overrideOriginalLocalize[this.systemLang](this.compiledAjv.errors);
        }
        if (this.acLocalize && this.acLocalize[this.systemLang]) {// ovoc\arm\voca messages
            this.acLocalize[this.systemLang](this.compiledAjv.errors);
        }

        const validatorErrors = this.getErrorObjectsArray(this.compiledAjv.errors);
        if (this.generalService.debugMode) {
            console.log({model: this.formModel, errors: validatorErrors});
        }
        forOwn(validatorErrors, (errors: any) => {
            forOwn(errors, (tError, key) => {
                this.formValidator[tError.inputName] = this.formValidator[tError.inputName] || {};
                this.formValidator[tError.inputName].isValid = false;

                if (!this.formValidator[tError.inputName].errors) {
                    this.formValidator[tError.inputName].errors = {};
                }

                if (tError.isArray) {
                    forOwn(errors, (errorElement: any) => {
                        this.formValidator[tError.inputName + '.' + errorElement.index] = {errors: {message: errorElement.messageAux && errorElement.messageAux !== '' ? errorElement.messageAux : errorElement.message}};
                    });

                    this.formValidator[tError.inputName].errors.message = 'Invalid values';
                } else {
                    if (!this.formValidator[tError.inputName].errors.message) {
                        this.formValidator[tError.inputName].errors.message = tError.messageAux && tError.messageAux !== '' ? tError.messageAux : tError.message;

                    }
                }
            });
        });

        this.firstIteration = false;

        if (this.parentAcFormComponent) {
            this.formValidationFinished$.next();
            this.propagateValidationFinishedToParentForm(this.parentAcFormComponent);
        } else {
            this.formValidationFinished$.next(++this.cycle);
        }

        return Object.keys(validatorErrors).length === 0;
    };

    propagateValidationFinishedToParentForm = (parentForm) => {
        if (parentForm.parentAcFormComponent) {
            parentForm.formValidationFinished$.next();
            this.propagateValidationFinishedToParentForm(parentForm.parentAcFormComponent);
        } else {
            parentForm?.formValidationFinished$.next(++this.cycle);
            return;
        }
    }

    deletePropertyPath = (obj, path, deleteOnlyIfEmptyString = false) => {
        if (!obj || !path) {
            return;
        }

        path = typeof path === 'string' ? path.split('.') : path;

        for (let i = 0; i < path.length - 1; i++) {
            obj = obj[path[i]];

            if (typeof obj === 'undefined') {
                return;
            }
        }

        const property = path.pop();
        if (deleteOnlyIfEmptyString) {
            if (obj[property] === '') {
                delete obj[property];
            }
            return;
        }

        delete obj[property];
    };

    generateRequiredErrors = (errors, auxiliaryModel, formModel) => {
        if (this.requiredFieldsPath && this.requiredFieldsPath.length > 0) {
            this.requiredFieldsPath.forEach((field) => {
                if (field.isRequiredAux) {
                    const fieldName = field.name.startsWith(this.formModelName) ? field.name.replace(this.formModelName, 'formModel') :
                        field.name.replace(field.name.substr(0, field.name.indexOf('.')), 'auxiliaryModel');
                    const instancePath = fieldName.replace('formModel', '').replace('auxiliaryModel.', '').split('.').join('/');
                    const property = StringUtils.byString({auxiliaryModel, formModel}, fieldName);

                    if (!property || property === '' || parseInt(property, 10) === -1 || (Array.isArray(property) && property.length === 0)) {
                        errors.push(SchemaHelperService.buildErrorItem({
                            inputName: field.name,
                            keyword: 'requiredAux'
                        }));
                    }
                }
            });
        }
    };

    getErrorObjectsArray = (errors) => {
        const errorsArray = {};

        forOwn(errors, (error) => {
            if (['not', 'oneOf', 'anyOf', 'allOf'].includes(error.keyword)) {
                return;
            }

            const errorDataPath = (error.instancePath).split('/');
            const path = (error.instancePath) + (error.keyword === 'required' ? '/' + error.params.missingProperty : '');

            const keyPath = this.isNumeric(errorDataPath[errorDataPath.length - 1]) ? errorDataPath[1] : path.replace('/', '');

            const errorObj = this.createErrorObj(path, error);
            errorsArray[keyPath] = errorsArray[keyPath] || [];
            errorsArray[keyPath].push(errorObj);
        });

        return errorsArray;
    };

    isNumeric = (num) => !isEmpty(num) && !isNaN(num);

    createErrorObj = (path, error) => {
        const errorDataPath = path.split('/');
        const errorObj: any = cloneDeep(error);
        errorObj.isArray = this.isNumeric(errorDataPath[errorDataPath.length - 1]);
        errorObj.inputName = errorObj.inputName ? errorObj.inputName : this.getInputName(errorObj);
        errorObj.index = parseInt(errorDataPath[errorDataPath.length - 1], 10);

        return errorObj;
    };

    getInputName = (errorObj) => {
        const inputName = this.formModelName + (errorObj.instancePath).ReplaceAll('/', '.') + (errorObj.params && errorObj.params.missingProperty ? '.' + errorObj.params.missingProperty : '');
        const matches = inputName.match(/\.\d+$/g);// regex checks if string ends with . + number
        const arrayInputName = matches ? inputName.replace(matches[0], '') : undefined;

        return arrayInputName || inputName;
    };

    updateOriginalModelAndRunInitValidation = () => {
        if (this.formSchema) {
            this.originalModel = cloneDeep(this.formModel);

            if (this.generalService.doInitValidationsForAcForm) {
                this.handleValidation(true);
            }
        }
    };
    protected readonly undefined = undefined;
}



