import {Injectable} from '@angular/core';

import {ExternalApplicationsRestService} from '../configuration-api/external-applications-rest.service';
import {GeneralService, RestServerUrlService} from 'ac-infra';

import * as _ from 'lodash';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {ServerInfoRestService} from '../../administration/administration-api/server-info-rest.service';
import {ExternalApplication} from '../../../common/state/external-application.actions';
import {Store} from '@ngxs/store';
import {Actions} from '../../../common/server-actions/actions';
import {GroupsActions} from '../../../network/services/actions/groups-actions.service';
import {AuthGroup} from '../../../common/utilities/session-helper.service';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {ServerInfoService} from "../../../common/services/server-info.service";
import {ServiceActionsMenuService} from "../../../network/services/service-actions-menu.service";

@Injectable({providedIn: 'root'})
export class ExternalApplicationsActionsService extends Actions {

    static get EXTERNAL_APPLICATION_OBJ(): any {
        return {
            ippmanager: {
                webUrl: ExternalApplicationsActionsService.getDeviceManagerURL(),
                discoverMoreUrl: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/device-manager'
            },
            arm: {
                configUrl: '/system/configuration/externalApplications/armApplication',
                disabledLink: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/audiocodes-routing-manager',
                discoverMoreUrl: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/audiocodes-routing-manager'
            },
            masterscope: {
                configUrl: '/system/configuration/externalApplications/masterScopeApplication',
                discoverMoreUrl: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/ovoc-masterscope-voice-quality-data-layer-monitoring'
            },
            zoompeering: {
                configUrl: '/system/configuration/externalApplications/zoomApplication',
            }
        };
    }

    public static readonly STATUS_MAP = {
        CONFIGURATION_MISMATCH: 'Conf Mismatch',
        NOT_CONNECTED: 'Not Connected',
        INDETERMINATE: 'Indeterminate',
        UNAUTHORIZED: 'Unauthorized',
        UNMANAGED: 'Unmanaged',
        CRITICAL: 'Critical',
        WARNING: 'Warning',
        MANAGED: 'Managed',
        MAJOR: 'Major',
        MINOR: 'Minor',
        CLEARED: 'Clear'
    };

    constructor(private externalApplicationsRestService: ExternalApplicationsRestService,
                private serverInfoRestService: ServerInfoRestService,
                private serviceActionsMenuService: ServiceActionsMenuService,
                public groupsActions: GroupsActions,
                public generalService: GeneralService,
                private store: Store,
                private authorizationService: AuthorizationService) {
        super({});
    }

    init = () => {
        if (!this.authorizationService.validationAccordingToMode(
            {ovoc: AuthGroup.TENANT_MONITORING_LINKS, oneLive: [AuthGroup.channel_operator, AuthGroup.customer_operator]})) {
            this.store.dispatch(new ExternalApplication.FetchService());
        }
    };

    public getApps = (apps) =>{
        if(this.authorizationService.securityLevel?.startsWith('TENANT')){
            if(ServerInfoService.oneLiveMode){
                return [];
            }else{
                return apps.filter((app) => app.id === 'IPPMANAGER');
            }
        }

        return apps;
    };

    public editArmIp = (armConnectionData, callback) => {
        const msg = 'ARM server configuration updated successfully';
        this.externalApplicationsRestService.editArmIp(armConnectionData, this.onGenericSuccess({
            msg,
            callback
        }), this.onGenericFailure);
    };

    public editZoomConfiguration = (zoomConfiguration, callback) => {
        const msg = 'Zoom server configuration updated successfully';
        this.externalApplicationsRestService.editZoomConfiguration(zoomConfiguration, this.onGenericSuccess({
            msg,
            callback
        }), this.onGenericFailure);
    };

    public editMasterScopeConfiguration = (masterScopeData) => {
        const msg = 'Data Layer Manager server configuration updated successfully';
        this.externalApplicationsRestService.editMasterScopeConfiguration(masterScopeData, this.onGenericSuccess({msg}), this.onGenericFailure);
    };

    getSortedExternalApplications(externalApplications: any[]) {
        const sortedExternalApplications = [];

        _.forOwn(ExternalApplicationsActionsService.EXTERNAL_APPLICATION_OBJ, (appProperties, appName) => {
            const externalApplication = externalApplications.find((app) => app && app.id.toLowerCase() === appName);
            externalApplication && sortedExternalApplications.push(externalApplication);
        });
        return sortedExternalApplications;
    }

    extendExternalApplications(externalApplications: any[]) {
        externalApplications.forEach(externalApplication => {
            const appName = externalApplication.id.toLowerCase();
            const applicationProps = ExternalApplicationsActionsService.EXTERNAL_APPLICATION_OBJ[appName];

            Object.assign(externalApplication, applicationProps);
        });
    }

    setStatusExternalApplications(externalApplications: any[]) {
        externalApplications.forEach(externalApplication => {
            externalApplication.status = ExternalApplicationsActionsService.STATUS_MAP[externalApplication.status] || 'Unmanaged';
        });
    }

    public openApplicationURL = (data) => {
        if (!data.webUrl || this.isNotConnected(data)) {
            return this.acDialogService.info(data.name + ' is not connected');
        }

        if (data.disabledLink && this.isUnManaged(data)) {
            return this.openNewTab(data.disabledLink);
        }

        switch (data.id.toLowerCase()) {
            case 'arm'      :
                this.performLoginToARM(data.webUrl);
                break;
            case 'reports'  :
                this.checkTomcatBeforeOpen(null);
                break;
            case 'ippmanager'  :
                this.groupsActions.openGeneralDeviceManagerPage();
                break;
            case 'zoompeering'  :
                this.serviceActionsMenuService.chooseTenantAndPreformAction({pageName: 'openZoomQualifiedLeadsPage', type: 'ZOOM'});
                break;
            default         :
                this.openNewTab(data.webUrl);
        }
    };

    isNotConnected = (data) => data.status === ExternalApplicationsActionsService.STATUS_MAP.NOT_CONNECTED;

    isUnManaged = (data) => data.status === ExternalApplicationsActionsService.STATUS_MAP.UNMANAGED;

    isNotValid = (data) => this.isNotConnected(data) || this.isUnManaged(data);

    performLoginToARM(basicUrl) {
        const onSuccess = (response: RestResponseSuccess) => {
            this.openNewTab(basicUrl + '/ARM/armui/index.html#/authToken=' + response.data.authToken);
        };

        this.externalApplicationsRestService.getArmServerToken(onSuccess);
    }

    openNewTab(url) {
        const linkElement = window.document.createElement('a');

        linkElement.setAttribute('href', url);
        linkElement.setAttribute('target', '_blank');

        window.document.body.appendChild(linkElement);
        linkElement.click();

        window.document.body.removeChild(linkElement);
    }

    checkTomcatBeforeOpen(event) {
        if (event) {
            event.preventDefault();
        }

        const onSuccess = (response: RestResponseSuccess) => {
            if (response && response.data && response.data.processesStatus && response.data.processesStatus.tomcat === 'UP') {
                window.open('https://' + window.location.hostname + ':9400/sem/Main.html', '_blank');
            } else {
                this.acDialogService.fail('Server is down<br>Can not open reports page');
            }
        };

        this.acDialogService.info('Opening please wait');
        this.serverInfoRestService.getServerInfo(onSuccess);
    }

    static getDeviceManagerURL() {
        return RestServerUrlService.getHostURL() + '/ipp/sso.php';
    }
}
