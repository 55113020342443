import {Component, OnInit} from '@angular/core';
import {AcNavAutoService} from './ac-nav-auto.service';

@Component({
    selector: 'ac-nav-auto',
    templateUrl: './ac-nav-auto.component.html',
    styleUrls: ['./ac-nav-auto.component.less']
})
export class AcNavAutoComponent {


    constructor(public acNavAutoService: AcNavAutoService) {
    }

    getChildren(route: any) {
        return route?.routeConfig.children || route?.routeConfig._loadedConfig?.routes[0].children;
    }

    getTitle = (nav) => {
        if(typeof nav.data?.text === 'function'){
            return nav.data.text();
        }
        return nav.data?.text || nav.path;
    };
}
