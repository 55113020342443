import {Component, EventEmitter, HostBinding, Input, Optional, Output, ViewChild} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {GeneralService} from '../../services/general.service';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {ValidatorsService} from "../../services/utilities/validators.service";
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'ac-toggle',
    templateUrl: './ac-toggle.component.html',
    styleUrls: ['./ac-toggle.component.less'],
})
export class AcToggleComponent {
    @Input() toggleId = 'slideToggleId';
    @Input() acModel = false;
    @Output() acModelChange = new EventEmitter<boolean>();

    @Input() ngDisabled = false;
    @Input() text = '';
    @Input() allowPropagation = false;
    @ViewChild('matSlideToggle') matSlideToggle: MatSlideToggle;

    @Input() color: ThemePalette = 'primary';

    @HostBinding('class.extra-small') @Input() extraSmall = false;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public validatorsService: ValidatorsService,
                @Optional() public acFormComponent: AcFormComponent) {}

    ngOnInit() {
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    ngAfterViewInit(){
        this.matSlideToggle.writeValue(this.acModel);
    }

    onChange = (value: boolean) => {
        this.acModelChange.emit(value);
    };
}



