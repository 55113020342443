import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {AcListComponent} from './ac-list.component';
import {AcProgressModule} from '../ac-progress/ac-progress.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AcChartModule} from '../ac-chart/ac-chart.module';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        FormsModule,

        AcProgressModule,
        ScrollingModule,
        AcChartModule
    ],
    declarations: [
        AcListComponent,
    ],
    entryComponents: [
        AcListComponent,
    ],
    exports: [
        AcListComponent,
    ]
})
export class AcListModule {
}
