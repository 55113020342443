import {Component, Inject, SkipSelf} from '@angular/core';
import * as _ from 'lodash';
import {AcDialogComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@Component({
    selector: 'network-operator-connect-dialog',
    templateUrl: './network-operator-connect-dialog.component.html',
})
export class NetworkOperatorConnectDialogComponent {

    tenant: any = {};
    operatorConnectSchema = require('../../../../schema/put/tenantsprocessor.json');
    errors;

    constructor(@SkipSelf() private acDialogComponent: AcDialogComponent,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig)  {
    }

    ngOnInit() {
        const params = this.dialogConfig.dialogData;
        this.tenant = params.tenant;
    }

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        this.errors = errors;
    };
}





