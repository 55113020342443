import {Injectable, isDevMode} from '@angular/core';
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject';
import {AcDialogRef, AcDialogService, GeneralService, PromiseService, RestServerUrlService, statuses, WSMessage} from 'ac-infra';
import {WebSocketNotification} from './ws.notification.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {ExternalApplication} from '../../state/external-application.actions';
import {wsConnectionLostDialogComponent} from '../../../dialogs/ws-connection-lost-dialog/ws-connection-lost-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {SessionService} from '../session.service';
import {ExceptionService} from '../errors/exception.service';
import {WsEntitiesService} from '../communication/ws-entities.service';
import {ServerInfoService} from '../server-info.service';

@Injectable({providedIn: 'root'})
export class WebSocketService {
    pingInterval;
    pongArrived = true;
    retryTimeout;
    retryCounter = new BehaviorSubject<number>(1);
    wsConnectionLostDialogRef: AcDialogRef;
    readonly RETRIES = 20;
    readonly RETRY_INTERVAL = 5000;
    private socket$: WebSocketSubject<any>;

    private connectionVerified = false;
    private skipError: boolean;

    constructor(private sessionService: SessionService,
                private webSocketNotification: WebSocketNotification,
                private wsEntitiesService: WsEntitiesService,
                private restServerURLService: RestServerUrlService,
                private acDialogService: AcDialogService,
                private generalService: GeneralService,
                private exceptionService: ExceptionService,
                private router: Router,
                private store: Store) {
        // setTimeout(() => {
        //     this.parseMessage({
        //         "protocol": "v1",
        //         "messageType": "Notifications",
        //         "name": "Gateway Administrative State Changed",
        //         "description": "[Device 2] Administrative state is unlocked",
        //         "module": "Alarms",
        //         "status": "critical",
        //         "entityType": "Alarm",
        //         "entities": [{"entityId": 7855, "tenantId": 413}]
        //     });
        // }, 5000)
    }

    hasActiveSocket = () => !!this.socket$;

    openWsConnectionDialog() {
        if (this.wsConnectionLostDialogRef) {
            return;
        }
        this.wsConnectionLostDialogRef = this.acDialogService.open(wsConnectionLostDialogComponent, {
            dialogData: {
                retryCounter: this.retryCounter.asObservable(),
                retries: this.RETRIES,
            }
        });
    }

    closeWsConnectionDialog() {
        const dialogRef = this.wsConnectionLostDialogRef;
        this.wsConnectionLostDialogRef = null;

        if (dialogRef?.dialogConfig) {
            dialogRef.dialogConfig.onCancel = undefined;
            dialogRef.close();
        }
    }

    connect = (isReconect?) => {
        this.skipError = false;
        let sessionSent = false;
        const defer = PromiseService.defer();

        if (this.socket$) {
            defer.resolve(true);
            return defer.promise;

        }
        this.pongArrived = true;

        if (this.sessionService.activeSession) {
            const ovocVersion = ServerInfoService.serverInfo.ovocVersion;
            const shouldSendSessionIdInURL = !((this.isGt(ovocVersion, '8.2.1238') &&
                this.isGt('8.2.2000', ovocVersion)) || this.isGt(ovocVersion, '8.2.2074'));
            this.socket$ = new WebSocketSubject({
                url: this.restServerURLService.getWebSocketServerURL() + (shouldSendSessionIdInURL ? '?SessionID=' + this.sessionService.activeSession.sessionId : ''),
                openObserver: {next: this.onOpen},
                closeObserver: {next: this.onClose},
            });

            this.socket$.subscribe({
                next: (message) => {
                    // MESSAGES WHILE CONNECTED
                    if (!sessionSent) {
                        console.warn('Sending session', this.sessionService.activeSession.sessionId);
                        this.socket$.next('SessionID=' + this.sessionService.activeSession.sessionId);
                        sessionSent = true;
                    }

                    this.closeWsConnectionDialog();
                    this.retryCounter.next(1);
                    try {
                        this.parseMessage(message, defer);
                    } catch (ex) {
                        console.log('WS MESSAGE', isDevMode() ? message : '', ex);
                        this.exceptionService.checkError(ex);
                    }
                },
                error: (err) => {
                    // ERROR CONNECTING OR WHILE CONNECTED (server shut down abruptly)
                    console.log('ERROR FROM WS')
                    if (err?.reason === 'Invalid session') {
                        console.log('WS err2, logout');
                        this.loginErrorMessage('WS: Invalid session');
                        this.logout();
                    } else {
                        console.log('WS err3 Reconnecting...');
                        this.openWsConnectionDialog();
                        this.reconnect(); // 'CLOSED BY SERVER (token wrong/expired)'
                    }
                },
                complete: () => {
                    // CLOSED BY SERVER (token wrong/expired)
                    if (this.connectionVerified) {
                        console.log('WS Closed Reconnecting...');
                        this.openWsConnectionDialog();
                        this.reconnect(); // 'CLOSED BY SERVER (token wrong/expired)'

                    } else {
                        console.log('LOGOUT FROM WS')
                        this.loginErrorMessage('WS: Connection Closed');
                        this.logout();
                    }
                }
            });

        } else if (!isReconect) {
            defer.reject('connectionNotEstablished');
        }
        return defer.promise;
    };

    disconnect = (stopRetry = true, skipError = false) => {
        if (stopRetry) {
            this.connectionVerified = false;
            clearTimeout(this.retryTimeout);
        }
        clearInterval(this.pingInterval);
        this.pongArrived = true;
        this.clearSocket(skipError);
    };

    onConnectionVerified = () => {
        this.connectionVerified = true;
        clearInterval(this.pingInterval);
        this.pingInterval = setInterval(this.doPing, 30000);
        this.doPing();
    };

    onOpen = (val: any) => {
        console.log('WS CONNECTED, DOING PING')
        this.socket$.next('ping');
    };

    onClose = (val: any) => {
        console.log('WS CLOSED,', val)
        if (val.reason) {
            this.loginErrorMessage(val.reason);
            this.disconnect();
        }
    };

    // public send(message: Message): void {
    //     this.socket$.next(message);
    // }

    ngOnDestroy() {
        clearInterval(this.pingInterval);
    }

    private logout = () => {
        this.connectionVerified = false;
        this.sessionService.endSession();
        this.router.navigateByUrl('login');
    };

    private parseMessage = (_message: any, defer) => {
        if (_message === 'pong') {
            this.pongArrived = true;
            return;
        }
        const message: WSMessage = _.assign(new WSMessage(), _message);
        message.prepareMessage();

        if (message.messageType === statuses.Notifications) {
            this.webSocketNotification.WSNotificationArrived.next(message);
        } else if (message.entityTypeName === 'externalApplications') {
            this.store.dispatch(new ExternalApplication.FetchWS());
        } else {
            if (message.messageType === statuses.FullSync) {
                if (!this.connectionVerified) {
                    this.onConnectionVerified();
                }
                defer.resolve(true);
            }
            this.wsEntitiesService.wsMessageArrived(message);
        }
    };

    private reconnect = (err?: any, instant?) => {
        console.warn('WS RECONNECT', err);
        if (this.sessionService.activeSession) {
            this.disconnect(true);

            if (this.retryCounter.getValue() === this.RETRIES) {
                this.closeWsConnectionDialog();
                this.exceptionService.reportException({type: 'WSServerException', message: 'Connection to Server Lost'});
                return;
            }

            this.retryTimeout = setTimeout(() => {
                this.retryCounter.next(this.retryCounter.getValue() + 1);
                this.clearSocket();
                this.connect(true);
            }, this.retryCounter.getValue() === 1 || instant ? 0 : this.RETRY_INTERVAL);
        }
    };

    private clearSocket = (skipError = false) => {
        if (this.socket$) {
            this.skipError = skipError;
            this.socket$.unsubscribe();
            this.socket$ = undefined;
        }
    };

    private doPing = () => {
        if (this.pongArrived === false) {
            console.warn('WS, NO PONG FOR PING');
            this.disconnect(false);
            this.loginErrorMessage('WS: Pong did not arrive');
            this.logout();
            return;
        }
        this.pongArrived = false;
        this.socket$.next('ping');
    };

    private isGt(ver1, ver2) {
        if (!ver1 || !ver2) {
            return false;
        }

        const parts1 = ver1.split('.');
        parts1.length = 3;
        const parts2 = ver2.split('.');

        for (let i = 0; i < parts1.length; i++) {
            const p1 = (parseInt(parts1[i], 10) || 0);
            const p2 = (parseInt(parts2[i], 10) || 0);

            if (p1 < p2) {
                return false;
            } else if (p1 > p2) {
                return true;
            }
        }
    }

    private loginErrorMessage(closeReason: string) {
        if (!this.skipError) {
            this.generalService.loginErrorMessage = closeReason;
        }
    }
}
