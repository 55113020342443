import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'allocation',
    templateUrl: './allocation.component.html',
    styleUrls: ['./allocation.component.less'],
})

export class AllocationComponent {
    @Input() containerLabel = '';
    @Input() hideLabel = false;
    @Input() hideAllocTitle = false;
    @Input() allocationBarWithInput = false;
    @Input() showSpinner = true;
    @Input() inputModel: any;
    @Input() inputMinValue = 0;
    @Input() inputMaxValue: number;
    @Input() barTitle = '';
    @Input() barTotal: number;
    @Input() formFieldName = '';
    @Output() spinnerChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _barUsed: number;
    @Input() set barUsed(value: any) {
        this._barUsed = value || 0;
    }

    get barUsed(): number {
        return this._barUsed;
    }

    inputChanged(event) {
        this.spinnerChanged.emit(event);
    }


}



