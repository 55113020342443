import {Component, Inject} from '@angular/core';

import * as _ from 'lodash';

import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    cancelButtonText: 'Close',
    id: 'teams-device-dialog',
    title: 'Teams Details',
    width: 600
})
@Component({
    selector: 'teams-device-dialog',
    templateUrl: './teams-device-dialog.component.html'
})
export class TeamsDeviceDialogComponent {

    isEdit;
    teamsDevice: any;
    deviceAddSchema = require('../../../../schema/post/devicesprocessor.json');
    deviceEditSchema = require('../../../../schema/put/devicesprocessor.json');
    additionalSettings: any;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.teamsDevice = this.dialogConfig.dialogData.entity;
        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.additionalSettings = _.cloneDeep(this.teamsDevice);
        this.additionalSettings.tenantId = undefined;
    }
}
