<ac-input-container label="Suspension">
    <ac-single-select [items]="suspendedOptions"
                      [(acModel)]="additionalSettings.suspendedState"
                      (acModelChange)="onSuspendedStateChange($event)"
    ></ac-single-select>
</ac-input-container>


<ng-container *ngIf="additionalSettings.suspendedState === 'suspended'">

    <ac-input-container formFieldName="operator.suspendReason" label="Suspension Reason">
        <ac-textfield [(acModel)]="operator.suspendReason"
                      textFieldId="operator-form-suspension-reason">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container *ngIf="isEdit && suspendSince" label="Suspended Since">
        <div>{{suspendSince}}</div>
    </ac-input-container>
</ng-container>

<ac-input-container *ngIf="additionalSettings.suspendedState === 'suspendedDate'"
                    class="operator-suspend-date"
                    label="Choose suspension date"
                    formFieldName="additionalSettings.suspendedDate">
    <ac-date-picker [hourOnly]="true"
                    [minDate]="minSuspendedDate"
                    [date]="additionalSettings.suspendedDate"
                    (dateChange)="onSuspendDateChange($event, true)"
    ></ac-date-picker>
</ac-input-container>
