import {Component, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {Observable} from 'rxjs';
import {MatExpansionPanel} from '@angular/material/expansion';


@Component({
    selector: 'ac-expansion-panel',
    templateUrl: './ac-expansion-panel.component.html',
    styleUrls: ['./ac-expansion-panel.component.less'],
})
export class AcExpansionPanelComponent {

    @Input() expanded: boolean;
    @Input() authorized = true;
    @Input() classes: string;
    @Input() header: string;
    @Input() disabled = false;
    @Input() description: string;
    @Input() descriptionObject: any;
    @Input() descriptionTemplate: TemplateRef<any>;
    @Input() headerObject: any;
    @Input() headerTemplate: TemplateRef<any>;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() opened: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatExpansionPanel) matExpansionPanel: MatExpansionPanel;

}



