import {Injectable} from '@angular/core';
import {SoftwareManagerRestService} from '../../../system/configuration/configuration-api/software-manager-rest.service';
import {PromiseService} from 'ac-infra';
import {RestResponseSuccess} from '../../server-actions/rest';

@Injectable({providedIn: 'root'})
export class LogoSelectionService {

    constructor(private softwareManagerRestService: SoftwareManagerRestService) {}

    public getLogoImage = (logoObject, logoPropertyName) => {
        const defer = PromiseService.defer();

        const onSuccessGetFileContent = (response: RestResponseSuccess) => {
            defer.resolve(response.data);
        };

        const onFailureGetFileContent = () => {
            defer.resolve(-1);
        };

        if (logoObject[logoPropertyName] && logoObject[logoPropertyName] > -1) {
            this.softwareManagerRestService.getFileContent(onSuccessGetFileContent, onFailureGetFileContent, logoObject[logoPropertyName]);
        } else {
            onFailureGetFileContent();
        }

        return defer.promise;
    };
}
