import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Store} from '@ngxs/store';
import {AcListEvents, AcListState} from './ac-list.state';
import {Observable} from 'rxjs';
import {SessionStorageService} from '../../services/session-storage.service';
import {cloneDeep} from 'lodash';

export interface AcListItem {
    id: number | string;
    data: any;
}

export interface AcListTemplate {
    itemTemplate?: TemplateRef<any>;
    itemTitleTemplate?: TemplateRef<any>;
    itemSubtitleTemplate?: TemplateRef<any>;
    itemContentTemplate?: TemplateRef<any>;
    itemActionsTemplate?: TemplateRef<any>;
}

@UntilDestroy()
@Component({
    selector: 'ac-list',
    templateUrl: './ac-list.component.html',
    styleUrls: ['./ac-list.component.less'],
})
export class AcListComponent {

    @Input() listId = '';
    @Input() itemSize = 80;

    items: AcListItem[];

    @Input('items') set setItems(items: any[]) {
        this.items = items?.map((data) => ({id: data?.id, data})) || [];

        const selection = this.getSelection();
        this.clearUnusedSelectionState(selection);
        this.forceSelectionIfNeeded(selection);
        this.loading = false;
    }

    @Input('selection') set setSelection(selection: number | string) {
        selection && this.selectItem({id: selection});
    }

    @Input() set refresh(refreshProps: any) {
        this.items = !!refreshProps?.showLoader ? null : this.items;
        this.refreshed.emit(refreshProps);
    }

    @Input() clearUnusedSelection = true;
    @Input() forceSelection = false;
    @Input() initialSelectionFirstTimeOnly = false;
    @Input() templates: AcListTemplate;

    @Output() refreshed = new EventEmitter();

    selection$: Observable<any>;
    loading = true;

    constructor(private store: Store) {
    }

    ngOnInit() {
        this.selection$ = this.store.select(AcListState.selection(this.listId));
    }

    clearUnusedSelectionState = (selection) => {
        if (!this.items || this.items.length <= 0) {
            return;
        }
        const selectedItem = selection && this.items?.find((item) => !!selection[item.id]);
        if (!selectedItem) {
            this.dispatch(new AcListEvents.clearSelect(this.listId))
        }
    };

    forceSelectionIfNeeded = (selection) => {
        if (!this.forceSelection || this.items?.length <= 0) {
            return;
        }
        const hasSelection = selection && this.items.find((item) => !!selection[item.id]);
        !hasSelection && this.selectItem(this.items[0]);
    };

    selectItem = ({id}: any) => {
        const newSelection = {[id]: true};
        this.dispatch(new AcListEvents.select(this.listId, newSelection));
    };

    getSelection = () => {
        return this.store.selectSnapshot<AcListState>(AcListState.selection(this.listId));
    };

    trackById = (index, item) => {
        return item.id;
    };

    dispatch = (action) => {
        this.store.dispatch(action);
        SessionStorageService.notify();
    }
}



