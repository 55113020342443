<ac-form *ngIf="dialogConfig.ready"
         [formModel]="teamsDevice"
         [formSchema]="isEdit ? deviceEditSchema : deviceAddSchema"
         [isEdit]="isEdit"
         id="teams-device-dialog-form"
         formModelName="teamsDevice">
    <div id="teams-device-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container formFieldName="teamsDevice.name" label="Name">
                <ac-textfield [(acModel)]="teamsDevice.name"
                              textFieldId="teams-device-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="teamsDevice.description" label="Description">
                <ac-textfield [(acModel)]="teamsDevice.description"
                              textFieldId="teams-device-description">
                </ac-textfield>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="teamsDevice"
                                         [additionalSettings]="additionalSettings"
                                         [isEdit]="isEdit"
                                         [ngDisabledTenant]="isEdit"
                                         [ngDisabledRegion]="isEdit"
                                         [regionFormFieldName]="'teamsDevice.regionId'"
                                         [horizontalView]="true">
        </network-tenant-region-selection>

        <ac-input-container formFieldName="teamsDevice.teamsInfo.msTenantId" label="Azure Tenant ID">
            <ac-textfield [acModel]="teamsDevice.teamsInfo.msTenantId || additionalSettings.teamsInfo.msTenantId"
                          (acModelChange)="teamsDevice.teamsInfo.msTenantId = $event"
                          [ngDisabled]="isEdit"
                          textFieldId="teams-device-ms-tenant-id">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container formFieldName="teamsDevice.teamsInfo.clientId" label="Client ID">
            <ac-textfield [(acModel)]="teamsDevice.teamsInfo.clientId"
                          textFieldId="teams-device-client-id">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container formFieldName="teamsDevice.teamsInfo.clientSecret" editModePrefix="Change" label="Client Secret">
            <ac-password [(acModel)]="teamsDevice.teamsInfo.clientSecret"
                         passwordId="teams-device-client-secret">
            </ac-password>
        </ac-input-container>

        <ac-input-container formFieldName="teamsDevice.location" label="Address">
            <ac-geo-location (acChanged)="teamsDevice.location=$event"
                             [acModel]="teamsDevice.location"></ac-geo-location>
        </ac-input-container>
    </div>
</ac-form>
