import {Component, EventEmitter, Input, Optional, Output, ViewChild} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ValidatorsService} from "../../services/utilities/validators.service";

@Component({
    selector: 'ac-textarea',
    templateUrl: './ac-textarea.component.html',
    styleUrls: ['./ac-textarea.component.less'],
})
export class AcTextareaComponent {
    @Input() acModel: string;
    @Output() acModelChange = new EventEmitter<string>();

    @Input() textAreaId: string;
    @Input() maxLength: number;
    @Input() ngDisabled = false;
    @Input() placeHolderText: string;
    @Input() visibleRows: number;
    @ViewChild(MatFormFieldControl) matFormFieldControl;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public validatorsService: ValidatorsService,
                @Optional() public acFormComponent: AcFormComponent) {}

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    ngAfterViewInit(){
        this.textAreaId = this.textAreaId || (this.acInputContainerComponent?.acInputContainerId + '-textarea');
        this.acInputContainerComponent?.initializeMaterialField(this.matFormFieldControl);
    }

    textAreaChanged(text) {
        this.acModelChange.emit(text);
    }
}
