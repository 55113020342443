<network-selection type="TOPOLOGY"
                   *ngIf="acFilterComponent.filterDropDown?.isOpen && !oneLiveMode && (!pageName || (pageName && pageName !== 'endpointGroup'))"
                   [sortItems]="true"
                   [model]="topologyGroups.value"
                   (acChanged)="topologyGroups.value = $event; emitFilterChange()"
                   formFieldName="topologyGroups.value">
</network-selection>

<network-selection type="ENDPOINT"
                   *ngIf="acFilterComponent.filterDropDown?.isOpen && !oneLiveMode && (pageName && ['topology', 'endpointGroup', 'endpoint'].includes(pageName))"
                   [sortItems]="true"
                   [model]="endpointsGroups.value"
                   (acChanged)="endpointsGroups.value = $event; emitFilterChange()"
                   formFieldName="endpointsGroups.value">
</network-selection>

<network-selection type="LC_CUSTOMER" *ngIf="acFilterComponent.filterDropDown?.isOpen && oneLiveMode"
                   [sortItems]="true"
                   [model]="lcCustomers.value"
                   (acChanged)="lcCustomers.value = $event; emitFilterChange()"
                   formFieldName="lcCustomers.value">
</network-selection>

<network-selection type="SERVICE" *ngIf="acFilterComponent.filterDropDown?.isOpen && oneLiveMode"
                   [sortItems]="true"
                   [model]="serviceGroups.value"
                   (acChanged)="serviceGroups.value = $event; emitFilterChange()"
                   formFieldName="serviceGroups.value">
</network-selection>

<network-selection type="SITE_LOCATION" *ngIf="acFilterComponent.filterDropDown?.isOpen && oneLiveMode"
                   [sortItems]="true"
                   [model]="siteLocations.value"
                   (acChanged)="siteLocations.value = $event; emitFilterChange()"
                   formFieldName="siteLocations.value">
</network-selection>


