<div class="flexRowParentEqualChildren oc-menu-container">
    <ac-layout-panel class="fullContainer oc-tree-panel" *ngIf="showTree">
        <ac-tree-navigation #navigationTree
                            [formSettings]="tenantFormSettings"
                            disableUpperCase="true"
                            (activeChange)="onOCTypeNavigationTreeSelection($event);changeHappened()"
                            (initialized)="onInitialize()"
                            [treeNodes]="treeData"
                            [errors]="_errors"
                            [doSort]="false"
                            [class.disabled]="!authorized"
                            class="navigation-tree"
                            displayField="text"
                            treeId="oc-navigation-tree"
        ></ac-tree-navigation>
    </ac-layout-panel>

    <ac-layout-panel class="fullContainer oc-type-panel">
        <div class="flexColumnParent">
            <div class="flexItem" class="add-button">
                <ac-button *ngIf="authorized" class="square-small"
                           [ngDisabled]="disableAddButton()"
                           (acClick)="addItem()"
                ><ac-svg width="24px" height="24px" name="add" fillColor="#fff"></ac-svg>
                </ac-button>
            </div>

            <div class="flexItemBiggest flexColumnParent scroll-container">
                <ng-container *ngIf="model && model.services.OC.info?.operatorContacts?.length > 0 && selectedOCType.type === 'operatorContacts'">
                    <div *ngFor="let contact of model.services.OC.info.operatorContacts; let index=index" class="contact-container">
                        <operator-connect-contact [contactObject]="contact"
                                                  [removeButton]="removeButton"
                                                  [formSettings]="tenantFormSettings"
                                                  [errors]="_errors"
                                                  [operatorContactTypesList]="operatorContactsList"
                                                  (operatorContactTypeWasSelected)="getUpdatedListOfItems('operatorContacts')"
                                                  [authorized]="authorized"
                                                  [index]="index">
                        </operator-connect-contact>
                    </div>
                </ng-container>

                <ng-container *ngIf="model && model.services.OC.info?.offers?.length > 0 && selectedOCType.type === 'offers'">
                    <div *ngFor="let offer of model.services.OC.info.offers; let index=index" class="contact-container">
                        <operator-connect-offer [offerObject]="offer" [index]="index"
                                                [offersTypesList]="offersList"
                                                [removeButton]="removeButton"
                                                [formSettings]="tenantFormSettings"
                                                [errors]="_errors"
                                                (offerTypeWasSelected)="getUpdatedListOfItems('offers')">
                        </operator-connect-offer>
                    </div>
                </ng-container>

                <ng-container *ngIf="model && model.services.OC.info?.webhook && selectedOCType?.type === 'webhook'">
                    <operator-connect-webhook [parentObject]="model.services.OC.info"
                                              [authorized]="authorized"
                                              [removeButtonTemplate]="removeButton"
                                              [formSettings]="tenantFormSettings"
                                              [errors]="_errors"
                                              [calculateItemsInTreeNode]="calculateItemsInTreeNode">
                    </operator-connect-webhook>
                </ng-container>

                <ng-container *ngIf="model && model.services.OC.info?.trustedProviderIssuers?.length > 0 && selectedOCType.type === 'trustedProviderIssuers'">
                    <div *ngFor="let issuer of model.services.OC.info.trustedProviderIssuers; let index=index;trackBy: byIndex" class="contact-container">
                        <operator-trusted-provider-issuer [parentObject]="model.services.OC.info"
                                                          [index]="index"
                                                          [removeButtonTemplate]="removeButton"
                                                          [formSettings]="tenantFormSettings"
                                                          [errors]="_errors">
                        </operator-trusted-provider-issuer>
                    </div>
                </ng-container>
            </div>

            <div class="flexItem">
                <ac-input-container [hidden]="selectedOCType?.type !== 'offers'"
                                    formFieldName="tenant.services.OC.info.offersError" class="flexItem"
                                    [sideMargin]="false"
                                    [upperPadding]="false"
                                    [displayOnlyError]="true">
                    <div></div>
                </ac-input-container>
            </div>
        </div>


    </ac-layout-panel>
</div>


<ng-template #removeButton let-object="object">
    <ac-button *ngIf="authorized"
               buttonType="icon-button"
               class="remove-oc-element-button"
               (mousedown)="$event.stopPropagation()"
               (click)="$event.stopPropagation()"
               (acClick)="removeItem(object.index, object.type);['operatorContacts', 'offers'].includes(object.type) && getUpdatedListOfItems(object.type)">
        <i class="fa fa-trash"></i>
    </ac-button>
</ng-template>
