<div class="ac-form-group-container {{size}}" [class.isClickable]="isClickable" [class.borderMode]="borderMode" [class.subTitleMode]="subTitleMode">
    <div class="title-container" [class.title-background]="titleBackground">
        <ng-container *ngIf="titles">
            <div  *ngFor="let title of titles; let last = last" class="title-box" (click)="acClick.emit(); titleClick.emit(title);">
                <div class="flexRowParent">
                    <span class="text" [id]="title?.text | acFunc: generalService.generateIdForText : '-form-group-text'">
                        {{title.text + (title.value !== undefined ? ':  ' : '')}}
                    </span>
                    <span *ngIf="title.value !== undefined" [innerHTML]="title.value | numberToK"
                          [id]="title?.text | acFunc: generalService.generateIdForText : '-form-group-value-' + title.value"
                          class="value">
                    </span>
                </div>
            </div>
        </ng-container>

        <div *ngIf="titleText" (click)="acClick.emit()" class="title-box">
            <div [id]="titleText | acFunc: generalService.generateIdForText : '-form-group-title'">{{titleText}}</div>
        </div>
    </div>

    <div class="content-container" [class.noSideGap]="noSideGap" [class.flexRowParent]="!contentAsColumn" [class.flexColumnParent]="contentAsColumn">
        <ng-content></ng-content>
    </div>
</div>
